a {
  outline: none;
  -webkit-text-decoration: none;
  text-decoration: none;
}
a,
a:active,
a:hover,
a:visited {
  color: inherit;
}
.mk-card {
  background: #fff;
  border: 1px solid #e9ebec;
  border-radius: 16px;
  position: relative;
  width: 230px;
}
.mk-card__top {
  background: #e9ebec;
  border-bottom: 1px solid #e9ebec;
  border-radius: 14px 14px 0 0;
  overflow: hidden;
  padding-top: 90%;
  position: relative;
}
.mk-card__top .link {
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.mk-card__top .link .hover-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: none;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.mk-card__top .link .hover-overlay > * {
  color: #fff;
}
.mk-card__top .link .hover-overlay .overlay-cta {
  align-items: center;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 20px;
  padding: 8px 16px;
}
.mk-card__top .link .hover-overlay .overlay-cta svg {
  color: #fff;
  margin-right: 8px;
}
.mk-card__top .link .hover-overlay .overlay-cta:focus,
.mk-card__top .link .hover-overlay .overlay-cta:hover {
  background-color: #fff;
  color: #000;
}
.mk-card__top .link .hover-overlay .overlay-cta:focus svg,
.mk-card__top .link .hover-overlay .overlay-cta:hover svg {
  color: #000;
}
.mk-card__top
  .link
  .hover-overlay:not(.hover-overlay--hovering-share)
  .overlay-cta.open-cta {
  background-color: #fff;
  color: #000;
}
.mk-card__top
  .link
  .hover-overlay:not(.hover-overlay--hovering-share)
  .overlay-cta.open-cta
  svg {
  color: #000;
}
.mk-card__top
  .link:focus
  .hover-overlay:not(.hover-overlay--hovering-share)
  .overlay-cta.open-cta {
  background-color: #fff;
  color: #000;
}
.mk-card__top
  .link:focus
  .hover-overlay:not(.hover-overlay--hovering-share)
  .overlay-cta.open-cta
  svg {
  color: #000;
}
.pd25 {
  padding-top: 25% !important;
}
.mk-card__bottom {
  background: #fff;
  border-radius: 0 0 16px 16px;
  padding: 18px 20px 16px;
}
.mk-card__bottom .entity-name {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.mk-card__bottom .entity-name,
.mk-card__bottom .entity-name .mk-inline-edit__display .value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 190px;
  text-align: start;
}
.mk-card__icons {
  display: none;
  flex-direction: row;
  gap: 24px;
  height: 22px;
}
.mk-icon-with-text {
  align-items: center;
  display: flex;
}
.mk-icon-with-text__icon {
  color: #000;
  display: inline-flex;
}
.mk-icon-with-text__text {
  color: #000;
  font-size: 13px;
  line-height: 15px;
  margin-left: 6px;
}
.icon path {
  stroke-width: 1.2;
  vector-effect: non-scaling-stroke;
}
.icon:not([data-style]) path {
  stroke: currentcolor;
}
.mk-inline-edit .mk-inline-edit__display {
  display: flex;
  font-size: inherit;
  font-weight: inherit;
}
.mk-inline-edit .mk-inline-edit__display .value {
  border-bottom: 1px solid transparent;
  float: left;
}
.mk-inline-edit:not(.mk-inline-edit--readonly) .mk-inline-edit__display {
  cursor: pointer;
}
.thumbnail-container {
  height: 100%;
  position: relative;
}
.thumbnail-container.thumbnail-loaded .thumbnail {
  -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
  display: block;
}
.thumbnail {
  height: 80%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}
.thumbnail-loaded .image-thumbnail {
  -webkit-transform: translate(-50%, -50%) rotate(5deg);
  transform: translate(-50%, -50%) rotate(5deg);
}
.image-thumbnail-img {
  background: #fff;
  padding: 10px;
}
.thumbnail-border {
  background: #fff;
  height: 80%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}
.image-loader-img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center top;
  object-position: center top;
  width: 100%;
}
.project-card:focus,
.project-card:hover {
  border: 1px solid #636567;
}
.project-card:focus.project-card-ready .thumbnail,
.project-card:focus.project-card-ready .thumbnail-border,
.project-card:hover.project-card-ready .thumbnail,
.project-card:hover.project-card-ready .thumbnail-border {
  -webkit-filter: blur(10px);
  filter: blur(10px);
  -webkit-transform: translate(0), scale(1.15);
  transform: translate(0), scale(1.15);
}
.project-card:not(.image-project-error):focus .link .hover-overlay,
.project-card:not(.image-project-error):hover .link .hover-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.project-card:not(.image-project-error):focus .mk-card__icons,
.project-card:not(.image-project-error):hover .mk-card__icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.project-card .mk-card__icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
}
.project-card .mk-card__bottom {
  padding: 20px 16px 16px 20px;
}
.project-card .mk-card__bottom .project-name {
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.project-card__icon {
  color: #979a9b;
}
.hover-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.bsnone {
  box-shadow: none !important;
}

/* pdf style already have */
div,
span {
  border: 0;
  cursor: inherit;
  font: inherit;
  letter-spacing: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  vertical-align: inherit;
}
div,
span {
  border: 0;
  cursor: inherit;
  font: inherit;
  letter-spacing: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  vertical-align: inherit;
}
div,
span {
  border: 0;
  cursor: inherit;
  font: inherit;
  letter-spacing: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  vertical-align: inherit;
}
div,
span {
  border: 0;
  cursor: inherit;
  font: inherit;
  letter-spacing: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  vertical-align: inherit;
}
div,
span {
  border: 0;
  cursor: inherit;
  font: inherit;
  letter-spacing: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  vertical-align: inherit;
}
* {
  box-sizing: inherit;
  font-family: inherit;
  font-size: inherit;
}
:before {
  box-sizing: initial !important;
}
div {
  display: block;
}
svg:not(:root) {
  overflow: hidden;
}

* {
  word-wrap: break-word;
  box-sizing: border-box;
}
div {
  margin: 0;
  outline: 0;
  padding: 0;
}
:before {
  box-sizing: initial !important;
}
.pdfinner {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
.pdfclass {
  font-size: 14px;
  border: 1px solid rgb(224, 225, 228);
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 10px;
  background: white;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
@media all {
  .pdf_svg__st8 {
    fill: #fefdfd;
  }
  .pdf_svg__st11 {
    fill: #fefcfc;
  }
  .pdf_svg__st13 {
    fill: #2b2b2b;
  }
}

.pdf-file-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}

.thumbnail-pdf {
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}
.thumbnail-border-pdf {
  background: #fff;
  height: 96%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.thumbnail-pdf {
  background: #fff;
  height: 88%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
}
