.menu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: inherit;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.menuList,
.menuSublist {
  list-style: none;
  margin: 0;
}
.menuList {
  padding: 16px 0;
}
.menuListBottom {
  position: sticky;
  bottom: 0;
  background-color: inherit;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.menuListSticky {
  box-shadow: 0 -5px 25px rgba(0, 0, 0, 0.15);
}
.menuItem {
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.menuItem > .menuList {
  padding: 4px 0;
}
.menuItem > .menuList .menuItem {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
:global(.login_as) .menuItem > .menuList .menuItem {
  color: #b99ba5;
}
.menuItem > .menuList .itemLink.isActive .itemLinkContent {
  font-weight: 600;
  background-color: transparent;
}
.menuItem > .menuList .itemLink.isActive .itemLinkContent:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
:global(.login_as)
  .menuItem
  > .menuList
  .itemLink.isActive
  .itemLinkContent:hover {
  background-color: #451a29;
}
.menuItem > .menuList .itemLink.isActive .itemLinkContent .itemIcon {
  opacity: 1;
}
.menuItem > .menuList .itemLinkContent {
  border-radius: 24px;
  padding: 4px calc(9.375% + 2px - 10px);
}
.menuItem > .menuList .itemIcon {
  color: currentColor;
  opacity: 0.25;
}
.itemLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  color: #c6c6c6 !important;
}
.itemLink,
.itemLink:hover,
.itemLink:active,
.itemLink:focus {
  color: currentColor;
  text-decoration: none;
}
.itemLink:hover:not(.isActive) .itemLinkContent {
  background-color: rgba(255, 255, 255, 0.05);
}
.itemLink.isActive .itemLinkContent {
  color: #ffffff;
  background-color: #303030;
}
:global(.login_as) .itemLink.isActive .itemLinkContent,
:global(.login_as) .itemLink:hover:not(.isActive) .itemLinkContent {
  color: #e7c2ce;
  background-color: #451a29;
}
.itemLinkContent {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px calc(9.375% + 2px - 10px);
  border-radius: 32px;
}
.itemIcon {
  width: 16px;
  height: 16px;
  margin-right: 11px;
  flex-shrink: 0;
  position: relative;
}
.itemIcon.itemIconWithCount.single svg {
  -webkit-clip-path: path("M0 16H16V9C11.0294 9 7 4.97056 7 0H0V16Z");
  clip-path: path("M0 16H16V9C11.0294 9 7 4.97056 7 0H0V16Z");
}
.itemIcon.itemIconWithCount.single .count {
  text-indent: 1px;
}
.itemIcon.itemIconWithCount.double svg {
  -webkit-clip-path: path("M0 16H16V9H9C4.02944 9 0 4.97056 0 0V16Z");
  clip-path: path("M0 16H16V9H9C4.02944 9 0 4.97056 0 0V16Z");
}
.itemIcon.itemIconWithCount.double .count {
  width: 23px;
}
.itemIcon.itemIconWithCount.triple svg {
  -webkit-clip-path: path("M15.9999 9H-1.09673e-05L-6.10352e-05 16H15.9999V9Z");
  clip-path: path("M15.9999 9H-1.09673e-05L-6.10352e-05 16H15.9999V9Z");
}
.itemBadge {
  margin-left: auto;
  line-height: 0;
}
.collapseText {
  opacity: 0.5;
}
.collapseIcon,
.itemExternalIcon {
  display: flex;
  color: #5e5e5e;
  margin-left: auto;
}
.collapseIcon svg,
.itemExternalIcon svg {
  width: 14px;
  height: 14px;
}
:global(.login_as) .collapseIcon,
:global(.login_as) .itemExternalIcon {
  color: #e7c2ce;
  opacity: 0.5;
}
.itemPopover {
  width: 220px;
}
.itemPopover .menuList {
  padding: 0 0 4px;
}
.itemPopover .menuItem {
  font-weight: 400;
}
.itemPopover .menuItem:hover {
  background-color: #f1f1f1;
}
.itemPopover .itemIcon {
  display: none;
}
.itemPopover .itemLink {
  padding: 4px 16px;
  line-height: 1.42857143;
}
.itemPopover .itemLink:hover:not(.isActive) .itemLinkContent,
.itemPopover .itemLink.isActive .itemLinkContent {
  color: currentColor;
  background-color: transparent;
}
.itemPopover .itemLinkContent {
  padding: 0;
}
.itemPopover .itemSubtitle {
  margin: 0;
  padding: 10px 16px 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14285714;
}
@media (max-width: 768px) {
  .menuCollapse {
    display: none;
  }
}
@media (min-width: 768px) {
  :global(.is-collapsed) .itemIcon {
    margin: 0;
    flex-shrink: initial;
  }
  :global(.is-collapsed) .collapseIcon {
    margin: 1px 0;
  }
  :global(.is-collapsed) .itemLinkContent {
    justify-content: center;
    padding: 8px 9.375%;
  }
  :global(.is-collapsed) .itemText,
  :global(.is-collapsed) .itemExternalIcon,
  :global(.is-collapsed) .collapseText {
    display: none;
  }
  :global(.is-collapsed) .collapseIcon {
    transform: scaleX(-1);
  }
}
.count {
  color: #ffffff;
  background-color: #3366ff;
  padding: 2px 4px 0 4px;
  min-width: 16px;
  height: 16px;
  display: block;
  text-align: center;
  font-size: 9px;
  line-height: 12px;
  font-weight: bold;
  border-radius: 8px;
  position: absolute;
  top: -8px;
  right: -8px;
  box-sizing: border-box;
}
