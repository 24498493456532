.TimeInput_menu {
  margin: 0;
  height: 252px;
  overflow: auto;
}
.SelectItem {
  position: relative;
  padding: 4px 8px 4px 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.42857143;
}

.SelectItem:not(.components_Item_disabled):hover {
  background-color: #f2f2f2;
}

.SelectItem.components_Item_disabled:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.1);
}
.components_Menu_isScrollable {
  overflow-y: auto;
}
.Select__list {
  list-style-type: none;
  margin: 4px 0;
}
.components_Item_selected svg {
  position: absolute;
  top: 6px;
  left: 12px;
  width: 16px;
  color: var(--primary-color);
}
