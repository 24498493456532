.popover {
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid #e3e8ee;
  box-shadow: 0 5px 10px rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  color: #262e39 !important;
  line-height: 20px !important;
  background-color: white !important;
  /* max-height: 70vh !important; */
  position: relative;
  z-index: 2;
}

.hasManualHeight {
  height: 100%;
  max-height: none;
}

.arrow {
  position: absolute;
  width: 12px;
  height: 12px;
}

[data-placement^="bottom"] > .arrow {
  top: 0;
}

.arrow::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border: 1px solid #e3e8ee;
  background-color: white;
  background-clip: padding-box;
}

.arrow.hasHeader::before {
  background-color: #f2f2f2;
}

[data-placement^="top"] > .arrow {
  bottom: 0;
}

[data-placement^="top"] > .arrow::before {
  bottom: -6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
}

[data-placement^="bottom"] > .arrow {
  top: 0;
}

[data-placement^="bottom"] > .arrow:before {
  top: -6px;
  border-right-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

[data-placement^="left"] > .arrow {
  right: 0;
}
[data-placement^="left"] > .arrow.hasManualPlacement {
  right: 16px;
}

[data-placement^="left"] > .arrow::before {
  right: -6px;
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

[data-placement^="right"] > .arrow {
  left: 0;
}
[data-placement^="right"] > .arrow::before {
  left: -6px;
  border-top-color: rgba(0, 0, 0, 0);
  border-right-color: rgba(0, 0, 0, 0);
}

@media (min-width: 768px) {
  .arrow.hasManualPlacement {
    transform: none !important;
  }
  [data-placement$="start"] > .arrow.hasManualPlacement {
    right: unset !important;
    left: 16px !important;
  }
  [data-placement$="end"] > .arrow.hasManualPlacement {
    left: unset !important;
    right: 16px !important;
  }
}

.scrollContainer {
  position: relative;
  z-index: 2;
  overflow-y: auto;
}

.contentWithPadding {
  padding: 8px 14px;
}

.popoverHeader,
.popoverFooter {
  width: -webkit-min-content;
  width: min-content;
  min-width: 100%;
  position: relative;
  box-sizing: border-box;
  z-index: 2;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f2f2f2;
}

.popoverHeader {
  border-bottom: 1px solid #e3e8ee;
}

.popoverFooter {
  border-top: 1px solid #e3e8ee;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 102;
}

.scrollContainer {
  position: relative;
  z-index: 2;
  overflow-y: auto;
}

.scrollContainer .Button {
  font-weight: 500 !important;
}

@media (max-width: 1440px) {
  .popover {
    border-radius: 4px;
    left: -10px;
  }
}

.ProfilePopover {
  padding: 12px 6px !important;
  margin-left: 0 !important;
  min-width: auto !important;
}

.ProfilePopover__link {
  font-weight: 500 !important;
}

@media (max-width: 600px) {
  .popover {
    left: -1px;
  }
}
