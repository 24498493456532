/* Comment box */
.text--_fontBase--QdLsd {
  font-family: var(--inter-font-family, "Inter"), sans-serif;
}
.text--fontPos11--2LvXf {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: calc(0.005px + var(--text-tracking-pos, 0) * 11px);
}
.text--fontPos13--xW8hS {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: calc(-0.003px + var(--text-tracking-pos, 0) * 13px);
}
.overflow--momentumScroll--qtsu7 {
  -webkit-overflow-scrolling: touch;
}
.overflow--overflowYAuto--nfK38 {
  overflow-y: auto;
}
.raw_components--_iconButton---ybo6 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
  grid-column-end: span 4;
}
.raw_components--iconButtonEnabled--WmVk5:hover {
  background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.06));
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  border-radius: 3px;
}
.raw_components--transparentIconButtonEnabled--l2bm3:hover {
  background-color: transparent;
}
.raw_components--iconButtonDisabled--SoTjK {
  fill: var(--color-icon-disabled);
  color: var(--color-icon-disabled);
}
.thread_comment_composer--inputSectionWrapper--Bxyab {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  background-color: var(--color-bg-secondary, rgba(0, 0, 0, 0.06));
  border-radius: 6px;
  margin: 8px 16px 16px 8px;
  min-height: 96px;
  max-height: 100%;
  outline: 0px solid transparent;
  transition: min-height 0.1s, outline 0.2 ease-in-out;
}
.thread_comment_composer--inputSectionWrapper--Bxyab.thread_comment_composer--newComment--eYLj7 {
  background-color: var(--color-bg, white);
  box-shadow: var(--elevation-200-canvas);
  margin: 0;
}
.thread_comment_composer--inputSectionWrapper--Bxyab.thread_comment_composer--composerEmpty--VgMY7 {
  min-height: 40px;
  flex-direction: row;
  align-items: center;
}
.thread_comment_composer--editableTypeahead--9R6Yd {
  flex-grow: 1;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 8px 16px;
  transition: padding-bottom 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:first-child {
  fill: var(--color-bg-brand, #0d99ff) !important;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:not(:first-child) {
  fill: var(--color-icon-onbrand, white) !important;
}
.thread_comment_composer--submitUpArrowIconDisabled--iPBY3 path:first-child {
  fill: var(--color-bg-disabled, rgba(0, 0, 0, 0.3)) !important;
}
.thread_comment_composer--submitUpArrowIconDisabled--iPBY3
  path:not(:first-child) {
  fill: var(--color-icon-ondisabled, #fff) !important;
}
.thread_comment_composer--actions--7LRnN {
  height: 40px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  border-top: 1px solid var(--color-border, rgba(0, 0, 0, 0.05));
}
.thread_comment_composer--composerEmpty--VgMY7
  .thread_comment_composer--actions--7LRnN {
  border: unset;
}
.editable_typeahead--wrapperBase--rwdvD {
  cursor: text;
}
.public-DraftEditorPlaceholder-root {
  color: var(--color-text-tertiary, #b3b3b3);
  position: absolute;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
}
.public-DraftEditorPlaceholder-root {
  pointer-events: none;
}
.wiggle--wiggleContainer--oGy1O {
  height: 100%;
  width: 100%;
}
.viewport_draggable--container---XH89- {
  /* position: fixed; */
  display: flex;
  /* flex: 0; */
  /* overflow: auto; */
}
.new_comment_container--translatedThreadContainer--2xkOH {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: auto;
}
.new_comment_container--threadContainer--XIsX {
  position: absolute;
  left: 0;
  top: 0;
  width: 254px;
  border-radius: 3px;
  box-shadow: var(--elevation-200-canvas, 0 0 0 0.5px rgba(0, 0, 0, 0.2));
  /* animation-name: new_comment_container--comments-fade-in--o49tI; */
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  cursor: default;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  pointer-events: auto;
}

div,
span,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: inherit;
}
button {
  background-color: var(--color-bg, #fff);
}
/* svg {
  display: block;
} */
.svg-container path:not([fill="none"]) {
  fill: inherit;
}
.fullscreen_view--fullscreenBody--w5jxN *::selection {
  background-color: var(--color-texthighlight, #0d99ff);
}
@keyframes new_comment_container--comments-fade-in--o49tI {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
/* Comment box end */

/* comment box expand */
.text--_fontBase--QdLsd {
  font-family: var(--inter-font-family, "Inter"), sans-serif;
}
.text--fontPos11--2LvXf {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: calc(0.005px + var(--text-tracking-pos, 0) * 11px);
}
.text--fontPos13--xW8hS {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: calc(-0.003px + var(--text-tracking-pos, 0) * 13px);
}
.overflow--momentumScroll--qtsu7 {
  -webkit-overflow-scrolling: touch;
}
.overflow--overflowYAuto--nfK38 {
  overflow-y: auto;
}
.raw_components--_iconButton---ybo6 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
  grid-column-end: span 4;
}
.raw_components--iconButtonEnabled--WmVk5:hover {
  background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.06));
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  border-radius: 3px;
}
.raw_components--transparentIconButtonEnabled--l2bm3:hover {
  background-color: transparent;
}
.thread_comment_composer--inputSectionWrapper--Bxyab {
  position: relative;
  /* display: block; */
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  background-color: var(--color-bg-secondary, rgba(0, 0, 0, 0.06));
  border-radius: 6px;
  margin: 8px 16px 16px 8px;
  min-height: 96px;
  max-height: 100%;
  outline: 0px solid transparent;
  transition: min-height 0.1s, outline 0.2 ease-in-out;
}
.thread_comment_composer--inputSectionWrapper--Bxyab.thread_comment_composer--newComment--eYLj7 {
  background-color: var(--color-bg, white);
  box-shadow: var(--elevation-200-canvas);
  margin: 0;
}
.thread_comment_composer--editableTypeahead--9R6Yd {
  flex-grow: 1;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 8px 16px;
  transition: padding-bottom 0.1s;
}
.thread_comment_composer--leftComposerButtonGroup--6jQ3I {
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  position: absolute;
  left: 0;
  height: 100%;
  box-sizing: border-box;
  padding: 0 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: height 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:first-child {
  fill: var(--color-bg-brand, #0d99ff);
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:not(:first-child) {
  fill: var(--color-icon-onbrand, white) !important;
}
.thread_comment_composer--actions--7LRnN {
  height: 40px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
}
.file_upload_icon_input--fileUploadIconLabel--GkBse {
  position: relative;
}
.file_upload_icon_input--fileUploadIconLabel--GkBse:hover {
  background-color: var(--color-bg-hover);
}
.file_upload_icon_input--fileInput--qwRav {
  color: transparent;
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.file_upload_icon_input--fileInput--qwRav ::-webkit-file-upload-button {
  visibility: hidden;
}
.editable_typeahead--wrapperBase--rwdvD {
  cursor: text;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
}
.new_comment_container--threadContainer--XIsVX {
  position: absolute;
  left: 0;
  top: 0;
  width: 320px;
  border-radius: 3px;
  box-shadow: var(
    --elevation-200-canvas,
    0px 2px 14px rgba(0, 0, 0, 0.15),
    0 0 0 0.5px rgba(0, 0, 0, 0.2)
  );
  animation-name: new_comment_container--comments-fade-in--o49tI;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  cursor: default;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  pointer-events: auto;
}
.new_comment_container--newCommentContainerNext--bCf7 {
  width: 281px;
  border-radius: 6px;
  /* box-shadow: var(
    --elevation-200-canvas,
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2)
  ); */
  outline: 0px solid transparent;
  /* transition: outline 0.2s ease-in-out; */
  margin-top: 2px;
  margin-left: 5px;
}
div,
span,
label,
input,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: inherit;
}
button {
  background-color: var(--color-bg, #fff);
}
/* svg {
  display: block;
} */
.svg-container path:not([fill="none"]) {
  fill: inherit;
}
.svg-container path:not([stroke="none"]) {
  stroke: inherit;
}
.fullscreen_view--fullscreenBody--w5jxN *::selection {
  background-color: var(--color-texthighlight, #0d99ff);
}
@keyframes new_comment_container--comments-fade-in--o49tI {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
#fileUploadIconInput-newCommentComposer {
  position: absolute;
  top: 10px;
  left: 44px;
  opacity: 0;
  cursor: pointer;
}
/* comment box expand end */

.attachment--thumbnailButton--CfAkK {
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 8px;
  border: 1px solid var(--color-border, rgba(0, 0, 0, 0.1));
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-secondary);
  cursor: default;
}
.attachment--thumbnailImage--Jx4fa {
  display: block;
  width: 80px !important;
  height: 80px;
  object-fit: cover;
  background-color: #111010;
  border-radius: 8px;
  max-width: 80px !important;
}
.attachment--overlay--dIw1C {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.attachment--pendingThumbnailContainer--iL-xu:hover
  .attachment--lightOverlay--F8qzZ {
  background-color: var(--color-bghovertransparent);
}
.attachment--composerContainer--mP7Kx {
  padding: 0px 0px 0px 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  cursor: text;
}
.attachment--pendingThumbnailContainer--iL-xu {
  padding: 8px 8px 0 0;
  position: relative;
  display: inline-block;
  width: 85px;
}
.attachment--deleteBadge--9aSjm {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  border: 2px solid white;
  background-color: #000;
  cursor: default;
}
.attachment--xIcon--hhlua {
  position: absolute;
  left: 5px;
  top: 5px;
  fill: #fff;
  z-index: 2;
}
.attachment--pendingThumbnailContainer--iL-xu:hover
  .attachment--deleteBadge--9aSjm {
  display: block;
}

.save-button {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
  padding: 0 12px;
  background-color: #3781b8;
  font-weight: 600;
  color: #ffff;
  outline-color: transparent;
  background-clip: border-box;
  width: 61px;
  border-radius: 6px;
}
.cancel-button {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
  padding: 0 12px;
  border: 2px solid #a2a1a1;
  font-weight: 600;
  color: #000;
  outline-color: transparent;
  background-clip: border-box;
  width: 61px;
  border-radius: 6px;
}
.cancel-button:hover {
  border: 2px solid #16598b;
}

.save-button:hover {
  background-color: #16598b;
}
.save-button-empty {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
  padding: 0 12px;
  background-color: #757575;
  font-weight: 600;
  color: #2c2c2c;
  outline-color: transparent;
  background-clip: border-box;
  width: 61px;
  border-radius: 6px;
}
.svg-container-block svg {
  display: block;
}
.image-loader-edit {
  z-index: 9999;
  padding: 15px;
  background: rgb(33 39 40 / 30%);
  border-radius: 8px;
  height: 80px;
  width: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
