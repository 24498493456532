/* Container for Task List */
.task-list {
  width: 80%;
  margin: 0 auto;
}

/* Task Item Styling */
.task-item {
  background-color: #f9f9f9;
  overflow: hidden;
  transition: background-color 0.3s;
}

/* Task Header (Main Content Area) */
.task-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  cursor: pointer;
}

/* Left Section of Task Item */
.task-header .left {
  display: flex;
  align-items: center;
}

.task-header .left h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.task-header .left p {
  margin: 0;
  font-size: 12px;
  color: #999;
}

/* Right Section of Task Item */
.task-header .right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.task-header .right button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.5s;
}

.task-header .right button.expanded {
  transform: rotate(0deg);
}
.task-header .right button.unexpanded {
  transform: rotate(180deg);
}

.task-header .right .extra-text {
  font-size: 14px;
  color: #999;
}

/* Task Item Details (Shown on Expand) */
.details {
  padding: 15px;
  background-color: #f1f1f1;
  font-size: 14px;
  color: #555;
  border-top: 1px solid #e0e0e0;
}

.task-list-file-name {
  color: #636567;
  display: inline-block;
  font-weight: 500;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thread-counter {
  background: #fff;
  border: 1px #636567;
  border-radius: 0;
  color: #979a9b;
  flex-basis: 18px;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  line-height: 20px;
  margin: 0 0 0 auto;
  padding: 1px 4px 0;
  text-align: center;
}

.accor__item-question {
  animation: openQuestion 0.5s ease-in-out;
}

@keyframes openQuestion {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon path {
  stroke-width: 1.2;
  vector-effect: non-scaling-stroke;
}
.icon:not([data-style]) path {
  stroke: currentcolor;
}

.collapsed {
  width: 0% !important;
  /* display: none; */
}

.thread-sidebar-toggle {
  right: 0;
  position: absolute;
  top: 50%;
  /* -webkit-transform: translateY(-50%);
  transform: translateY(-50%); */
  left: 22%;
  z-index: 999;
  width: 24px;
}
.thread-sidebar-toggle-button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
  align-items: center;
  background: none;
  background-color: #fff;
  border: 1px solid #e9ebec;
  border-left: none;
  border-radius: 0 4px 4px 0;
  -webkit-box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: inherit;
  font-weight: inherit;
  height: 40px;
  justify-content: center;
  outline: none;
  padding: 0;
  pointer-events: auto;
  width: 24px;
}
.thread-sidebar-toggle-button .thread-sidebar-toggle-icon {
  color: #636567;
  left: auto;
  top: auto;
}
.thread-sidebar-toggle-button:hover .thread-sidebar-toggle-icon {
  color: #000;
}

.thread-sidebar-toggle-collapsed {
  right: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0%;
  z-index: 999;
  width: 24px;
}
