.avatar--avatar {
  display: flex;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  overflow: hidden;
  color: #000;
  border-radius: 100%;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  -webkit-user-select: none;
  user-select: none;
}
.avatar--avatar img {
  width: 100%;
}
.avatar--circle {
  overflow: hidden;
  border-radius: 100%;
  background-color: rgb(151, 71, 255);
  color: #fff;
}
.typeahead--typeahead {
  position: absolute;
  z-index: 1;
  bottom: 0;
}
.mentions_typeahead--mentionsTypeahead {
  background-color: #fff;
  border-radius: 6px;
  pointer-events: auto;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 10px 16px rgba(0, 0, 0, 0.12),
    0px 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 2;
}
.mentions_typeahead--commentsMentionsTypeaheadUp {
  margin-bottom: 8px;
}
.mentions_typeahead--mentionsTypeaheadRow {
  display: flex;
  height: 48px;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  color: #000;
}
.mentions_typeahead--mentionsTypeaheadRow:hover {
  color: #fff !important;
  background-color: #0d99ff;
  border-color: #0d99ff;
}
.mentions_typeahead--mentionsTypeaheadRow:hover .mentions_typeahead--email {
  color: #fff;
}
.mentions_typeahead--avatar {
  height: 32px;
  width: 32px;
  margin-right: 10px;
  border-radius: 100%;
}
.mentions_typeahead--profile {
  min-width: 0;
}
.mentions_typeahead--profileItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  cursor: default;

  text-align: start;
  font-weight: 500;
}
.mentions_typeahead--metadata {
  color: #aaa;
  display: flex;
}
.mentions_typeahead--email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
}
div,
span,
img {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: inherit;
}
