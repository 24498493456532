#__coupon_code {
  outline: none;
}

#__coupon_code:active {
  border: none;
}

#__coupon_code:focus {
  border: none;
}

.duration-sub {
  color: rgb(48, 35, 87);
  background-color: rgb(255, 255, 255);
  will-change: background;
}

.p-package-item-wrapper-choose {
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(200deg, #ea552b, #ea552b 50%, #ea552b);
}

.your-plan,
.billing-frequency {
  display: none;
}

#__view_pwd {
  cursor: pointer;
}

#__view_pwd:hover {
  color: #878e96;
}

#__card_element {
  padding: 15px;
  height: 49px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.three-form-field {
  display: flex;
  justify-content: space-between;
}

.three-form-field .form-field {
  width: 30%;
}

#__btn_next,
#__btn_finish_pay {
  width: 100%;
}

/* Modal */
.terms_modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 30px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}

/* width */
.terms_modal ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.terms_modal ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.terms_modal ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.terms_modal ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.terms_modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  outline: none;
  background: none;
  font-size: 24px;
  color: #747474;
  font-weight: bold;
}

.modal__close:hover {
  color: #000;
}

.modal__header {
  margin-bottom: 40px;
}

.modal__container {
  position: relative;
  z-index: 22;
  max-width: 800px;
  min-height: 200px;
  max-height: 90vh;
  top: 4%;
  margin: 10px auto;
  background-color: #fff;
  overflow: auto;
  padding: 30px;
}

.modal__content .text__center {
  text-align: center;
}

.modal__content h3 {
  font-size: 1.125rem;
  line-height: 2em;
  font-weight: 600;
}

.modal__content h4 {
  font-size: 16px;
  line-height: 2em;
  font-weight: 600;
}

.modal__content p {
  font-size: 15px;
  line-height: 2em;
  margin-bottom: 10px;
  color: #737980;
}

.modal__content ul {
  margin-left: 25px;
  margin-bottom: 15px;
}

.modal__content ul li {
  font-size: 15px;
  line-height: 2em;
  list-style: decimal;
  color: #737980;
}

@media screen and (max-width: 991px) {
  .your-plan,
  .billing-frequency {
    display: block;
  }

  .three-form-field {
    display: block;
    justify-content: space-between;
  }

  .three-form-field .form-field {
    width: 100%;
  }

  .co-bottom-wrapper {
    padding: 2em 2em 0px;
  }
}

.p-package-benefits-item.co-benefits-item {
  justify-content: space-between;
}
.incl {
  font-size: 12px;
  color: #2f9e44;
  background-color: #d8f8dd;
  padding: 0 10px;
  line-height: 2.3;
  font-weight: 600;
  border-radius: 4px;
  display: inline-block;
}

.pln {
  font-size: 12px;
  color: #ea552b;
  background-color: #fbefec;
  padding: 2px 10px !important;
  /* font-weight: 600; */
  border-radius: 4px;
  display: inline-block;
}

.popular-tag {
  font-size: 14px;
}
.ca-form-block {
  max-width: 100%;
}
.form-create {
  display: block;
}

.co-bottom-wrapper {
  padding: 2em 2em 100px;
}
.co-grid-right {
  top: -4em;
}

.flx {
  display: flex;
  justify-content: space-between;
}
.fnt32 {
  font-size: 32px !important;
  color: #ea552b !important;
}
.fnt32 span {
  color: #7d7d7d;
  font-size: 14px !important;
}

.vbot {
  vertical-align: bottom;
}
.mgt1 {
  margin-top: 10px;
}
.mgt2 {
  margin-top: 20px;
}
.mgt3 {
  margin-top: 30px;
}
.mgt4 {
  margin-top: 40px;
}
.mgt5 {
  margin-top: 50px;
}
.mgt6 {
  margin-top: 60px;
}
.mgt7 {
  margin-top: 70px;
}
.mgt8 {
  margin-top: 80px;
}
.mgt9 {
  margin-top: 90px;
}
.mgt10 {
  margin-top: 100px;
}
.lstcont {
  color: #000;
  font-size: 15px;
  line-height: 140%;
  font-weight: 400;
  text-align: left;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  user-select: none;
}
.checkbox-group > * {
  margin: 0.5rem 0.5rem;
}

.checkbox-group-legend {
  font-size: 1.5rem;
  font-weight: 700;
  color: #9c9c9c;
  text-align: center;
  line-height: 1.125;
  margin-bottom: 1.25rem;
}

.checkbox-input {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.checkbox-input:checked + .grn.checkbox-tile {
  border-color: #ea552b;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.checkbox-input:checked + span.grn:before {
  transform: scale(1);
  opacity: 1;
  background-color: #40c057;
  border-color: #40c057;
}
.checkbox-input:checked + .checkbox-tile .checkbox-icon,
.checkbox-input:checked + .checkbox-tile .checkbox-label {
  color: #ea552b;
}
/* .checkbox-input:focus + .checkbox-tile {
    border-color: #2260ff;
  } */
.checkbox-input:focus + .checkbox-tile:before {
  transform: scale(1);
  opacity: 1;
}

.grn.checkbox-tile {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: space-between; */
  border-radius: 0.5rem;
  border: 1px solid #cfd4da;
  background-color: #fff;
  transition: 0.15s ease;
  cursor: pointer;
  padding: 15px;
  position: relative;
  width: 100%;
}

.grns .checkbox-tiles {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: space-between; */
  border-radius: 0.5rem;
  border: 1px solid #cfd4da;
  background-color: #fff;
  transition: 0.15s ease;
  cursor: pointer;
  padding: 15px;
  position: relative;
  width: 100%;
}

.grn.checkbox-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #cfd4da;
  background-color: #fff;
  border-radius: 50%;
  top: unset;
  left: unset;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* .checkbox-tile:hover {
    border-color: #2260ff;
  } */
.checkbox-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.checkbox-icon {
  transition: 0.375s ease;
  color: #494949;
}
.checkbox-icon svg {
  width: 3rem;
  height: 3rem;
}

.checkbox-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
}

.rincl {
  text-align: right;
  width: 28%;
}

.ctitle {
  color: #3c4257;
  font-weight: 700;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}

.cdesc {
  color: #3c4257;
  font-size: 14px;
  font-weight: 400;
}

.vishid {
  visibility: hidden;
}

.hrule {
  margin-top: 40px;
  border: 0;
  border-top: 1px solid #ccc;
}
.ckalign {
  vertical-align: top;
  margin-top: 7px;
}

.plansss {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* background: #fff; */
  border-radius: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.plansss .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plansss .plan {
  cursor: pointer;
  width: 32%;
}

.plansss .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plansss .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

/* .plansss .plan .plan-details span {
    margin-bottom: 10px;
    display: block;
    font-size: 20px;
    line-height: 24px;
    color: #252f42;
  } */

.container_ .title {
  font-size: 20px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plansss .plan .plan-details p {
  color: #3c4257;
  font-size: 14px;
  font-weight: 400;
}

.blk {
  color: #000;
  font-weight: 600;
}
.grn {
  color: #39ca86 !important;
}

.plansss .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plansss .plan input[type="radio"]:checked + .plan-content:after {
  /* content: ""; */
  position: absolute;
  height: 8px;
  width: 8px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.plansss .plan input[type="radio"]:checked + .plan-content {
  border: 1px solid #ea552b;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

@media screen and (max-width: 991px) {
  .plansss {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 40px;
  }

  .plansss .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plansss .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plansss .plan input[type="radio"]:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .plansss .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plansss .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plansss .plan input[type="radio"]:checked + .plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plansss .plan .plan-content .plan-details {
    width: 100%;
  }

  .plansss .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

.block2,
.block3,
.btn2,
.btn3,
.discount-box {
  display: none;
}

.ckitems {
  padding-left: 20px;
  width: 100%;
  line-height: 22px;
}

@media screen and (max-width: 767px) {
  .flx,
  .checkbox-tile {
    display: block;
  }
  .flx,
  .checkbox-tiles {
    display: block;
  }
  .ckitems {
    padding-left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .co-bottom-wrapper {
    padding: 2em 1em 100px;
  }
  .co---guarantee {
    padding: 1em 1em;
  }
  .co-sidebar {
    margin-bottom: 20px;
  }
  .plansss {
    padding: 0;
    margin: 0;
  }
}

.arr1,
.arr2 {
  cursor: pointer;
}

.loader {
  border: 1px solid #f3f3f3; /* Light grey */
  border-top: 1px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
