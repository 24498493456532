.pop-title-new-version {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
  color: #1a1f36;
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.Box-divider--light-bottom-1 {
  box-shadow: inset 0 -1px #e3e8ee;
}
.pd16 {
  padding: 16px;
  color: #1a1f36;
  font-size: 15px;
  font-weight: 500;
}
.mgt2 {
  margin-top: 20px !important;
}
.notesbox {
  border-radius: 5px;
  padding: 8px 12px;
  background-color: #fff;
}
.center {
  text-align: center !important;
}
.mgl1 {
  margin-left: 10px;
}
.browbtn {
  display: table;
  text-align: center;
  margin: 0 auto;
}
.Box-hideIfEmpty:empty {
  display: none;
}
.pointer1 {
  pointer-events: auto;
}
.bl-but-new-version {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: rgb(255, 255, 255) !important;
  font-weight: 500;
  display: inline-flex;
  min-height: 28px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #3781b8;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
  position: relative;
  width: 100%;
  align-items: baseline;
  flex-direction: row;
  display: flex;
  transition: background-color 0.24s, box-shadow 0.24s;
}
.bl-but:hover {
  color: rgb(255, 255, 255);
  background-color: #e3532a;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}

.ReactModal__Content--after-open {
  background: transparent !important;
  border: unset !important;
}
.mfp-close {
  top: 8px !important;
  color: #333;
}
.new-version-close {
  height: 20px;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #333;
  font-style: normal;
  font-size: 23px;
  font-family: Arial, Baskerville, monospace;
  cursor: pointer;
  font-weight: 500;
}
.new-version-close:hover {
  height: 20px;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: red;
  font-style: normal;
  font-size: 23px;
  font-family: Arial, Baskerville, monospace;
  cursor: pointer;
  font-weight: 500;
}

.notesdetail {
  color: #c8c9cd;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 20px;
  font-weight: 400;
  font-size: 12px;
  max-width: 355px;
  margin: 0 auto;
}
.file_upload_icon_input--fileInputs--qwRav {
  color: transparent;
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
#fileUploadIconInput-newCommentComposers {
  position: absolute;
  left: -85px;
  opacity: 0;
}
.alert {
  display: none;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
}
.notification {
  position: absolute;
  bottom: 60px;
  z-index: 99999;
  margin: 0 auto;
  left: 42%;
}
