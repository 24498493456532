.svg--autoscale---9awM > svg {
  width: 100%;
  height: 100%;
}
.close_button--closeX--Bc-63 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  cursor: default;
  fill: #000;
  color: #000;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
}
.close_button--closeX--Bc-63:hover {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.close_button--closeX--Bc-63 {
  border: 1px solid transparent;
  outline: none;
}
.close_button--closeX--Bc-63:focus {
  border: 1px solid var(--fg-focus);
  outline: 1px solid var(--fg-focus);
  outline-offset: -2px;
}
.close_button--closeX--Bc-63:focus:not(:focus-visible) {
  border: 1px solid transparent;
  outline: none;
}
.loading_spinner--root--vwYfu {
  transform: translateZ(0);
  transform-origin: 50% 50%;
  display: inline-flex;
  align-content: center;
  justify-content: center;
}
.loading_spinner--root--vwYfu .svg {
  animation: loading_spinner--rotate--pD7uQ 1s linear infinite;
}
.loading_spinner--sizeSmall--CN0gS {
  width: 16px;
  height: 16px;
}
.loading_spinner--svgContainer--KOY5x {
  display: flex;
  justify-content: center;
  align-content: center;
}
.loading_spinner--grayColor--GhzPN .svg {
  fill: #aaa;
  color: #aaa;
}
.loading_spinner--grayColor--GhzPN path {
  fill: #aaa;
}
.raw_components--_iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
  grid-column-end: span 4;
}
.raw_components--iconButtonEnabled:hover {
  background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.06));
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  border-radius: 3px;
}
.button--buttonBase {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-clip: border-box;
  height: 32px;
  max-width: 200px;
  flex-shrink: 0;
  border-radius: 6px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--ui2-button-color-bg);
  color: var(--ui2-button-color-text);
  outline-color: var(--ui2-button-color-border);
  outline-width: 1px;
  outline-style: solid;
  outline-offset: -1px;
  padding: 0 13px;
  cursor: pointer;
}
.button--buttonBase > .button--iconContainer {
  margin-left: -13px;
}
.button--buttonBase.button--withNoChildren {
  border-radius: 2px;
}
.button--buttonBase.button--withNoChildren > .button--iconContainer {
  margin-right: -13px;
}
body:not([data-fpl-version="ui3"]) .button--buttonBase:focus {
  outline-width: 2px;
}
.button--buttonBase:focus:not(:disabled) {
  outline-color: #0d99ff;
}
.button--buttonBase:active:not(:disabled) {
  background-color: var(--ui2-button-color-bg-pressed);
}
.button--buttonBase:disabled {
  background-color: var(--ui2-button-color-bg-disabled);
  color: var(--ui2-button-color-text-disabled);
  outline-color: var(--ui2-button-color-border-disabled);
}
.button--buttonBase:not(:disabled) > .button--iconContainer svg path {
  fill: var(--ui2-button-color-icon);
}
.button--buttonBase:disabled > .button--iconContainer svg path {
  fill: var(--ui2-button-color-icon-disabled);
}
.button--buttonText {
  --ui2-button-color-bg: transparent;
  --ui2-button-color-border: transparent;
  --ui2-button-color-text: var(--color-text, var(--fallback-color-text));
  --ui2-button-color-icon: var(--color-icon, var(--fallback-color-icon));
  --ui2-button-color-bg-pressed: var(
    --color-bg-pressed,
    var(--fallback-color-bg-pressed)
  );
  --ui2-button-color-border-selected: var(
    --color-border-selected,
    var(--fallback-color-border-selected)
  );
  --ui2-button-color-bg-disabled: transparent;
  --ui2-button-color-border-disabled: transparent;
  --ui2-button-color-text-disabled: var(
    --color-text-disabled,
    var(--fallback-color-text-disabled)
  );
  --ui2-button-color-icon-disabled: var(
    --color-icon-disabled,
    var(--fallback-color-icon-disabled)
  );
}
.button--buttonText:hover:not(:disabled) {
  background-color: var(--color-bg-pressed, var(--fallback-color-bg-pressed));
}
.button--buttonHugContents {
  width: auto;
}
.toolbar_styles--enabledButton {
  fill: var(--color-icon-toolbar, var(--fg-toolbar));
  color: var(--color-icon-toolbar, var(--fg-toolbar));
  background-color: transparent;
}
body:not([data-fpl-version="ui3"]) .toolbar_styles--enabledButton:hover {
  fill: var(--color-icon-toolbar-hover, var(--fg-toolbar-hover));
  color: var(--color-icon-toolbar-hover, var(--fg-toolbar-hover));
  background-color: var(--color-bg-toolbar-hover, var(--bg-toolbar-hover));
}
.action--root {
  height: var(--toolbar-height);
  min-width: var(--toolbar-height);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  position: relative;
  padding: 0;
}
.action--root {
  border: 1px solid transparent;
  outline: none;
}
.action--root:focus {
  border: 1px solid var(--fg-focus);
  outline: 1px solid var(--fg-focus);
  outline-offset: -2px;
}
.action--root:focus:not(:focus-visible) {
  border: 1px solid transparent;
  outline: none;
}
.chevron--chevronContainer {
  padding-top: 0;
  width: 18px;
  flex: 0 0 auto;
  fill: var(--fg-toolbar-chevron);
  display: flex;
}
.chevron--chevronContainer .chevron--svgChevron {
  transition: padding-top 0.15s;
  margin: auto;
  padding-top: 0;
}
.chevron--chevronContainer:hover .chevron--svgChevron {
  padding-top: 6px;
}
.zoom_menu--zoomMenuBase {
  --zoom-menu-border-color: var(--color-border-toolbar, rgba(0, 0, 0, 0.1));
  --zoom-menu-text-color: var(--color-text-toolbar);
  --zoom-menu-text-active-color: var(--color-text-toolbar-selected);
  --zoom-menu-text-hover-color: var(--color-text-toolbar-hover);
  --zoom-menu-fill-color: var(--color-icon-toolbar, var(--fg-toolbar));
  --zoom-menu-fill-hover-color: var(
    --color-icon-toolbar-hover,
    var(--fg-toolbar-hover)
  );
  --zoom-menu-fill-active-color: var(
    --color-icon-toolbar-onselected,
    var(--fg-toolbar-active)
  );
  --zoom-menu-bg-hover-color: var(
    --color-bg-toolbar-hover,
    var(--bg-toolbar-hover)
  );
  --zoom-menu-bg-active-color: var(
    --color-bg-toolbar-selected,
    var(--bg-toolbar-active)
  );
}
.zoom_menu--zoomAmount {
  display: flex;
  height: 100%;
  align-items: center;
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  color: var(--zoom-menu-text-color);
  font-size: 12px;
}
.zoom_menu--chevronContainer {
  height: 100%;
  display: flex;
}
.zoom_menu--zoomControls {
  border: 1px solid var(--zoom-menu-border-color);
  box-sizing: border-box;
  border-radius: 6px;
  width: 126px;
  height: 32px;
  display: flex;
  margin: 8px;
  color: #000;
  overflow: hidden;
}
.zoom_menu--middleZoomButton {
  width: 63px;
  min-width: auto;
  height: auto;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}
.zoom_menu--zoomMiddleChevronContainer {
  padding-right: 0;
  width: auto;
}
.zoom_menu--zoomMiddleChevronSVGContainer {
  margin-right: 0 !important;
  margin-left: 6px !important;
}
.zoom_menu--zoomMinus,
.zoom_menu--zoomPlus {
  height: 30px;
  width: 30px;
  display: flex;
  margin: auto;
  align-items: center;
  border: none;
  border-radius: 0 !important;
  fill: var(--zoom-menu-fill-color);
}
.zoom_menu--zoomMinus.zoom_menu--enabled:hover,
.zoom_menu--zoomPlus.zoom_menu--enabled:hover {
  fill: var(--zoom-menu-fill-hover-color);
  color: var(--zoom-menu-text-hover-color);
  background-color: #f5f5f5;
}
.zoom_menu--zoomMinus.zoom_menu--enabled:active,
.zoom_menu--zoomPlus.zoom_menu--enabled:active,
.zoom_menu--zoomMinus.zoom_menu--enabled:focus,
.zoom_menu--zoomPlus.zoom_menu--enabled:focus {
  fill: #fff;
  color: #fff;
  background-color: #0d99ff;
  border-color: transparent;
  box-shadow: none;
}
div.zoom_menu--feedZoomPan .zoom_menu--zoomMenuBase {
  --zoom-menu-border-color: var(--color-border);
  --zoom-menu-text-color: var(--color-text);
  --zoom-menu-text-active-color: var(--color-text-onselected-strong);
  --zoom-menu-text-hover-color: var(--color-text-hover);
  --zoom-menu-fill-color: var(--color-icon);
  --zoom-menu-fill-hover-color: var(--color-icon-hover);
  --zoom-menu-fill-active-color: var(--color-icon-onselected-strong);
  --zoom-menu-bg-hover-color: var(--color-bg-hover);
  --zoom-menu-bg-active-color: var(--color-bg-menu-selected);
}
div.zoom_menu--feedZoomPan .zoom_menu--zoomControls {
  margin: 0;
  border: unset;
}
div.zoom_menu--feedZoomPan .zoom_menu--zoomMinus,
div.zoom_menu--feedZoomPan .zoom_menu--zoomPlus {
  width: 27px;
  height: 31px;
}
div.zoom_menu--feedZoomPan .zoom_menu--middleZoomButton,
div.zoom_menu--feedZoomPan .zoom_menu--middleZoomButton svg {
  color: var(--zoom-menu-text-color);
  fill: var(--zoom-menu-fill-color);
  -webkit-user-select: none;
  user-select: none;
}
div.zoom_menu--feedZoomPan .zoom_menu--middleZoomButton:hover,
div.zoom_menu--feedZoomPan .zoom_menu--middleZoomButton svg:hover {
  background-color: #f5f5f5;
}
.feed_post_detail_modal--contentOverlayPill {
  position: absolute;
  margin: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 5px 12px rgba(0, 0, 0, 0.13),
    0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  background: #ffffff;
  overflow: hidden;
}
.feed_post_zoom_pan--zoomPanContainer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feed_post_zoom_pan--zoomPanInteractionLayer {
  position: absolute;
  inset: 0;
}
.feed_post_zoom_pan--transformedImage {
  display: block;
  -webkit-backface-visibility: hidden;
}
.feed_post_zoom_pan--zoomControls {
  right: 0;
  bottom: 0;
}
.attachment--thumbnailButton {
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 8px;
  border: 1px solid var(--color-border, rgba(0, 0, 0, 0.1));
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-secondary);
  cursor: default;
}
.attachment--selectedThumbnailButton {
  border: 1px solid #e6e6e6;
  outline: 1px solid #e6e6e6;
  outline-offset: -2px;
  background-color: #f5f5f5;
  border-radius: 8px;
}
.attachment--thumbnailImage--Jx4fa {
  display: block;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  background-color: var(--color-bg-secondary);
}
.attachment--overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.attachment_detail_modal--contentSectionContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}
.attachment_detail_modal--headerContainer--fMrIM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid var(--color-border);
}
.attachment_detail_modal--headerTitle--2r0B0 {
  margin: 8px;
  font-weight: 600;
  font-size: 13px;
}
.attachment_detail_modal--headerCloseButton--Ueo20 {
  height: 32px;
  width: 32px;
  border-radius: 2px;
  color: var(--color-icon);
  overflow: hidden;
}
.attachment_detail_modal--headerCloseButton--Ueo20:hover {
  background-color: var(--color-bg-hover);
}
.attachment_detail_modal--selectedContent {
  width: calc(100% - 32px);
  padding: 16px;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
}
.attachment_detail_modal--thumbnailSection {
  display: flex;
  background-color: var(--color-bg);
  align-items: center;
  gap: 8px;
  padding: 16px;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.attachment_detail_modal--thumbnailContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  height: 55px;
  animation: unset;
  flex-direction: row;
  overflow-x: auto;
}
.attachment_detail_modal--thumbnailWrapper {
  width: 54px;
  height: 54px;
}
.attachment_detail_modal--thumbnailWrapper button {
  width: 50px;
  height: 50px;
  margin: 2px;
  box-sizing: border-box;
}

.zoom-close {
  height: 20px;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #333;
  font-style: normal;
  font-size: 30px;
  font-family: Arial, Baskerville, monospace;
  cursor: pointer;
  font-weight: 500;
}
.zoom-close:hover {
  height: 20px;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: red;
  font-style: normal;
  font-size: 30px;
  font-family: Arial, Baskerville, monospace;
  cursor: pointer;
  font-weight: 500;
}
.zoom-In {
  box-shadow: var(
      --elevation-200-canvas,
      0px 0px 0px rgba(0, 0, 0, 0.3),
      0 0 1px rgba(0, 0, 0, 0.05)
    ),
    0 0 0 2.5px var(--color-border-selected, #0d99ff);
}

.button--buttonBase:disabled > .button--iconContainer svg path {
  fill: rgb(0 0 0 / 30%);
}

/* Dropdown.css */
.zoom-dropdown {
  position: relative;
  display: inline-block;
}

.zoom-dropdown-button {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.zoom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 250px;
  padding: 10px 0 10px 0;
  text-align: start;
}

.zoom-dropdown-item {
  padding: 6px 0 6px 20px;
  font-size: 15px;
  color: #fff;
  cursor: default;
}

.zoom-dropdown-item:hover {
  background-color: #0d99ff;
}

.text--_negText {
  color: #fff;
  -webkit-font-smoothing: subpixel-antialiased;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5),
  screen and (min-resolution: 1.5dppx) {
  .text--_negText {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.text--_fontBase {
  font-family: Inter, sans-serif;
}
.text--fontNeg14 {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: calc(-0.006px + var(--text-tracking-pos, 0) * 14px);
}
.ellipsis--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._overlayBase--_overlayBase {
  background-color: #000;
  box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.2), 0px 2px 7px rgba(0, 0, 0, 0.15),
    inset 0 0 0 1px #2a2929, 0 0 0 1px rgba(0, 0, 0, 0.1);
}
@media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 2dppx) {
  ._overlayBase--_overlayBase {
    box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.2), 0px 2px 7px rgba(0, 0, 0, 0.15),
      inset 0 0 0 0.5px #000, 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  }
}
div,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: inherit;
}
.ReactModal__Content--after-open {
  overflow: unset !important;
}
