.page-settings {
  display: flex;
  margin: -20px;
  /* min-height: calc(100% + 20px); */
  background: #fff;
  flex: 1;
  /* adapted from https://css-tricks.com/css3-progress-bars/ */
}
.page-settings h3 {
  margin: 0;
}
/* .page-settings label {
  font-weight: bold;
       font-size: 0.9em;
} */
.page-settings .settings-nav,
.side-set {
  min-width: 140px;
  max-width: 215px;
  width: 40%;
  padding: 24px;
  border-right: 1px solid #ddd;
}
.page-settings .settings-nav {
  background: #fafafa;
}
.page-settings .settings-nav > select {
  width: 100%;
  margin-bottom: 20px;
}
.page-settings .settings-nav-warning-icon {
  margin-right: 26px;
  float: right;
  line-height: inherit;
}
.page-settings .settings-nav-warning-icon svg {
  vertical-align: text-bottom;
}
.page-settings .settings-nav-warning-icon-payment svg {
  fill: #d0011b;
}
.page-settings .settings-nav-warning-icon-usage svg {
  fill: #fcba63;
}
.page-settings .settings-nav a.active .settings-nav-warning-icon svg {
  fill: #fff;
}
.page-settings .settings-content {
  position: relative;
  background: #fff;
  padding: 20px 25px;
  flex: 1;
  min-width: 0;
}

.page-settings .settings-group-option {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
.page-settings .settings-group {
  font-size: 13px;
  margin: 0 -24px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e8ee;
}
.page-settings .settings-group:last-child {
  border-bottom: 0;
}
.page-settings .settings-group h2 {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 8px 0;
  padding: 0 12px 0 24px;
}
.page-settings .settings-group ul {
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
}
.page-settings .settings-group ul li {
  display: block;
  font-size: 14px;
  line-height: 1.14285714;
}
.page-settings .settings-group ul li a {
  display: block;
  padding: 6px 0 6px 24px;
  margin-left: -24px;
  color: #1a1f36;
  font-weight: 300;
}
.page-settings .settings-group ul li a:active,
.page-settings .settings-group ul li a:focus {
  text-decoration: none;
}
.page-settings .settings-group ul li a:hover {
  /* color: #1a1f36; */
  color: var(--primary-color);
  text-decoration: none;
  /* background-color: white; */
}

.page-settings .settings-group ul li a.active {
  margin-right: -1px;
  /* background: var(--primary-color); */
  /* background-color: #ebf0ff; */
  color: var(--primary-color);
}

.page-settings .settings-group1-option {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
.page-settings .settings-group1 {
  font-size: 13px;
  margin: 0 -24px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e8ee;
}
.page-settings .settings-group1 .btn {
  height: 52px;
}
/* .page-settings .settings-group1 .btn:active, 
   .page-settings .settings-group1 .btn:hover {
     box-shadow: none;
     border: none;
     color: #31354A;
     font-weight: 500;
   } */
.page-settings .settings-group1:last-child {
  border-bottom: 0;
}
.page-settings .settings-group1 h2 {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  margin: 0 0 8px 0;
  padding: 0 12px 15px 24px;
}
.page-settings .settings-group1 ul {
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
}
.page-settings .settings-group1 ul li {
  font-size: 14px;
  line-height: 1.14285714;
}
.page-settings .settings-group1 ul li a {
  display: block;
  padding: 0px 0 0px 24px;
  margin-left: -24px;
  color: #1a1f36;
  /* background: #FFF; */
}
.page-settings .settings-group1 button,
.page-settings .settings-group1 .btn.btn-primary:focus:not(:hover) {
  background: #fff;
  border-color: transparent;
  box-shadow: none;
  color: #767986;
}

.page-settings .settings-group1 ul li a:active,
.page-settings .settings-group1 ul li a:focus {
  text-decoration: none;
}
.page-settings .settings-group1 ul li a:hover {
  color: #1a1f36;
  text-decoration: none;
}

.page-settings .settings-group1 ul li a.active {
  margin-right: -1px;
  background: var(--primary-color);
  color: #fff;
}

.page-settings .account form {
  margin: 0;
}
.page-settings .account .msg {
  display: inline-block;
  margin: 0 0 0 10px;
  font-weight: bold;
  color: darkgreen;
}
.page-settings .account .profile .profile-img {
  margin-bottom: 15px;
}
.page-settings .account .profile .profile-img .profile-img-avatar {
  margin-right: 10px;
}
.page-settings .account .profile .profile-img img {
  width: 48px;
  height: 48px;
}
.page-settings .account .profile,
.page-settings .account .change-email,
.page-settings .account .change-password,
.page-settings .account .two-factor:not(:empty) {
  margin-bottom: 20px;
  background: white;
  border: solid 1px #ddd;
  padding: 12px 20px 20px 20px;
  border-radius: 3px;
}
.page-settings .members .invite-form form {
  margin: 0 0 0 8px;
}
.page-settings .members .members-list td {
  vertical-align: middle;
}
.page-settings .members .members-list td.user-availability {
  padding-left: 0;
}
.page-settings .members .members-list td.user-availability span {
  float: right;
  visibility: hidden;
}
.page-settings .members .members-list .user-img {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}
.page-settings .members .members-list-item-user {
  display: flex;
}
.page-settings .members .members-list-item-user-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.page-settings .members .members-list-item-role-name {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-settings .members .members-list-header-2fa,
.page-settings .members .members-list-item-2fa {
  text-align: center;
}
.page-settings .members .members-list-header-2fa .inline-help-icon,
.page-settings .members .members-list-item-2fa .inline-help-icon {
  margin-left: 0;
}
.page-settings .members .members-list-header-2fa .glyphicons,
.page-settings .members .members-list-item-2fa .glyphicons {
  color: #d0011b;
}
.page-settings .members .members-list-header-2fa .glyphicons.is-enabled,
.page-settings .members .members-list-item-2fa .glyphicons.is-enabled {
  color: #47bb7f;
}
.page-settings
  .members
  .members-list-header-2fa
  .glyphicons.members-list-header-2fa-help-icon,
.page-settings
  .members
  .members-list-item-2fa
  .glyphicons.members-list-header-2fa-help-icon {
  color: #1a1f36;
  margin-left: 0;
}
.page-settings .manage-organizations li {
  line-height: 30px;
}
.page-settings .manage-organizations .leave {
  margin: -5px 0 0 15px;
}
.page-settings .email-settings form {
  margin: 0;
  width: 360px;
}
.page-settings .email-settings .signature-form label {
  display: none;
}
.page-settings .email-settings .signature-form textarea {
  width: 100%;
  height: 70px;
  resize: vertical;
  box-sizing: border-box;
}
.page-settings .email-settings form.track-email-opens-form {
  width: auto;
  margin-bottom: 20px;
}
.page-settings .email-settings form.track-email-opens-form label {
  font-weight: normal;
}
.page-settings .email-settings .lead-from-email {
  display: block;
  width: 360px;
  margin: 5px 0 20px 0;
  /* since it's readonly */
  cursor: auto;
  background-color: #fff;
}
.page-settings .email-settings .email-accounts ul {
  margin: 0;
}
.page-settings .email-settings .email-accounts .account,
.page-settings .email-settings .email-accounts .add-account {
  border: solid 1px #ddd;
  margin-bottom: 10px;
  display: block;
  width: 360px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 6px;
  background-image: linear-gradient(#fcfcfc, #f9f9f9 50%, #eee);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e3e8ee;
}
.page-settings .email-settings .email-accounts .add-account {
  height: 50px;
  line-height: 15px;
  text-align: left;
  font-size: 18px;
  color: #767986;
  outline: none;
}
.page-settings .email-settings .email-accounts .add-account:hover {
  color: #555;
  border-color: #ccc;
}
.page-settings .email-settings .email-accounts .add-account:focus {
  border-color: #9faece;
}
.page-settings .email-settings .email-accounts .account .glyphicons {
  vertical-align: -1px;
  color: #bbb;
}
.page-settings .email-settings .email-accounts .account .glyphicons:hover {
  color: #777;
}
.page-settings .email-settings .email-accounts .account .identity {
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-settings .email-settings .email-accounts .account .identity a {
  display: none;
  margin-left: 3px;
}
.page-settings .email-settings .email-accounts .account .identity:hover a {
  text-decoration: none;
  display: inline;
}
.page-settings .email-settings .email-accounts .account .add-identity {
  display: inline-block;
  margin-top: 2px;
  font-size: 12px;
}
.page-settings .email-settings .email-accounts .account .googleAccountInfo {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto 1fr;
  grid-template-columns: auto auto 1fr;
  grid-gap: 6px;
  align-items: center;
}
.page-settings .email-settings .email-accounts .account .googleAccountInfoLogo {
  line-height: 0;
}
.page-settings
  .email-settings
  .email-accounts
  .account
  .googleAccountInfoLogo
  svg {
  width: 16px;
}
.page-settings
  .email-settings
  .email-accounts
  .account
  .googleAccountInfoEmail {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-settings .email-settings .email-accounts .credentials {
  *zoom: 1;
  font-size: 12px;
  line-height: 14px;
  color: #666;
}
.page-settings .email-settings .email-accounts .credentials:before,
.page-settings .email-settings .email-accounts .credentials:after {
  display: table;
  content: "";
  line-height: 0;
}
.page-settings .email-settings .email-accounts .credentials:after {
  clear: both;
}
.page-settings .email-settings .email-accounts .credentials .calendar-syncing,
.page-settings .email-settings .email-accounts .credentials .smtp,
.page-settings .email-settings .email-accounts .credentials .imap {
  width: 40%;
  margin-right: 5%;
  float: left;
  margin-top: 8px;
}
.page-settings .email-settings .email-accounts .credentials .calendar-syncing p,
.page-settings .email-settings .email-accounts .credentials .smtp p,
.page-settings .email-settings .email-accounts .credentials .imap p {
  margin: 0;
}
.page-settings
  .email-settings
  .email-accounts
  .credentials
  .calendar-syncing
  p.minor-details,
.page-settings
  .email-settings
  .email-accounts
  .credentials
  .smtp
  p.minor-details,
.page-settings
  .email-settings
  .email-accounts
  .credentials
  .imap
  p.minor-details {
  margin: 4px 0 2px 0;
  color: #afafaf;
  text-overflow: ellipsis;
  overflow: hidden;
}
.page-settings
  .email-settings
  .email-accounts
  .credentials
  .calendar-syncing
  h5,
.page-settings .email-settings .email-accounts .credentials .smtp h5,
.page-settings .email-settings .email-accounts .credentials .imap h5 {
  font-size: 12px;
  margin: 0;
}
.page-settings
  .email-settings
  .email-accounts
  .credentials
  .calendar-syncing
  .btn,
.page-settings .email-settings .email-accounts .credentials .smtp .btn,
.page-settings .email-settings .email-accounts .credentials .imap .btn {
  margin-top: 5px;
}
.page-settings .email-settings .email-accounts .credentials .calendar-syncing {
  margin-top: 0;
  margin-bottom: 16px;
}
.page-settings .email-settings .email-accounts .credentials .status-ok,
.page-settings .email-settings .email-accounts .credentials .status-initial {
  color: darkgreen;
}
.page-settings .email-settings .email-accounts .credentials .status-delayed {
  color: #b38947;
}
.page-settings .email-settings .email-accounts .credentials .status-error {
  color: darkred;
}
.page-settings .email-settings .email-accounts .credentials .status-disabled {
  color: #666;
}
.page-settings
  .email-settings
  .email-accounts
  .credentials
  .email-account-actions {
  padding-top: 16px;
  clear: both;
}
.page-settings .org-settings {
  position: relative;
}
.page-settings .org-settings .form-success {
  display: none;
}
.page-settings .org-settings form {
  margin: 0 0 5px 5px;
}
.page-settings .org-settings form label {
  display: none;
}
.page-settings .org-settings form fieldset {
  display: inline-block;
}
.page-settings .org-settings form input[type="submit"] {
  display: inline-block;
  vertical-align: top;
}
.page-settings .org-settings .opportunity-status-form .bbf-field {
  display: inline-block;
}
.page-settings .org-settings .opportunity-status-form .bbf-field:first-child {
  margin-right: 5px;
}
.page-settings .org-settings .opportunity-status-form .bbf-field select {
  width: 95px;
}
.page-settings .org-settings .list-statuses {
  margin-left: -40px;
  padding-left: 40px;
  width: 385px;
}
.page-settings .org-settings .list-statuses li {
  position: relative;
  list-style: none;
  border: 1px solid #ccc;
  border-top: none;
  background: white;
  margin: 0 5px 0 5px;
  min-height: 38px;
}
.page-settings .org-settings .list-statuses li:first-child {
  margin-top: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: solid 1px #ccc;
}
.page-settings .org-settings .list-statuses li:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.page-settings .org-settings .list-statuses li .drag-to-sort-handle-wrapper {
  height: 100%;
  left: -24px;
  width: 23px;
}
.page-settings
  .org-settings
  .list-statuses
  li
  .drag-to-sort-handle-wrapper
  .drag-to-sort-handle {
  position: absolute;
  top: calc(50% - 10px);
  margin-top: 0;
}
.page-settings .org-settings .list-statuses li .pull-right {
  margin-right: 5px;
}
.page-settings .org-settings .list-statuses li .control-btns {
  float: right;
}
.page-settings .org-settings .list-statuses li .control-btns a {
  float: left;
  padding: 0 5px;
  cursor: pointer;
  margin-left: 5px;
}
.page-settings .org-settings .list-statuses li .control-btns a span {
  float: left;
  display: inline-block;
  vertical-align: middle;
  line-height: 38px;
  height: 38px;
}
.page-settings .org-settings .list-statuses li .loader {
  display: none;
  float: right;
  margin-top: 15px;
  height: 38px;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 10px 0 0;
}
.page-settings .org-settings .list-statuses li .status-label {
  padding: 10px 0 10px 10px;
  display: inline-block;
  width: 220px;
  line-height: 18px;
}
.page-settings .org-settings .list-statuses li .status-label span {
  font-size: 0.8em;
  color: #767986;
  line-height: 10px;
  display: block;
}
.page-settings .org-settings .list-statuses li .control-btns {
  margin-right: 5px;
}
.page-settings .org-settings .list-statuses li .control-btns .glyphicons {
  width: 16px;
  vertical-align: 11px;
  padding: 0;
  font-size: 14px;
  color: #555;
}
.page-settings .org-settings .list-statuses li .info {
  margin: 5px 20px;
}
.page-settings .org-settings .list-statuses li .replace-btns {
  padding: 5px 10px 5px 20px;
}
.page-settings .org-settings .list-statuses li .btn {
  margin: 3px 5px 3px 5px;
}
.page-settings .org-settings .custom-fields .table td {
  vertical-align: middle;
}
.page-settings .org-settings .custom-fields .table,
.page-settings .org-settings .integration-links .table {
  width: auto;
}
.page-settings .org-settings .custom-fields .add-button,
.page-settings .org-settings .integration-links .add-button {
  margin-bottom: 10px;
}
.page-settings .org-settings .custom-fields .lcf-container,
.page-settings .org-settings .integration-links .lcf-container {
  display: flex;
  align-items: center;
}
.page-settings .org-settings .custom-fields .lcf-name,
.page-settings .org-settings .integration-links .lcf-name {
  flex: 1;
}
.page-settings .org-settings .custom-fields .lcf-lock,
.page-settings .org-settings .integration-links .lcf-lock {
  display: block;
  margin-left: 10px;
}
.page-settings .audio-level {
  position: relative;
  margin: 6px 0;
  background: #767986;
  border-radius: 12px;
  padding: 1px;
  width: 220px;
}
.page-settings .audio-level .meter {
  height: 12px;
  position: relative;
  background: #fff;
  border-radius: 12px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
.page-settings .audio-level .meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
.page-settings .audio-level .yellow > span {
  background-color: #ff3;
  background-image: linear-gradient(#ff3, #cc3);
}
.page-settings .audio-level .red > span {
  background-color: #ff0;
  background-image: linear-gradient(#f99, #f33);
}
.page-settings .audio-level .green > span {
  background-color: #6f6;
  background-image: linear-gradient(#6f6, #393);
}
.page-settings .audio-volume input[type="range"] {
  margin-top: -4px;
  margin-bottom: 6px;
  width: 220px;
}
.page-settings .audio-volume input[type="range"] input[type="range"] {
  -webkit-appearance: none !important;
  background: red;
  height: 4px;
  background-color: #ccc;
  border: 1px solid #767986;
  border-radius: 12px;
}
.page-settings
  .audio-volume
  input[type="range"]
  input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  height: 16px;
  width: 16px;
  border: 1px solid #767986;
  box-shadow: 0 0 3px #000;
  border-radius: 14px;
  background-color: white;
}
.page-settings .audio-adjust {
  display: none;
}
.page-settings .audio-adjust label {
  font-weight: normal;
}
.page-settings .audio-adjust input {
  margin-top: -4px;
}

.email-settings form .Button {
  margin-top: 0 !important;
}

/* .members .Button,
.email-settings form .Button {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  border: 0 !important;
  font-weight: 500 !important;
  max-height: 32px !important;
  padding: 4px 8px !important;
  margin-top: 10px !important;
} */

.members .Button:hover,
.email-settings form .Button:hover,
.Table_moreIcon__aLY5q:hover {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  border: 0 !important;
}

.email-settings .AppHeader_actions .Button {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  border: 0 !important;
}

.email-settings .AppHeader_actions .Button:hover {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  border: 0 !important;
}

.email-settings .btn-danger,
.btn-danger {
  max-height: 32px !important;
  padding: 4px 12px !important;
  font-weight: 500 !important;
  border: 0 !important;
}
.email-settings .btn-danger:hover,
.email-settings .btn-danger:focus,
.btn.btn-danger:focus:not(:hover) {
  background: #c6011a !important;
  max-height: 32px !important;
  padding: 4px 12px !important;
  font-weight: 500 !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  border: 0 !important;
}

.page-settings .settings-group ul li a {
  font-weight: 400 !important;
  color: #464a55 !important;
}

/* img.js-avatar{width: 35px !important; height: 35px !important;} */
.page-settings .account form {
  padding-left: 150px;
}

.page-settings .settings-group ul li a:hover {
  background: #f2f2f2 !important;
  color: #464a55 !important;
}

.page-settings .settings-group ul li a.active {
  background: #3781b8 !important;
  color: #fff !important;
}

/* .settings-group ul li a:hover {
  color: #5469d4 !important;
} */

.settings-page .inline-field > div:first-child {
  margin-left: 78px !important;
  width: auto !important;
}

.settings-content .AppHeader_inner {
  padding-bottom: 15px !important;
}

.lcf-name {
  text-transform: capitalize !important;
  margin: 0;
}

.lcf-desc {
  margin: 0;
  font-size: 0.8rem;
}

@media (max-width: 1440px) {
  .page-settings .account form {
    padding-left: 90px !important;
  }

  .settings-page .inline-field > div:first-child {
    width: 200px !important;
  }
  .settings-page .inline-field > div:first-child {
    margin-left: 77px !important;
  }

  .inline-field p.mgt1 {
    width: auto !important;
  }

  .inline-field > div:first-child {
    max-width: 86px !important;
  }
}

#assigned_user {
  max-width: 100% !important;
  margin: 0 auto !important;
}

.manage-organizations tr td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

@media (max-width: 600px) {
  .settings-content .AppHeader_inner {
    display: flex;
  }
}

/* Settings Page */

.settings-page .settings-content {
  background: #fff;
  padding-bottom: 0;
}

.settings-page .account .profile,
.settings-page .account .change-email,
.settings-page .account .change-password,
.settings-page .account .two-factor:not(:empty) {
  padding: 20px 0px 0px 0px !important;
  margin-bottom: 30px;
}

.settings-page .account .profile h2,
.settings-page .account .change-email h2,
.settings-page .account .change-password h2,
.settings-page .account .two-factor:not(:empty) h2 {
  padding-left: 20px;
}

.settings-page .account .profile .set-bg,
.settings-page .account .change-email .set-bg,
.settings-page .account .change-password .set-bg,
.settings-page .account .two-factor:not(:empty) .set-bg {
  background: var(--light-color);
  padding: 30px 20px;
}

.settings-page .btn-rt {
  padding: 20px 20px;
  text-align: right;
}

.settings-page .account .profile h2,
.settings-page .account .change-email h2,
.settings-page .account .change-password h2,
.settings-page .account .two-factor:not(:empty) h2 {
  border-bottom: solid #e3e8ee 1px;
  color: #1a1f36;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  padding-bottom: 20px;
  margin-bottom: 0;
}

.settings-page .inline-field {
  display: flex;
  align-items: center;
}
.settings-page .profile-img-avatar img {
  border-radius: 50% 50%;
  border: none;
}
.settings-page .avatar:after {
  border: none;
}
.settings-page .mgt1 + p,
.settings-page .support-msg {
  color: #767986;
  font-size: 14px;
  font-weight: normal;
}
.settings-page .support-msg {
  margin-top: 10px;
}
.settings-page .mgt1 + p a {
  font-weight: bold;
}

.settings-page .inline-field > div:first-child {
  width: 200px;
}
.settings-page .inline-field input[type="text"],
.settings-page .inline-field input[type="password"] {
  width: 240px;
}

.edit-vm-modal p {
  text-align: center;
}
.edit-vm-modal-hint {
  width: 80%;
  margin: 20px auto 5px;
  font-style: italic;
  font-size: 20px;
  line-height: 1.2;
  font-weight: bold;
}
.edit-vm-modal-hint:before {
  content: "Here is a compliant example:";
  display: block;
  margin-bottom: 5px;
  color: #464a55;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.23076923;
}
.edit-vm-modal-hint:empty {
  display: none;
}
.edit-vm-modal-description {
  font-size: 16px;
  line-height: 1.25;
  width: 80%;
  padding-bottom: 20px;
  margin: 0 auto 5px;
  border-bottom: 1px solid #e3e8ee;
}
.edit-vm-modal .delete-vm {
  color: #da4f49;
}
.voicemail-recorder .form {
  overflow: hidden;
  margin: 0;
}
.voicemail-recorder .form div[type] > *:not(.dropzone) {
  display: none;
}
.voicemail-recorder .form .dropzone {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
}
.voicemail-recorder .button-wrapper {
  text-align: center;
  padding: 20px 0;
}
.voicemail-recorder .button-wrapper > .btn {
  margin-left: 8px;
  margin-right: 8px;
}
.voicemail-recorder label {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #3770b8;
}
.voicemail-recorder .loading {
  line-height: 2.28571429;
  display: none;
  padding: 20px 0;
  margin: 10px 0 0;
  text-align: center;
}
.voicemail-recorder .description {
  margin-top: 10px;
}
.voicemail-recorder .unsupported {
  display: none;
}
.voicemail-recorder .play-recording,
.voicemail-recorder .stop-recording,
.voicemail-recorder .stop-playback,
.voicemail-recorder .restart,
.voicemail-recorder .start-recording,
.voicemail-recorder .loading,
.voicemail-recorder .description {
  display: none;
}
.voicemail-recorder[data-state="initial"] .start-recording,
.voicemail-recorder[data-state="error"] .start-recording {
  display: inline-block;
}
.voicemail-recorder[data-state="initial"] .description,
.voicemail-recorder[data-state="error"] .description {
  display: block;
}
.voicemail-recorder[data-state="initial"].no-recording .button-wrapper,
.voicemail-recorder[data-state="error"].no-recording .button-wrapper {
  display: none;
}
.voicemail-recorder[data-state="initial"].no-recording .unsupported,
.voicemail-recorder[data-state="error"].no-recording .unsupported {
  display: block;
}
.voicemail-recorder[data-state="initial"].no-recording .supported,
.voicemail-recorder[data-state="error"].no-recording .supported {
  display: none;
}
.voicemail-recorder[data-state="initial"].no-recording .alert,
.voicemail-recorder[data-state="error"].no-recording .alert {
  margin-top: 20px;
}
.voicemail-recorder[data-state="recording"] .stop-recording {
  display: inline-block;
}
.voicemail-recorder[data-state="recorded"] .play-recording,
.voicemail-recorder[data-state="recorded"] .restart {
  display: inline-block;
}
.voicemail-recorder[data-state="playing"] .stop-playback,
.voicemail-recorder[data-state="playing"] .restart {
  display: inline-block;
}
.voicemail-recorder[data-state="uploading"] .button-wrapper {
  display: none;
}
.voicemail-recorder[data-state="uploading"] .loading {
  display: block;
}
.edit-forwarding-modal form {
  margin: 0;
}
.edit-forwarding-modal form .field-forward_to_enabled {
  margin-top: 10px;
}
.edit-forwarding-modal form .field-forward_to_enabled label {
  display: inline-block;
  margin: 1px 0 0 5px;
}
.edit-forwarding-modal form .field-forward_to_enabled .bbf-editor {
  float: left;
}
.group-number-participants-modal .modal-header h3 .secondary {
  color: #767986;
  padding-left: 5px;
}
.group-number-participants-modal ul {
  margin: 10px 0 10px 5px;
  list-style: none;
}
.group-number-participants-modal ul li {
  line-height: 1.78571429;
}
.group-number-participants-modal ul li .pic {
  margin-right: 3px;
  vertical-align: -5px;
}
.group-number-participants-modal ul li .glyphicons-earphone {
  color: #555;
  margin-right: 4px;
  margin-left: 4px;
}
.edit-group-number-modal .group-number-container {
  display: flex;
}
.edit-group-number-modal .group-number-container-left,
.edit-group-number-modal .group-number-container-right {
  flex: 1;
  box-sizing: border-box;
  width: 50%;
}
.edit-group-number-modal .group-number-container-left {
  padding-right: 10px;
}
.edit-group-number-modal .group-number-container-right {
  padding-left: 10px;
}
.edit-group-number-modal .group-number-user {
  line-height: 2;
  clear: both;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.edit-group-number-modal .group-number-user-icon {
  font-size: 12px;
}
.edit-group-number-modal .group-number-media-item {
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit-group-number-modal .group-number-user-pic {
  -ms-grid-row-align: normal;
  align-self: normal;
  vertical-align: top;
  width: 28px;
  height: 28px;
}
.edit-group-number-modal .group-number-user-name {
  font-weight: bold;
}
.edit-group-number-modal .group-number-list-item {
  margin: -1px 0 0 0;
  padding: 8px 0;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.edit-group-number-modal .group-number-list-item > .group-number-error {
  margin-top: 0;
  padding-bottom: 4px;
}
.edit-group-number-modal .group-number-list {
  margin: 16px 0 0 0;
  padding: 0;
}
.edit-group-number-modal .group-number-overflow {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}
.edit-group-number-modal .group-number-user-input {
  flex: 1;
  display: inline;
  height: 30px;
}
.edit-group-number-modal .group-number-add-user-container {
  display: flex;
}
.edit-group-number-modal .group-number-add-user-container .selectize-input {
  font-size: 14px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.edit-group-number-modal .group-number-add-user-container-icon {
  width: 28px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.edit-group-number-modal .group-number-phone {
  line-height: 2;
  display: flex;
  clear: both;
  flex-wrap: wrap;
}
.edit-group-number-modal .group-number-add-phone-container {
  display: flex;
  align-items: center;
}
.edit-group-number-modal .group-number-add-phone-container-input {
  display: flex;
  flex: 1;
  margin-right: 5px;
}
.edit-group-number-modal .group-number-phone-input {
  flex: 1;
  width: auto;
  margin-bottom: 0;
}
.edit-group-number-modal .group-number-member-remove {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  border: 0;
  background: transparent;
  margin-right: -10px;
  width: 28px;
  height: 28px;
}
.edit-group-number-modal .group-number-member-remove:hover,
.edit-group-number-modal .group-number-member-remove:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.edit-group-number-modal .group-number-error {
  line-height: 1.14285714;
  width: 100%;
  margin-top: 8px;
}
.edit-group-number-modal .group-number-error:empty {
  display: none;
}
.edit-group-number-modal .group-number-loader {
  margin-top: 5px;
  margin-bottom: 5px;
}
.edit-group-number-modal #new-member-user-selectized {
  margin-left: -4px !important;
  font-size: 14px;
}
.page-settings .email-templates .help-link {
  margin-top: 5px;
}
.page-settings .email-templates .help-link span.glyphicons {
  color: #bbb;
  margin-top: 2px;
  vertical-align: top;
}
.page-settings .email-templates .loader {
  margin-left: 10px;
}
.page-settings .email-templates.list-email-templates a.add {
  margin: 7px 0 10px;
}
.page-settings .email-templates.list-email-templates a.add .glyphicons {
  top: 0;
}
.page-settings .email-templates.list-email-templates .filters {
  margin: 5px 0 10px 15px;
  display: inline-block;
  vertical-align: top;
}
.page-settings .email-templates.list-email-templates .filters span {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}
.page-settings .email-templates.list-email-templates .filters ul.nav {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
.page-settings .email-templates.list-email-templates table {
  table-layout: fixed;
  margin-bottom: 5px;
}
.page-settings .email-templates.list-email-templates table col.col-type {
  width: 10%;
}
.page-settings .email-templates.list-email-templates table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  height: 37px;
  padding: 0 8px;
}
.page-settings .email-templates.list-email-templates table td:first-child {
  text-align: left;
}
.page-settings .email-templates.list-email-templates table td:first-child a {
  margin: 8px 0;
  line-height: 15px;
  display: inline-block;
  float: left;
}
.page-settings .email-templates.list-email-templates table tr .archive-toggle {
  float: right;
  display: none;
}
.page-settings
  .email-templates.list-email-templates
  table
  tr
  .archive-toggle
  .btn {
  line-height: 13px;
  font-size: 12px;
  margin: 2px 0 0 0;
}
.page-settings
  .email-templates.list-email-templates
  table
  tr
  .archive-toggle
  .btn[disabled] {
  pointer-events: none;
}
.page-settings
  .email-templates.list-email-templates
  table
  tr:hover
  .archive-toggle {
  display: block;
}
.page-settings .email-templates.list-email-templates table th {
  text-align: center;
}
.page-settings .email-templates.list-email-templates table th:first-child {
  text-align: left;
}
.page-settings .email-templates.list-email-templates table .attachment {
  vertical-align: top;
  float: right;
  margin: 2px 0 0 8px;
  padding: 0 0 0 20px;
}
.page-settings .email-templates.list-email-templates table .attachment::before {
  font-size: 16px;
  color: #7a7a7a;
}
.page-settings .email-templates.list-email-templates table .glyphicons {
  position: static;
  margin-top: 4px;
}
.lcf-edit.edit-choices-only .lcf-fields {
  display: none;
}
.lcf-edit .lcf-fields form {
  margin: 0;
}
.lcf-edit .lcf-fields ul {
  display: -ms-grid;
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
.lcf-edit .lcf-fields li .bbf-editor {
  display: flex;
}
.lcf-edit .lcf-fields li .bbf-editor > * {
  flex: 1;
}
.lcf-edit .lcf-fields label {
  font-weight: bold;
}
.bbf-label-append {
  margin-left: 5px;
  font-style: normal;
  font-weight: normal;
  color: #6b6e77;
}
.no-permission-modal-header {
  border-bottom: 0;
}
.no-permission-modal-footer {
  border-top: 0;
}
.edit-membership-modal .modal-footer .glyphicons {
  margin: 7px 9px 0 9px;
}
.email-settings h4 .fas,
.org-settings h3 .glyphicons {
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-left: 3px;
  padding: 0;
  display: inline-block;
  font-size: 13px;
  color: #464a55;
}
.email-settings h4 .fas:hover,
.org-settings h3 .glyphicons:hover {
  color: #262e39;
}
.status-list-container {
  position: relative;
}
.status-list-container .status-disabled-overlay {
  display: none;
}
.status-list-container.status-list-disabled .status-disabled-overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 405px;
  opacity: 0.05;
  z-index: 1;
  cursor: not-allowed;
}
.merge-custom-fields-modal select {
  width: 240px;
}

.integration .add-button {
  margin-bottom: 10px;
}
