/*! CSS Used from: https://www.figma.com/webpack-artifacts/assets/figma_app-a59e678c28d2186a.min.css.br */
.comment_cluster_elements--avatarContainer--MIWC0 {
  display: flex;
  min-width: 26px;
  visibility: visible;
}
.comment_cluster_elements--avatar---UxUS {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid var(--color-bg, #ffffff);
  background: linear-gradient(
      136.68deg,
      rgba(0, 0, 0, 0.05) 11.37%,
      rgba(255, 255, 255, 0.039) 82.26%
    ),
    #f0f0f0;
}
.comment_cluster_elements--fallbackAvatar--Wzl2w {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  border: 1px solid var(--color-bg, #ffffff);
}
.comment_cluster_elements--avatarsRoot--lXrbc {
  display: flex;
  transition: opacity var(--expandAnimationTime) ease-in-out,
    visibility var(--expandAnimationTime) ease-in-out;
}
.comment_cluster_elements--avatarsContainer--tcQC0 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 26px;
  min-height: 26px;
}
.comment_cluster_elements--avatarsPrimaryContainer--v1PL- {
  display: flex;
  flex-direction: row-reverse;
}
.comment_cluster_elements--avatarsPrimaryContainer--v1PL-
  > .comment_cluster_elements--avatarContainer--MIWC0 {
  margin-left: -6px;
  margin-right: 0;
  transition: margin-left var(--expandAnimationTime) ease-in-out;
  -webkit-transition: margin-left var(--expandAnimationTime) ease-in-out;
  z-index: 0;
}
.comment_cluster_elements--avatarsPrimaryContainer--v1PL-
  > .comment_cluster_elements--avatarContainer--MIWC0:last-child {
  margin-left: 0;
}
.comment_cluster_elements--avatarsOverflowContainer--nDRn0 {
  display: flex;
  flex-direction: row-reverse;
  margin-left: -6px;
  transition: margin-left var(--expandAnimationTime) ease-in-out;
  -webkit-transition: margin-left var(--expandAnimationTime) ease-in-out;
  min-width: 4px;
  z-index: 0;
}
.comment_cluster_elements--avatarsSecondaryContainer--1qP2a {
  display: flex;
  flex-direction: row-reverse;
  margin-left: -26px;
  max-width: 26px;
  overflow: hidden;
  border-radius: 16px 0 0 16px;
  transition: max-width var(--expandAnimationTime) ease-in-out;
  -webkit-transition: max-width var(--expandAnimationTime) ease-in-out;
}
.comment_cluster_elements--avatarOverflowCounter--e523R {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--color-bg-secondary, #b2b2b2);
  border: 1px solid var(--color-bg, white);
  color: var(--color-text, white);
  font-size: 13px;
  text-transform: uppercase;
  font-family: var(--inter-font-family, "Inter"), sans-serif;
  transition: --num var(--expandAnimationTime);
  -webkit-transition: --num var(--expandAnimationTime);
  counter-reset: num var(--num);
  --num: var(--overflowCount);
  opacity: var(--num);
  z-index: 0;
}
.comment_cluster_elements--avatarOverflowCounter--e523R:after {
  content: counter(num);
}
.comment_cluster_elements--avatarPinAnchor--QPpy0:not(
    .comment_cluster_elements--selected--M-70L
  )
  .comment_cluster_elements--avatarPinContainer---daUJ:hover
  .comment_cluster_elements--pinnedThreadToFileIndicator--Y1TFE {
  transform: scale(0);
  opacity: 0;
  transition: unset;
  pointer-events: none;
  cursor: pointer !important;
}
.comment_cluster_elements--avatarPinContainer---daUJ {
  display: flex;
  /* position: absolute; */
  left: 100px;
  bottom: 300px;
  min-width: fit-content;
  align-self: flex-start;
  justify-self: flex-start;
  flex-direction: row;
  max-width: auto;
  box-sizing: border-box;
  padding: 3px;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-text, #333333);
  background-color: var(--color-bg, white);
  border-radius: 16px 16px 16px 0;
  box-shadow: var(
    --elevation-200-canvas,
    0px 3px 6px rgba(0, 0, 0, 0.3),
    0 0 1px rgba(0, 0, 0, 0.05)
  );
  font-family: var(--inter-font-family, "Inter"), sans-serif;
  transform-origin: bottom left;
  transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out,
    padding var(--expandAnimationTime) ease-in-out,
    min-width var(--expandAnimationTime) ease-in-out;
  -webkit-transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out,
    padding var(--expandAnimationTime) ease-in-out,
    min-width var(--expandAnimationTime) ease-in-out;
}
.comment_cluster_elements--pinnedThreadToFileIndicator--Y1TFE {
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: var(--elevation-200-canvas);
  display: flex;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}
.comment_cluster_elements--avatarPinPreview--LyYBG {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  visibility: hidden;
  max-width: 0px;
  max-height: 0px;
  font-size: 11px;
  transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out;
  -webkit-transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out;
  cursor: default;
}
.comment_cluster_elements--avatarPinPreviewHeader--KFft4 {
  display: flex;
  font-weight: 400;
}
.comment_cluster_elements--avatarPinPreviewAuthorName---M3T7 {
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 15px;
}
.comment_cluster_elements--avatarPinPreviewTimestamp--7jd3I {
  margin-left: 4px;
  flex-shrink: 0;
  color: var(--color-text-secondary, rgba(0, 0, 0, 0.3));
}
.comment_cluster_elements--avatarPinPreviewText--jC62s {
  display: flex;
  overflow: hidden;
  min-width: 180px;
  max-width: 180px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.comment_cluster_elements--avatarPinPreviewReplySummary--tQ-W6 {
  display: flex;
  flex-grow: 1;
  color: var(--color-text-secondary, rgba(0, 0, 0, 0.3));
  -webkit-line-clamp: 1;
  max-height: 1.5em;
}
@media (hover: hover) {
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    > .comment_cluster_elements--avatarPinContainer---daUJ {
    pointer-events: all;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsSecondaryContainer--1qP2a {
    max-width: 160px;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    :not(.comment_cluster_elements--multipleAvatars--3RDVv)
    .comment_cluster_elements--avatarPinPreview--LyYBG {
    margin-left: 8px;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    ):not(.comment_cluster_elements--multipleAvatars--3RDVv)
    .comment_cluster_elements--avatarsPrimaryContainer--v1PL-
    > .comment_cluster_elements--avatarContainer--MIWC0:last-child {
    margin-left: 0 !important;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsPrimaryContainer--v1PL-
    > .comment_cluster_elements--avatarContainer--MIWC0:last-child {
    margin-left: -2px !important;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsPrimaryContainer--v1PL-
    > .comment_cluster_elements--avatarContainer--MIWC0 {
    margin-left: 5px !important;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsOverflowContainer--nDRn0 {
    margin-left: 5px !important;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarOverflowCounter--e523R {
    --num: 0;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarPinContainer---daUJ {
    min-width: 240px;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarPinContainer---daUJ:not(
      .comment_cluster_elements--multipleAvatars--3RDVv
    ):not(.comment_cluster_elements--feedPostPinContainer--Kq02d) {
    padding: 10px;
  }
  .comment_cluster_elements--avatarsHoverContainer--Aopn-:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarPinPreview--LyYBG {
    max-width: 240px;
    max-height: 156px;
    visibility: visible;
  }
}
div,
span,
img {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: inherit;
}

/*! CSS Used from: https://www.figma.com/webpack-artifacts/assets/figma_app-a59e678c28d2186a.min.css.br */
.text--_fontBase--QdLsd {
  font-family: var(--inter-font-family, "Inter"), sans-serif;
}
.text--fontPos11--2LvXf {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: calc(0.005px + var(--text-tracking-pos, 0) * 11px);
}
.text--fontPos13--xW8hS {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: calc(-0.003px + var(--text-tracking-pos, 0) * 13px);
}
.close_button--closeX--Bc-63 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  cursor: default;
  fill: var(--color-icon, #000);
  color: var(--color-icon, #000);
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
}
.close_button--closeX--Bc-63:hover {
  background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.06));
  border-radius: 3px;
}
.close_button--closeX--Bc-63 {
  border: 1px solid transparent;
  outline: none;
}
.close_button--closeX--Bc-63:focus {
  border: 1px solid var(--fg-focus);
  outline: 1px solid var(--fg-focus);
  outline-offset: -2px;
}
.close_button--closeX--Bc-63:focus:not(:focus-visible) {
  border: 1px solid transparent;
  outline: none;
}
.cx_hFull--460YR {
  height: 100%;
}
.modal--modalShadow--d-rJf {
  box-shadow: var(
    --elevation-500-modal-window,
    0px 2px 14px rgba(0, 0, 0, 0.15),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.2)
  );
}
.raw_components--_iconButton---ybo6 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
  grid-column-end: span 4;
}
.raw_components--iconButtonEnabled--WmVk5:hover {
  background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.06));
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  border-radius: 3px;
}
.raw_components--transparentIconButtonEnabled--l2bm3:hover {
  background-color: transparent;
}
.raw_components--iconButtonDisabled--SoTjK {
  fill: var(--color-icon-disabled);
  color: var(--color-icon-disabled);
}
.avatar--avatar--fFibd {
  display: flex;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  overflow: hidden;
  color: var(--color-text, white);
  border-radius: 100%;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  -webkit-user-select: none;
  user-select: none;
}
.avatar--circle--ag36Q {
  overflow: hidden;
  border-radius: 100%;
}
.scroll_container--full--CiWTy {
  width: 100%;
  height: 100%;
}
.scroll_container--clipContainer--5rNO2 {
  overflow: hidden;
  position: relative;
}
.scroll_container--scrollContainer--DYS0c {
  max-height: inherit;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  scrollbar-width: none;
  overscroll-behavior: none;
  -ms-overflow-style: none;
  touch-action: pan-y;
}
.scroll_container--scrollContainer--DYS0c::-webkit-scrollbar {
  display: none;
}
.draggable_modal--positionedContainer--HdvZU {
  position: fixed;
  z-index: 7;
}
.draggable_modal--frame--keEDC {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg, #fff);
  position: relative;
  border-radius: 2px;
}
.draggable_modal--contentContainer--5tm46 {
  flex-grow: 1;
  min-height: 0;
}
.comment_message--commentMessage--kZCzD {
  white-space: pre-wrap;
}
.thread_comment_composer--inputSectionWrapper--Bxyab {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  background-color: var(--color-bg-secondary, rgba(0, 0, 0, 0.06));
  border-radius: 6px;
  margin: 8px 16px 16px 8px;
  min-height: 96px;
  max-height: 100%;
  outline: 0px solid transparent;
  transition: min-height 0.1s, outline 0.2 ease-in-out;
}
.thread_comment_composer--inputSectionWrapper--Bxyab.thread_comment_composer--composerEmpty--VgMY7 {
  min-height: 40px;
  flex-direction: row;
  align-items: center;
}
.thread_comment_composer--editableTypeahead--9R6Yd {
  flex-grow: 1;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 8px 16px;
  transition: padding-bottom 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:first-child {
  fill: var(--color-bg-brand, #0d99ff) !important;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:not(:first-child) {
  fill: var(--color-icon-onbrand, white) !important;
}
.thread_comment_composer--submitUpArrowIconDisabled--iPBY3 path:first-child {
  fill: var(--color-bg-disabled, rgba(0, 0, 0, 0.3)) !important;
}
.thread_comment_composer--submitUpArrowIconDisabled--iPBY3
  path:not(:first-child) {
  fill: var(--color-icon-ondisabled, #fff) !important;
}
.thread_comment_composer--replyComposerContainer---8DSl {
  display: flex;
}
.thread_comment_composer--replyAuthorElement--qze1r {
  margin-top: 16px;
  margin-left: 16px;
}
.thread_comment_composer--actions--7LRnN {
  height: 40px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  border-top: 1px solid var(--color-border, rgba(0, 0, 0, 0.05));
}
.thread_comment_composer--composerEmpty--VgMY7
  .thread_comment_composer--actions--7LRnN {
  border: unset;
}
.editable_typeahead--wrapperBase--rwdvD {
  cursor: text;
}
.public-DraftEditorPlaceholder-root {
  color: var(--color-text-tertiary, #b3b3b3);
  position: absolute;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
}
.public-DraftEditorPlaceholder-root {
  pointer-events: none;
}
.attachment--thumbnailButton--CfAkK {
  position: relative;
  aspect-ratio: 1/1;
  border-radius: 8px;
  border: 1px solid var(--color-border, rgba(0, 0, 0, 0.1));
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-secondary);
  cursor: default;
}
.attachment--thumbnailImage--Jx4fa {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--color-bg-secondary);
}
.attachment--overlay--dIw1C {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.attachment--publishedThumbnailContainer--oFj0f:hover
  .attachment--lightOverlay--F8qzZ {
  background-color: var(--color-bghovertransparent);
}
.attachment--publishedThumbnailContainer--oFj0f {
  position: relative;
  display: flex;
  flex: 1 0 0px;
}
.visually_hidden--visuallyHidden--To537 {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.thread_comment--_threadHeader--59aR8 {
  display: flex;
  align-items: center;
}
body:not([data-fpl-version="ui3"]) .thread_comment--_threadHeader--59aR8 {
  padding-bottom: 4px;
}
.thread_comment--threadHeaderLeft--gppoV {
  justify-content: flex-start;
  flex: 1;
}
body:not([data-fpl-version="ui3"]) .thread_comment--threadHeaderLeft--gppoV {
  margin-left: -12px;
}
.thread_comment--threadHeaderRight--1gMsu {
  justify-content: flex-end;
}
body:not([data-fpl-version="ui3"]) .thread_comment--threadHeaderRight--1gMsu {
  margin-right: -12px;
}
.thread_comment--threadHeaderContainer--0TaTS {
  box-sizing: border-box;
  height: 42px;
  padding-top: 4px;
  display: flex;
  border-bottom: 1px solid var(--color-border, #e5e5e5);
  margin: 0 16px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.thread_comment--threadHeaderButton--psBeY {
  fill: var(--color-icon, #333333);
  width: 32px;
  display: flex;
  justify-content: center;
}
.thread_comment--threadHeaderButtonInactive--LhTNi {
  opacity: 0.375;
}
.thread_comment--addReactionButton--n1Dj5 {
  visibility: hidden;
  align-self: flex-start;
}
.thread_comment--threadContainer--oH8Ia {
  z-index: 0;
  fill: var(--color-icon, #000);
}
.thread_comment--threadContainerInner--Bzqm7 {
  overflow: auto;
}
.thread_comment--_threadElement--PYPeg {
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  color: var(--color-text, #333333);
}
.thread_comment--_threadElement--PYPeg:hover
  .thread_comment--addReactionButton--n1Dj5 {
  visibility: visible;
}
.thread_comment--threadElement--4grcZ {
  padding-top: 8px;
  padding-bottom: 0;
  border-top: none;
}
.thread_comment--threadSubelementNoMargin--aGW6z {
  position: relative;
  min-height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.thread_comment--threadSubelement--7-le3 {
  margin-bottom: 8px;
}
.thread_comment--indentedThreadSubelement--gs1fI {
  margin-left: 32px;
}
.thread_comment--rightJustified--lK8hG {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-self: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}
.thread_comment--leftJustified--HHuN0 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  min-width: 0;
}
.thread_comment--headerLabel--Oq7ar {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  min-width: 0;
  margin-left: 8px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.thread_comment--authorAvatar--xOCLt {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}
.thread_comment--authorHandle--fmYkb {
  margin-left: 8px;
  font-weight: 600;
  color: var(--color-text, rgba(0, 0, 0, 0.8));
}
.thread_comment--text--FHTSU {
  flex-grow: 1;
  flex-basis: auto;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  width: calc(100% - 32px);
  white-space: pre-wrap;
  word-wrap: break-word;
}
.thread_comment--grayLabel--6ZZkD {
  flex-shrink: 0;
  color: var(--color-text-tertiary, #b3b3b3);
}
.thread_comment--attachmentsContainer--lOeTy {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  margin-left: 28px;
  margin-bottom: 8px;
}
.wiggle--wiggleContainer--oGy1O {
  height: 100%;
  width: 100%;
}
div,
span,
a,
img,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: inherit;
}
button {
  background-color: var(--color-bg, #fff);
}
/* svg {
  display: block;
} */
.svg-container path:not([fill="none"]) {
  fill: inherit;
}
.fullscreen_view--fullscreenBody--w5jxN *::selection {
  background-color: var(--color-texthighlight, #0d99ff);
}

/* Comment dot */
.anchor-commentPinIcon {
  height: 32px;
  width: 32px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 5px 8px rgba(0, 0, 0, 0.3);
  border-radius: 16px 16px 16px 0;
  background-color: #0d99ff;
  position: absolute;
  top: 107px;
  left: 80px;
}
@media (pointer: coarse) {
  .anchor-commentPinIcon {
    transform: translate(2.4px) translateY(-2.4px) scale(1.15);
  }
}
/* Comment dot end */

.zoom-in {
  zoom: 1.1;
  box-shadow: var(
    --elevation-200-canvas,
    0px 8px 6px rgba(0, 0, 0, 0.3),
    0 0 1px rgba(0, 0, 0, 0.05)
  );
  margin-right: 12px;
  box-shadow: var(
      --elevation-200-canvas,
      0px 8px 6px rgba(0, 0, 0, 0.3),
      0 0 1px rgba(0, 0, 0, 0.05)
    ),
    0 0 0 2.5px var(--color-border-selected, #0d99ff);
}
.zoom-out {
  zoom: 0;
}
