section {
  display: block;
}

section.content {
  /* background: #fff; */
  /* border-bottom: 1px solid #e9f1f7; */
  /* border-left: 1px solid #e9f1f7;
  border-right: 1px solid #e9f1f7; */
  overflow: hidden;
  padding: 20px;
  transition: all 0.1s linear;
}
.file-wt {
  text-align: center;
  /* max-width: 140px; */
}
.imgwrap {
  width: 100% !important;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.imgwrap div {
  /* width: max-content;
  height: auto; */
  /* margin-right: 5px; */
  margin-bottom: 0px;
}
.thread-label {
  background: #ea552b;
  border: 0 solid #fff;
  border-radius: 12px;
  -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-size: 10.5px;
  font-weight: 400;
  font-weight: 600;
  height: 24px;
  right: -13px;
  letter-spacing: normal;
  line-height: 20px;
  line-height: 24px;
  min-width: 24px;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: center;
  top: -13px;
  -webkit-transition: background 0.25s;
  transition: background 0.25s;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.nmgt1 {
  margin-top: 10px;
}

ul {
  margin-bottom: 10px;
  margin-top: 0;
  padding-left: 40px;
}
ul {
  margin: 0;
  padding: 0;
}
.Margin-top--0 {
  margin-top: 0 !important;
}
ul.accordion-menu li header {
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #e9f1f7;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}
ul.accordion-menu li {
  background: none;
  padding: 0;
}
ul.accordion-menu li header {
  -webkit-touch-callout: none;
  background-color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
  padding: 12px 20px;
  text-align: left;
  transition: all 0.1s ease;
  user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}
ul.accordion-menu li header:hover {
  background-color: #fafafa;
  border-color: #f7fafc;
}
ul.accordion-menu li section.content {
  /* border-bottom: 1px solid #e9f1f7 !important;
  border-left: 1px solid #e9f1f7 !important;
  border-right: 1px solid #e9f1f7 !important; */
  overflow: hidden !important;
  padding: 20px 0 !important;
  transition: all 0.1s linear !important;
  background-color: unset !important;
}
ul.accordion-menu li.open header {
  background-color: #fafafa;
  border: 1px solid #e9f1f7;
}
.open {
  display: block !important;
}

.imgdiv {
  font-size: 14px;
  width: max-content !important;
  cursor: pointer;
  margin-right: 15px !important;
  margin-bottom: 10px !important;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.unset-border {
  border: unset !important;
}
