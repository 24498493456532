.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

body {
  margin-bottom: 0px;
  padding: 0px;
  font-family: "Inter", sans-serif;
  /* font-size: 1.125rem !important; */
  line-height: 1.55em;
  font-weight: 400;
}

h1 {
  margin: 0px;
  padding: 0px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
}

h2 {
  margin: 0px;
  padding: 0px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h3 {
  font-size: 1.125rem;
  line-height: 1.3333333333333333em;
  font-weight: 600;
}

h4 {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h6 {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin: 0px;
  padding: 0px;
}

a {
  color: #ea552b;
  text-decoration: none;
}

ul {
  margin: 0px;
  padding: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.section-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
  padding-top: 1em;
  padding-bottom: 1em;
}

.section-nav.transparent {
  color: #fff;
}

.nav {
  z-index: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.nav.padding-vertical-default {
  height: 8vh;
  padding-top: 1em;
  padding-bottom: 1em;
}

.nav-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #ea552b;
}

.nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin: 0px;
  padding: 0px 1%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-container.align-object-right {
  padding-left: 1%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.nav-links {
  z-index: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-links.display-hidden {
  display: none;
}

.nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0.5em 1.5em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0.3em;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.0625rem;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.nav-link:hover {
  background-color: transparent;
  color: #ea552b;
}

.nav-link.w--current {
  color: #2c2c2c;
}

.nav-link.w--current:hover {
  color: #ea552b;
}

.nav-link.w--open {
  background-color: #fff;
}

.nav-link.alt {
  background-color: transparent;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}

.nav-link.alt:hover {
  background-color: transparent;
  color: #fff;
  text-decoration: none;
}

.nav-link.alt:active {
  background-color: transparent;
  color: #fff;
}

.nav-link.padding-large:hover {
  background-color: transparent;
}

.nav-link.extra {
  display: none;
}

.nav-link.non-click {
  display: none;
}

.body {
  margin: 0px;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5em;
}

.nav-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.nav-buttons.display-none {
  display: none;
}

.nav-button-filled {
  display: block;
  padding: 0.5em 1.5em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 0.3em;
  background-color: #ea552b;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  font-size: 1.0625rem;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  -o-object-fit: fill;
  object-fit: fill;
}

.nav-button-filled:hover {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 0px none transparent;
  background-color: #2c2c2c;
  color: #fff;
}

.nav-button-filled:active {
  background-color: #2c2c2c;
  color: #fff;
}

.nav-button-filled.alt {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
}

.nav-button-filled.alt:hover {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f5;
  background-color: #fff;
  color: #ea552b;
}

.nav-button-filled.alt:active {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
  color: #fff;
}

.cs-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 3em;
  padding-bottom: 4em;
}

body div.container_ {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 1%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 1.125rem !important;
}

.container.h-testimonials {
  max-width: 1280px;
}

.container.divider.margin-top-disable {
  margin-top: 0em;
  margin-bottom: 0em;
}

.container.object-align-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.container.modal {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.cs-header-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin: 0px;
  padding: 0px;
}

.cs-header-column-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px 2.5em 0px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.h1 {
  display: block;
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 4rem;
  line-height: 1.0625em;
  font-weight: 900;
}

.h1.h-header {
  position: relative;
  z-index: 10;
  width: 15ch;
}

.h1.text-color-inverse {
  color: #fff;
}

.h1.inline {
  display: inline;
}

.paragraph-x-large {
  color: #737980;
  font-size: 1.25rem;
  line-height: 1.4em;
}

.paragraph-x-large.max-ch-54 {
  width: 54ch;
}

.paragraph-x-large.text-color-inverse {
  color: #fff;
}

.button-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  background-color: #ea552b;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-large:hover {
  background-color: #2c2c2c;
}

.button-large:active {
  background-color: #2c2c2c;
}

.button-large.padding-1em {
  padding-top: 1em;
  padding-bottom: 1em;
}

.button-large.padding-1em.green {
  background-color: #ea552b;
}

.button-large.padding-1em.green:hover {
  background-color: #2c2c2c;
}

.button-large.padding-1em.green:active {
  background-color: #2c2c2c;
}

.button-large.padding-1em.mobile {
  display: none;
}

.button-text-large {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  line-height: 1.33em;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.icon-chevron-right {
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
}

.cs-item-thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-item-preview {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 70%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-item-logo {
  position: absolute;
  left: 0.75em;
  top: auto;
  right: auto;
  bottom: 0.75em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding-right: 1em;
  padding-left: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.2em;
  background-color: #fff;
}

.cs-list-wrapper {
  padding-top: 4em;
  padding-bottom: 4em;
  background-color: #f8f9fa;
}

.cs-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0.5em;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(163, 163, 164, 0.05),
    0 4px 8px 0 rgba(181, 181, 182, 0.1);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  color: #2c2c2c;
  cursor: pointer;
}

.cs-item:hover {
  box-shadow: 0 0 4px 0 rgba(163, 163, 164, 0.05),
    0 4px 8px 0 rgba(181, 181, 182, 0.1), 0 8px 16px 0 rgba(201, 202, 202, 0.2),
    0 16px 24px 0 rgba(223, 224, 225, 0.4),
    0 24px 32px 0 rgba(223, 224, 225, 0.2);
  color: #2c2c2c;
}

.cs-item-description {
  margin: 0px;
  padding: 0px;
  font-size: 1.125rem;
  line-height: 1.3333333333333333em;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.cs-logo {
  max-height: 19px;
}

.cs-list {
  display: -ms-grid;
  display: grid;
  margin-bottom: 2em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.25em;
  grid-row-gap: 1.25em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cs-item-description-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30%;
  padding: 1.25em 0.75em 1.25em 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-logo-override {
  height: 36px;
}

.cs-hero-logo {
  position: absolute;
  left: 1.5em;
  top: auto;
  right: auto;
  bottom: 1.5em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  padding-right: 1em;
  padding-left: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.2em;
  background-color: #fff;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.cs-hero-thumbnail {
  position: relative;
  overflow: hidden;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cs-hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.75em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.icon-play {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: 2;
  height: 5em;
  max-height: 80px;
  min-height: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.button-endenhancer-gray-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.3em;
  background-color: #e9ecef;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-endenhancer-gray-large:hover {
  background-color: #ea552b;
  color: #fff;
}

.button-endenhancer-gray-large:active {
  background-color: #2c2c2c;
}

.icon---chevron-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1em;
}

.icon---chevron-right.lc-featured-icon-chevron {
  margin-left: 0.125em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.icon---chevron-right.margin-left-tiny {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.25em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon---chevron-right.margin-left-tiny.banner {
  font-size: 1.5rem;
  cursor: pointer;
}

.icon---chevron-right.margin-disable {
  margin-left: 0em;
}

.icon---chevron-right.margin-left-small {
  margin-left: 0.25em;
}

.icon---chevron-right.margin-right-small {
  margin-right: 0.5em;
}

.icon---chevron-right._1-125-rem {
  font-size: 1.125rem;
  line-height: 1.33em;
}

.icon---chevron-right.margin-left-tiny-yes {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.25em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-list-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.paragraph-default {
  margin-top: 1em;
  color: #737980;
}

.paragraph-default.wp-dashboard {
  margin-top: 1em;
  color: #878e96;
}

.paragraph-default.text-color-dark-blue {
  color: #2c2c2c;
}

.paragraph-default.max-ch {
  width: 42ch;
}

.paragraph-default.hidden {
  display: block;
}

.paragraph-default.max-ch-2 {
  width: 60ch;
  margin-top: 0em;
}

.paragraph-default.max-ch-3 {
  width: 61ch;
}

.section-cs-story-header {
  padding-top: 3em;
  padding-bottom: 6em;
  color: #2c2c2c;
}

.cs-story-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.cs-story-column-left {
  margin: 0px;
  padding: 0px;
}

.cs-story-column-right {
  margin: 0px;
  padding: 0px;
}

.button-text-default {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.button-text-default._1-125-rem {
  border-bottom: 1px solid #f8f9fa;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  line-height: 1.33em;
  font-weight: 600;
  letter-spacing: 0px;
}

.button-text-default._1-125-rem.alt {
  border-bottom-color: #ea552b;
}

.cs-story-header-content {
  margin-top: 2em;
  padding-right: 4em;
}

.cs-story-hero {
  width: 100.000026%;
  height: 100.0001%;
  border-radius: 0.5em;
}

.cs-story-thumbnail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-story-info {
  display: -ms-grid;
  display: grid;
  margin-top: -7em;
  margin-bottom: 3em;
  padding: 1.5em 2em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: auto auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04),
    0 0 1px 0 rgba(0, 0, 0, 0.04);
}

.paragraph-default-bold {
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1.375em;
  font-weight: 600;
}

.paragraph-default-dark {
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1.375em;
}

.cs-story-info-logo {
  height: 2em;
  min-height: 0px;
}

.cs-story-thumbnail-square {
  overflow: hidden;
  max-height: 100%;
  margin-right: 0.25em;
  margin-left: 0.25em;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cs-story-thumbnail-square:hover {
  -webkit-filter: brightness(70%);
  filter: brightness(70%);
  -webkit-transform: scale3d(1none, 1none, 1none);
  transform: scale3d(1none, 1none, 1none);
}

.section-cs-story-article {
  padding-top: 5em;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f8f9fa),
    color-stop(50%, #fff),
    to(#f8f9fa)
  );
  background-image: linear-gradient(90deg, #f8f9fa, #fff 50%, #f8f9fa);
}

.cs-story-paragraph-default {
  color: #4a5057;
}

.cs-story-img-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-bottom: 3em;
  margin-left: 0px;
  padding: 0px;
}

.cs-story-img {
  overflow: hidden;
  max-height: 30em;
  max-width: 30em;
  border-radius: 0.5em;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  cursor: pointer;
}

.cs-story-img:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.cs-story-img-column-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 2em;
  padding-left: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cs-story-img-column-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4em;
  padding-right: 2em;
  padding-left: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.img-caption {
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: "Lab Grotesque Mono", Arial, sans-serif;
  color: #4a5057;
  font-size: 0.875rem;
  line-height: 1.43em;
}

.cs-story-heading {
  margin-bottom: 0.5em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2rem;
  line-height: 1.25em;
}

.cs-story-content {
  margin-bottom: 4em;
  padding-right: 20%;
  padding-left: 20%;
}

.section-cs-story-previews {
  padding-top: 6em;
  padding-bottom: 4em;
  /* background-image: url('../images/bg-cs-story-graphics.svg'); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.cs-story-graphic {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cs-story-graphic {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.cs-story-graphic:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #e9ecef;
}

.divider.divider-pricing-item {
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  background-color: #dee2e6;
}

.divider.lc-featured-item-divider {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  margin-left: 12em;
}

.divider.large {
  margin-top: 3em;
  margin-bottom: 3em;
}

.divider.rating-divider {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 0px;
}

.divider.margin-disable {
  margin: 0px;
  padding: 0px;
}

.divider.margin-disable.nav {
  display: none;
}

.divider.hidden {
  display: none;
}

.divider.margin-tiny {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.divider.margin-tiny {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.divider.margin-top-default {
  margin-top: 1em;
  margin-bottom: 1em;
}

.cs-story-paragraph-default-light {
  color: #aeb5bd;
}

.cs-story-paragraph-default-light.hidden {
  display: none;
}

.cs-story-stats {
  display: none;
  margin-bottom: 4em;
  padding: 2em 4em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto auto auto auto;
  grid-template-columns: auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.cs-story-stats-item {
  text-align: center;
}

.cs-story-stats-heading {
  margin-bottom: 0.125em;
  font-family: "Inter", sans-serif;
  color: #ea552b;
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 700;
}

.cs-story-stats-subheading {
  color: #4a5057;
  font-size: 1rem;
  line-height: 1.375em;
}

.h2 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2.5rem;
  line-height: 1.2em;
  font-weight: 900;
}

.cs-story-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cs-story-list {
  display: -ms-grid;
  display: grid;
  margin-top: 2em;
  margin-bottom: 2em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.25em;
  grid-row-gap: 1.25em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.cs-story-link {
  text-decoration: underline;
}

.nav-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.25em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.nav-banner:hover {
  opacity: 1;
  -webkit-filter: brightness(95%);
  filter: brightness(95%);
  -webkit-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
}

.icon-caret-down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.25em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-footer {
  padding-top: 4em;
  padding-bottom: 12em;
  /* background-image: url('../images/bg-footer.svg'); */
  background-position: 50% 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.section-footer.footer-bg-alt {
  padding-top: 2em;
  /* background-image: url('../images/bg-footer.svg'), -webkit-gradient(linear, left top, left bottom, from(#f1f3f5), to(#fff)); */
  /* background-image: url('../images/bg-footer.svg'), linear-gradient(180deg, #f1f3f5, #fff); */
  background-position: 50% 100%, 0px 0px;
  background-size: 100%, auto;
  background-repeat: no-repeat, repeat;
}

.section-footer.padding-top-alt {
  padding-top: 2em;
}

.footer-cta-column {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 120%;
  max-width: 1200px;
  margin: 0px;
  padding: 4em 4em 4em 5em;
  border-radius: 0.8em;
  background-color: #ea552b;
}

.footer-cta-column-left {
  z-index: 3;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.footer-cta-column-right {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0px;
  bottom: 0%;
  margin: 0px;
  padding: 0px;
  border-radius: 1em;
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(132, 94, 247, 0.8)), to(rgba(132, 94, 247, 0.8))), url('../images/bg-cta.svg'); */
  /* background-image: linear-gradient(180deg, rgba(132, 94, 247, 0.8), rgba(132, 94, 247, 0.8)), url('../images/bg-cta.svg'); */
  background-position: 0px 0px, 0% 100%;
  background-size: auto, contain;
  background-repeat: repeat, repeat;
  background-clip: padding-box;
  -webkit-text-fill-color: inherit;
}

.cta-heading {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.14em;
  font-weight: 700;
}

.cta-heading.alt {
  font-size: 3rem;
  line-height: 1.1666666666666667em;
}

.paragraph-default-alt {
  margin: 1em 2em 2em;
  color: #fff;
}

.paragraph-default-alt.wp-locked {
  margin-right: 0em;
  margin-left: 0em;
  color: #dad8df;
}

.paragraph-default-alt.p-money-back-paragraph {
  margin-top: 0.5em;
}

.footer-cta-graphic {
  position: absolute;
  left: auto;
  top: auto;
  right: 10%;
  bottom: 0%;
  z-index: 2;
  height: 90%;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
}

.footer-cta-graphic.alt {
  bottom: 10%;
  height: 80%;
}

.cta-button-primary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1em;
  padding: 1em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  background-color: #fff;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  cursor: pointer;
}

.cta-button-primary:hover {
  background-color: #2c2c2c;
  color: #fff;
}

.cta-button-primary:active {
  background-color: #2c2c2c;
}

.cta-button-primary.alt {
  display: inline;
}

.icon-play-cta {
  max-height: 24px;
  margin-right: 0.5em;
}

.footer-cta-buttongroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.cta-button-secondary {
  padding: 1em 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #1a9cfd;
  border-radius: 0.3em;
  background-color: #018ef5;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.cta-button-secondary:hover {
  border-style: solid;
  border-color: #0180dc;
  background-color: #0173c6;
}

.cta-button-secondary:active {
  border-color: #178ee3;
  background-color: #0065af;
}

.cta-button-secondary.margin-top-large {
  margin-top: 2em;
  background-color: #31a8fe;
}

.cta-button-secondary.margin-top-large:hover {
  background-color: #0180dc;
}

.cta-button-secondary.with-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.footer-press-logo-list {
  display: -ms-grid;
  display: grid;
  padding: 4em 0.75em 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-items: center;
  grid-auto-columns: auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: auto auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-press-logo-list.margin-alt {
  padding-top: 1em;
}

.footer-press-logo {
  position: relative;
  -o-object-fit: contain;
  object-fit: contain;
}

.footer-press-logo.pos-top {
  top: -0.25em;
}

.footer-main {
  margin-top: 1em;
}

.footer-column {
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-column-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer-menu-list {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.footer-graphic-philly {
  height: 4em;
  min-height: 72px;
  margin-left: 0.5em;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.footer-logo {
  min-height: 40px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.footer-text-copyright {
  margin-top: 1em;
  margin-left: 0.5em;
  color: #aeb5bd;
  font-size: 0.875rem;
  line-height: 1.28em;
}

.footer-menu-title {
  margin-bottom: 1em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  font-weight: 700;
}

.footer-menu-item {
  display: block;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  color: #737980;
  font-size: 1rem;
  cursor: pointer;
}

.footer-menu-item:hover {
  color: #2c2c2c;
}

.footer-menu-item:active {
  color: #ea552b;
}

.footer-menu-item.display-none {
  display: none;
}

.section-wp-header {
  overflow: hidden;
  padding-top: 4em;
  padding-bottom: 4em;
}

.h1-large {
  margin-bottom: 0.125em;
  font-family: "Inter", sans-serif;
  font-size: 4.5rem;
  line-height: 1.0625em;
  font-weight: 900;
}

.h1-large.text-color-inverse {
  color: #fff;
}

.h1-large.text-color-inverse.margin-bottom-1 {
  margin-bottom: 1em;
}

.h1-large.text-color-dark-purple {
  color: #2c2c2c;
}

.h1-large.text-color-dark-purple {
  color: #2c2c2c;
}

.h1-large.text-color-inverse {
  color: #fff;
}

.wp-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px 8em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #2c2c2c;
  text-align: center;
}

.wp-header-rating-graphic {
  height: 1.5em;
  min-height: 24px;
}

.wp-header-rating-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
  margin-bottom: 0.5em;
  padding: 1em 1.75em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 4em;
  background-color: #f1f3f5;
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.wp-header-rating-wrapper:hover {
  background-color: #f8f9fa;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.08);
  -webkit-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
}

.wp-rating-text {
  margin-left: 0.25em;
  color: #2c2c2c;
  font-size: 1.25rem;
  font-weight: 600;
  white-space: nowrap;
}

.icon-chevron-right-alt {
  height: 0.6em;
  margin-left: 0.5em;
}

.paragraph-semibold {
  line-height: 1.33em;
  font-weight: 600;
}

.wp-testimonial-card-blue {
  position: relative;
  overflow: hidden;
  max-height: 360px;
  min-height: 360px;
  min-width: 640px;
  padding: 3em 2em 2em;
  grid-auto-columns: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 5% 70% 25%;
  grid-template-columns: 5% 70% 25%;
  border-radius: 0.75em;
  background-color: #1a9cfd;
  /* background-image: url('../images/wp-testimonial-graphic-1.png'), url('../images/wp-header-card-bg-3.svg'); */
  background-position: 110% 35%, 180% 50%;
  background-size: auto 70%, auto 400px;
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.icon-quote {
  height: 2em;
  min-height: 32px;
}

.wp-testimonial-heading {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 900;
}

.wp-testimonial-paragraph-blue {
  color: #dbf3ff;
  font-size: 1rem;
  line-height: 1.5em;
}

.wp-testimonial-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 4em;
  margin-left: 0.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.wp-testimonial-link-blue {
  color: #dbf3ff;
  text-decoration: underline;
}

.wp-testimonial-client-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wp-testimonial-client-avatar-blue {
  max-height: 64px;
  margin-right: 1em;
  border: 3px solid #4db3ff;
  border-radius: 8em;
}

.wp-testimonial-client-name {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}

.wp-testimonial-designer-name-blue {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 10ch;
  color: #53b1f8;
  font-size: 0.875rem;
  line-height: 1.28em;
  text-align: right;
}

.wp-testimonial-designer-info {
  position: relative;
}

.wp-header-bg {
  /* background-image: url('../images/wp-header-bg-left.svg'), url('../images/wp-header-bg-right.svg'); */
  background-position: 0px 0px, 100% 0%;
  background-size: auto, auto;
  background-repeat: no-repeat, no-repeat;
}

.wp-testimonial-card-purple {
  position: relative;
  overflow: hidden;
  max-height: 360px;
  min-height: 360px;
  min-width: 640px;
  padding: 3em 2em 2em;
  grid-auto-columns: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 5% 70% 25%;
  grid-template-columns: 5% 70% 25%;
  border-radius: 0.75em;
  background-color: #ea552b;
  /* background-image: url('../images/wp-testimonial-graphic-2.png'), url('../images/wp-testimonial-bg-2.svg'); */
  background-position: 110% 35%, 180% 50%;
  background-size: auto 70%, auto 400px;
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.wp-testimonial-designer-name-purple {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 10ch;
  color: #c4b0ff;
  font-size: 0.875rem;
  line-height: 1.28em;
  text-align: right;
}

.wp-testimonial-paragraph-purple {
  color: #f9f0ff;
  font-size: 1rem;
  line-height: 1.5em;
}

.wp-testimonial-client-avatar-purple {
  max-height: 64px;
  margin-right: 1em;
  border: 3px solid #aa8dff;
  border-radius: 8em;
}

.wp-testimonial-card-orange {
  position: relative;
  overflow: hidden;
  max-height: 360px;
  min-height: 360px;
  min-width: 640px;
  padding: 3em 2em 2em;
  grid-auto-columns: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 5% 70% 25%;
  grid-template-columns: 5% 70% 25%;
  border-radius: 0.75em;
  background-color: #fb9801;
  /* background-image: url('../images/wp-testimonial-graphic-3.png'), url('../images/wp-testimonial-bg-3.svg'); */
  background-position: 110% 35%, 180% 50%;
  background-size: auto 70%, auto 400px;
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.wp-testimonial-paragraph-orange {
  color: #fff7e6;
  font-size: 1rem;
  line-height: 1.5em;
}

.wp-testimonial-designer-name-orange {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 10ch;
  color: #ffd591;
  font-size: 0.875rem;
  line-height: 1.28em;
  text-align: right;
}

.wp-testimonial-client-avatar-orange {
  max-height: 64px;
  margin-right: 1em;
  border: 3px solid #ffaf34;
  border-radius: 8em;
}

.wp-testimonial-designer-name-green {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 10ch;
  color: #22af3b;
  font-size: 0.875rem;
  line-height: 1.28em;
  text-align: right;
}

.wp-testimonial-card-green {
  position: relative;
  overflow: hidden;
  max-height: 360px;
  min-height: 360px;
  min-width: 640px;
  padding: 3em 2em 2em;
  grid-auto-columns: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 5% 70% 25%;
  grid-template-columns: 5% 70% 25%;
  border-radius: 0.75em;
  background-color: #40c057;
  /* background-image: url('../images/wp-testimonial-graphic-4.png'), url('../images/wp-testimonial-bg-4.svg'); */
  background-position: 110% 35%, 180% 50%;
  background-size: auto 70%, auto 400px;
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.wp-testimonial-client-avatar-green {
  max-height: 64px;
  margin-right: 1em;
  border: 3px solid #6be280;
  border-radius: 8em;
}

.wp-testimonial-card-yellow {
  position: relative;
  overflow: hidden;
  max-height: 360px;
  min-height: 360px;
  min-width: 640px;
  padding: 3em 2em 2em;
  grid-auto-columns: auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 5% 70% 25%;
  grid-template-columns: 5% 70% 25%;
  border-radius: 0.75em;
  background-color: #eaecab;
  /* background-image: url('../images/wp-testimonial-graphic-5.png'), url('../images/wp-testimonial-bg-5.svg'); */
  background-position: 110% 35%, 180% 50%;
  background-size: auto 70%, auto 400px;
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.icon-quote-alt {
  height: 2em;
  min-height: 32px;
}

.wp-testimonial-designer-name-yellow {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 10ch;
  color: #e0cba1;
  font-size: 0.875rem;
  line-height: 1.28em;
  text-align: right;
}

.wp-testimonial-client-name-alt {
  color: #2c2c2c;
  font-size: 1rem;
  font-weight: 600;
}

.wp-testimonial-heading-alt {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 900;
}

.wp-testimonial-paragraph-yellow {
  padding-right: 4em;
  color: #7b8087;
  font-size: 1rem;
  line-height: 1.5em;
}

.wp-testimonial-client-avatar-yellow {
  max-height: 64px;
  margin-right: 1em;
  border: 3px solid #f5f6cf;
  border-radius: 8em;
}

.section-wp-services {
  padding-top: 4em;
  padding-bottom: 8rem;
}

.section-wp-services.desktop {
  overflow: hidden;
}

.section-wp-services.mobile {
  display: none;
}

.wp-services {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 4em 4em 16em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 1em;
  background-color: #f1f3f5;
}

.wp-services-content-header-line {
  display: block;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wp-content-headline {
  height: 3em;
  margin-top: -1.5em;
  margin-right: 0.5em;
  -o-object-fit: contain;
  object-fit: contain;
}

.wp-services-content-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wp-services-column-left {
  margin: 0px;
  padding: 1em 3em 0px 0px;
}

.wp-services-column-right {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -ms-grid;
  display: grid;
  height: auto;
  margin: 4em 0px;
  padding: 2em 3em;
  grid-auto-flow: row;
  grid-auto-columns: auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: 50% 50%;
  -ms-grid-rows: 5% 95%;
  grid-template-rows: 5% 95%;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  background-color: #fafafa;
}

.wp-services-graphic {
  position: absolute;
  left: -35%;
  top: 120%;
  min-width: 130%;
}

.wp-services-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
  border-radius: 1em;
  background-color: #f1f3f5;
}

.wp-services-header {
  display: inline;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3.5rem;
  line-height: 1.0625em;
  font-weight: 900;
}

.text-block {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
}

.wp-services-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon-check-circle {
  height: 1.5em;
  min-height: 24px;
  margin-right: 0.5em;
}

.wp-services-item-text-default {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 500;
}

.wp-services-item-text-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  font-family: "Inter", sans-serif;
  color: #ea552b;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 500;
}

.wp-services-item-text-alt:hover {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  color: #2c2c2c;
}

.icon-chevron-left-flipped {
  height: 12px;
  max-height: 16px;
  margin-left: 0.5em;
  -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.section-wp-assign {
  position: relative;
  overflow: hidden;
  margin-bottom: 0px;
  padding-top: 6em;
  padding-bottom: 4em;
}

.h1-small-alt {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.14em;
  font-weight: 900;
}

.h1-small-alt.inline {
  display: inline;
  margin-right: 0.25em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.h1-small-alt.inline.text-color-dark-blue {
  color: #2c2c2c;
}

.wp-assign-content {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 12em;
  padding-left: 12em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.wp-assign-graphic {
  position: relative;
  z-index: 0;
  width: 100vw;
  height: auto;
  max-width: 100vw;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  mix-blend-mode: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

.wp-swap-wrapper {
  overflow: hidden;
  margin-top: -1.1em;
  padding-top: 4em;
  padding-bottom: 4em;
  /* background-image: url('../images/wp-assign-bg-overlay.svg'); */
  background-position: 0px 0px;
  background-size: cover;
}

.wp-section-container {
  overflow: hidden;
  /* background-image: url('../images/wp-assign-bg.svg'); */
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.swap-designers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column {
  padding-right: 2em;
  padding-left: 4em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.section-wp-dashboard {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 6em 0px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: #f1f3f5;
}

.wp-dashboard-content {
  padding-right: 8em;
  padding-left: 8em;
  text-align: center;
}

.h1-small {
  margin: 0px 0px 0.25em;
  padding: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3.5rem;
  line-height: 1.14em;
  font-weight: 900;
  white-space: break-spaces;
}

.h1-small.shrink {
  margin-right: 0.5em;
  margin-left: 0.5em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.h1-small.shrink.alt.margin-disable {
  margin: 0em;
}

.h1-small.alt {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.h1-small.alt {
  color: #2c2c2c;
}

.h1-small.alt.margin-bottom-default {
  margin-bottom: 0.25em;
}

.h1-small.text-color-inverse {
  color: #fff;
}

.h1-small.text-color-inverse.sizing-disable {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.h1-small.text-color-inverse.sizing-disable.max-ch {
  width: 14ch;
}

.h1-small.padding-horizontal-large {
  padding-right: 2em;
  padding-left: 2em;
  text-align: center;
}

.h1-small.text-color-dark-purple {
  color: #2c2c2c;
}

.h1-small.text-color-dark-purple.margin {
  margin-bottom: 1em;
}

.h1-small.margin-bottom-large {
  margin-bottom: 1em;
}

.h1-small.text-align-center.margin-bottom-default {
  margin-bottom: 1em;
}

.h1-small.mobile-center {
  text-align: left;
}

.h1-small.center {
  text-align: center;
}

.h1-small.center.max-ch-spotlight {
  width: 18ch;
}

.h1-small.max-ch {
  width: 24ch;
}

.wp-dashboard-menu {
  position: relative;
  left: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 480px;
  min-width: 480px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.wp-dashboard-tab {
  margin: -0.5em 0px 0px;
  padding: 0px;
  background-color: transparent;
}

.wp-dashboard-tab.w--current {
  margin: 0px;
  padding: 2px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-image: linear-gradient(323deg, #32a9ff, #7d65f8 40%, #f360f5);
}

.wp-dashboard-tab-container {
  padding: 1.75em 2em 1.5em;
  border-radius: 5px;
  white-space: break-spaces;
}

.wp-dashboard-tab-heading {
  color: #4a5057;
  font-size: 1.25rem;
  font-weight: 600;
}

.wp-dashboard-tab-icon {
  width: 2em;
  height: 2em;
  max-height: 32px;
  margin-right: 0.5em;
}

.wp-dashboard-tab-heading-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.25em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wp-dashboard-content-mask {
  overflow: visible;
  width: 60%;
  height: 100%;
  padding-left: 4em;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.section-footer-mobile {
  display: none;
  padding-top: 4em;
  padding-bottom: 12em;
  /* background-image: url('../images/bg-footer.svg'); */
  background-position: 50% 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.icon-embed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2em;
  height: 2em;
  margin-right: 1em;
  padding: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 4em;
  background-color: #fff;
  -webkit-transition: color 0ms linear, border-color 0ms linear,
    background-color 0ms linear;
  transition: color 0ms linear, border-color 0ms linear,
    background-color 0ms linear;
  color: #aeb5bd;
  cursor: pointer;
}

.icon-embed:hover {
  border-color: #ea552b;
  color: #ea552b;
}

.icon-embed:active {
  background-color: #ea552b;
  color: #fff;
}

.icon-embed.alt {
  width: 2em;
  margin-right: 0px;
  border-color: transparent;
  background-color: transparent;
  font-size: 1.125rem;
}

.icon-embed.alt:active {
  color: #2c2c2c;
}

.social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.divider-small {
  height: 1px;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #e9ecef;
}

.nav-button-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0.5em 1.5em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0.3em;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.0625rem;
  font-weight: 700;
}

.nav-button-link:hover {
  background-color: transparent;
  color: #ea552b;
}

.nav-button-link.alt {
  color: #fff;
}

.nav-button-link.alt:hover {
  background-color: transparent;
  color: #fff;
}

.nav-button-link.alt:active {
  background-color: hsla(0, 0%, 100%, 0.1);
  color: #fff;
}

.wp-dashboard-tab-paragraph {
  color: #737980;
}

.wp-dashboard-graphic {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border-radius: 0.5em;
  background-color: transparent;
  /* background-image: url('../images/ezgif-1-ff485e2dea.gif'); */
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: no-repeat;
}

.wp-dashboard-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 70vh;
  margin-top: 4em;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.section-wp-outsource {
  position: relative;
  margin: 0px;
  padding: 6em 0px 8em;
}

.wp-outsource-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.wp-outsource-column-left {
  margin: 0px;
  padding: 0px;
}

.wp-outsource-column-right {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.wp-outsource-graphic {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -o-object-fit: contain;
  object-fit: contain;
}

.wp-outsource-content {
  padding-right: 8em;
}

.wp-outsource-benefits {
  display: -ms-grid;
  display: grid;
  margin-top: 2em;
  grid-auto-columns: auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.wp-outsource-benefits-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.wp-benefits-icon {
  width: 2em;
  height: 2em;
  margin-right: 1em;
}

.columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.column-2 {
  margin: 0px;
  padding: 0px;
}

.wp-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -o-object-fit: none;
  object-fit: none;
}

.slider-wrapper.slider-wrapper-ow-gallery {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  overflow: hidden;
  padding-top: 2em;
  padding-bottom: 3em;
}

.slider-wrapper.position-center {
  position: relative;
  left: -1.8%;
}

.slider-navigation-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100vw;
}

.slider-navigation-wrapper.slider-navigation-wrapper-ow-gallery {
  overflow: visible;
  width: 100%;
  height: 18em;
}

.slider-navigation-wrapper.spotlight {
  margin-top: -4em;
}

.slider-navigation-wrapper.h-portfolio {
  margin-top: -2em;
}

.slider-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1em;
  height: 1em;
  padding: 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f5;
  border-radius: 4em;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  color: #878e96;
  font-size: 1.4rem;
  cursor: pointer;
}

.slider-icon:hover {
  border-color: #ea552b;
  color: #ea552b;
}

.slider-icon:active {
  background-color: #ea552b;
  color: #fff;
}

.slider-icon.slider-icon-ow-gallery {
  padding: 1.3em;
}

.slider-icon.h-testimonial {
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  font-weight: 900;
}

.slider-icon.left {
  left: 1%;
  top: 0%;
  bottom: 20%;
}

.slider-icon.left:hover {
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.slider-icon.left:active {
  border-style: none;
  border-width: 0px;
}

.slider-icon.right {
  left: auto;
  top: 0%;
  right: 0vw;
  bottom: 20%;
}

.slider-icon.right:hover {
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.slider-icon.right:active {
  border-style: none;
}

.slider-navigation {
  background-color: transparent;
}

.slider-navigation.next-slide {
  position: absolute;
  right: 5%;
  z-index: 3;
  margin-right: 0%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -o-object-fit: none;
  object-fit: none;
}

.slider-navigation.next-slide.ow-gallery-arrow-right {
  right: -3%;
}

.slider-navigation.prev-slide {
  position: relative;
  left: 5%;
  z-index: 2;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -o-object-fit: none;
  object-fit: none;
}

.slider-navigation.prev-slide.ow-gallery-arrow-left {
  position: absolute;
  left: -3%;
}

.slider-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2em;
  list-style-type: none;
}

.slider-list.slider-list-ow-gallery {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.slider-list.h-portfolio {
  position: relative;
  left: -4vw;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.slider-list.spolight {
  width: 100%;
}

.slider-item {
  position: relative;
  max-height: 360px;
  max-width: 640px;
  min-height: 360px;
  margin: 0px 1em;
  padding: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.slider-item.p-reviews-card {
  margin-right: 1.75em;
  margin-left: 0px;
}

.slider-item.slider-item-ow-gallery {
  overflow: hidden;
  width: 23%;
  height: 100%;
  max-height: none;
  max-width: 23%;
  min-height: 100%;
  margin-right: 2%;
  margin-left: 0.5%;
  border-radius: 0.5em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.slider-item.slider-item-ow-gallery:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.slider-item.h-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18vw;
  height: 24vh;
  max-height: none;
  max-width: 18vw;
  min-height: 420px;
  min-width: 320px;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
}

.slider-item.spotlight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-p-header-parent {
  background-color: #ea552b;
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(132, 94, 247, 0.3)), to(rgba(132, 94, 247, 0.3))), url('../images/pricing-bg-graphic-right.svg'), url('../images/pricing-bg-graphic-left.svg'); */
  /* background-image: linear-gradient(180deg, rgba(132, 94, 247, 0.3), rgba(132, 94, 247, 0.3)), url('../images/pricing-bg-graphic-right.svg'), url('../images/pricing-bg-graphic-left.svg'); */
  background-position: 0px 0px, 100% 50%, 0% 50%;
  background-size: auto, auto 100%, auto 100%;
  background-repeat: repeat, no-repeat, no-repeat;
}

.p-header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

.p-packages {
  margin-bottom: 3em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-items: center;
  grid-auto-columns: auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 24% 24% 24% 24%;
  grid-template-columns: 24% 24% 24% 24%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p-package-title {
  display: inline-block;
  font-family: "Inter", sans-serif;
  color: #514d5c;
  font-size: 24px;
  line-height: 1.25em;
  font-weight: 700;
}

.p-packages-item {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  min-width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  background-color: #f2f4f6;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  background: #fafafa !important;
  min-height: 700px;
}

.p-package-heading-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p-package-price {
  display: inline;
  margin-right: 0.125em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 32px;
  line-height: 1.25em;
  font-weight: 900;
}

.p-package-price-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: center;
}

.p-package-price-container.margin-disable {
  margin-top: 0px;
  margin-bottom: 0px;
}

.p-package-price-container.co-alt {
  margin-bottom: 0em;
}

.p-package-frequency {
  margin-top: 0.25em;
  color: #4a5057;
}

.button-large-purple {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  background-color: #ea552b;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-large-purple:hover {
  background-color: #9775fa;
}

.button-large-purple:active {
  background-color: #7950f2;
}

.button-large-purple.pricing-button {
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #ea552b;
}

.button-large-purple.pricing-button:active {
  background-color: #e762bc;
}

.p-package-info-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1.5em;
  padding-right: 1.5em;
  padding-left: 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p-package-info-bottom {
  padding-right: 2em;
  padding-bottom: 1.5em;
  padding-left: 2em;
}

.p-package-heading-bottom {
  color: #2c2c2c;
  line-height: 1.2em;
}

.p-package-heading-bottom.margin-right-0-25 {
  margin-right: 0.25em;
  letter-spacing: -0.2px;
}

.icon-check-circle-green {
  height: 1.3rem;
  margin-right: 0.75em;
}

.p-package-benefits-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.75em;
  margin-bottom: 1em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p-package-benefits-item.co-benefits-item {
  margin-bottom: 0.5em;
}

.p-package-benefits-item-text {
  color: #878e96;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5em;
  text-align: left;
}

.p-package-benefits-item-text.text-color-dark-blue {
  color: #2c2c2c;
}

.p-package-benefits-list {
  margin-top: 1em;
}

.icon-shield-check {
  height: 0.555556em;
  min-height: 20px;
  margin-right: 0.75em;
  margin-top: -5px;
  margin-left: 3px;
}

.blck {
  color: #000 !important;
}

.p-logo-list-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.p-logo-list-heading {
  font-family: "Inter", sans-serif;
  color: #353a40;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
}

.p-logo-list {
  display: -ms-grid;
  display: grid;
  margin-top: 2em;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: center;
  grid-auto-columns: auto;
  grid-column-gap: 4em;
  grid-row-gap: 1em;
  -ms-grid-columns: auto auto auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.p-logo-list-item {
  height: 1.75em;
  min-height: 28px;
}

.section-p-benefits {
  padding-top: 4em;
  padding-bottom: 10em;
  /* background-image: url('../images/pricing-benefits-bg.svg'); */
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
}

.benefits-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-benefits-heading-line-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.p-benefits-heading-line-2 {
  margin-top: 0.5em;
}

.p-benefits-heading-outline {
  height: 3.5em;
  min-height: 56px;
  margin-right: 1em;
  margin-left: 1em;
}

.feature-item-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.feature-item-heading {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.feature-item-icon {
  width: 3.5em;
  height: 3.5em;
  max-height: 56px;
  margin-right: 1em;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.feature-item-paragraph {
  color: #5c6166;
  font-size: 1rem;
  line-height: 1.5em;
}

.feature-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  white-space: break-spaces;
}

.benefits-list {
  display: -ms-grid;
  display: grid;
  margin-top: 4em;
  grid-auto-columns: auto;
  grid-column-gap: 3em;
  grid-row-gap: 4em;
  -ms-grid-columns: auto auto auto;
  grid-template-columns: auto auto auto;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.p-cta {
  position: relative;
  bottom: 6em;
  display: none;
  padding: 3em 4em;
  border-radius: 0.5em;
  background-color: #ea552b;
  /* background-image: url('../images/pricing-cta-graphic.png'); */
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.p-cta-content-main {
  width: 40%;
}

.cta-paragraph {
  margin-top: 1em;
  margin-bottom: 3em;
  color: #fff;
  font-size: 1rem;
}

.button-text-small {
  display: inline;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  line-height: 1.33em;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.section-p-reviews {
  z-index: 1;
  padding-top: 4em;
  padding-bottom: 2em;
  background-color: #1a9cfd;
  /* background-image: url('../images/pricing-reviews-bg.svg'); */
  background-position: -5% 0%;
  background-size: auto;
  background-repeat: no-repeat;
}

.p-reviews-subheading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-pricing-reviews-text {
  margin-right: 0.25em;
  margin-left: 1em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #353a40;
  line-height: 1.33em;
  font-weight: 600;
}

.p-pricing-reviews-text.text-color-inverse {
  color: #fff;
}

.p-reviews-client-avatar {
  width: 2.5em;
  height: 2.5em;
  margin-right: 1em;
  border-radius: 4em;
}

.paragraph-tiny {
  color: #878e96;
  font-size: 1rem;
  line-height: 1.5em;
}

.p-reviews-card-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 1.25em;
  min-height: 20px;
  margin-top: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-reviews-card-client {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.p-reviews-card-client.z-index-10 {
  position: relative;
  z-index: 10;
}

.p-reviews-client-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p-reviews-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  max-height: 360px;
  max-width: 360px;
  min-height: 360px;
  margin-right: 1.75em;
  padding: 2em 2em 1.25em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f5;
  border-radius: 0.8em;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04),
    0 0 1px 0 rgba(0, 0, 0, 0.04);
}

.p-reviews-card-text {
  margin-top: 1em;
  color: #878e96;
  font-size: 1rem;
  line-height: 1.5em;
}

.button-endenhancer-default {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 200ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition: all 200ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  font-family: "Inter", sans-serif;
  color: #ea552b;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-endenhancer-default:hover {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 rgba(132, 94, 247, 0.5);
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.p-reviews-card-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
}

.p-reviews-card-button.display-none {
  display: none;
}

.p-reviews-rating-link {
  -webkit-transition: all 200ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition: all 200ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.p-reviews-rating-link:hover {
  color: hsla(0, 0%, 100%, 0.8);
}

.icon-chevron-right-alt {
  width: 0.5px;
  height: 0.5em;
  min-height: 8px;
  min-width: 8px;
}

.p-reviews-content {
  padding-right: 12em;
  padding-bottom: 2em;
  padding-left: 12em;
  text-align: center;
}

.faq {
  position: relative;
  z-index: 5;
  padding-top: 6em;
}

.faq.alt {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f3f5),
    color-stop(30%, hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #f1f3f5, hsla(0, 0%, 100%, 0) 30%);
}

.p-faq {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pricing-link {
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.pricing-link:hover {
  border-bottom: 2px solid #ea552b;
}

.faq-graphic {
  position: relative;
  z-index: 1;
  max-height: 400px;
  max-width: 120%;
  min-height: 400px;
  margin-left: -20%;
}

.p-faq-list {
  position: relative;
  z-index: 1;
  margin-left: 2em;
}

.faq-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  padding: 1em 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.5em;
  background-color: #f1f3f5;
}

.faq-question {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  white-space: break-spaces;
}

.p-faq-question-text {
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1.375em;
  font-weight: 600;
}

.p-faq-answer {
  position: relative;
  display: block;
  padding: 0px;
  background-color: transparent;
}

.p-faq-answer.w--open {
  background-color: transparent;
}

.p-faq-icon {
  position: relative;
  margin: 0px;
  padding: 0px;
}

.faq-answer-text {
  margin-right: 1em;
  padding-top: 0.5em;
  color: #737980;
  font-size: 1rem;
  line-height: 1.5em;
}

.footer-cta-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wp-designer-name {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 3%;
}

.wp-assign-graphic-container {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  max-width: 100vw;
  margin-top: -6em;
  margin-bottom: 3em;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.paragraph-default-alt-2 {
  margin-top: 1em;
  margin-bottom: 2em;
  color: #fff;
}

.paragraph-default-alt-2.max-ch {
  width: 44ch;
}

.cta-heading-2 {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 3.5rem;
  line-height: 1.14em;
  font-weight: 700;
}

.cta-heading-2.max-ch {
  width: 16ch;
}

.faq-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p-packages-main.alt {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p-packages-main.package-price-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p-pricing-reviews-text-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.nav-list-icon.desktop {
  width: 3em;
  height: 3em;
  margin-right: 0.75em;
}

.nav-list-item {
  display: none;
}

.nav-list-text.line-height-1em {
  margin-bottom: 0.125em;
  line-height: 1em;
}

.p-faq-subheading {
  margin-top: 1em;
  color: #737980;
}

.p-package-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.p-package-item-wrapper {
  position: relative;
  padding: 2px;
  border-radius: 0.6em;
  opacity: 1;
  cursor: default;
  /* min-height: 100px; */
}

.p-package-item-wrapper:hover {
  background-image: linear-gradient(200deg, #ea552b, #ea552b 50%, #ea552b);
}
.p-package-item-wrapper:hover button {
  background-color: rgb(44, 44, 44);
}

.most-popular {
  position: relative;
  height: 1.5555555555555556em;
  max-height: 28px;
  margin-left: 0.25em;
}

.p-build-plan-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em 1em 1em 1.25em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  color: #514d5c;
}

.p-build-plan-button:hover {
  background-color: rgba(132, 94, 247, 0.1);
  color: #ea552b;
}

.p-build-plan-button-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 0.5;
  font-size: 1.5rem;
  line-height: 1;
}

.p-build-plan-button-arrow.text-color-inverse {
  color: #fff;
}

.p-build-plan-button-text {
  margin-right: 0.25em;
  margin-left: 0.75em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: "Inter", sans-serif;
  font-size: 1.3125rem;
  line-height: 1.33em;
  font-weight: 500;
}

.p-build-plan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-money-back {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5em;
  margin-bottom: 5em;
  padding-top: 3em;
  padding-right: 4em;
  padding-left: 6em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-radius: 0.5em;
  background-color: #1a9cfd;
  /* background-image: url('../images/pricing-money-back-bg.svg'); */
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
}

.pricing-money-back-icon {
  height: 2.6666666666666665em;
  min-height: 48px;
  margin-top: 0.5em;
  margin-right: 1.5em;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.pricing-money-back-heading {
  width: 13ch;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 3.111111111111111rem;
  line-height: 1.2em;
  font-weight: 900;
}

.p-money-back-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.p-money-back-bold {
  font-weight: 600;
}

.p-faq-button-expand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
}

.p-faq-expand-icon {
  position: relative;
  top: 0em;
  bottom: 0em;
  margin-left: 0.25em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 1.5rem;
  line-height: 1em;
}

.p-faq-list-extension {
  display: none;
}

.p-faq-list-extension-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta-button-icon {
  margin-right: 0.75em;
  font-size: 0.75rem;
  line-height: 1em;
}

.p-money-back-content-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.pricing-money-back-graphic {
  height: 14em;
  max-width: none;
  margin-top: -20%;
  margin-right: 0px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -o-object-fit: contain;
  object-fit: contain;
}

.section-p-tabs {
  padding-top: 4em;
}

.p-tab-button-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  text-align: center;
  align-items: stretch;
}

.p-tab-button-container.w--current {
  margin: 0px;
  padding: 0px;
  border-right: 1px none #000;
  background-color: transparent;
  color: #fff;
}

.p-tab-comment {
  margin-bottom: 0.5em;
  font-size: 1rem;
}

.p-tab-comment.invisible {
  opacity: 0;
}

.cs-story-thumbnail-square-container {
  overflow: hidden;
}

.cs-story-img-item {
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
  cursor: pointer;
  -o-object-fit: cover;
  object-fit: cover;
}

.cs-story-graphic-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.ow-header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 12em;
  padding-left: 12em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.header-graphic-right {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ow-section-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40vh;
  max-height: 600px;
  padding-top: 4em;
  padding-bottom: 4em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* background-image: url('../images/ow-header-graphic-right.png'), url('../images/ow-header-graphic-left.png'); */
  background-position: 100% 50%, 0% 50%;
  background-size: auto 85%, auto 85%;
  background-repeat: no-repeat, no-repeat;
}

.ow-category-list {
  display: -ms-grid;
  display: grid;
  width: 100%;
  justify-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.ow-modal-project-info {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.ow-designer-name {
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.2857142857142858em;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.ow-modal-thumbnail {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ow-category-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  padding: 0px 0px 0px 2em;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.ow-category-item:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.ow-category-item.web {
  position: relative;
  min-height: 104px;
  margin-right: 0px;
  margin-left: 0px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#bec6c8),
    color-stop(50%, #cdd6d8),
    color-stop(60%, #d7e1e2),
    color-stop(80%, #dfe9ea),
    to(#e0eaec)
  );
  background-image: linear-gradient(
    180deg,
    #bec6c8,
    #cdd6d8 50%,
    #d7e1e2 60%,
    #dfe9ea 80%,
    #e0eaec
  );
  color: #2c2c2c;
}

.ow-category-item.illus {
  position: relative;
  min-height: 104px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #010101;
  color: #fff;
}

.ow-category-item.presentations {
  position: relative;
  overflow: hidden;
  min-height: 104px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #ea552b;
  color: #fff;
}

.ow-category-item._2d-animation {
  position: relative;
  overflow: hidden;
  min-height: 6em;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #362919;
  background-image: none;
  color: #fff;
}

.ow-category-item.infographics {
  position: relative;
  overflow: hidden;
  min-height: 104px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #fff;
  color: #2c2c2c;
}

.button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.button-wrapper.reviews {
  margin-top: 0.5em;
}

.ow-category-info {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ow-modal-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 280px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.ow-modal-designer-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.125em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ow-tab-text {
  white-space: nowrap;
}

.ow-category-name {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
  white-space: nowrap;
}

.ow-modal-overlay {
  position: absolute;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 1em;
  padding-left: 1.5em;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 0.5em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.5))
  );
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
  font-family: "Inter", sans-serif;
  -o-object-fit: cover;
  object-fit: cover;
}

.ow-tab-content {
  padding-top: 2em;
  padding-bottom: 2em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f2f5f8),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #f2f5f8, hsla(0, 0%, 100%, 0));
}

.ow-designer-avatar {
  height: 1.1111111111111112em;
  margin-right: 0.5em;
}

.h4 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
}

.h4.lc-featured-article-mobile {
  letter-spacing: 0.2px;
}

.h4.text-color-purple-600 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  color: #7950f2;
}

.h4.text-color-purple-600.link:hover {
  color: #9775fa;
}

.h4.text-color-inverse {
  color: #fff;
}

.h4.margin-bottom-1em {
  margin-bottom: 1em;
}

.ow-header-sparkle {
  position: absolute;
  left: auto;
  top: -10%;
  right: -3%;
  bottom: auto;
  height: 2em;
  max-width: none;
  margin-right: -2%;
  margin-bottom: -4%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.ow-tab-item {
  height: 100%;
  min-width: 100%;
  padding: 0.5em 1em;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.ow-our-picks {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-top: 1em;
  margin-bottom: 4em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ow-popular-categories {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-bottom: 4em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ow-featured-designs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-bottom: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ow-tab-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ow-featured-designs-wrapper {
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.ow-modal {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
}

.ow-list {
  display: -ms-grid;
  display: grid;
  margin-top: -1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 24% 24% 24% 24%;
  grid-template-columns: 24% 24% 24% 24%;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.ow-list._2 {
  position: relative;
  margin-top: 0em;
}

.ow-list-item {
  position: relative;
  overflow: hidden;
  border-radius: 0.5em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.ow-list-item:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

.ow-modal-full {
  position: relative;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60vw;
  height: 95vh;
  max-width: 1200px;
  margin-top: 5vh;
  padding: 1.5em 1.5em 3em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: auto;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 1fr 12fr;
  grid-template-rows: 1fr 12fr;
  border-radius: 0.8em 0.8em 0em 0em;
  background-color: #fff;
}

.text-block-2 {
  margin-bottom: 0.25em;
  color: #2c2c2c;
  font-size: 1.1428571428571428rem;
  line-height: 1em;
  font-weight: 600;
}

.text-block-2-copy {
  color: #878e96;
  font-size: 1.1428571428571428rem;
  line-height: 1em;
}

.ow-modal-designer-info-full {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 5%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.ow-modal-designer-avatar {
  width: 3em;
  height: 3em;
  margin-right: 0.75em;
  cursor: pointer;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ow-modal-button {
  font-size: 2rem;
  line-height: 1em;
  cursor: pointer;
}

.ow-modal-button:hover {
  color: #ea552b;
}

.ow-modal-button.co-modal.co-pricing-modal {
  position: absolute;
  left: auto;
  top: -3%;
  right: 3%;
  bottom: auto;
  color: #f8f9fa;
}

.img-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ow-sections {
  position: relative;
  z-index: 0;
}

.ow-wrapper {
  position: fixed;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: 5;
  display: none;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.ow-featured-designs-wrapper-2 {
  margin-top: -1em;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.lc-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.button-fill-large {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  background-color: #ea552b;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-fill-large:hover {
  background-color: #2c2c2c;
}

.button-fill-large:active {
  background-color: #2c2c2c;
}

.lc-header-content {
  margin-bottom: 2em;
}

.lc-header {
  width: 100%;
  margin: 1rem 0px 0px;
  padding: 0px;
}

.lc-header-column-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.lc-header-column-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.lc-header-column-right:hover {
  box-shadow: 4px 11px 16px -19px rgba(0, 0, 0, 0.7);
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.lc-featured-wrapper {
  padding-top: 4em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f8f9fa),
    to(#fff)
  );
  background-image: linear-gradient(180deg, #f8f9fa, #fff);
}

.h3 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2rem;
  line-height: 1.25em;
}

.h3.small {
  font-size: 1.75rem;
  line-height: 1.1428571428571428em;
}

.h3.small.margin-bottom-default {
  margin-bottom: 1em;
}

.lc-featured-info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.lc-author-name {
  margin-right: 0.25em;
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 0.875rem;
  line-height: 1em;
  white-space: nowrap;
}

.lc-author-name.lc-all {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.lc-featured-thumbnail-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
}

.lc-featured {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.lc-featured-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 1.5em 0px 0px;
  padding: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.lc-featured-column-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 0px 0px 2em;
  padding: 0px 2em 0px 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.lc-featured-author-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lc-featured-thumbnail {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.lc-featured-item-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  margin: 0px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0.5em;
  background-color: transparent;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
}

.lc-featured-item-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  border-radius: 0.5em;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
}

.h5 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
  letter-spacing: 0.2px;
}

.h5.swipe-anim {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #353a40;
}

.h5.margin-bottom-small {
  margin-bottom: 0.5em;
}

.lc-featured-info-wrapper-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.25em;
  margin-left: 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.lc-author-link {
  margin-right: 0.25em;
  color: #878e96;
  text-decoration: underline;
  cursor: pointer;
}

.link-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #737980;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.link-button:hover {
  color: #ea552b;
}

.link-button.margin-top-default {
  margin-top: 1em;
}

.link-button.purple {
  border-bottom: 0px none transparent;
}

.link-button.purple.margin-bottom-default {
  margin-bottom: 1em;
}

.link-button.white {
  color: #fff;
}

.link-button.blue:hover {
  color: #018ef5;
}

.link-button.checkout {
  display: none;
}

.link-button.purple-2 {
  color: #fff;
}

.link-button.purple-2._1-125-rem {
  margin-left: 0.25em;
  color: #7950f2;
}

.lc-featured-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lc-featured-info {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.lc-featured-info.margin-right-default {
  margin-right: 1em;
}

.lc-featured-thumbnail-wrapper-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 40%;
  height: auto;
  max-height: 144px;
  max-width: 200px;
  min-height: 144px;
  min-width: 200px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
}

.lc-recent-list {
  width: 100%;
  margin-top: 1.5em;
  grid-auto-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.lc-recent-list.bp {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lc-recent-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0.5em;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
}

.lc-recent-info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.lc-recent-thumbnail-wrapper {
  overflow: hidden;
  width: 100%;
  height: 50%;
  min-height: 144px;
  border-radius: 0.5em;
}

.lc-recent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: left;
}

.lc-recent.margin-top-small {
  margin-top: 2vh;
}

.lc-header-img {
  overflow: hidden;
  height: 22em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 0.5em;
  -o-object-fit: cover;
  object-fit: cover;
}

.lc-all-wrapper {
  display: none;
  padding-top: 4em;
  padding-bottom: 4em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#000),
    color-stop(0%, #f2f4f6)
  );
  background-image: linear-gradient(180deg, #000, #f2f4f6 0%);
}

.lc-all {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.lc-all-list {
  width: 100%;
  margin: 2em 0px 0px;
  padding: 0px;
  list-style-type: none;
}

.button-text-filled-gray {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.3em;
  background-color: #e9ecef;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-text-filled-gray:hover {
  border-color: #ea552b;
  background-color: rgba(132, 94, 247, 0.03);
  color: #ea552b;
}

.button-text-filled-gray:active {
  background-color: rgba(132, 94, 247, 0.3);
}

.button-text-filled-gray.lc-all {
  display: inline-block;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.lc-all-thumbnail {
  margin-right: 2em;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
  border-radius: 0.5em;
}

.lc-all-item-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.lc-all-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
  cursor: pointer;
}

.lc-all-item:hover {
  background-color: #e9ecef;
}

.play-button {
  position: absolute;
  width: 29px;
}

.r-client-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.r-stats-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
}

.video-lightbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 5px;
}

.review-tab-menu {
  margin-bottom: 1.5em;
}

.rating-bar {
  overflow: hidden;
  width: 70%;
  height: 1em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 0.2em;
  background-color: #f1f3f5;
}

.link-text-review {
  padding: 0.75em 1em;
  border-radius: 3px;
  background-color: #fff;
  color: #4a5057;
  font-size: 1.1428571428571428rem;
  line-height: 1em;
  font-weight: 500;
  letter-spacing: -0.3px;
  white-space: nowrap;
}

.rating-highlight {
  position: relative;
  left: 0px;
  top: 0px;
  width: 84%;
  height: 100%;
  margin-left: 0px;
  border: 0px none #000;
  border-radius: 0px;
  background-color: #878e96;
}

.rating-highlight._3 {
  width: 3%;
}

.rating-highlight._8 {
  width: 8%;
}

.rating-highlight.h4 {
  width: 4%;
}

.rating-highlight._1 {
  width: 1%;
}

.blue-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  padding-top: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.review-logo-item-mobile {
  display: none;
}

.review-logos-list {
  display: -ms-grid;
  display: grid;
  margin-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 3.5em;
  grid-row-gap: 1em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.stats-title {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1em;
  font-weight: 600;
}

.tab-link-review {
  margin-right: 0.5em;
  padding: 2px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  border-radius: 5px;
  background-color: #e9ecef;
  background-image: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
}

.tab-link-review:hover {
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fd5ff4),
    color-stop(55%, #ea552b),
    to(#31a8fe)
  );
  background-image: linear-gradient(90deg, #fd5ff4, #ea552b 55%, #31a8fe);
}

.tab-link-review.w--current {
  padding: 2px;
  border-radius: 5px;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fd5ff4),
    color-stop(55%, #ea552b),
    to(#31a8fe)
  );
  background-image: linear-gradient(90deg, #fd5ff4, #ea552b 55%, #31a8fe);
  color: #000;
  font-weight: 600;
}

.r-item-content {
  margin-top: 1em;
}

.video-title {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 600;
}

.video-thumbnail-imge {
  width: 100%;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -o-object-fit: cover;
  object-fit: cover;
}

.r-header-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.r-client-info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-reviews {
  padding-top: 3em;
  padding-bottom: 3em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f3f5),
    color-stop(80%, #f1f3f5),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(
    180deg,
    #f1f3f5,
    #f1f3f5 80%,
    hsla(0, 0%, 100%, 0)
  );
}

.r-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  list-style-type: none;
}

.r-item {
  margin-bottom: 1em;
  padding: 1.5em;
  border-radius: 0.5em;
  background-color: #fff;
}

.review-video-lighbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  max-width: 50%;
  margin-right: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #878e96;
  cursor: pointer;
  -o-object-fit: cover;
  object-fit: cover;
}

.review-video-lighbox:hover {
  color: #ea552b;
}

.rating {
  margin-top: 0.5rem;
}

.review-tab_component {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.review-left {
  position: relative;
  top: -177px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 306px;
  min-width: 306px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.review-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #2c2c2c;
  font-size: 18px;
  font-weight: 700;
}

.review-link:hover {
  background-color: #f8f9fa;
}

.review-video-wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 1rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.r-client-name {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.r-time {
  font-family: "Inter", sans-serif;
  color: #aeb5bd;
  font-size: 0.875rem;
  line-height: 1.4285714285714286em;
}

.right-review {
  width: 100%;
  margin-left: 5em;
}

.r-stats {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: -2em;
  padding-top: 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border: 1px solid #f1f3f5;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  text-align: center;
}

.reviews-tab {
  margin-top: 1rem;
}

.stats-subtitle {
  color: #878e96;
  font-size: 1rem;
  line-height: 1em;
}

.video-item {
  border-radius: 5px;
  background-color: #fafafa;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.video-item:hover {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.blue-bar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 2em;
  padding-bottom: 2em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #31a8fe;
}

.video-description {
  font-family: "Inter", sans-serif;
  color: #737980;
  font-size: 0.875rem;
  line-height: 1.4285714285714286em;
}

.video-details {
  padding-top: 0.75em;
  padding-right: 1em;
  padding-bottom: 0.75em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.star-title {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.r-stats-list {
  width: 100%;
  margin-top: 1.5em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.r-videos {
  margin-top: 2rem;
}

.review-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-assets {
  display: -ms-grid;
  display: grid;
  margin-top: 37px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.text-align-center {
  text-align: center;
}

.assets-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 4em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.assets-item:hover {
  box-shadow: -3px 4px 7px 0 rgba(0, 0, 0, 0.02);
}

.download-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 0.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ea552b;
  font-weight: 700;
  text-decoration: none;
}

.download-button:hover {
  color: #9775fa;
}

.asset-item {
  width: 100%;
  max-width: 408px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.featured-bar {
  width: 100%;
  height: 1px;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #e9ecef;
}

.media-details {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
}

.assets-thumbnail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 85px;
  max-width: 126px;
  margin-right: 2rem;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px 0px 0px 5px;
  background-color: #ea552b;
}

.assets-thumbnail.grey {
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #f1f3f5;
}

.mk-inquiries {
  margin-top: 2em;
  padding: 1em;
  border-radius: 0.3em;
  background-color: #f1f3f5;
  color: #878e96;
  font-size: 1rem;
  line-height: 1.75em;
  text-align: center;
}

.brand-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.media-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.media-thumbnail {
  overflow: hidden;
  height: 246px;
  border-radius: 0.5em;
}

.media-thumbnail.bg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  /* background-image: url('../images/Frame-4883-1.svg'); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.brand-container {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 1fr 1.5fr;
  grid-template-columns: 1fr 1.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.media-list {
  display: -ms-grid;
  display: grid;
  margin-top: 4em;
  grid-auto-columns: 1fr;
  grid-column-gap: 42px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.assets-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  font-family: "Inter", sans-serif;
  color: #7950f2;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.assets-title:hover {
  color: #7950f2;
}

.media-title {
  margin-bottom: 0.75em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.r-header-wrapper {
  position: relative;
  padding-top: 4em;
  padding-bottom: 6em;
}

.r-header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px 0px 4em;
}

.r-header-rating-graphic {
  margin-right: 1em;
}

.r-header-video {
  position: absolute;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.8em;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04), 0 1px 6px 0 rgba(0, 0, 0, 0.04),
    0 0 1px 0 rgba(0, 0, 0, 0.04);
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  cursor: pointer;
}

.r-header-video.z-index-2 {
  position: absolute;
  z-index: 2;
  -webkit-transform: scale(0.8) translate(20%, 0px);
  -ms-transform: scale(0.8) translate(20%, 0px);
  transform: scale(0.8) translate(20%, 0px);
}

.r-header-thumbnail {
  height: 100%;
  max-width: none;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: all 200ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition: all 200ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  -o-object-fit: cover;
  object-fit: cover;
}

.r-header-thumbnail:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.r-icon-play {
  position: absolute;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 3em;
  height: 3em;
  padding-left: 0.125em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4em;
  background-color: #fff;
  color: #ea552b;
  font-size: 2rem;
  line-height: 1em;
}

.r-icon-play:hover {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.r-header-column-left {
  margin: 0px;
  padding-right: 3em;
  padding-bottom: 0px;
  padding-left: 0px;
}

.icon-play-alt {
  position: absolute;
}

.r-header-column-right {
  position: absolute;
  left: auto;
  top: -8%;
  right: 0%;
  bottom: 0%;
  height: 100.00009%;
  margin: 0px;
  padding: 0px 0px 0px 2em;
}

.r-header-thumbnail-info {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  padding: 1.5em;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5),
    hsla(0, 0%, 100%, 0)
  );
  color: #fff;
}

.r-customer-name {
  margin-bottom: 0.25em;
  line-height: 1em;
  font-weight: 600;
}

.r-customer-info {
  font-size: 1rem;
  line-height: 1em;
  font-weight: 400;
}

.r-stats-content {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.rating-text {
  width: 10%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 1rem;
  line-height: 1em;
}

.rating-percentage {
  width: 15%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 1rem;
  line-height: 1em;
  text-align: left;
}

.ow-tab-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 1em 1%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.work-with-designer {
  position: absolute;
  left: 1.5em;
  top: 5em;
  right: auto;
  bottom: auto;
  z-index: 5;
  max-width: none;
  margin-top: auto;
  margin-left: 0px;
  cursor: pointer;
}

.ow-category-graphic {
  position: absolute;
  left: auto;
  top: auto;
  right: -3%;
  bottom: auto;
  height: 100%;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.ow-category-graphic.gif {
  right: 0%;
  height: 150%;
}

.gradient-overlay-brown {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(60%, #362919),
    color-stop(65%, rgba(54, 41, 25, 0))
  );
  background-image: linear-gradient(
    90deg,
    #362919 60%,
    rgba(54, 41, 25, 0) 65%
  );
}

.image-4 {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  -o-object-fit: fill;
  object-fit: fill;
}

.helper-swipe {
  display: none;
  max-height: 300px;
  padding: 0.25em 1em 0.25em 0.75em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 4em;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.image-5 {
  height: 2em;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 5em;
  margin-top: -5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.tabs-menu.p-modal {
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  background-color: #222;
}

.p-tab-text {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #333;
}

.p-tab-text.radius-top-right {
  border-top-right-radius: 0.5em;
}

.p-tab-text.radius-top-left {
  margin-bottom: 0px;
  border: 1px none #000;
  border-top-left-radius: 0.5em;
}

.tabs-content {
  margin-top: 3em;
}

.tabs-content.p-modal {
  margin: 2em 2em -2em;
}

.p-package-item-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  padding: 2px;
  border-radius: 0.6em;
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
  opacity: 1;
}

.p-package-item-bg:hover {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.wp-swap-icon {
  margin-right: 0.25em;
  color: #e762bc;
  font-size: 3rem;
}

.p-tabs-wrapper {
  padding-bottom: 1em;
}

.p-tabs-wrapper.p-modal {
  position: relative;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background-color: #fff;
}

.r-modal-wrapper {
  position: fixed;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  z-index: 1001;
  display: none;
  width: 100%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.r-modal {
  position: relative;
  top: auto;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  margin: auto;
  padding: 2em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-auto-columns: auto;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 1fr 12fr;
  grid-template-rows: 1fr 12fr;
  border-radius: 0.8em;
  background-color: #fff;
}

.r-modal-button {
  font-size: 2rem;
  line-height: 1em;
  cursor: pointer;
}

.r-modal-button:hover {
  color: #ea552b;
}

.r-modal-info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.r-button-icon {
  margin-right: 0.5em;
  font-size: 1.125rem;
  line-height: 1em;
}

.div-block-12 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p-package-price-wrapper {
  margin-top: 1em;
  margin-bottom: 1em;
}

.p-package-savings {
  margin-top: 0.25em;
  color: #40c057;
  font-weight: 600;
}

.icon-play-svg {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20em;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  font-size: 2rem;
}

.icon-play-svg:hover {
  color: #ea552b;
}

.button-endenhancer-gray-large-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0em 0em 0em 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  background-color: transparent;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-endenhancer-gray-large-link:hover {
  color: #2c2c2c;
}

.button-endenhancer-gray-large-link:active {
  color: #ea552b;
}

.r-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1em;
}

.r-mobile-header-wrapper {
  display: none;
}

.list {
  display: none;
}

.r-video-type {
  font-size: 1rem;
  line-height: 1em;
  font-weight: 400;
}

.r-logo-list-mobile-grid {
  display: none;
}

.paragraph-small {
  color: #878e96;
  font-size: 1rem;
  line-height: 1.5em;
}

.paragraph-small.h-testimonial {
  margin-top: 1em;
}

.paragraph-small.margin-top-small {
  margin-top: 0.5em;
}

.paragraph-small.margin-bottom-large {
  margin-bottom: 2em;
}

.mk-header-wrapper {
  padding-top: 4em;
  padding-bottom: 3em;
}

.mk-brand-wrapper {
  padding-top: 3em;
  padding-bottom: 6em;
}

.html-embed-2 {
  margin-right: 0.5em;
  font-size: 1.25rem;
  line-height: 1em;
}

.mk-bg {
  /* background-image: url('../images/wp-header-bg-left.svg'), url('../images/wp-header-bg-right.svg'); */
  background-position: 0px 0px, 100% 0%;
  background-size: auto, auto;
  background-repeat: no-repeat, no-repeat;
}

.mk-thumb {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.tos-header-wrapper {
  padding-top: 4em;
  padding-bottom: 4em;
}

.tos-header {
  text-align: center;
}

.r-item-text-default {
  position: relative;
  margin-top: 0.5em;
  font-family: "Inter", sans-serif;
  color: #737980;
  font-size: 1rem;
  line-height: 1.5em;
  text-align: left;
}

.r-item-text-strong {
  color: #2c2c2c;
  line-height: 1.33em;
  font-weight: 600;
}

.r-item-text-strong.hidden {
  display: none;
}

.r-item-text-link {
  text-decoration: underline;
  cursor: pointer;
}

.r-client-img {
  position: relative;
  overflow: hidden;
  width: 3em;
  height: 3em;
  max-height: 3em;
  max-width: 543em;
  margin-right: 0.5em;
  border-radius: 4em;
  background-color: #e5dbff;
  color: #2c2c2c;
}

.r-client-img.green {
  background-color: #b2f2bb;
  color: #2f9e44;
}

.r-client-img.pink {
  background-color: #ffe9fe;
  color: #e745dd;
}

.r-client-rating {
  height: 1.1111111111111112em;
  margin-top: 0.25em;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.r-client-name-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.r-lightbox {
  width: 100%;
  height: 100%;
}

.r-lightbox-img {
  width: 100%;
  height: 100%;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
}

.r-thumbnail-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 1em 0px 0px;
  padding: 0px;
  list-style-type: none;
}

.r-thumbnail-item {
  overflow: hidden;
  width: 4em;
  height: 4em;
  margin-right: 0.5em;
  border-radius: 0.3em;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  cursor: pointer;
}

.r-thumbnail-item:hover {
  -webkit-filter: brightness(80%);
  filter: brightness(80%);
}

.r-client-alt-text {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 700;
  text-align: center;
}

.r-list-heading {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.container-2 {
  position: relative;
  width: 85vw;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.button-blue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 42px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #31a8fe;
  border-radius: 5px;
  background-color: #31a8fe;
  box-shadow: none;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.2px;
  text-decoration: none;
}

.button-blue:hover {
  border-color: #209af1;
  background-color: #209af1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.button-blue.alt {
  margin-top: 1.5em;
  padding: 1em 3em;
  border-style: none;
  border-color: #31a8fe;
  background-color: #31a8fe;
}

.button-blue.alt:hover {
  border-style: none;
  background-color: #018ef5;
  box-shadow: 0 0 5px 0 rgba(49, 168, 254, 0.2),
    0 5px 10px 0 rgba(49, 168, 254, 0.2), 0 10px 20px 0 rgba(49, 168, 254, 0.2),
    0 20px 40px 0 rgba(49, 168, 254, 0.1);
  -webkit-transform: translate(0px, -1px);
  -ms-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
  mix-blend-mode: normal;
}

.button-blue.alt:active {
  background-color: #0173c6;
}

.text-center {
  text-align: center;
}

.hero-header-con {
  z-index: 999;
  display: inline-block;
}

.h-header-graphic {
  position: relative;
  z-index: 3;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.h-header-wrapper {
  position: relative;
  /* background-image: url('../images/Cloud-Right.svg'), url('../images/Background-Building-right.svg'), url('../images/Cloud-left.svg'), url('../images/Background-Building-left.svg'); */
  background-position: 100% 0%, 100% 100%, 0% 0%, 0% 100%;
  background-size: auto 20%, auto 80%, auto 20%, auto 80%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.h-header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4em 8em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.pub-card {
  position: relative;
  overflow: hidden;
  padding: 2em;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: none;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform: perspective(500px);
  transform: perspective(500px);
  -webkit-transition: box-shadow 250ms ease, opacity 200ms ease;
  transition: box-shadow 250ms ease, opacity 200ms ease;
  color: #2c2c2c;
}

.pub-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
}

.pub-card.blue {
  overflow: hidden;
  background-color: #31a7fd;
  /* background-image: url('../images/BG-Blue.svg'); */
  background-position: 100% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.btn-grey {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  border-radius: 7px;
  background-color: #e9ecef;
  text-decoration: none;
}

.btn-grey.header {
  position: absolute;
  left: auto;
  top: auto;
  right: -13vw;
  bottom: 0%;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
}

.btn-grey.header:hover {
  background-color: #d4d6d8;
}

.btn-grey.pub {
  position: absolute;
  left: auto;
  top: auto;
  right: -13vw;
  bottom: 0%;
  -webkit-transition: background-color 250ms ease;
  transition: background-color 250ms ease;
}

.btn-grey.pub:hover {
  background-color: #d4d6d8;
}

.pub-card-deets {
  position: relative;
  margin-right: -10px;
  text-align: right;
}

.pub-card-con {
  display: -ms-grid;
  display: grid;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pub-card-con.destop {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
}

.pub-card-con.mbl {
  display: none;
}

.image {
  position: absolute;
  left: auto;
  top: auto;
  right: 5%;
  bottom: -10%;
  height: 110%;
  max-width: none;
  -o-object-fit: contain;
  object-fit: contain;
}

.pub-card-rev {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1em;
}

.pub-card-title {
  width: 15ch;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  font-size: 2.375rem;
  line-height: 1.1578947368421053em;
  font-weight: 900;
}

.pub-card-rate {
  font-size: 36px;
  line-height: 48px;
  font-weight: 900;
}

.pub-card-rate.color--white {
  margin-right: 10px;
}

.pub-card-b-sm {
  font-size: 12px;
  line-height: 20px;
}

.btn-grey-text {
  padding: 10px 20px;
  background-color: transparent;
  color: #353a40;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.pub-card-icons {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  overflow: visible;
  height: 150px;
  margin-top: 20px;
  margin-right: -40px;
  margin-left: -40px;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform: perspective(500px);
  transform: perspective(500px);
}

.circle-pub-bg {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.circle-pub {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
}

.circle-pub.lya {
  left: 63%;
  top: 63%;
}

.circle-pub.ph {
  left: 87%;
  top: 60%;
}

.circle-pub.gray {
  left: 88%;
  top: 25%;
}

.circle-pub.pepboys {
  left: 48%;
  top: 57%;
}

.circle-pub.kohls {
  left: 80%;
  top: 60%;
}

.circle-pub.ryan {
  left: 57%;
  top: 25%;
}

.circle-pub.reebok {
  left: 2%;
  top: auto;
  right: auto;
  bottom: -5%;
}

.circle-pub.ups {
  left: 8%;
  top: 10%;
}

.circle-pub.bestbuy {
  left: 43%;
}

.circle-pub.cvs {
  left: 64%;
}

.circle-pub.uber {
  left: 28%;
  top: 40%;
}

.image-64 {
  position: absolute;
  left: 0%;
  top: 48%;
  right: auto;
  bottom: auto;
  z-index: 0;
}

.work-category {
  position: relative;
  top: -60px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 20px;
}

.work-category.color--gray-900.text-lg.semi-bold.text-center {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: inline-block;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 3px 3px 5px 4px rgba(0, 0, 0, 0.25);
}

.work-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-caption {
  font-family: "Inter", sans-serif;
  color: #868e96;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.work-card-link {
  margin-right: 1.5vw;
  text-decoration: none;
}

.work-avatar {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.work-card-img {
  width: 100%;
  height: 100%;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
}

.work-cards-base {
  margin-top: 70px;
}

.work-cards-con {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.work-card-img-con {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 20vw;
  border-radius: 10px;
  text-align: center;
}

.work-avatar-con {
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 50%;
}

.work-card {
  width: 20vw;
  border-radius: 5px;
}

.h-portfolio-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 60px;
}

.right-arrow-50x50 {
  right: -26px;
  width: 50px;
  height: 50px;
  border: 1px solid #f1f3f5;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #737980;
}

.right-arrow-50x50:hover {
  border-color: #9775fa;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transform: translate(1px, 0px);
  -ms-transform: translate(1px, 0px);
  transform: translate(1px, 0px);
  color: #ea552b;
}

.right-arrow-50x50:active {
  background-color: #ea552b;
  color: #fff;
}

.icon-2 {
  font-size: 17px;
  line-height: 17px;
  font-weight: 600;
}

.left-arrow-50x50 {
  left: -26px;
  width: 50px;
  height: 50px;
  border: 1px solid #f1f3f5;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #737980;
}

.left-arrow-50x50:hover {
  border-color: #ea552b;
  -webkit-transform: translate(-1px, 0px);
  -ms-transform: translate(-1px, 0px);
  transform: translate(-1px, 0px);
  color: #ea552b;
}

.left-arrow-50x50:active {
  background-color: #ea552b;
  color: #fff;
}

.testimonial-slider {
  height: 271px;
  max-width: 1102px;
  margin-right: auto;
  margin-left: auto;
  background-color: transparent;
}

.div-block-100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50ch;
  margin-right: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image-70 {
  display: inline-block;
  width: 22px;
  height: 21px;
}

.mr-16 {
  margin-right: 16px;
}

.slider-wraper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 2.5em 3em 2.5em 4em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1.5px solid #f1f3f5;
  border-radius: 10px;
  /* background-image: url('../images/Slider-bg.svg'); */
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.hide {
  display: none;
}

.h-testimonial-small {
  position: relative;
  overflow: hidden;
  padding-bottom: 6em;
}

.hiw-arrow {
  position: relative;
  height: 1em;
  margin-left: -0.5em;
}

.h-hiw-step-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.h-hiw-step-wrapper.alt {
  margin-top: 6em;
}

.hiw-arrow-wrapper {
  margin-top: 8em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.hiw-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.h-hiw-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.333333333333336%;
  max-width: 22.22222222222222em;
  padding-right: 2em;
  padding-left: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.h-hiw-item-description-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.why-us-wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 4em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: auto;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.testi-img-person {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testi-img-person.mobile {
  display: none;
}

.h-testimonial-quote {
  margin-left: -0.5em;
}

.test-img-el {
  position: absolute;
  left: auto;
  top: 10%;
  right: 10%;
  bottom: auto;
  z-index: 2;
  width: 175px;
}

.test-img-el._2 {
  top: 45%;
  right: 5%;
  z-index: 3;
  width: 120px;
}

.test-img-el._2.bulb {
  top: 31%;
  right: 0%;
  width: 220px;
}

.test-img-el._2.holm {
  display: none;
}

.test-img-el._3 {
  top: 51%;
  right: 25%;
  z-index: 1;
  width: 120px;
}

.test-img-el._3.holm {
  display: none;
}

.test-img-el._1.holmes {
  top: 12%;
  right: -6%;
  width: 220px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
}

.test-img-el._1.holmes.lance {
  top: 9%;
  width: 200px;
  box-shadow: none;
}

.test-img-el._1.holmes.lance._2 {
  top: 44%;
  right: 24%;
  width: 180px;
}

.test-img-el._2 {
  top: 45%;
  right: 5%;
  z-index: 3;
  width: 120px;
}

.test-img-el._3 {
  top: 51%;
  right: 25%;
  z-index: 1;
  width: 120px;
}

.h-testimonial-column {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
}

.h-testimonial-left {
  padding-right: 0px;
  padding-left: 0px;
}

.h-testimonial-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4em 8em 4em 6em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.h-testimonial-slide {
  position: relative;
  top: 2em;
  overflow: hidden;
  height: 85%;
  margin: 0.25em 1em 4em;
  border-radius: 0.5em;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.06), 0 2px 6px 0 rgba(0, 0, 0, 0.04),
    0 0 1px 0 rgba(0, 0, 0, 0.04);
}

.h-testimonial-client-name {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 700;
}

.h-testimonial-large-client-info {
  margin-top: 2em;
}

.faq-intercom {
  position: relative;
}

.faq-intercom-graphic-button {
  position: absolute;
  left: auto;
  top: 37%;
  right: 10%;
  bottom: auto;
  z-index: 3;
  -webkit-transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.faq-intercom-graphic-button:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.faq-intercom-graphic-button:active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.faq-intercom-graphic {
  z-index: 1;
  min-height: 7em;
  min-width: 7em;
}

.h-testimonials-wrapper {
  padding-top: 4em;
  padding-bottom: 2em;
}

.h-testimonials-slider {
  height: auto;
  background-color: transparent;
}

.left-arrow-5 {
  left: -2%;
  bottom: 1.75em;
}

.right-arrow-5 {
  right: -2%;
  bottom: 1.75em;
}

.slide-nav-2 {
  position: relative;
  font-size: 1.1428571428571428rem;
}

.h-testimonial-slide-wrapper {
  height: 32em;
}

.h-testimonial-img {
  height: 100%;
}

.h-social-proof {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: -1em;
  padding-top: 2em;
  padding-bottom: 4em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f5f5f5),
    color-stop(89%, rgba(248, 249, 250, 0))
  );
  background-image: linear-gradient(
    180deg,
    #f5f5f5,
    rgba(248, 249, 250, 0) 89%
  );
}

.h-header-graphic-alt {
  position: relative;
  z-index: 3;
  display: none;
  margin-right: auto;
  margin-left: auto;
}

.h-spotlight-wrapper {
  padding-top: 6em;
  padding-bottom: 6em;
  /* background-image: url('../images/h-spotlight-graphic-left.png'), url('../images/h-spotlight-graphic-right.png'); */
  background-position: 0px -20vh, 100% -20vh;
  background-size: auto 120%, auto 120%;
  background-repeat: no-repeat, no-repeat;
}

.h-spotlight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ds-card-thumbnail {
  width: 100%;
  height: 100%;
  max-width: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  -o-object-fit: cover;
  object-fit: cover;
}

.h-hiw-item-heading {
  width: 16ch;
  margin-left: 0.75em;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.h-hiw-item-number {
  font-family: "Inter", sans-serif;
  font-size: 4.25rem;
  line-height: 1em;
  font-weight: 700;
}

.h-hiw-item-subheading {
  margin-top: 0.25em;
  color: #d8cef7;
}

.h-hiw-item-subheading.alt {
  color: #737980;
}

.h-hiw-wrapper {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-top: 6em;
  padding-bottom: 8em;
  /* background-image: url('../images/How-it-works-background.svg'); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.h-benefits-wrapper {
  padding-top: 2em;
  padding-bottom: 8em;
}

.h-reviews-wrapper {
  z-index: 1;
  overflow: hidden;
  padding-top: 4em;
  padding-bottom: 2em;
  background-color: #31a8fe;
}

.h-reviews-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.h-reviews {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
}

.h-reviews-logo-list {
  width: 50%;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: auto;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: auto auto auto auto;
  grid-template-columns: auto auto auto auto;
}

.h-reviews-logo {
  min-height: 2.52em;
  min-width: auto;
}

.h-hiw-item-description {
  margin-top: 2em;
}

.h-hiw-item-description.alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.h-hiw-item-graphic {
  max-height: 13.333333333333334em;
  margin-left: -3em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: -webkit-grab;
  cursor: grab;
}

.h-hiw-item-graphic:hover {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.h-hiw-item-graphic.alt {
  margin-left: 0em;
}

.h-portfolio-heading {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2em;
  padding-right: 8em;
  padding-left: 8em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.header-press-logo-list {
  display: -ms-grid;
  display: grid;
  margin-top: 1em;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  justify-items: center;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 3em;
  grid-row-gap: 1em;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content max-content max-content
    max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.press-logo-list-heading {
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 0.75rem;
  line-height: 1em;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.press-logo-list-heading.margin-bottom-default {
  margin-bottom: 1em;
}

.div-block-117 {
  position: absolute;
  left: 2em;
  right: 2em;
  bottom: 1em;
  z-index: 3;
}

.h-portfolio-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-radius: 0.3em;
  background-color: #e9ecef;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.h-portfolio-btn:hover {
  border-style: none;
  background-color: #ea552b;
  color: #fff;
}

.h-portfolio-btn:active {
  background-color: #2c2c2c;
}

.h-portfolio-thumbnail {
  z-index: 2;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -o-object-fit: cover;
  object-fit: cover;
}

.h-portfolio-category {
  position: absolute;
  bottom: 1.5em;
  z-index: 10;
  display: block;
  height: 2.5em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.5em 1.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 4em;
  background-color: #fff;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.1);
  color: #353a40;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.2px;
}

.h-portfolio-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 0.5em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
  -o-object-fit: cover;
  object-fit: cover;
}

.h-portfolio-item:hover {
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.05),
    0 10px 15px 0 rgba(0, 0, 0, 0.1), 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.h-portfolio-designer-avatar {
  max-height: 2em;
  max-width: 2em;
  min-height: 2px;
  min-width: 2em;
}

.h-portfolio-designer-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h-portfolio-designer-name {
  margin-left: 1em;
  font-family: "Lab Grotesque Mono", Arial, sans-serif;
  color: #878e96;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
}

.h-portfolio-designer-name.cms {
  margin-left: 0.5em;
}

.text-span {
  color: #353a40;
  font-weight: 600;
}

.h-testimonial-small-client-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-span-2 {
  margin-left: -0.5em;
}

.link-block {
  cursor: pointer;
}

.mk-link {
  color: #737980;
  font-weight: 600;
  cursor: pointer;
}

.mk-link:hover {
  color: #ea552b;
}

.page-wrapper {
  position: relative;
  overflow: hidden;
}

.h-spotlight-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60ch;
  max-width: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.h-hiw-item-graphic-alt {
  display: none;
  max-height: 13.333333333333334em;
  margin-left: -3em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: -webkit-grab;
  cursor: grab;
}

.h-hiw-item-graphic-alt:hover {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.h-social-proof-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0em;
  margin-bottom: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-61 {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
}

.faq-drop {
  display: block;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 15px;
  border: 1px solid #f1f3f5;
  border-radius: 10px;
  background-color: #f1f3f5;
}

.semi-bold {
  font-weight: 600;
}

.semi-bold.mbl-block {
  color: #f8f9fa;
}

.hiw-process-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.how-p {
  font-family: "Inter", sans-serif;
  color: #4a5057;
  font-size: 1.125rem;
  line-height: 28px;
  font-weight: 400;
}

.hiw-step-number {
  min-height: 2em;
  margin-right: 0.5em;
}

.color--white {
  color: #fff;
}

.hiw-process-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  list-style-type: none;
}

.service-icon {
  width: 1.3333333333333333em;
  height: 1.3333333333333333em;
  margin-right: 0.75em;
}

.text-field {
  margin-bottom: 0px;
  border: 2px solid #f1eeff;
}

.text-field:focus {
  border-color: #7757ff;
}

.text-field.search-lg {
  position: relative;
  height: 3.5rem;
  padding: 1.5em 1.5em 1.5em 3.5em;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 5px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  font-size: 1.125rem;
}

.text-field.search-lg:focus {
  border-color: #7757ff;
}

.sos-list-wrapper.margin-60 {
  margin-top: 3em;
}

.sidebar-wrapper {
  margin-bottom: 3em;
}

.sidebar-wrapper.margin-disable {
  margin-bottom: 0em;
}

.sos-header-wrapper {
  min-height: 30vh;
  padding-top: 6em;
  padding-bottom: 6em;
}

.software-inner-wrapper {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sos-list-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1em;
  font-weight: 600;
}

.sos-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sos-list-item-text {
  font-size: 1rem;
  line-height: 1em;
}

.service-inner-wrapper {
  margin-left: 25px;
}

.service-inner-wrapper.collection-list {
  color: #2c2c2c;
}

.chat-icon {
  margin-right: 0.5em;
}

.sos-search-wrapper {
  position: relative;
  top: -3em;
  width: 70%;
  padding: 2em;
  border-radius: 5px;
  background-color: #fff;
}

.filters-wrapper {
  width: 100%;
}

.software-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.service-block {
  padding: 1em;
  border-radius: 0.3em;
  background-color: #f1f3f5;
}

.service-block.transparent {
  background-color: transparent;
}

.chat-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-decoration: none;
  cursor: pointer;
}

.chat-btn:hover {
  background-color: #ea552b;
  color: #fff;
}

.chat-btn:active {
  background-color: #2c2c2c;
}

.search-bar {
  margin: 0px;
  padding: 0px;
}

.search-bar.search-icon {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.filters-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.sos-icon {
  width: 1.5em;
  margin-right: 0.75em;
  font-size: 1rem;
  line-height: 1em;
  text-align: center;
}

.custom-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 205px;
  height: 56px;
  padding: 15px 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(132, 94, 247, 0.05),
    0 0 2px 0 rgba(132, 94, 247, 0.05), 1px 1px 8px 0 rgba(132, 94, 247, 0.05);
  color: #ea552b;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-decoration: none;
}

.custom-btn.blue {
  background-color: #31a8fe;
  color: #fff;
}

.custom-btn.blue.margin-24 {
  margin-left: 24px;
}

.sos-sidebar {
  width: 25%;
  margin-top: 3em;
}

.form-label {
  margin-bottom: 0.5em;
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1.375em;
  font-weight: 600;
}

.wd-form-field {
  width: 100%;
  height: 3.5em;
  margin-bottom: 2em;
  padding: 1em;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  background-color: transparent;
}

.wd-form-field._w-select.margin-bottom-30 {
  margin-bottom: 1em;
  padding: 1em;
  color: #878e96;
}

.wd-header-content {
  width: 55ch;
  color: #fff;
}

.form-wrapper {
  margin-top: 12px;
}

.form-bottom-text {
  margin-top: 1.5em;
  color: #878e96;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
  text-align: center;
}

.wd-list-wrapper {
  margin-top: 2.5em;
}

.wd-form {
  margin-bottom: 0px;
}

.wd-header-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: auto;
  max-width: 24em;
  min-width: 24em;
  padding: 2em 2em 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0.5em;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.04);
}

.recaptcha {
  margin-top: 1em;
  margin-bottom: 1em;
}

.hiw-header-bg {
  position: relative;
  overflow: hidden;
  /* background-image: url('../images/hiw-header-bg.svg'); */
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.hiw-header-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 35vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hiw-header {
  position: relative;
  margin-top: -1em;
  color: #fff;
  text-align: center;
}

.hiw-list-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1em;
  margin-left: 1em;
  opacity: 0.5;
  font-size: 1.5rem;
  line-height: 1;
}

.hiw-process-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f1f3f5;
}

.hiw-steps-wrapper {
  padding-top: 6em;
  padding-bottom: 6em;
}

.hiw-steps {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hiw-step-wrapper {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.hiw-step-wrapper.step-2 {
  top: -15em;
}

.hiw-step-wrapper.step-3 {
  top: -30em;
}

.hiw-step-wrapper.step-4 {
  top: -45em;
}

.hiw-step-info {
  max-width: 28em;
}

.hiw-step-info.step-2 {
  margin-right: 0em;
  margin-left: 4em;
}

.hiw-step-info.step-3 {
  margin-right: 6em;
}

.hiw-step-graphic {
  min-height: 24em;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: -webkit-grab;
  cursor: grab;
}

.hiw-step-graphic:hover {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.hiw-step-graphic.alt {
  max-width: 32em;
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}

.hiw-step-heading {
  margin-bottom: 0.25em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3rem;
  line-height: 1.1666666666666667em;
  font-weight: 900;
  letter-spacing: 0.2px;
}

.hiw-step-faq-list {
  margin-top: 2em;
}

.hiw-connector {
  position: relative;
  top: -5em;
  z-index: 1;
  max-width: none;
  min-width: 60%;
}

.hiw-connector.step-2 {
  position: relative;
  top: -25em;
}

.hiw-connector.step-3 {
  top: -35em;
  right: 5em;
}

.hiw-features-wrapper {
  position: relative;
  z-index: 300;
  padding-top: 6em;
  padding-bottom: 6em;
  background-color: #f1f3f5;
  /* background-image: url('../images/hiw-features-bg-right.svg'), url('../images/hiw-features-bg-left.svg'); */
  background-position: 100% 100%, 0px 0px;
  background-size: auto, auto;
  background-repeat: no-repeat, no-repeat;
}

.hiw-features {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hiw-features-heading {
  margin-bottom: 1em;
}

.everything-outline {
  height: 4rem;
  margin-top: 0.5em;
}

.everything-outline.margin-disable {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}

.hiw-features-content {
  max-width: 31ch;
  margin-right: 4em;
}

.hiw-features-list {
  grid-auto-columns: auto;
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  -ms-grid-columns: 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 0.5fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.button-filled-blue {
  display: inline-block;
  margin-top: 1.5em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.75em 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #31a8fe;
  border-radius: 5px;
  background-color: #31a8fe;
  box-shadow: none;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.2px;
  text-decoration: none;
}

.button-filled-blue:hover {
  border-color: #209af1;
  background-color: #209af1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.button-filled-blue.margin-top-huge {
  margin-top: 2em;
}

.button-filled-blue.margin-top-huge.hidden.alt {
  margin-top: 1em;
}

.button-filled-blue.margin-top-huge.hidden.alt.block {
  display: block;
  box-shadow: none;
}

.button-filled-blue.margin-top-huge.hidden.alt.block:hover {
  background-color: #018ef5;
}

.button-filled-blue.margin-top-huge.hidden.alt.block:active {
  background-color: #0173c6;
}

.hiw-feature-icon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 3em;
  height: 3em;
  min-height: 3em;
  min-width: 3em;
  margin-right: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 4em;
  background-color: #fff;
}

.hiw-feature-icon-wrapper.alt {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f5;
}

.hiw-feature-icon {
  width: 2em;
  height: 2em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.hiw-feature-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  white-space: break-spaces;
}

.hiw-feature-heading {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.hiw-feature-paragraph {
  color: #5c6166;
  font-size: 1rem;
  line-height: 1.5em;
}

.hiw-feature-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.hiw-reviews-wrapper {
  z-index: 1;
  overflow: hidden;
  padding-top: 6em;
  padding-bottom: 2em;
  -webkit-transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hiw-reviews-content {
  padding-right: 12em;
  padding-bottom: 2em;
  padding-left: 12em;
  color: #2c2c2c;
  text-align: center;
}

.hiw-reviews-subheading {
  margin-left: 1em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #353a40;
  line-height: 1.33em;
  font-weight: 600;
}

.hiw-bottom-wrapper {
  margin-top: -40em;
}

.hiw-header-graphic {
  position: absolute;
  left: auto;
  top: 5vh;
  right: -5vw;
  bottom: auto;
  height: 90%;
}

.hiw-header-graphic.left {
  position: absolute;
  left: -4vw;
  top: 10vh;
  right: auto;
  bottom: 0%;
  height: 60%;
  max-width: none;
}

.p-tab-text-small {
  font-size: 1rem;
  font-weight: 600;
}

.wd-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.wd-list {
  margin-top: 1em;
  list-style-type: none;
}

.wd-list-icon {
  display: inline;
  margin-right: 0.5em;
  font-size: 1.5rem;
}

.wd-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.75em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.wd-form-heading {
  font-family: "Inter", sans-serif;
  color: #353a40;
  font-size: 1.75rem;
  line-height: 1.1428571428571428em;
}

.wd-form-icon {
  margin-right: 0.5em;
  color: #ea552b;
  font-size: 1.75rem;
}

.wd-form-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  color: #9775fa;
}

.wd-header-bg {
  position: relative;
  height: 100vh;
  /* background-image: url('../images/wd-header-bg.svg'); */
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.success-message {
  padding: 0px;
  background-color: transparent;
}

.text-block-3 {
  letter-spacing: -0.2px;
}

.wd-form-graphic {
  width: 80%;
  margin-bottom: 2em;
}

.error-message {
  margin-top: 1em;
}

.wd-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 92vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-81 {
  position: absolute;
  left: auto;
  top: 5%;
  right: -12%;
  bottom: 0%;
}

.wp-services-wrapper {
  position: relative;
}

.sos-sidebar-heading {
  margin-bottom: 1em;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1em;
  font-weight: 600;
}

.sos-description {
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1.5em;
}

.sos-content-wrapper {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f2f5f8),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #f2f5f8, hsla(0, 0%, 100%, 0));
}

.sos-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sos-search-icon {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1.5em;
  margin-left: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1em;
  text-align: center;
}

.nav-dropdown {
  z-index: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0.3em;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.0625rem;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.nav-dropdown:hover {
  background-color: transparent;
  color: #ea552b;
}

.nav-dropdown.w--current {
  color: #2c2c2c;
}

.nav-dropdown.w--current:hover {
  color: #ea552b;
}

.dropdown-list {
  position: absolute;
  z-index: 300;
}

.dropdown-list.w--open {
  position: absolute;
  top: 2.5em;
  right: -20vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 45em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f5;
  border-radius: 0.5em;
  background-color: transparent;
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05);
}

.nav-list-item-desktop {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5em;
  padding: 0.5em 1.5em 0.5em 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  color: #2c2c2c;
  cursor: pointer;
}

.nav-list-item-desktop:hover {
  background-color: #f8f9fa;
  color: #018ef5;
}

.nav-list-item-desktop:active {
  background-color: transparent;
}

.nav-list-item-desktop.margin-disable {
  margin-bottom: 0em;
}

.nav-list-content-block {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.nav-dd-item-subheading {
  font-family: "Inter", sans-serif;
  color: #737980;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 400;
}

.div-block-118 {
  position: relative;
  z-index: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1.25em 1em 1em 1.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.nav-dd-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #737980;
  font-size: 1rem;
  line-height: 1em;
  cursor: pointer;
}

.nav-dd-item:hover {
  color: #2c2c2c;
}

.nav-dd-item:active {
  color: #2c2c2c;
}

.div-block-120 {
  padding-right: 2em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #f1f3f5;
}

.div-block-121 {
  padding-left: 2em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.nav-dd-title {
  margin-bottom: 1.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.0625rem;
  line-height: 1em;
  font-weight: 700;
}

.cs-lightbox {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.lightbox-link {
  cursor: pointer;
}

.hiw-steps-wrapper-alt {
  display: none;
  padding-top: 6em;
  padding-bottom: 6em;
}

.hiw-step-wrapper-alt {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.hiw-header-bg-alt {
  display: none;
}

.icon-star {
  display: inline;
  color: #fcc419;
  font-size: 1.5rem;
}

.ps-header-wrapper {
  padding-top: 6em;
  padding-bottom: 6em;
  /* background-image: url('../images/ps-header-bg.svg'); */
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.ps-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ps-testimonials-wrapper {
  padding-top: 4em;
  padding-bottom: 4em;
}

.ps-testimonials {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ps-benefits-wrapper {
  padding-top: 4em;
  padding-bottom: 4em;
}

.ps-benefits {
  padding: 4em;
  border-radius: 0.5em;
  background-color: #f1f3f5;
  text-align: center;
}

.ps-partners-wrapper {
  padding-top: 4em;
  padding-bottom: 8em;
  text-align: center;
}

.ps-header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ps-testimonial-thumbnail {
  border-radius: 0.5em;
  -o-object-fit: cover;
  object-fit: cover;
}

.ps-client-name {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.25em;
  font-weight: 700;
}

.ps-testimonial-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ps-testimonials-content {
  margin-top: 1em;
}

.ps-testimonials-client-info {
  margin-top: 1em;
}

.ps-benefits-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ps-benefits-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.ps-benefits-graphic {
  -o-object-fit: none;
  object-fit: none;
}

.ps-partners-list {
  justify-items: center;
  grid-column-gap: 1.5em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.cta-purple {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 2em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  background-color: #ea552b;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.cta-purple:hover {
  background-color: #9775fa;
}

.cta-purple:active {
  background-color: #7950f2;
}

.prs-header-wrapper {
  /* background-image: url('../images/wp-header-bg-left.svg'), url('../images/wp-header-bg-right.svg'); */
  background-position: 0px 0px, 100% 0%;
  background-size: auto, auto;
  background-repeat: no-repeat, no-repeat;
}

.prs-item {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 6em;
  padding-bottom: 4em;
}

.prs-header-content {
  margin-bottom: 4em;
  color: #2c2c2c;
  text-align: center;
}

.prs-suggested-wrapper {
  margin-top: -12em;
  padding-top: 12em;
  padding-bottom: 4em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f3f5),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #f1f3f5, hsla(0, 0%, 100%, 0));
}

.heading {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 600;
}

.prs-suggested-item-heading {
  margin: 0.25em 0px 0px;
  padding: 0px;
  color: #2c2c2c;
}

.prs-suggested-item-heading:hover {
  padding-top: 0px;
  border-style: none;
  border-color: #000;
  border-radius: 0px;
  background-color: transparent;
  text-decoration: underline;
}

.prs-suggested-item {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.prs-suggested-item:hover {
  padding: 0em;
  background-color: transparent;
}

.prs-suggested {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 12em;
  padding-left: 12em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.prs-suggested-list {
  margin-top: 1em;
  margin-bottom: 2em;
}

.lc-recent-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.button-medium {
  padding: 13px 28px;
  border-radius: 5px;
  background-color: #ea552b;
  font-size: 16px;
  line-height: 22px;
}

.button-medium.background-color-white {
  margin-top: 40px;
  background-color: #fff;
  color: #ea552b;
  font-weight: 600;
  text-align: center;
}

.button-medium.background-color-white.aff-hero-btn {
  margin-top: 0px;
  padding: 15px 36px;
  color: #40c057;
  font-size: 1.125em;
  line-height: 1.3em;
}

.button-medium.background-color-white {
  background-color: #fff;
  color: #ea552b;
  font-weight: 600;
  text-align: center;
}

.button-medium.margin-top {
  margin-top: 20px;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  color: #ea552b;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.nav-base {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 999;
  width: 100%;
  background-color: transparent;
}

.a-header-wrapper {
  position: relative;
  padding-top: 4em;
  padding-bottom: 4em;
}

.aff-text-wrapper {
  margin-top: 30px;
  padding-right: 100px;
}

.border-bottom {
  border-width: 1px 1px 2px;
  border-color: #000 #000 #fff;
  border-bottom-style: none;
  font-size: 16px;
}

.aff-hero_left {
  width: 58%;
}

.aff-hero_right {
  width: 40%;
}

.a-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section-base {
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 60px;
}

.section-base.sale {
  z-index: 2;
  display: none;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #3bc9db;
}

.logo-sec {
  overflow: hidden;
  padding-top: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}

.purple {
  border-bottom: 1px solid #ea552b;
  color: #ea552b;
}

.div-block-129 {
  margin-top: 40px;
}

.pub-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pub-block.mbl-hde.mb032 {
  margin-bottom: 0px;
}

.pub-block.mbl-show {
  display: none;
}

.a-features-wrapper {
  padding-top: 6em;
  padding-bottom: 6em;
  background-color: transparent;
}

.a-hiw-wrapper {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-top: 6em;
  padding-bottom: 8em;
  /* background-image: url('../images/hiw-features-bg-right.svg'), url('../images/hiw-features-bg-left.svg'), -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#f1f3f5)); */
  /* background-image: url('../images/hiw-features-bg-right.svg'), url('../images/hiw-features-bg-left.svg'), linear-gradient(180deg, hsla(0, 0%, 100%, 0), #f1f3f5); */
  background-position: 100% 100%, 0px 0px, 0px 0px;
  background-size: auto, auto, auto;
  background-repeat: no-repeat, no-repeat, repeat;
}

.a-hiw-item-heading {
  width: auto;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.tos-h3 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  color: #737980;
  font-weight: 700;
}

.priv-list {
  padding-top: 0.5em;
  border-style: solid;
  border-width: 0px;
  border-color: #737980;
  color: #737980;
  line-height: 1em;
}

.priv-h2 {
  margin-bottom: 0.75em;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1.1666666666666667em;
  font-weight: 600;
}

.container-3 {
  max-width: 1200px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 1%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.priv-content {
  padding-right: 12em;
  padding-left: 12em;
}

.priv-content-wrapper {
  padding-top: 4em;
  padding-bottom: 4em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f3f5),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #f1f3f5, hsla(0, 0%, 100%, 0));
}

.priv-content-block {
  margin-bottom: 3em;
}

.h1-large-2 {
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 4.5rem;
  line-height: 1.0625em;
  font-weight: 900;
}

.paragraph-default-2 {
  color: #737980;
}

.paragraph-default-2.text-color-dark-blue {
  color: #2c2c2c;
}

.paragraph-default-2.tos-list {
  margin-top: 0em;
  line-height: 1em;
}

.divider-2 {
  height: 1px;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #e9ecef;
}

.divider-2.margin-small {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.priv-list-item {
  margin-bottom: 0.4em;
  line-height: 1.5em;
}

.priv-list-contact {
  color: #737980;
}

.priv-list-contact-item {
  margin-bottom: 0.5em;
}

.nav-list-extra {
  display: none;
}

.value-image {
  width: 100%;
}

.value-image.m {
  display: none;
}

.mobile-scroll {
  display: none;
}

.value-card.white {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fff;
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
}

.service-grid {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.service-grid.margin-top-40 {
  margin-top: 40px;
}

.review-card {
  padding-top: 27px;
}

.review-card.yellow {
  padding: 2em 3em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ffe066;
}

.review-card.yellow.purple {
  position: relative;
  top: -5em;
  border-bottom-style: none;
  background-color: #e5dbff;
}

.review-card.yellow.green {
  position: relative;
  top: -90px;
  background-color: #b2f2bb;
}

.review-card.yellow.pink {
  padding-left: 3em;
  background-color: #ffd2fc;
}

.review-card.yellow.blue {
  background-color: #bfe4ff;
}

.review-card.yellow.gray {
  position: relative;
  top: -90px;
  border: 2px dashed #dee2e6;
  background-color: transparent;
}

.grid-6 {
  max-width: 56em;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.carousel-bar-text {
  color: #2c2c2c;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.chart-absolute-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  padding-top: 4em;
  padding-bottom: 4em;
}

.chart-icon {
  min-height: 46px;
  min-width: 46px;
  margin-right: 15px;
}

.community-content {
  margin-right: 28em;
}

.chart-head-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.75em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.carousel-placeholder {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.review-by-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.review-name-text {
  margin-top: 0px;
}

.service-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.absoulte-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-text {
  margin-bottom: 0px;
  font-size: 1.25rem;
  line-height: 1.6em;
}

.hero-text.center {
  text-align: center;
}

.hero-text.center.grey {
  color: #7b8087;
}

.hero-text.center.grey.argom {
  margin-top: 1em;
}

.chart-mobile {
  display: none;
}

.community-sub-heading {
  margin-top: 0px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.review-content {
  margin-top: 1.5em;
  margin-bottom: 32px;
}

.service-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.arrow {
  display: none;
  min-height: 27px;
  min-width: 27px;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.arrow.edited {
  position: relative;
  left: 65px;
}

.chart {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  padding-bottom: 140px;
  background-color: #1c147b;
  /* background-image: url('../images/journey--bg--img.png'); */
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.chart.response {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  /* background-image: url('../images/journey--bg--img.png'); */
}

.chart.response.hidden {
  overflow: hidden;
}

.chart.response {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  background-image: none;
  background-size: auto;
}

.review-heading {
  margin-top: 0px;
  margin-bottom: 0.5em;
  color: #2c2c2c;
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 700;
  letter-spacing: -0.2px;
}

.box__for__cursor {
  width: 150px;
  height: 50px;
  margin-top: 0px;
  margin-left: 0px;
  cursor: pointer;
}

.community-grid {
  margin-top: 2em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: -webkit-max-content;
  -ms-grid-rows: max-content;
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
}

.community-card-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ea552b;
}

.community-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em 1em 1em 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.paragraph {
  font-family: "Inter", sans-serif;
  color: #747c84;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.paragraph.center {
  text-align: center;
}

.paragraph.center.grey-icon {
  color: #7b8087;
}

.paragraph.big {
  font-size: 1.125rem;
  line-height: 1.5555555555555556em;
}

.paragraph.big.green {
  color: #245274;
}

.paragraph.big.white {
  color: #fff;
}

.paragraph.big.center.grey {
  color: #7b8087;
}

.paragraph.big.pink {
  color: #2c2c2c;
}

.paragraph.big.yelllow {
  color: #776625;
}

.paragraph.big.gray {
  max-width: 550px;
  margin-bottom: 0px;
  color: #878e96;
}

.paragraph.big.grey {
  color: #737980;
}

.paragraph.big.grey.define {
  max-width: 413px;
}

.paragraph.grey {
  color: #878e96;
}

.paragraph.grey.smaller {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15px;
}

.paragraph.font-color {
  color: #7b8087;
}

.paragraph.font-color.no-margin {
  margin-bottom: 0px;
  font-size: 0.8rem;
}

.paragraph.font-color.defined {
  max-width: 257px;
}

.paragraph.font-color.no-margin {
  margin-bottom: 0px;
}

.feature-card-heading {
  margin-top: 0px;
  margin-bottom: 12px;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
  letter-spacing: 0.2px;
}

.feature-card-heading.no-margin {
  margin-bottom: 2px;
}

.wrapper__join {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 11%;
  margin-bottom: 11%;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.item {
  position: relative;
}

.site-container {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1260px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.service-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3.5rem;
  line-height: 48px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.2px;
}

.service-heading.margin-10 {
  margin-bottom: 10px;
}

.service-heading.margin-right {
  margin-right: 7px;
}

.service-heading.margin-right {
  margin-right: 7px;
  font-family: "Inter", sans-serif;
}

.service-heading.margin-10 {
  margin-bottom: 10px;
}

.text-span-3 {
  font-size: 32px;
  line-height: 32px;
}

.inverted-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.review-container {
  width: 100%;
}

.secondry-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3.5rem;
  line-height: 1.1428571428571428em;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.2px;
}

.secondry-heading.align-left {
  text-align: left;
}

.secondry-heading.align-left.margin-24 {
  margin-bottom: 24px;
}

.secondry-heading.align-left.white {
  color: #fff;
}

.secondry-heading.align-left.white.margin-24 {
  font-family: "Inter", sans-serif;
}

.secondry-heading.align-left.white.margin-24.text-left {
  text-align: left;
}

.secondry-heading.align-left.margin-24 {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
}

.secondry-heading.align-left.white {
  color: #fff;
}

.secondry-heading.align-left.white.margin-24 {
  font-family: "Inter", sans-serif;
}

.our--journey.hide-mbl {
  width: 50ch;
}

.our--journey.hide-mbl {
  width: 503px;
}

.owl-carousel.owl-theme {
  position: relative;
  z-index: 1;
}

.values-grid {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.carousel-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 70px 30px 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fcfcfc;
}

.carousel-wrapper.m {
  display: none;
}

.carousel-wrapper.d {
  padding-right: 20px;
  padding-left: 20px;
  background-color: transparent;
}

.chart-card {
  z-index: 1;
}

.chart-card._2020 {
  position: absolute;
  right: 17.35%;
  bottom: 34%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-card._2018 {
  position: absolute;
  right: 38.8%;
  bottom: 10%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-card._2019 {
  position: absolute;
  right: 28%;
  bottom: 20%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-card._2021 {
  position: absolute;
  top: 35%;
  right: 6.3%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-card._2017 {
  position: absolute;
  right: 49.35%;
  bottom: 3%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart-card._2022 {
  position: absolute;
  top: 10%;
  right: 1%;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.carousel-icon {
  margin-right: 20px;
}

.review-profile {
  width: 57px;
  height: 57px;
  min-height: 57px;
  min-width: 57px;
  margin-right: 16px;
}

.review-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #2c2c2c;
  font-size: 14px;
}

.arrow-wrapper {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: -3%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.arrow-wrapper.inverted {
  left: 0%;
  top: -3%;
  right: 0%;
  bottom: auto;
}

.carousel-image {
  max-height: 24em;
  min-height: 24em;
  border-radius: 0.5em;
  -o-object-fit: cover;
  object-fit: cover;
}

.carousel-icons {
  width: 62px;
  height: 62px;
}

.sub-content-wrapper {
  margin-top: 2em;
}

.service-container {
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}

.chart-text {
  display: inline;
  margin-bottom: 0px;
  color: #fff;
  line-height: 1.5555555555555556em;
}

.chart-text.margin-20 {
  display: block;
  margin-bottom: 24px;
}

.box__hidden {
  margin-bottom: 5px;
  padding: 20px 20px 60px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2);
}

.box__hidden._2019 {
  position: relative;
}

.value-card-wrapper {
  margin-bottom: 1em;
}

.carousel-heading-2 {
  margin-top: 4px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.value-card-heading-2 {
  margin-top: 0px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1.25em;
  letter-spacing: 0.2px;
}

.section-3 {
  position: relative;
  display: none;
  overflow: hidden;
}

.team-card {
  width: 100%;
}

.job-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 14px 28px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f1f3f5;
  text-decoration: none;
}

.testimonial-inner-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.section-4 {
  overflow: hidden;
  padding-top: 4em;
  padding-bottom: 4em;
}

.section-5 {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-top: 6em;
  padding-bottom: 6em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section-6 {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.section-7 {
  margin-top: 2em;
  margin-bottom: 8em;
}

.section-7.hidden {
  overflow: hidden;
}

.section-8 {
  overflow: hidden;
  padding-top: 8em;
  padding-bottom: 8em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f3f5),
    to(#fff)
  );
  background-image: linear-gradient(180deg, #f1f3f5, #fff);
}

.image-83 {
  max-width: 90vw;
  min-width: 180%;
}

.div-block-133 {
  width: 50ch;
  margin-bottom: 4em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
}

.div-block-134 {
  overflow: hidden;
  /* background-image: url('../images/pricing-bg-graphic-left.svg'), url('../images/pricing-bg-graphic-right.svg'); */
  background-position: 0% 50%, 100% 50%;
  background-size: auto, auto;
  background-repeat: no-repeat, no-repeat;
}

.image-84 {
  position: absolute;
  left: 5vw;
  top: auto;
  right: auto;
  bottom: 10%;
  z-index: 2;
}

.image-85 {
  position: absolute;
  left: auto;
  top: auto;
  right: 5vw;
  bottom: 10%;
  z-index: 2;
}

.ow-h2-heading {
  display: inline;
  margin: 0px 0px 0.25em;
  padding: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3.5rem;
  line-height: 1.14em;
  font-weight: 900;
  white-space: nowrap;
}

.div-block-135 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.div-block-135.align-object-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.image-87 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  min-width: 100vw;
}

.div-block-137 {
  z-index: 3;
  margin-bottom: 12em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.mask-3 {
  overflow: visible;
  width: 50%;
  height: auto;
}

.slider-3 {
  left: -5%;
  right: 0px;
  overflow: visible;
  width: 80vw;
  height: auto;
  padding-right: 5%;
  padding-left: 5%;
  background-color: transparent;
}

.slide-nav-3 {
  display: none;
}

.div-block-138 {
  overflow: hidden;
  padding: 4em;
  border-radius: 0.8em;
  /* background-image: url('../images/ow-pfg-bg.png'); */
  background-position: 50% 50%;
  background-size: auto 110%;
  background-repeat: no-repeat;
}

.slide-4 {
  overflow: hidden;
  width: auto;
  min-width: 800px;
  padding-right: 4em;
}

.section-9 {
  position: relative;
  overflow: hidden;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-139 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.om-card-icon {
  width: 2.25em;
  height: 2.25em;
}

.outline {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}

.text-span-4 {
  display: inline-block;
  margin-left: 1em;
  padding: 0.25em 0.75em;
  border-radius: 4em;
  background-color: #ffdeeb;
  color: #d1447c;
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
}

.ch-back-btn {
  display: inline-block;
  width: 48px;
  margin-right: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.ico-rotate-left {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ico-rotate-left.gray {
  margin-left: -2px;
  opacity: 0.6;
}

.image-93 {
  margin-right: 12px;
}

.ico-check-teal {
  padding-right: 5px;
}

.co-grid-left.padding-left.modal {
  position: relative;
  width: 70%;
  max-height: 90vh;
  padding: 2em;
  border-radius: 1em;
  background-color: #fff;
}

.co-grid-left.padding-left.grid-alt {
  overflow: visible;
  width: 102%;
}

.color--gray-700 {
  color: #878e96;
}

.color--gray-700.text-xl {
  width: 46ch;
  margin-bottom: 2em;
  color: #585c62;
  font-size: 1.25rem;
  line-height: 1.6em;
}

.ch-back-btn-base {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  margin-bottom: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e6;
  border-radius: 50%;
}

.ch-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: -75px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ch-top.hide {
  display: none;
}

.sn-value {
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 600;
}

.sn-value.color--gray-800 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.sn-value.color--black {
  color: #212529;
}

.sn-value.color--black.semi-bold {
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
}

.sn-value.text-color-green {
  color: #40c057;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 600;
}

.small-title-wrapper.m-0 {
  margin-top: 0px;
}

.small-title-wrapper.m-0.co-hide {
  display: none;
}

.inter_14-20 {
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
}

.inter_14-20.text-color-green {
  color: #40c057;
}

.inter_14-20.grey-700 {
  line-height: 1.5em;
}

.co-form-wrapper {
  height: auto;
}

.co-form-wrapper.billing-freq {
  position: absolute;
}

.co-item-thumbnail {
  overflow: hidden;
  width: 5em;
  height: 5em;
  min-width: 5em;
  border-radius: 0.3em;
  -o-object-fit: cover;
  object-fit: cover;
}

.co-item-thumbnail.png {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.color--green {
  color: #40c057;
}

.co-bottom-wrapper {
  padding: 2em 2em 600px;
  background-color: #fafafa;
}

.hide-desktop {
  display: none;
}

.sn-label {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 1rem;
  line-height: 1.5em;
}

.sn-label.color--black {
  color: #212529;
}

.sn-label.color--black.semi-bold {
  color: #2c2c2c;
}

.flex-horizontal-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal-center.grid-item {
  z-index: 800;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.co-grid-wrapper {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 4em;
  grid-row-gap: 2em;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.sn-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.5em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sn-row.color--gray-700 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.co-sidebar {
  position: relative;
  padding: 1.6em;
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e6;
  border-radius: 0.5em;
  background-color: #fff;
}

.ch-label-text {
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 400;
}

.ch-label-text.color--gray-700.hide-mob {
  display: block;
}

.ch-label-text.color--gray-700.hide-mob.strikethrough {
  text-decoration: none;
}

.ch-label-text.color--gray-700.mobile-visible {
  display: none;
}

.ch-dc-price {
  margin-top: 0.25em;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
}

.ch-dc-price.color--gray-700 {
  margin-bottom: 10px;
  font-weight: 400;
  text-decoration: line-through;
}

.discount-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
}

.co-grid-right {
  position: -webkit-sticky;
  position: sticky;
  top: 12em;
}

.image-92 {
  margin-right: 10px;
}

.checkout-title-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ch-grid-block {
  margin-bottom: 2em;
}

.ch-grid-block.hidden {
  display: none;
}

.lab-gasture20-24 {
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.lab-gasture20-24.text-dark {
  color: #2c2c2c;
}

.lab-gasture20-24.text-dark.grid-item {
  white-space: pre;
}

.color--gray-900 {
  font-family: "Inter", sans-serif;
  color: #353a40;
  font-size: 3rem;
  line-height: 1.1666666666666667em;
  font-weight: 700;
  text-align: center;
}

.color--gray-900.ch-heading {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 48px;
  line-height: 56px;
}

.color--gray-900.text-dark {
  color: #fff;
}

.color--gray-900.text-dark.mob-align-left {
  font-size: 40px;
  letter-spacing: 0.2px;
}

.lab-gasture16-22 {
  color: #ea552b;
  font-size: 16px;
  line-height: 1.375em;
  font-weight: 700;
  letter-spacing: 0px;
}

.lab-gasture16-22.steel-blue {
  color: #0b74c2;
}

.co---guarantee---content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.discount-box {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.small-title {
  margin-bottom: 1em;
  margin-left: 0px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 600;
}

.ch-label-desc {
  margin: 1em 2em;
}

.ch-label-desc.padding-right-large {
  padding-right: 15%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ch-label-desc.padding-right-large.alt {
  margin-top: 0em;
  margin-bottom: 0em;
}

.ch-label-desc.grid-alt {
  position: relative;
  margin-right: 1em;
  margin-left: 1em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.ch-label-desc.grid-alt.discount {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.ch-discount {
  text-align: right;
}

.ch-discount.hide-mob {
  margin-top: 1em;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.co-item-link {
  color: #4a5057;
  text-decoration: underline;
  cursor: pointer;
}

.heading-20-24 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Inter", sans-serif;
  color: #353a40;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
}

.heading-20-24.text-dark {
  color: #2c2c2c;
}

.faq-drop-2 {
  display: block;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 16px;
  border: 1px solid #f1f3f5;
  border-radius: 10px;
  background-color: #f1f3f5;
}

.color--gray-800 {
  color: #4a5057;
}

.div-block-145 {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ca-form-block {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-right: 40px;
  padding-left: 40px;
}

.ca-form-block.p-0 {
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.ico-validation {
  margin-right: 8px;
}

.validation-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-40 {
  position: absolute;
  left: auto;
  top: 13px;
  right: 10px;
  bottom: 0%;
  color: #ced4da;
  font-size: 14px;
  line-height: 20px;
}

.ca-form-validation {
  display: -ms-grid;
  display: grid;
  margin-top: 10px;
  margin-bottom: 40px;
  padding-right: 10px;
  padding-left: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.width-50 {
  width: 48%;
}

.color--purple {
  color: #ea552b;
  cursor: pointer;
}

.form-label-2 {
  margin-bottom: 0.5em;
  font-family: "Inter", sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 1.375em;
  font-weight: 600;
  text-align: left;
}

.form-label-2.color--gray-900 {
  color: #353a40;
  /* font-size: 18px; */
}

.form-label-2.sm {
  font-size: 16px;
  line-height: 22px;
}

.input-field {
  border-color: #cfd4da;
}

.input-field.input-styling {
  position: relative;
  height: 3.5em;
  margin-right: 1em;
  margin-bottom: 2em;
  padding: 1em;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.3em;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.input-field.input-styling:focus {
  border-color: #ea552b;
}

.input-field.input-styling::-webkit-input-placeholder {
  color: #cfd4da;
}

.input-field.input-styling:-ms-input-placeholder {
  color: #cfd4da;
}

.input-field.input-styling::-ms-input-placeholder {
  color: #cfd4da;
}

.input-field.input-styling::placeholder {
  color: #cfd4da;
}

.div-block-146 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ch-terms {
  margin-bottom: 0px;
  padding: 2px;
  border: 0px solid #000;
  border-radius: 5px;
  background-color: #dee2e6;
}

.ch-terms.active {
  background-image: linear-gradient(45deg, #44e5e7, #ea552b 55%, #f06595);
}

.ch-terms.border-0 {
  padding: 0px;
  border: 0px solid #000;
}

.ff-csz {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1.5fr 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.ch-terms-title {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.ch-terms-title.color--gray-900 {
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  text-align: left;
}

.ch-select {
  width: 200px;
  height: auto;
  padding: 1em;
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e6;
  border-radius: 5px;
  background-color: #fff;
  color: #212529;
}

.ch-select.country {
  width: 100%;
}

.ch-agree {
  width: 1.5em;
  height: 1.5em;
  min-height: 1.5em;
  min-width: 1.5em;
  margin: 0px 1em 0px 0px;
  border-color: #dee2e6;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.ch-agree.w--redirected-checked {
  border-color: #9775fa;
  background-color: #9775fa;
  /* background-image: url('../images/ico-check-white.svg'); */
  background-size: cover;
}

.form-field {
  margin-bottom: 20px;
}

.form-field.no-marg {
  margin-bottom: 0px;
}

.purple-text {
  color: #ea552b;
}

.grid-34 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-34.width {
  width: 79%;
}

.ch-terms-desc {
  height: 100%;
  padding: 20px 20px 20px 60px;
  border-radius: 5px;
  background-color: #fff;
}

.ch-terms-desc.payment {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0px;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
}

.inter-16-24 {
  color: #878e96;
  font-size: 16px;
  line-height: 24px;
}

.inter-16-24.dark-grey.m-0 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #353a40;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 600;
}

.inter-16-24.max {
  max-width: 309px;
}

.checkbox-label {
  color: #2c2c2c;
}

.image-98 {
  position: absolute;
  width: 42%;
}

.image-98.himde-image {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 0%;
  z-index: 0;
  width: 30%;
  max-width: 40rem;
  min-width: 30rem;
}

.fi-heading {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 4.5rem;
  line-height: 1em;
  font-weight: 900;
}

.div-block-148 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.div-block-149 {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.finish-new {
  position: fixed;
  width: 100%;
  height: 100vh;
}

.nav-checkout-wrapper {
  z-index: 300;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.co-nav-sign-in {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  line-height: 1.2941176470588236em;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.nav-checkout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.checkout-link {
  font-weight: 700;
}

.co-nav-wrapper {
  z-index: 1000;
  padding-top: 18px;
  padding-bottom: 18px;
  /* border-bottom: 1px solid #f1f3f5; */
  background-color: #000;
}

.co-progress-bar-wrapper {
  height: 5px;
}

.co-progress-bar {
  width: 16.666666666666668%;
  height: 100%;
  background-color: #ea552b;
}

.co-progress-bar.step-2 {
  width: 33.333333333333336%;
}

.co-progress-bar.step-3 {
  width: 50%;
}

.co-progress-bar.step-4 {
  width: 66.66666666666667%;
}

.co-progress-bar.step-5 {
  width: 83.33333333333334%;
}

.co-progress-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
}

.nav-checkout-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.co-item-wrapper {
  position: relative;
  z-index: 2;
  margin-bottom: 1em;
  padding: 2px;
  border-radius: 0.6em;
  opacity: 1;
  cursor: pointer;
}

.co-item-wrapper:hover {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.co-item-wrapper:focus {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.co-item-wrapper.grid-alt {
  width: 100%;
  margin-bottom: 0em;
}

.co-item-wrapper.grid-alt:hover {
  background-image: none;
}

.co-item-wrapper.plan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 128px;
}

.co-item {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 100%;
  padding: 1em 1.5em 1em 1em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  background-color: #e9ecef;
}

.co-item:hover {
  background-color: #fff;
}

.co-item.default {
  background-color: #fff;
}

.co-item.grid-alt {
  padding: 0.5em;
}

.co-item.grid {
  background-color: #fff;
}

.co-item.grid.margin1 {
  padding-right: 1em;
}

.co-item._1 {
  padding: 0.5em;
}

.co-item._2 {
  padding: 0.5em;
}

.co-item._3 {
  padding: 0.5em;
}

.co-item._4 {
  padding: 0.5em;
}

.co-item-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  padding: 2px;
  border-radius: 0.6em;
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
  opacity: 1;
}

.co-item-bg:hover {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.discount-tag {
  margin-left: 1em;
  padding: 0.5em;
  border-radius: 0.3em;
  background-color: #caffd3;
  color: #0e7a22;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
}

.co-item-toggle {
  position: absolute;
  left: auto;
  top: auto;
  right: 1.5em;
  bottom: 1em;
  z-index: 10;
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  padding: 0px;
  border-width: 1px;
  border-color: #cfd4da;
  border-radius: 4em;
  background-color: transparent;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
  box-shadow: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.co-item-toggle:active {
  display: inline;
  background-color: #40c057;
  /* background-image: url('../images/co-icon-check.svg'); */
  background-position: 50% 50%;
  background-size: 0.8em;
  background-repeat: no-repeat;
}

.co-item-toggle.w--redirected-checked {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-width: 0px;
  border-color: transparent;
  border-radius: 4em;
  background-color: #40c057;
  /* background-image: url('../images/co-icon-check.svg'); */
  background-position: 50% 50%;
  background-size: 0.8em;
  background-repeat: no-repeat;
}

.co-item-toggle.w--redirected-focus {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4em;
  background-color: #40c057;
  /* background-image: url('../images/co-icon-check.svg'); */
  background-position: 50% 50%;
  background-size: 0.8em;
  background-repeat: no-repeat;
  box-shadow: none;
}

.co-item-toggle.w--redirected-focus-visible {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-color: #40c057;
  border-radius: 4em;
}

.co-item-toggle.grid-alt {
  right: 1em;
  z-index: 3;
}

.success-message-2 {
  background-color: transparent;
}

.co-hidden {
  display: none;
}

.co-item-bg-fixed {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  padding: 2px;
  border-radius: 0.6em;
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
  opacity: 1;
}

.co-item-bg-fixed:hover {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.co-cart-title {
  margin-bottom: 0.75em;
  font-family: "Inter", sans-serif;
  color: #353a40;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.co-cart-title.button {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #ea552b;
  font-size: 1rem;
  cursor: pointer;
}

.co-cart-title.button:hover {
  color: #2c2c2c;
}

.co-cart-title.button:active {
  color: #2c2c2c;
}

.co-item-list {
  overflow: auto;
  margin-bottom: 2em;
}

.co-item-list.alt {
  overflow: visible;
}

.co-top-wrapper {
  padding: 14px;
  background-color: #000;
}

.co-nav-menu {
  display: none;
}

.co-item-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.discount-tag-mobile {
  display: none;
  margin-left: 1em;
  padding: 0.5em;
  border-radius: 0.3em;
  background-color: #caffd3;
  color: #0e7a22;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
}

.co---guarantee {
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em 1.6em;
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e6;
  border-radius: 0.5em;
  background-color: #fff;
}

.co-sidebar-wrapper {
  position: relative;
  top: -6em;
  /* font-size: 18px; */
  display: block;
  width: 100%;
}

.co-page-wrapper.payment {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
  background-color: #fff;
}

/* .dropdown {
  background-color: #e9ecef;
} */

.co-billing-price {
  color: #878e96;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
}

.co-billing-price {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: #878e96;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 400;
}

.co-billing-price.green {
  color: #40c057;
}

.co-price {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 600;
}

.div-block-154 {
  padding-bottom: 0em;
}

.grid-35 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.radio-button-label-2 {
  display: none;
}

.div-block-155 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #cfd4da;
  border-radius: 0.3em;
  background-color: #f8f9fa;
}

.input-field-multi {
  margin: 0px;
  padding: 0px;
  border: 1px none #000;
  background-color: transparent;
}

.input-field-multi._60 {
  width: 80%;
}

.input-field-multi._20 {
  width: 10%;
}

.white-card-box-new {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 3px;
  padding: 2em 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 0.2em;
  background-color: #fff;
  outline-color: #fff;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 3px;
  text-align: center;
}

.white-card-box-new:hover {
  height: 98%;
  border-radius: 0.3em;
  outline-color: transparent;
}

.inter_14-20-2 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.submit-button-2 {
  width: 117px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ea552b;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
}

.mr-20 {
  margin-right: 20px;
}

.flex-horizontal-center-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal-center-2.mb_32.vertical {
  margin-bottom: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.link-4 {
  color: #fff;
}

.heading_4-2 {
  margin-top: 0px;
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
}

.animation-starter {
  width: 100%;
  height: 1px;
  background-color: #ea552b;
}

.mb_16 {
  margin-bottom: 16px;
}

.inter_16-22-2 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}

.inter_16-22-2.grey-700.mb_76.mbl-white {
  margin-bottom: 3em;
  color: #737980;
}

.text-field-2 {
  height: 63px;
  margin-bottom: 0px;
  padding-left: 64px;
  border-radius: 5px;
  /* background-image: url('../images/carbon_search.svg'); */
  background-position: 20px 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #878e96;
  font-size: 1.125rem;
  line-height: 1.5555555555555556em;
  font-weight: 400;
}

.text-field-2::-webkit-input-placeholder {
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.text-field-2:-ms-input-placeholder {
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.text-field-2::-ms-input-placeholder {
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.text-field-2::placeholder {
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.text-field-2.search {
  border: 0px solid transparent;
}

.text-field-2.search.destop {
  color: #353a40;
}

.text-field-2.search.tablet {
  display: none;
}

.form-block {
  width: 636px;
  margin-bottom: 0px;
}

.form-block.mb_28 {
  margin-top: 2em;
  margin-bottom: 1em;
}

.need-help-new {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2em 3em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: solid;
  border-width: 2px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  text-align: center;
}

.icon-purple {
  color: #878e96;
}

.gradient-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
  background-image: linear-gradient(225deg, #44e5e7, #9864fd 50%, #edc2f1);
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.vertical-centr-space {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mr-8 {
  margin-right: 8px;
}

.white--underline {
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

.div-block-43 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.hc-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1.5em 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid #dee2e6;
  color: #ea552b;
  font-weight: 600;
}

.hc-link.border-bottom-disable {
  border-bottom-style: none;
}

.answer-1-new-working {
  display: none;
}

.color-color-black {
  color: #000;
}

.hc-item-wrapper {
  margin-bottom: 16px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-radius: 5px;
  background-color: #fff;
}

.hc-item-wrapper.mb_20 {
  margin-bottom: 20px;
}

.hc-topic-thumb {
  display: block;
  width: 2.5em;
  margin-right: 1em;
}

.hc-topic-thumb.ovr {
  width: 2em;
}

.hc-topic-thumb.ovr-1 {
  width: 3em;
}

.div-block-78-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 0px;
  grid-template-columns: 30% repeat(auto-fit, 65%);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-100 {
  width: 3em;
  height: 3em;
  margin-right: 1em;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.hc-section-topic {
  padding: 1em;
  font-family: "Inter", sans-serif;
  color: #353a40;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.hc-section-topic:hover {
  background-color: #cfd4da;
}

.hc-section-topic.list-actve {
  background-color: #dee2e6;
  color: #ea552b;
}

.horizontal-center-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.horizontal-center-start.tab-hid {
  margin-bottom: 2em;
}

._1st-dropdown-list-copy {
  position: relative;
  background-color: #e9ecef;
}

._1st-dropdown-list-copy._3 {
  display: none;
}

._1st-dropdown-list-copy._6 {
  display: none;
}

._1st-dropdown-list-copy._2 {
  display: none;
}

._1st-dropdown-list-copy._5 {
  display: none;
}

._1st-dropdown-list-copy._4 {
  display: none;
}

._24x24 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  color: #cfd4da;
  font-size: 18px;
}

._24x24.mx-8 {
  margin-right: 8px;
  margin-left: 8px;
}

.hc-section-wrapper {
  width: 100%;
  padding-bottom: 0px;
}

.hc-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.random-16-24 {
  margin-top: 8px;
  font-family: "Inter", sans-serif;
  color: #737981;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.div-block-160 {
  display: none;
}

.cht {
  margin-right: 12px;
}

.cht.tb-show {
  display: none;
}

.hc-articles-desktop {
  margin-left: 1em;
  border-radius: 10px;
}

.text-span-12 {
  font-weight: 600;
}

.answer-4-new {
  display: none;
}

.image-101 {
  margin-right: 16px;
}

.dropdown-text-14-24 {
  font-family: "Inter", sans-serif;
  color: #868e96;
  font-size: 14px;
  line-height: 20px;
}

.dropdown-text-12-24 {
  font-family: "Inter", sans-serif;
  color: #868e96;
  font-size: 12px;
  line-height: 20px;
}

.answer-3-new {
  display: none;
}

.form-block-2-copy {
  width: 100%;
  margin-bottom: 0px;
}

.hc-cta-wrapper {
  max-width: 816px;
  margin-right: auto;
  margin-left: auto;
  padding: 1.5em;
  border: 1px none #4a4a4a;
  border-radius: 10px;
  background-color: #ea552b;
}

.hc-cta-wrapper.mobile {
  display: none;
}

.link-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 195px;
  height: 56px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #31a8fe;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.hc-item-dd {
  width: 100%;
}

.hc-icon {
  margin-top: 9px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.text-block-32-copy {
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.text-block-32-copy.tbshw {
  display: none;
}

.left-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-top: 7px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 5px;
  background-color: #dee2e6;
}

.dark-grey-copy {
  margin-top: 0px;
  color: #353a40;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.2px;
}

.dark-grey-copy.mb_40 {
  margin-bottom: 0.5em;
  font-family: "Inter", sans-serif;
  font-size: 2.5rem;
  line-height: 1.4em;
  font-weight: 900;
}

.text-block-32 {
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.hc-item-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-weight: 500;
}

.hc-item-toggle.fxed {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.div-block-162 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dropdown-4-text-gray {
  margin-bottom: 0px;
  font-family: "Inter", sans-serif;
  color: #737981;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.hc-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #353a40;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 700;
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  cursor: pointer;
}

.hc-section:hover {
  background-color: #cfd4da;
}

.hc-section.w--open {
  border-bottom-style: none;
  border-radius: 5px 5px 0px 0px;
}

.image-102 {
  display: block;
  width: 62px;
  height: 62px;
}

.answer-1-new {
  display: block;
}

.dropdown-list-6 {
  position: relative;
  padding-right: 60px;
  padding-bottom: 16px;
  padding-left: 20px;
  background-color: transparent;
}

.dropdown-list-6.sow {
  display: block;
}

.hc-cta-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.answer-2-new_test {
  display: none;
}

.video-by-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-by-wrapper.margin-top-56 {
  margin-top: 56px;
}

.video-by-wrapper.margin-top-56 {
  margin-top: 2em;
}

.video-image {
  position: relative;
  width: 100%;
}

.lightbox-link-2 {
  width: 100%;
}

.video-icon {
  margin-right: 20px;
}

.dropdown-4 {
  margin-right: 0px;
  margin-left: 0px;
  font-size: 1em;
  line-height: 1.6em;
}

.secondry-heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #2c2c2c;
  font-size: 56px;
  line-height: 64px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.2px;
}

.secondry-heading-2.align-left {
  text-align: left;
}

.secondry-heading-2.align-left.hide-it {
  margin-left: 1em;
}

.how-to-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1105px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.program-grid-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text--block.custom-width {
  width: 480px;
  color: #212529;
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
}

.culture-heading-wrapper {
  max-width: 460px;
  margin-right: auto;
  margin-left: auto;
}

.feature-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.team-heading {
  margin-top: 18px;
  margin-bottom: 3px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
}

.quote-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #2c2c2c;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.slider-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.inner-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1088px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.comma-span {
  display: none;
}

.map-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rusty.mt--40 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.rusty.mt--40 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.box__for__cursor-2 {
  width: 150px;
  height: 50px;
  margin-top: 0px;
  margin-left: 0px;
}

.testimonial-grid {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.center-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider-img {
  max-width: none;
  padding: 0px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.slider-img.m {
  display: none;
}

.slider-img.m {
  display: none;
}

.glassdoor-btn {
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.glassdoor-btn.margin-60 {
  margin-bottom: 64px;
  -webkit-transition: -webkit-filter 400ms ease;
  transition: -webkit-filter 400ms ease;
  transition: filter 400ms ease;
  transition: filter 400ms ease, -webkit-filter 400ms ease;
}

.glassdoor-btn.margin-60:hover {
  -webkit-filter: saturate(50%);
  filter: saturate(50%);
}

.map-img {
  position: relative;
  top: -30px;
  z-index: -1;
}

.leaders-grid {
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.leaders-grid.margin {
  margin-top: 60px;
  grid-column-gap: 25px;
  grid-row-gap: 85px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.leaders-grid.margin {
  margin-top: 4em;
  margin-bottom: 6em;
  grid-column-gap: 25px;
  grid-row-gap: 85px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.testimonial-image {
  width: 100%;
  height: 100%;
}

.testimonial-image.m {
  display: none;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonial-image.hide-it {
  -o-object-fit: cover;
  object-fit: cover;
}

.leaders-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.leaders-wrapper.down {
  position: relative;
  top: 35px;
}

.apply-title {
  margin-bottom: 8px;
  color: #2c2c2c;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.section-14 {
  padding: 4em 2em;
}

.div-block-163 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-15 {
  padding: 4em 2em;
  background-color: #f1f3f5;
}

.section-16 {
  padding: 6em 2em;
}

.hc-links-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: left;
}

.columns-2 {
  margin: 0px;
  padding: 0px;
}

.hc-link-list {
  margin-top: 1em;
}

.column-3 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

.icon-intercom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hc-toplc-link {
  margin-right: 2em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.section-17 {
  padding: 4em 2em;
}

.hc-article-wrapper {
  padding: 2em 2em 3em;
  background-color: #f1f3f5;
}

.form-create {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.lottie-animation {
  position: absolute;
  z-index: 1;
  width: 100vw;
}

.co-modal-schedule {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1500;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.co-modal-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.co-modal-heading.alt {
  margin-bottom: 0em;
}

.co-cart-pencil {
  display: none;
  margin-left: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
}

.co-cart-tablet {
  display: none;
}

.co-cart-button {
  margin-right: 0.25em;
  margin-left: 0.25em;
  font-size: 1.25rem;
  line-height: 1em;
}

.co-button-text {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.hc-articles-mobile {
  display: none;
  margin-left: 1em;
  border-radius: 10px;
}

.hiw-feature-heading-2 {
  margin-bottom: 0.25em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.feature-item-heading-3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.2px;
}

.feature-item-heading-3.margin-16 {
  margin-bottom: 0.5em;
}

.slider-icon-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1em;
  height: 1em;
  padding: 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f5;
  border-radius: 4em;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  color: #878e96;
  font-size: 1.4rem;
  cursor: pointer;
}

.slider-icon-3:hover {
  color: #ea552b;
}

.slider-icon-3:active {
  background-color: #ea552b;
  color: #fff;
}

.image-105 {
  display: none;
}

.job-card-heading-2 {
  margin-top: 0px;
  margin-bottom: 1em;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.c-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* background-image: url('../images/c-spotlight-bg.svg'); */
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.h4-2 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.5rem;
  line-height: 1.25em;
  font-weight: 700;
}

.quotes-2 {
  padding-top: 0.25em;
  font-family: "Inter", sans-serif;
  font-size: 5.625rem;
  font-weight: 600;
  text-align: center;
}

.heading-wrapper-2 {
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h1-small-2 {
  margin: 0px 0px 0.25em;
  padding: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3.5rem;
  line-height: 1.14em;
  font-weight: 900;
  white-space: break-spaces;
}

.h1-small-2.margin-bottom-large {
  margin-bottom: 1em;
}

.h1-small-2.margin-bottom-0-125 {
  margin-bottom: 0.125em;
}

.h1-small-2.shrink {
  margin-right: 0.5em;
  margin-left: 0.5em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.h1-small-2.shrink.alt.margin-disable {
  margin: 0em;
}

.h1-small-2.margin-bottom-0-5 {
  margin-bottom: 0.5em;
}

.container-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1200px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 1%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.hiw-feature-icon-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 3em;
  height: 3em;
  min-height: 3em;
  min-width: 3em;
  margin-right: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 4em;
  background-color: #fff;
}

.hiw-feature-icon-wrapper-2.alt {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f5;
}

.c-header-graphic-left {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: auto;
  height: 20vw;
}

.section-18 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6em 2em;
}

.section-18.c-header {
  position: relative;
  padding-top: 6em;
  padding-bottom: 12em;
  background-color: #31a8fe;
}

.section-18.c-benefits {
  padding-top: 6em;
  padding-bottom: 6em;
  background-color: #f1f3f5;
}

.section-18.c-testimonials {
  padding-top: 6em;
  padding-bottom: 6em;
  /* background-image: url('../images/divider-dashed.svg'); */
  background-position: 50% 0%;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-18.c-work {
  position: relative;
  margin-top: -4em;
  padding-top: 6em;
  padding-bottom: 6em;
  /* background-image: url('../images/c-work-bg.svg'); */
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-18.c-leaders {
  padding-top: 6em;
  padding-bottom: 0em;
  /* background-image: url('../images/c-leaders-bg.svg'); */
  background-position: 100% 10%;
  background-size: auto;
  background-repeat: no-repeat;
}

.section-18.c-jp {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#f1f3f5),
    to(hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(0deg, #f1f3f5, hsla(0, 0%, 100%, 0));
}

.section-18.pg-members {
  padding-top: 6em;
  padding-bottom: 6em;
}

.section-18.pg-benefits {
  padding-top: 6em;
  padding-bottom: 6em;
}

.section-18.pg-hopeworks {
  /* background-image: url('../images/pg-hopeworks-bg.svg'); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-18.pg-stats {
  margin-top: -4em;
  padding-top: 0em;
  padding-bottom: 0em;
}

.section-18.pg-apply {
  background-color: #f1f3f5;
  /* background-image: url('../images/Grid-2.svg'), none; */
  background-position: 0px 0px, 100% 100%;
  background-size: auto, auto;
  background-repeat: no-repeat, no-repeat;
}

.section-18.pg-header {
  padding-bottom: 8em;
}

.section-18.pg-header.pg-main {
  padding-bottom: 4em;
}

.section-18.pg-header.pg-main.pg-main-bg {
  padding-top: 2em;
}

.blog-content-2 {
  margin-top: 40px;
  text-align: center;
}

.quote-text-2 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.75rem;
  line-height: 1.2857142857142858em;
  font-weight: 500;
  text-align: center;
}

.quote-text-2.margin-20 {
  margin-bottom: 24px;
}

.h1-outline-small {
  height: 3.5rem;
  margin-right: 1em;
}

.site-container-3 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1260px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-168 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3em;
  padding-right: 4em;
  padding-left: 4em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #2c2c2c;
}

.div-block-166 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hiw-features-list-2 {
  grid-auto-columns: auto;
  grid-column-gap: 3em;
  grid-row-gap: 3em;
  -ms-grid-columns: 0.5fr 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 0.5fr 0.5fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.testimonial-item-2 {
  position: relative;
  cursor: pointer;
}

.div-block-167 {
  margin-top: 4em;
}

.paragraph-default-3 {
  color: #737980;
}

.hiw-feature-item-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  white-space: break-spaces;
}

.blog-wrapper-2 {
  text-decoration: none;
  cursor: pointer;
}

.job-grid-2 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.job-grid-2.one-item {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.job-grid-2.one-item.no-bottom-margin {
  border-bottom-style: none;
}

.testimonial-name-2 {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 5%;
}

.c-op-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.twinkle-img-2 {
  position: absolute;
  left: auto;
  top: -1em;
  right: -2em;
  bottom: auto;
  margin-bottom: 20px;
}

.html-embed-5 {
  display: inline;
  margin-right: 0.125em;
  font-size: 5rem;
}

.blog-image-2 {
  width: 100%;
}

.blog-image-2.d {
  -o-object-fit: cover;
  object-fit: cover;
}

.blog-image-2.m {
  display: none;
}

.career-hero-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 650px;
  margin-right: auto;
  margin-bottom: 2em;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.c-op-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 3em;
  height: 3em;
  padding: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4em;
  background-color: #fff;
  color: #878e96;
  font-size: 1rem;
  cursor: pointer;
}

.c-op-button:hover {
  color: #ea552b;
}

.paragraph-x-large-2 {
  color: #737980;
  font-size: 1.25rem;
  line-height: 1.5555555555555556em;
}

.paragraph-x-large-2.text-color-inverse {
  color: #fff;
}

.quote-content-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}

.div-block-169 {
  text-align: center;
}

.job-heading-2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.feature-icon-2 {
  width: 8em;
  height: 8em;
  margin-bottom: 2em;
}

.key-feature-grid-2 {
  width: 100%;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.divider-3 {
  width: 100%;
  height: 1px;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #e9ecef;
}

.divider-3.margin-1 {
  margin-top: 1em;
  margin-bottom: -1em;
}

.divider-3.margin-30 {
  width: 71%;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.h1-2 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 4rem;
  line-height: 1.0625em;
  font-weight: 900;
}

.h1-2.text-color-inverse {
  color: #fff;
}

.h1-2.text-color-inverse.margin-bottom-0-25 {
  margin-bottom: 0.25em;
}

.quote-smaller-text-2 {
  font-family: "Inter", sans-serif;
  color: #878e96;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}

.c-header-graphic-right {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: auto;
  height: 20vw;
}

.overlay-text-2 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 50;
  display: none;
  margin: 1em;
  padding: 1em 1.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
  background-color: #fff;
}

.paragraph-3 {
  font-family: "Inter", sans-serif;
  color: #747c84;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 400;
}

.paragraph-3.font-color-2 {
  color: #adb5bd;
  text-align: center;
}

.paragraph-3.big {
  font-size: 18px;
  line-height: 28px;
}

.paragraph-3.big.dark-grey {
  color: #495057;
}

.paragraph-3.big.dark-grey.margin {
  margin-bottom: 20px;
}

.paragraph-3.big.white {
  color: #fff;
}

.paragraph-3.big.bold {
  margin-bottom: 12px;
  color: #2c2c2c;
  font-weight: 600;
}

.paragraph-3.big.margin-24 {
  margin-bottom: 24px;
}

.paragraph-3.center {
  text-align: center;
}

.paragraph-3.center._2px {
  font-size: 18px;
}

.paragraph-3.lightgrey {
  color: #7b8087;
}

.paragraph-3.lightgrey.margin-38 {
  margin-bottom: 38px;
}

.paragraph-3.lightgrey.margin-32 {
  margin-bottom: 32px;
}

.paragraph-3.white {
  color: #fff;
}

.paragraph-3.dark-grey {
  color: #7b8087;
}

.div-block-170 {
  width: 49ch;
  margin-bottom: 3em;
  text-align: center;
}

.c-job-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1em 1em 1em 1.5em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f1f3f5;
  color: #353a40;
  text-decoration: none;
  cursor: pointer;
}

.blog-grid-2 {
  grid-column-gap: 37px;
  grid-row-gap: 37px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.paragraph-small-2 {
  color: #878e96;
  font-size: 1rem;
  line-height: 1.5em;
}

.button-large-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.75em 1.5em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 0.3em;
  background-color: #ea552b;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1.0625rem;
  font-weight: 700;
  cursor: pointer;
}

.button-large-2:hover {
  background-color: #9775fa;
}

.button-large-2:active {
  background-color: #7950f2;
}

.button-large-2.padding-1em {
  padding-top: 1em;
  padding-bottom: 1em;
}

.button-large-2.padding-1em.white {
  background-color: #fff;
  color: #2c97e5;
}

.button-large-2.padding-1em.white:hover {
  background-color: #2c97e5;
  color: #fff;
}

.button-large-2.padding-1em.white:active {
  background-color: #0173c6;
}

.button-large-2.padding-1em.padding-horizontal-3 {
  padding-right: 3em;
  padding-left: 3em;
}

.overview-wrapper-2 {
  margin-bottom: 3em;
}

.overview-wrapper-2.margin {
  margin-bottom: 3em;
}

.job-details-wrapper-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.apply-block-2 {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  padding: 1.5em;
  border: 1px solid #e9ecef;
  border-radius: 0.5em;
}

.list-4 {
  padding-left: 20px;
  border-bottom-style: none;
}

.list-4.purple {
  margin-bottom: 2em;
  color: #2c2c2c;
}

.job-details-2 {
  width: 60%;
}

.div-block-171 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.h2-2 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2.5rem;
  line-height: 1.2em;
  font-weight: 900;
}

.h2-2.margin-bottom-0-5 {
  margin-bottom: 0.5em;
}

.sbout-wrapper-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h3-2 {
  margin: 0px;
  padding: 0px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2rem;
  line-height: 1.25em;
}

.h3-2.text-color-light-blue {
  color: #31a8fe;
}

.requirements-icon-2 {
  width: 6em;
  height: 6em;
  margin-right: 1.5em;
}

.video-conetent-2 {
  width: 50%;
  margin-left: 4em;
}

.how-to-left-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.video-by-title-2 {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.video-block-2 {
  position: relative;
}

.requirements-heading-2 {
  margin-top: 0px;
  margin-bottom: 40px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.75rem;
  line-height: 1.0714285714285714em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.how-to-right-2 {
  width: 527px;
  padding: 2em;
  border-radius: 0.5em;
  background-color: #fff;
  line-height: 30px;
}

.requirements-inner-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.requirements-inner-2.no-margin {
  margin-bottom: 0px;
}

.about-img-2 {
  width: 50%;
}

.requirement-title-2 {
  margin-bottom: 6px;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 1.25rem;
  line-height: 1.5em;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.about-image-2 {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.upper-card-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}

.pg-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pg-stats-subheading {
  font-family: "Inter", sans-serif;
  color: #495057;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.pg-members {
  text-align: center;
}

.about-content-2 {
  width: 40%;
  margin-top: 0px;
  margin-left: 2em;
}

.programs-features-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.members-grid-2 {
  margin-top: 4em;
  margin-right: auto;
  margin-left: auto;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.pg-header-content {
  margin-bottom: 2em;
}

.benefits-heading-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.program-bar-2 {
  margin-bottom: 1em;
  color: #ea552b;
  font-size: 0.875rem;
  line-height: 2em;
  font-weight: 600;
  letter-spacing: 1px;
}

.program-title-2 {
  margin-top: 0px;
  margin-bottom: 16px;
  font-family: "Inter", sans-serif;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

.pg-main-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pg-main-header-caption {
  margin-bottom: 1em;
  color: #bab2ab;
  font-size: 1rem;
  line-height: 1.75em;
  font-weight: 600;
  letter-spacing: 1px;
}

.pg-main-info {
  margin-right: 3em;
  color: #2c2c2c;
  cursor: pointer;
}

.pg-main-info:hover {
  color: #018ef5;
}

.pg-main-info-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.pg-main-item-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.div-block-165 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.program-image-2 {
  overflow: hidden;
  width: 30%;
  margin-right: 3em;
  margin-bottom: 0px;
  border-radius: 0.5em;
  -o-object-fit: cover;
  object-fit: cover;
}

.pg-main-header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pg-main-content {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.header-wrapper.blue {
  background-color: #31a8fe;
}

.header-wrapper.pg {
  /* background-image: url('../images/Rectangle-2680.png'); */
  background-position: 50% 0%;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-wrapper.hc {
  /* background-image: url('../images/Article-BG.png'); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-wrapper.hc-main {
  /* background-image: url('../images/Help-Center-Hero-Background.svg'); */
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-wrapper.a {
  /* background-image: url('../images/a-header-bg.svg'); */
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-wrapper.pr {
  /* background-image: url('../images/ps-header-bg.svg'); */
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-wrapper.sos {
  /* background-image: url('../images/sos-header-bg.svg'); */
  background-position: 50% 50%;
  background-size: cover;
}

.co-modal-plans {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1500;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.co-modal-count {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1500;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.select-field {
  display: none;
  height: auto;
  margin-bottom: 0px;
  padding: 1em;
  border-radius: 0.5em;
  background-color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.image-89 {
  position: absolute;
  left: -6%;
  top: auto;
  right: auto;
  bottom: -38%;
  display: block;
  width: 52%;
  height: 368px;
  min-width: 52%;
}

.social-link-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e9ecef;
  border-radius: 100px;
}

.social-link-circle.hide-desktop {
  display: none;
}

.padding-left-right-2 {
  padding-right: 60px;
  padding-left: 60px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-100.mob-40.margin-1em {
  margin-top: 1em;
}

.text-purple {
  color: #ea552b;
  text-decoration: underline;
  cursor: pointer;
}

.text-19px {
  margin-bottom: 0px;
  font-size: 1.25rem;
  line-height: 1.8em;
}

.text-19px.mt30 {
  margin-top: 30px;
}

.get-design-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e9ecef;
  border-radius: 10px;
  /* background-image: url('../images/get-design.svg'), -webkit-gradient(linear, left top, left bottom, from(#1e133e), to(#2d2053)); */
  /* background-image: url('../images/get-design.svg'), linear-gradient(180deg, #1e133e, #2d2053); */
  background-position: 50% 50%, 0px 0px;
  background-size: cover, auto;
  text-align: center;
}

.blog-single_header-wrapper-2 {
  margin-top: 1em;
}

.blog-single_author-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.author-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.images-wrapper-2 {
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mt-45 {
  margin-top: 2em;
}

.blog-single_info-grid-2 {
  width: 50%;
  -ms-grid-columns: 15% 1fr;
  grid-template-columns: 15% 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-block-13 {
  color: #99e9f2;
  font-size: 1.25rem;
  line-height: 1.2em;
  font-weight: 700;
}

.feature-blog1-2 {
  overflow: hidden;
  border-radius: 0.3em;
  background-color: #ea552b;
}

.text-block-11 {
  font-family: "Inter", sans-serif;
  color: #737980;
  font-size: 1rem;
  line-height: 1.5em;
}

.image-36 {
  margin-top: 20px;
  padding-right: 15px;
  padding-left: 20px;
}

.grey-block {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  background-color: #f1f3f5;
}

.div-block-173 {
  padding-top: 20px;
}

.mt-64 {
  margin-top: 2em;
}

.blog-title {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2.5rem;
  line-height: 1.2em;
  font-weight: 900;
}

.single-blog_grid-2 {
  position: relative;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 5fr 1fr;
  grid-template-columns: 1fr 5fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.single-blog_grid-2.toc {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.single-blog_tag-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-blog_tag-2.hide {
  display: none;
}

.feature-blog1_content-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 23px 20px 36px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.grid-31 {
  padding: 2.5em;
  grid-column-gap: 40px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.mt-32 {
  margin-top: 32px;
}

.blog-image.mt-40 {
  margin-top: 40px;
}

.post-tags-2 {
  margin-left: 20px;
  padding-top: 3px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 100px;
  background-color: #f8f9fa;
}

.text-size-xsmall-2 {
  color: #878e96;
  font-size: 14px;
  font-weight: 300;
}

.text-size-xsmall-2.text-color-black {
  font-weight: 500;
}

.div-block-34 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-block-33 {
  color: #fff;
}

.single-blog_post-body-2 {
  padding: 2em;
  border-radius: 5px;
  background-color: #fff;
}

.single-blog_post-body-2.mbl-overflow-hid {
  padding-top: 0em;
}

.feature-blog2-2 {
  margin-top: 30px;
  border-radius: 0.3em;
  background-color: #f1f3f5;
  /* background-image: url('../images/holiday-image.png'); */
  background-position: 0px 0px;
  background-size: auto;
  text-align: center;
}

.date-time-2 {
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-blog2-content-2 {
  padding: 1em 1em 6em;
}

.single-blog_featue-blog-2 {
  display: none;
}

.heading-normal-2 {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 700;
}

.div-block-125 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.image-88 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.show-mob {
  display: none;
}

.images-grid-2 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin-top: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.75fr 1fr 1fr 1fr 0.5fr;
  grid-template-columns: 0.75fr 1fr 1fr 1fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.images-grid-2.mbl-show {
  display: none;
}

.text-14px {
  color: #878e96;
  font-size: 14px;
  line-height: 20px;
}

.text-14px.mt-10 {
  margin-top: 12px;
}

.div-block-41 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.list-item-5 {
  padding-bottom: 16px;
  padding-left: 48px;
  /* background-image: url('../images/Group-1.svg'); */
  background-position: 0% 0%;
  background-size: 28px 28px;
  background-repeat: no-repeat;
}

.list-5 {
  margin-top: 2em;
  list-style-type: none;
}

.text-20px {
  margin-bottom: 0px;
  font-size: 20px;
  line-height: 36px;
}

.weight-600 {
  font-weight: 600;
}

.ds-card-thumbnail-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 16em;
  max-width: none;
  border-radius: 0.5em;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
  -o-object-fit: cover;
  object-fit: cover;
}

.ds-card-thumbnail-wrapper:hover {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1), 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0px, -2px);
  -ms-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
}

.cs-icon---play {
  position: absolute;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4em;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  font-size: 4rem;
}

.cs-icon---play:hover {
  color: #ea552b;
}

.success-message-3 {
  background-color: transparent;
}

.lc-search-wrapper {
  padding-top: 1rem;
  padding-bottom: 4rem;
}

.lc-all-wrappers {
  padding-top: 3em;
  padding-bottom: 3em;
}

/* .tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
} */

.tabs-menu-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.tab-link-tab-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 2.5em;
  height: 2.5em;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.3em;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  color: #737980;
  font-weight: 600;
}

.tab-link-tab-1:hover {
  color: #ea552b;
}

.tab-link-tab-1.w--current {
  background-color: rgba(132, 94, 247, 0.05);
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #ea552b;
}

.tabs-content-2 {
  margin-top: 2vh;
}

.text-span-13 {
  font-weight: 600;
}

.button-icon---left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-19 {
  padding: 4rem 2rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f1f3f5),
    color-stop(50%, hsla(0, 0%, 100%, 0))
  );
  background-image: linear-gradient(180deg, #f1f3f5, hsla(0, 0%, 100%, 0) 50%);
}

.co---chevron---mobile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  margin-right: 0.5rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 3rem;
}

.html-embed-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #2c2c2c;
  font-size: 2.5rem;
}

.blog-post-toc-content {
  padding: 0em 2em 2em;
  border-radius: 5px;
  background-color: #fff;
}

.div-block-175 {
  position: -webkit-sticky;
  position: sticky;
  top: 6em;
  z-index: 100;
  display: block;
  padding: 2em;
  border-radius: 0.5em;
  background-color: #fff;
}

.list-6 {
  margin-top: 1em;
  list-style-type: none;
}

.blog-toc-item {
  height: 100%;
  min-width: 100%;
  padding: 0.5em 1em;
  border-radius: 0.3em;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #737980;
}

.blog-toc-item:hover {
  color: #2c2c2c;
}

.blog-toc-item:active {
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.blog-toc-item:focus {
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
  font-weight: 600;
}

.image-106 {
  height: 2em;
  margin-right: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
}

.pg-bg {
  /* background-image: url('../images/Rectangle-2680.png'); */
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.div-block-176 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.div-block-178 {
  display: inline-block;
  margin-right: 0.5em;
}

.tabfeatured {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabfeatured:hover {
  color: #2c2c2c;
}

.tabfeatured.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.tabanimation {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabanimation:hover {
  color: #2c2c2c;
}

.tabanimation.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.tabbranding {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabbranding:hover {
  color: #2c2c2c;
}

.tabbranding.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.tabdigital {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabdigital:hover {
  color: #2c2c2c;
}

.tabdigital.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.tabprints {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabprints:hover {
  color: #2c2c2c;
}

.tabprints.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.tabilustrations {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabilustrations:hover {
  color: #2c2c2c;
}

.tabilustrations.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.tabpresentations {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabpresentations:hover {
  color: #2c2c2c;
}

.tabpresentations.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.tabwebapp {
  margin: 0px;
  padding: 2px;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  opacity: 1;
  color: #878e96;
  font-weight: 500;
  cursor: pointer;
}

.tabwebapp:hover {
  color: #2c2c2c;
}

.tabwebapp.w--current {
  position: static;
  background-color: rgba(132, 94, 247, 0.05);
  color: #ea552b;
}

.buttonbranding {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 104px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px 0px 0px 2em;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.5em;
  background-color: #fb9600;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  cursor: pointer;
}

.buttonbranding:hover {
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.co-heading-main {
  display: none;
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 3rem;
  line-height: 1.1666666666666667em;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.2px;
}

.co-heading-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.co-item-frequency {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 100%;
  padding: 1.5em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  background-color: #fff;
}

.co-item-frequency:hover {
  background-color: #fff;
}

.om-programs-wrapper-tablet {
  display: none;
  overflow: hidden;
}

.support-mobile {
  overflow: hidden;
  padding: 4em;
  border-radius: 0.8em;
  /* background-image: url('../images/ow-pfg-bg.png'); */
  background-position: 50% 50%;
  background-size: auto 110%;
  background-repeat: no-repeat;
}

.heading-swipe {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ovrride-text {
  margin-left: -0.4em;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.nav-logo-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20em;
  padding-left: 20em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.h1-404 {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-size: 2.5rem;
  font-weight: 900;
}

.paragraph-default-4 {
  margin-top: 1em;
  margin-bottom: 2em;
  color: #737980;
}

.link-close-cart {
  position: relative;
  top: -0.5em;
  z-index: 100;
  margin-left: 0em;
  padding-bottom: 3px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #6741d9;
  font-size: 0.875rem;
  line-height: 1em;
  font-weight: 600;
}

.om-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.service-outline {
  height: 3rem;
  margin-top: -1%;
  margin-right: 0.75em;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.om-job-title {
  margin-top: 0.25em;
  line-height: 1em;
}

.icon---chevron-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.25em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1em;
}

.image-111 {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.image-112 {
  margin-top: -4em;
  margin-bottom: 1em;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.co-modal-tos {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1500;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.co-modal-tos.aa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tos-wrapper {
  overflow: scroll;
  height: 60vh;
}

.co-modal-pp {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1500;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.pp-wrapper {
  overflow: scroll;
  height: 60vh;
  padding-right: 0em;
  padding-left: 0em;
}

.link-5 {
  cursor: pointer;
}

.co---schedule---edit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.co---schedule---edit:hover {
  color: #ea552b;
}

.co---workload---edit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.co---workload---edit:hover {
  color: #ea552b;
}

.promo-banner-wrapper {
  position: relative;
  display: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #15aabf;
}

.promo-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-45 {
  margin-left: 1rem;
  font-family: "Inter", sans-serif;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.text-span-14 {
  border-bottom: 1px solid #ffe066;
  color: #ffe066;
  cursor: pointer;
}

.promo-banner-close {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0.5rem;
  bottom: 0%;
  cursor: pointer;
}

.grid-36 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  -ms-grid-columns: 0.25fr 1fr;
  grid-template-columns: 0.25fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.checklist-item {
  font-family: "Inter", sans-serif;
  color: #2c2c2c;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.co-checkout-checklist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.co-checklist-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.co-checklist-list {
  margin-right: 1rem;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.co-item-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.co-item-grid._2 {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.tooltip-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0.5em;
}

.tooltip {
  position: relative;
  z-index: 100;
  color: #aeb5bd;
  cursor: pointer;
}

.tooltip:hover {
  color: #ea552b;
}

.tooltip:active {
  color: #2c2c2c;
}

.div-block-179 {
  position: absolute;
  left: 1rem;
  top: auto;
  right: auto;
  bottom: 1rem;
  z-index: 100;
  display: none;
  width: 240px;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #dee2e6;
}

.div-block-179:hover {
  color: #aeb5bd;
}

.div-block-179.alt {
  left: auto;
  right: 1rem;
  display: none;
}

.text-block-46 {
  margin-bottom: 0.5rem;
  color: #2c2c2c;
  font-size: 0.875rem;
  line-height: 1.25em;
  font-weight: 600;
}

.text-block-47 {
  color: #aeb5bd;
  font-size: 0.875rem;
  line-height: 1.5em;
  font-weight: 400;
}

.div-block-180 {
  position: relative;
}

.div-block-181 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.co-item-wrapper-2 {
  position: relative;
  z-index: 2;
  margin-bottom: 1em;
  padding: 2px;
  border-radius: 0.6em;
  opacity: 1;
  cursor: pointer;
}

.co-item-wrapper-2:hover {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.co-item-wrapper-2:focus {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.co-item-wrapper-2.grid-alt {
  width: 100%;
  margin-bottom: 0em;
}

.co-item-wrapper-2.plan {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 128px;
}

.co-item-wrapper-static {
  position: relative;
  z-index: 2;
  width: 200px;
  margin-bottom: 1em;
  padding: 2px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 0.6em;
  opacity: 1;
  cursor: pointer;
}

.co-item-wrapper-static:hover {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.co-item-wrapper-static:focus {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.co-item-check-wrapper {
  position: absolute;
  left: auto;
  top: auto;
  right: 1.5em;
  bottom: 1em;
  z-index: 10;
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #cfd4da;
  border-radius: 4em;
  background-color: transparent;
  box-shadow: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.co-item-check-wrapper:active {
  display: inline;
  background-color: #40c057;
}

.co-item-check-wrapper.w--redirected-checked {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-width: 0px;
  border-color: transparent;
  border-radius: 4em;
  background-color: #40c057;
  /* background-image: url('../images/co-icon-check.svg'); */
  background-position: 50% 50%;
  background-size: 0.8em;
  background-repeat: no-repeat;
}

.co-item-check-wrapper.w--redirected-focus {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4em;
  background-color: #40c057;
  /* background-image: url('../images/co-icon-check.svg'); */
  background-position: 50% 50%;
  background-size: 0.8em;
  background-repeat: no-repeat;
  box-shadow: none;
}

.co-item-check-wrapper.w--redirected-focus-visible {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-color: #40c057;
  border-radius: 4em;
}

.co-item-check-wrapper.grid-alt {
  right: 1em;
  z-index: 3;
}

.co-item-checkmark-copy {
  position: absolute;
  left: auto;
  top: auto;
  right: 1.5em;
  bottom: 1em;
  z-index: 10;
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  padding: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #cfd4da;
  border-radius: 4em;
  background-color: transparent;
  box-shadow: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.co-item-checkmark-copy:active {
  display: inline;
  background-color: #40c057;
}

.co-item-checkmark-copy.w--redirected-checked {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-width: 0px;
  border-color: transparent;
  border-radius: 4em;
  background-color: #40c057;
  /* background-image: url('../images/co-icon-check.svg'); */
  background-position: 50% 50%;
  background-size: 0.8em;
  background-repeat: no-repeat;
}

.co-item-checkmark-copy.w--redirected-focus {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4em;
  background-color: #40c057;
  /* background-image: url('../images/co-icon-check.svg'); */
  background-position: 50% 50%;
  background-size: 0.8em;
  background-repeat: no-repeat;
  box-shadow: none;
}

.co-item-checkmark-copy.w--redirected-focus-visible {
  width: 1.5em;
  height: 1.5em;
  margin: 0px;
  border-color: #40c057;
  border-radius: 4em;
}

.co-item-checkmark-copy.grid-alt {
  right: 1em;
  z-index: 3;
}

.co-item-check-icon {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4em;
  /* background-image: url('../images/icon-check-circle-green.svg'); */
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0;
}

.co-item-gradient {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  padding: 2px;
  border-radius: 0.6em;
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
  opacity: 0;
}

.co-modal-offer-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1500;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.co-modal-top-bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-block-48 {
  margin-bottom: 2em;
}

.co-item-discount {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.co-item-new-price {
  background-color: rgba(178, 242, 187, 0.5);
}

.text-span-15 {
  color: #2f9e44;
  font-weight: 600;
  text-decoration: none;
}

.text-span-16 {
  text-decoration: line-through;
}

.discount-rate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5rem;
  padding: 0.75em 1em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 0.3em;
  background-color: rgba(178, 242, 187, 0.5);
  color: #2f9e44;
  font-size: 0.875rem;
  line-height: 1em;
}

.co-white {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 100%;
  padding: 1em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  border-radius: 0.5em;
  background-color: #fff;
}

.co-white:hover {
  background-color: #fff;
}

.gradient-bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  padding: 2px;
  border-radius: 0.6em;
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
  opacity: 1;
}

.gradient-bg:hover {
  background-image: linear-gradient(200deg, #fd5ff4, #8f58fe 50%, #1a9cfd);
}

.form-3 {
  width: 80%;
  background-color: #f1f3f5;
}

.co-modal-pricing {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1001;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* -webkit-box-align: center; */
  /* -webkit-align-items: center; */
  /* -ms-flex-align: center; */
  /* align-items: center; */
  background-color: rgba(0, 0, 0, 0.5);
}

.div-block-182 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.close-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -2rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-183 {
  position: relative;
  padding-top: 2em;
  transform: scale(0.8, 0.8);
}

.black-overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
}

.button-2 {
  position: absolute;
  left: auto;
  top: auto;
  right: 5%;
  bottom: auto;
  padding: 0px;
  background-color: transparent;
  color: #ea552b;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.button-2:hover {
  color: #2c2c2c;
  text-align: left;
}

@media screen and (min-width: 1280px) {
  .rating-bar {
    border-color: #fff;
    background-color: #f1f3f5;
  }

  .rating-highlight._3 {
    width: 4%;
  }

  .review-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #2c2c2c;
    text-decoration: none;
  }

  .review-link:hover {
    text-decoration: underline;
  }

  .review-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .work-category.color--gray-900.text-lg.semi-bold.text-center {
    top: -80px;
  }

  .h-portfolio-category {
    display: block;
  }

  .h-portfolio-item {
    position: relative;
  }

  .nav-base {
    width: 100%;
  }

  .discount-box {
    padding-right: 10px;
    padding-left: 10px;
  }

  .answer-2-new_test {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .h-portfolio-wrapper {
    overflow: hidden;
  }

  .h-testimonial-small {
    overflow: hidden;
  }

  .h-social-proof {
    overflow: hidden;
    margin-top: -20px;
  }

  .h-hiw-wrapper {
    overflow: hidden;
    padding-bottom: 190px;
  }

  .faq-drop {
    background-color: #f1f3f5;
  }

  .pub-icon {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  .section-base {
    overflow: hidden;
  }

  .a-hiw-wrapper {
    overflow: hidden;
    padding-bottom: 190px;
  }

  .box__for__cursor {
    width: 160px;
    height: 80px;
  }

  .chart-card._2020 {
    right: 19.5%;
    bottom: 33%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .chart-card._2018 {
    right: 41%;
    bottom: 9.5%;
  }

  .chart-card._2019 {
    right: 30%;
    bottom: 20%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .chart-card._2021 {
    top: 33%;
    right: 8.5%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .chart-card._2017 {
    right: 52%;
    bottom: 3%;
  }

  .chart-card._2022 {
    top: 8%;
    right: 3%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .ch-back-btn-base {
    background-color: #fff;
  }

  .co-grid-wrapper {
    -ms-grid-columns: 1fr 0.5fr;
    grid-template-columns: 1fr 0.5fr;
  }

  .faq-drop-2 {
    background-color: #f1f3f5;
  }

  .ca-form-block {
    max-width: 600px;
  }

  .form-label-2 {
    font-size: 15px;
  }

  .input-field.input-styling::-webkit-input-placeholder {
    font-size: 15px;
  }

  .input-field.input-styling:-ms-input-placeholder {
    font-size: 15px;
  }

  .input-field.input-styling::-ms-input-placeholder {
    font-size: 15px;
  }

  .input-field.input-styling::placeholder {
    font-size: 15px;
  }

  .box__for__cursor-2 {
    width: 160px;
    height: 80px;
  }
}

@media screen and (min-width: 1920px) {
  .container-2 {
    width: 73vw;
  }

  .h-header-graphic {
    position: relative;
    bottom: -20px;
    z-index: 5;
  }

  .pub-card {
    height: 360px;
  }

  .pub-card-con {
    max-width: none;
  }

  .work-card-link {
    margin-right: 30px;
  }

  .h-testimonial-right {
    padding-left: 120px;
    text-align: left;
  }

  .h-social-proof {
    margin-top: 0px;
  }

  .h-header-graphic-alt {
    position: relative;
    bottom: -20px;
    z-index: 5;
  }

  .h-hiw-wrapper {
    padding-top: 130px;
    padding-bottom: 190px;
  }

  .a-hiw-wrapper {
    padding-top: 130px;
    padding-bottom: 190px;
  }

  .chart.response {
    /* background-image: url('../images/Chart.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .chart.response {
    /* background-image: url('../images/Chart.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }

  .chart-card._2020 {
    right: 23%;
  }

  .chart-card._2018 {
    right: 44%;
  }

  .chart-card._2019 {
    right: 34%;
  }

  .chart-card._2021 {
    right: 12%;
  }

  .chart-card._2017 {
    right: 55%;
  }

  .chart-card._2022 {
    right: 3%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .purple-text {
    color: #ea552b;
  }
}

@media screen and (max-width: 991px) {
  .section-nav {
    position: -webkit-sticky;
    position: sticky;
    padding-top: 0em;
    padding-bottom: 0em;
    border-bottom: 1px solid #e9ecef;
    background-color: #fff;
  }

  .section-nav.transparent {
    background-color: transparent;
  }

  .section-nav.alt {
    border-bottom-color: transparent;
    background-color: transparent;
  }

  .nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 300;
    height: 4em;
    padding: 0.5em 1em;
  }

  .nav-logo {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .nav-container {
    padding: 0em;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .nav-links {
    position: absolute;
    z-index: 1000;
    height: 100vh;
    margin-top: 4em;
    margin-left: 50%;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    background-color: #fff;
  }

  .nav-link {
    display: block;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    border-radius: 0px;
    background-color: #fff;
    font-size: 1rem;
  }

  .nav-link:hover {
    background-color: #e5dbff;
  }

  .nav-link:active {
    background-color: #ea552b;
    color: #f1f3f5;
  }

  .nav-link.alt {
    color: #2c2c2c;
  }

  .nav-link.non-click {
    display: block;
  }

  .nav-link.non-click:hover {
    background-color: transparent;
    color: #2c2c2c;
  }

  .nav-buttons {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .cs-header-wrapper {
    padding: 2em 2em 3em;
  }

  .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 1;
  }

  .cs-header-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cs-header-column-left {
    margin-bottom: 2em;
    padding-right: 0em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    text-align: center;
  }

  .cs-header-column-right {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .h1.h-header {
    width: auto;
  }

  .paragraph-x-large.max-ch-54 {
    width: auto;
  }

  .button-large.padding-1em.mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .cs-item-preview {
    height: auto;
  }

  .cs-list-wrapper {
    padding: 3em 2em;
  }

  .cs-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .cs-item-description-container {
    height: auto;
    padding-top: 1.6em;
    padding-right: 1em;
    padding-bottom: 1.5em;
  }

  .cs-header-content {
    margin-bottom: 1.5em;
  }

  .nav-menu {
    padding: 0.25em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 2rem;
  }

  .nav-menu:active {
    color: #2c2c2c;
  }

  .nav-menu.w--open {
    padding: 0.25em;
    background-color: transparent;
    color: #ea552b;
  }

  .nav-menu.alt {
    color: #fff;
  }

  .icon---chevron-right.lc-featured-icon-chevron {
    display: none;
  }

  .paragraph-default.max-ch {
    width: auto;
  }

  .paragraph-default.hidden {
    display: none;
  }

  .paragraph-default.max-ch-2.em-mobile {
    margin-bottom: 2em;
  }

  .paragraph-default.max-ch-3 {
    width: auto;
  }

  .section-cs-story-header {
    margin-right: 2em;
    margin-left: 2em;
    padding-top: 2em;
  }

  .cs-story-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cs-story-column-left {
    margin-bottom: 2em;
  }

  .cs-story-column-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cs-story-header-content {
    padding-right: 0em;
  }

  .cs-story-thumbnail {
    width: 100%;
    height: auto;
  }

  .cs-story-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: auto;
    grid-row-gap: 1.5em;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .section-cs-story-article {
    padding: 3em 2em 0em;
  }

  .cs-story-img-column {
    margin-top: 0px;
  }

  .cs-story-img-column-left {
    padding-right: 1em;
    padding-left: 1em;
  }

  .cs-story-img-column-right {
    padding-right: 1em;
    padding-left: 1em;
  }

  .cs-story-content {
    padding-right: 5%;
    padding-left: 5%;
    text-align: justify;
  }

  .section-cs-story-previews {
    padding: 4em 2em 2em;
  }

  .divider.nav-divider {
    margin-top: 0em;
    margin-bottom: 0em;
  }

  .divider.margin-disable.nav {
    display: block;
    height: 1px;
  }

  .cs-story-stats {
    -webkit-box-pack: stretch;
    -webkit-justify-content: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .cs-story-more {
    margin-top: 0em;
  }

  .cs-story-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .nav-link-text {
    font-size: 1.25rem;
  }

  .icon-caret-down {
    display: none;
  }

  .section-footer {
    padding: 3em 2em 8em;
    background-position: 0% 100%;
    background-size: auto;
  }

  .footer-cta-column {
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .footer-cta-column-left {
    padding-right: 0px;
  }

  .footer-cta-column-right {
    z-index: 1;
    display: none;
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(132, 94, 247, 0.8)), to(rgba(132, 94, 247, 0.8))), url('../images/bg-cta.svg'); */
    /* background-image: linear-gradient(180deg, rgba(132, 94, 247, 0.8), rgba(132, 94, 247, 0.8)), url('../images/bg-cta.svg'); */
    background-repeat: repeat, no-repeat;
  }

  .paragraph-default-alt.p-money-back-paragraph {
    margin-bottom: 0px;
    margin-left: 0em;
  }

  .footer-cta-graphic {
    top: auto;
    bottom: 0%;
    height: auto;
  }

  .cta-button-primary {
    -o-object-fit: fill;
    object-fit: fill;
  }

  .footer-cta-buttongroup {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer-press-logo-list {
    margin-left: 0px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    justify-items: center;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .footer-press-logo {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .footer-column {
    margin-left: 2em;
    grid-auto-columns: auto;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .section-wp-header {
    padding-top: 3em;
    padding-bottom: 0em;
  }

  .h1-large {
    font-size: 4rem;
  }

  .h1-large.text-color-inverse {
    width: 9ch;
  }

  .h1-large.text-color-inverse.margin-bottom-1 {
    width: auto;
  }

  .h1-large.text-color-inverse.sos {
    width: auto;
  }

  .h1-large.text-color-dark-purple {
    padding-right: 0px;
    font-size: 3.5rem;
  }

  .wp-header {
    padding-right: 0em;
    padding-left: 0em;
  }

  .wp-testimonial-card-blue {
    min-width: 100%;
  }

  .wp-header-bg {
    background-image: none;
  }

  .section-wp-services {
    overflow: hidden;
    margin-top: 4em;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #f1f3f5;
  }

  .section-wp-services.desktop {
    display: block;
  }

  .section-wp-services.mobile {
    display: none;
  }

  .wp-services {
    padding-top: 4em;
    padding-bottom: 4em;
    background-color: transparent;
  }

  .wp-services-content {
    margin-bottom: 2em;
  }

  .wp-services-content-header-line {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wp-content-headline {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .wp-services-column-left {
    padding-top: 0em;
    padding-right: 0em;
  }

  .wp-services-column-right {
    position: relative;
    z-index: 2;
    margin-top: -35%;
    margin-bottom: 0px;
    border-radius: 0.5em;
  }

  .wp-services-graphic {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    z-index: 1;
    max-width: 100%;
    min-width: auto;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .wp-services-column {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .section-wp-assign {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding-top: 4em;
    padding-right: 2em;
    padding-left: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .h1-small-alt {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .wp-assign-content {
    padding-right: 4em;
    padding-left: 4em;
  }

  .wp-assign-graphic {
    min-width: 1920px;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .wp-swap-wrapper {
    padding-top: 4em;
    padding-bottom: 4em;
    background-position: 50% 0%;
    text-align: center;
  }

  .wp-section-container {
    background-position: 50% 0%;
  }

  .wp-swap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .div-block {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .column {
    padding-top: 0px;
    padding-right: 4em;
    padding-bottom: 0px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .section-wp-dashboard {
    min-height: auto;
    padding: 4em;
  }

  .wp-dashboard-content {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .h1-small.alt {
    margin-bottom: 0em;
    text-align: center;
  }

  .h1-small.padding-horizontal-large {
    padding-right: 0em;
    padding-left: 0em;
  }

  .h1-small.margin-disable {
    margin-bottom: 0em;
  }

  .h1-small.margin-disable.alt {
    margin-bottom: -0.5em;
  }

  .h1-small.margin-bottom-large {
    margin-bottom: 1em;
  }

  .h1-small.center.max-ch-spotlight {
    width: auto;
  }

  .h1-small.max-ch {
    width: 17ch;
  }

  .wp-dashboard-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: none;
    min-width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .wp-dashboard-content-mask {
    position: static;
    width: 100%;
    height: 24em;
    margin-bottom: 2em;
    padding: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .section-footer-mobile {
    padding-right: 2em;
    padding-bottom: 8em;
    padding-left: 2em;
    background-position: 0% 100%;
    background-size: auto;
  }

  .nav-button-link {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 0px;
    background-color: #fff;
    font-size: 1.2rem;
  }

  .nav-button-link:hover {
    border-radius: 0.3em;
    background-color: transparent;
  }

  .nav-button-link.alt {
    background-color: transparent;
    font-size: 1rem;
  }

  .wp-dashboard-graphic {
    height: 24em;
  }

  .wp-dashboard-tabs {
    height: auto;
    margin-top: 3em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .section-wp-outsource {
    overflow: hidden;
    padding: 4em 0px 4em 4em;
  }

  .wp-outsource-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wp-outsource-column-right {
    position: static;
  }

  .wp-outsource-content {
    margin-bottom: 4em;
    padding-right: 4em;
  }

  .wp-outsource-benefits {
    grid-auto-columns: auto;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .column-2 {
    padding: 0px 4em 4px;
  }

  .slider-wrapper {
    margin: 0px;
    padding: 0px 4em;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .slider-wrapper.slider-wrapper-ow-gallery {
    overflow: visible;
    margin-top: -1em;
    padding-right: 0px;
    padding-left: 0px;
  }

  .slider-navigation-wrapper {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .slider-icon.left {
    left: 5vw;
  }

  .slider-icon.right {
    right: 5vw;
  }

  .slider-navigation.next-slide {
    display: none;
  }

  .slider-navigation.prev-slide {
    display: none;
  }

  .slider-list {
    margin-left: -4em;
  }

  .slider-list.slider-list-ow-gallery {
    height: auto;
  }

  .slider-list.h-portfolio {
    margin-left: -4em;
  }

  .slider-list.spolight {
    width: 100vw;
    margin-left: -4em;
  }

  .slider-item {
    min-width: 640px;
  }

  .slider-item.p-reviews-card {
    min-width: auto;
  }

  .slider-item.slider-item-ow-gallery {
    position: -webkit-sticky;
    position: sticky;
    width: 14em;
    max-height: 264px;
    max-width: 14em;
    min-width: auto;
  }

  .slider-item.h-item {
    overflow: hidden;
    max-width: none;
    margin-right: 1vw;
    margin-left: 1vw;
    padding-right: 1vw;
  }

  .slider-item.spotlight {
    min-width: auto;
  }

  .section-p-header-parent {
    position: relative;
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(132, 94, 247, 0.8)), to(rgba(132, 94, 247, 0.8))), url('../images/pricing-bg-graphic-right.svg'), url('../images/pricing-bg-graphic-left.svg'); */
    /* background-image: linear-gradient(180deg, rgba(132, 94, 247, 0.8), rgba(132, 94, 247, 0.8)), url('../images/pricing-bg-graphic-right.svg'), url('../images/pricing-bg-graphic-left.svg'); */
    background-position: 0px 0px, 80vw 50%, -10vw 50%;
    background-size: auto, auto 100%, auto 100%;
  }

  .p-packages {
    margin-top: 2em;
    margin-bottom: 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-row-gap: 2em;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .p-packages-item {
    width: 100%;
    max-width: 600px;
  }

  .p-package-price-container {
    margin-top: 1em;
    margin-bottom: 0px;
  }

  .button-large-purple {
    max-height: 188vw;
  }

  .button-large-purple.pricing-button {
    max-height: none;
  }

  .p-logo-list {
    grid-column-gap: 3em;
    grid-row-gap: 2em;
    -ms-grid-columns: auto auto auto auto;
    grid-template-columns: auto auto auto auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .section-p-benefits {
    padding: 4em 2em;
  }

  .p-benefits-heading-outline {
    height: 3.5rem;
  }

  .benefits-list {
    grid-column-gap: 1em;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }

  .p-cta {
    padding-top: 2.5em;
    padding-right: 3em;
    padding-left: 3em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    /* background-image: url('../images/pricing-cta-graphic-alt.png'); */
    background-position: 40vw 35%;
    background-size: auto 180%;
  }

  .p-cta-content-main {
    width: 50%;
  }

  .section-p-reviews {
    padding-bottom: 0em;
    background-size: auto 20%;
  }

  .p-reviews-subheading {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .p-reviews-card {
    width: 50%;
  }

  .p-reviews-content {
    padding-right: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
  }

  .faq {
    padding-top: 3em;
    padding-right: 2em;
    padding-left: 2em;
    text-align: center;
  }

  .p-faq {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .faq-graphic {
    max-width: 100%;
    min-height: auto;
    margin-left: 0%;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .p-faq-list {
    margin-left: 0em;
  }

  .footer-cta-container {
    height: auto;
  }

  .wp-designer-name {
    bottom: 5%;
  }

  .wp-assign-graphic-container {
    height: 100%;
    min-width: 1920px;
  }

  .paragraph-default-alt-2.max-ch {
    width: auto;
  }

  .cta-heading-2.max-ch {
    width: auto;
  }

  .faq-content {
    margin-bottom: 2em;
  }

  .p-cta-content {
    width: 80%;
  }

  .p-packages-main {
    margin-bottom: 2em;
    padding-right: 4em;
  }

  .p-packages-main.package-price-alt {
    margin-bottom: 0em;
    padding-right: 0em;
  }

  .nav-list-icon {
    width: 2.5em;
    height: 2.5em;
    min-height: 40px;
    min-width: 40px;
    margin-right: 1em;
  }

  .nav-list-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0.5em 4em 0.5em 1.5em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
  }

  .nav-list-text {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    font-family: "Inter", sans-serif;
    color: #2c2c2c;
    line-height: 1.1em;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .p-package-item-wrapper {
    width: 80vw;
  }

  .p-money-back {
    overflow: hidden;
    min-height: auto;
    padding-right: 3em;
    padding-left: 3em;
    background-position: 80% 100%;
    background-size: auto 25vh;
  }

  .pricing-money-back-graphic {
    height: 24vw;
    margin-top: 0em;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .p-tab-button-container {
    width: 26.666666666666668vw;
  }

  .cs-story-thumbnail-square-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .cs-story-graphic-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ow-header-content {
    margin-bottom: 0px;
    padding-right: 20%;
    padding-left: 20%;
    opacity: 1;
  }

  .ow-section-header {
    height: auto;
    max-height: none;
    padding-top: 2em;
    padding-bottom: 2em;
    background-position: 105% 40%, -5% 50%;
    background-size: auto 60%, auto 60%;
  }

  .ow-category-list {
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .ow-modal-project-info {
    letter-spacing: 0.5px;
  }

  .ow-category-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 0.3em;
  }

  .ow-category-item.web {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 50% 0%;
    background-size: auto 80%;
  }

  .ow-category-item.illus {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 50% 0%;
    background-size: auto 80%;
  }

  .ow-category-item.presentations {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: 50% 0%;
    background-size: auto 80%;
  }

  .ow-category-item._2d-animation {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
  }

  .ow-category-item.infographics {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
  }

  .ow-category-info {
    width: 100%;
    height: 3em;
    margin-right: 0rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    color: #2c2c2c;
  }

  .ow-modal-wrapper {
    max-width: none;
  }

  .ow-section-gallery {
    position: relative;
  }

  .ow-tab-text {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }

  .ow-category-name {
    font-size: 1rem;
  }

  .ow-modal-overlay {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(transparent),
      to(rgba(0, 0, 0, 0.5))
    );
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.5));
  }

  .ow-tab-content {
    padding-top: 1em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .ow-header-sparkle {
    height: 1.5em;
    margin-right: 0%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .ow-tab-item {
    width: 100%;
    max-width: 600px;
  }

  .ow-our-picks {
    margin-bottom: 2em;
  }

  .ow-popular-categories {
    margin-top: -3em;
    margin-bottom: 2em;
  }

  .ow-list {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .ow-modal-full {
    width: 90vw;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .lc-header-wrapper {
    padding: 0em 2em 2em;
  }

  .lc-header-content {
    margin-bottom: 1.2em;
  }

  .lc-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lc-header-column-right {
    margin-top: 2em;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .lc-featured-wrapper {
    padding: 3em 2em 0em;
  }

  .lc-author-name.lc-all {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .lc-featured-thumbnail-wrapper {
    max-height: 320px;
  }

  .lc-featured-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lc-featured-column-right {
    margin-left: 0em;
    padding-right: 0px;
    padding-left: 0px;
  }

  .lc-featured-item-vertical {
    width: 100%;
    margin-bottom: 2em;
  }

  .link-button.checkout {
    display: none;
  }

  .lc-recent-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .lc-all-wrapper {
    padding: 3em 2em;
  }

  .video-lightbox {
    width: 591.1875px;
    height: 130px;
  }

  .review-tab-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .link-text-review {
    display: block;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }

  .r-header-rating {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .section-reviews {
    padding: 2em;
  }

  .review-video-lighbox {
    width: 30%;
    max-width: 30%;
  }

  .review-tab_component {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .review-left {
    position: static;
    max-width: none;
    min-width: auto;
  }

  .review-video-wrapper {
    position: relative;
  }

  .right-review {
    margin-top: 3em;
    margin-left: 0rem;
  }

  .r-stats {
    margin-top: 0em;
  }

  .video-item {
    position: relative;
    overflow: hidden;
  }

  .blue-bar {
    padding-right: 1em;
    padding-left: 1em;
  }

  .r-videos {
    width: 100%;
  }

  .brand-assets {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 2em;
    grid-auto-flow: column;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .assets-item {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .brand-left {
    text-align: left;
  }

  .mk-header {
    text-align: center;
  }

  .download-button {
    margin-top: 0px;
  }

  .media-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }

  .assets-thumbnail.grey {
    height: auto;
    padding-top: 13px;
    padding-bottom: 13px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .brand-right {
    margin-left: 0px;
    text-align: left;
  }

  .media-item {
    margin-bottom: 1em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .media-thumbnail {
    width: 60%;
    height: auto;
    max-width: 200px;
  }

  .media-thumbnail.bg {
    height: auto;
    padding: 10px;
  }

  .brand-container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .media-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .assets-title {
    font-size: 1rem;
    line-height: 24px;
  }

  .r-header-wrapper {
    padding: 3em 2em;
  }

  .r-header {
    padding-bottom: 70%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .r-header-video {
    height: auto;
  }

  .r-header-video.z-index-2 {
    left: 5%;
    height: 90%;
  }

  .r-header-thumbnail {
    height: 100%;
  }

  .r-header-column-left {
    z-index: 50;
    padding-right: 4em;
    padding-left: 4em;
    text-align: center;
  }

  .r-header-column-right {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    margin-top: 3em;
    padding-left: 0em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .ow-tab-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    padding-left: 5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    background-color: #fff;
    box-shadow: 0 -1px 0.5px 0 rgba(0, 0, 0, 0.1);
  }

  .ow-category-graphic {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    height: 80%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .gradient-overlay-brown {
    display: none;
  }

  .helper-swipe {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .image-5 {
    height: 2em;
    max-width: none;
    min-width: auto;
  }

  .image-5.swipe-icon {
    height: 30px;
    padding-right: 0.25em;
  }

  .tabs-menu {
    margin-top: -5em;
  }

  .tabs-content {
    margin-top: 0px;
  }

  .p-package-item-bg {
    width: 80vw;
  }

  .r-modal {
    width: 90vw;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .p-package-price-wrapper {
    margin-bottom: 0px;
  }

  .icon-play-svg {
    font-size: 3rem;
  }

  .r-mobile-header-wrapper {
    display: none;
  }

  .list {
    display: none;
  }

  .mk-header-wrapper {
    padding: 3em 2em 2em;
  }

  .mk-brand-wrapper {
    padding: 2em 2em 3em;
  }

  .mk-bg {
    background-image: none;
  }

  .container-2 {
    width: 90vw;
  }

  .h-header-wrapper {
    padding-right: 2em;
    padding-left: 2em;
    /* background-image: url('../images/h-header-bg-alt.svg'); */
    background-position: 50% 100%;
    background-size: cover;
  }

  .h-header-content {
    padding: 3em 2em;
  }

  .btn-grey.header {
    position: static;
    display: none;
    margin-top: 20px;
  }

  .btn-grey.pub {
    position: static;
    display: none;
    margin-top: 20px;
  }

  .pub-card-con {
    margin-top: 48px;
    grid-column-gap: 12px;
  }

  .image {
    right: 0%;
    bottom: -40%;
  }

  .pub-card-title {
    width: auto;
  }

  .work-cards-base {
    overflow: auto;
  }

  .work-card-img-con {
    height: 33vw;
  }

  .work-card {
    width: 33vw;
  }

  .h-portfolio-wrapper {
    padding: 3em 2em;
  }

  .right-arrow-50x50 {
    width: 50px;
    height: 50px;
  }

  .left-arrow-50x50 {
    width: 50px;
    height: 50px;
  }

  .testimonial-slider {
    width: 90vw;
    height: auto;
    max-width: none;
  }

  .div-block-100 {
    margin-right: 3em;
  }

  .slider-wraper {
    padding-left: 3em;
  }

  .h-testimonial-small {
    padding-top: 0px;
    padding-bottom: 2em;
  }

  .hiw-arrow {
    margin-top: 235px;
  }

  .h-hiw-step-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .h-hiw-step-wrapper.alt {
    margin-top: 4em;
  }

  .hiw-arrow-wrapper {
    display: none;
  }

  .h-hiw-item {
    width: 100%;
    margin-bottom: 4em;
  }

  .h-hiw-item-description-block {
    padding-right: 0px;
  }

  .why-us-wrapper {
    margin-top: 3em;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .testi-img-person {
    overflow: hidden;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .test-img-el._2 {
    top: 55%;
    right: 10%;
  }

  .test-img-el._2.bulb {
    right: 10%;
  }

  .test-img-el._3 {
    top: 61%;
  }

  .test-img-el._1 {
    top: 20%;
    right: 25%;
  }

  .test-img-el._1.holmes {
    right: 10%;
  }

  .test-img-el._1.holmes.lance._2 {
    top: 30%;
    right: 30%;
  }

  .h-testimonial-column {
    display: block;
  }

  .h-testimonial-left {
    overflow: hidden;
  }

  .h-testimonial-right {
    height: 20em;
    padding: 3em 4em;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .h-testimonial-slide {
    height: 100%;
  }

  .faq-intercom {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .left-arrow-5 {
    left: 0%;
  }

  .right-arrow-5 {
    right: 0%;
  }

  .h-testimonial-slide-wrapper {
    height: auto;
  }

  .h-testimonial-img {
    display: inline-block;
    height: 25em;
  }

  .h-social-proof {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .h-spotlight-wrapper {
    padding: 1em 2em 3em;
    background-image: none;
  }

  .h-hiw-wrapper {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .h-benefits-wrapper {
    padding-right: 2em;
    padding-bottom: 4em;
    padding-left: 2em;
  }

  .h-reviews-wrapper {
    padding-right: 2em;
    padding-bottom: 0em;
    padding-left: 2em;
    background-image: none;
  }

  .h-reviews-content {
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .h-reviews {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .h-reviews-logo-list {
    width: 100%;
    margin-top: 2em;
  }

  .h-hiw-item-graphic {
    display: none;
    max-height: none;
    margin-right: 0px;
    margin-left: 0em;
  }

  .h-portfolio-heading {
    padding-right: 4em;
    padding-left: 4em;
  }

  .header-press-logo-list {
    margin-top: 1em;
    margin-bottom: 0em;
    margin-left: 0px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    justify-items: center;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
    -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    -ms-grid-columns: max-content max-content max-content max-content;
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content max-content;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .h-portfolio-btn {
    position: static;
    right: auto;
    display: inline-block;
    margin-top: 2em;
  }

  .h-portfolio-thumbnail {
    height: 110%;
    min-width: auto;
  }

  .h-portfolio-item {
    width: auto;
  }

  .h-spotlight-content {
    width: auto;
  }

  .h-hiw-item-graphic-alt {
    display: block;
    max-height: none;
    margin-right: 0px;
    margin-bottom: -8em;
    margin-left: 0em;
  }

  .h-hiw-item-graphic-alt.margin-disable {
    margin-bottom: 0em;
  }

  .h-social-proof-wrapper {
    margin-top: 0em;
    margin-bottom: 0em;
  }

  .how-p {
    padding-top: 5px;
  }

  .hiw-step-number {
    padding-right: 5px;
  }

  .hiw-process-list {
    padding-left: 0px;
  }

  .sos-header-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 25vh;
    padding-top: 0em;
    padding-bottom: 0em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sos-list-item-text {
    margin-bottom: 0px;
  }

  .service-inner-wrapper {
    margin-left: 0px;
  }

  .sos-search-wrapper {
    width: 60%;
    margin-right: 0px;
  }

  .search-bar.search-icon.tablet {
    width: auto;
    margin-right: 2em;
    margin-left: 2em;
  }

  .sos-sidebar {
    width: 35%;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .wd-header-content {
    width: auto;
    margin-bottom: 3em;
  }

  .hiw-header-bg {
    z-index: 1;
    padding-bottom: 4em;
    /* background-image: url('../images/hiw-header-bg-alt.svg'); */
    background-position: 50% 100%;
  }

  .hiw-header-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    height: 22em;
    padding-top: 4em;
    padding-right: 2em;
    padding-left: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hiw-process-wrapper {
    display: none;
  }

  .hiw-steps-wrapper {
    display: none;
  }

  .hiw-step-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hiw-step-info {
    max-width: none;
    margin-bottom: 2em;
  }

  .hiw-step-info.step-2 {
    margin-left: 0em;
  }

  .hiw-step-graphic {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .hiw-step-graphic.alt {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }

  .hiw-step-heading {
    position: relative;
  }

  .hiw-connector {
    display: none;
  }

  .hiw-features-wrapper {
    z-index: 3;
    padding: 4em 2em;
    /* background-image: url('../images/hiw-features-bg-right.svg'); */
    background-position: 100% -50%;
    background-size: auto;
    background-repeat: no-repeat;
  }

  .hiw-features {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hiw-features-heading {
    margin-bottom: 3em;
  }

  .everything-outline.margin-disable.tablet {
    height: 3.5rem;
    margin-top: 0em;
  }

  .hiw-features-content.alt {
    max-width: none;
    margin-right: 0em;
    margin-bottom: 3em;
    text-align: center;
  }

  .button-filled-blue.margin-top-huge.hidden {
    display: none;
  }

  .button-filled-blue.margin-top-huge.hidden.alt {
    display: inline-block;
    margin-top: 0em;
  }

  .hiw-feature-icon-wrapper {
    background-color: transparent;
  }

  .hiw-feature-icon {
    width: 2.5em;
    height: 2.5em;
    max-width: none;
  }

  .hiw-reviews-wrapper {
    padding-top: 4em;
    padding-bottom: 2em;
    background-image: none;
  }

  .hiw-reviews-content {
    padding-right: 4em;
    padding-bottom: 2em;
    padding-left: 4em;
  }

  .hiw-bottom-wrapper {
    margin-top: -4em;
  }

  .hiw-header-graphic {
    display: none;
  }

  .wd-header {
    margin-top: 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .wd-header-bg {
    height: auto;
    background-position: 50% 100%;
    background-size: cover;
  }

  .wd-header-wrapper {
    height: auto;
    padding: 3em 2em;
  }

  .image-81 {
    display: none;
  }

  .sos-header-content {
    margin-top: -3em;
    text-align: center;
  }

  .sos-description {
    margin-bottom: 0px;
  }

  .sos-content-wrapper {
    padding-right: 2em;
    padding-left: 2em;
  }

  .nav-dropdown {
    display: none;
    padding-top: 1em;
    padding-bottom: 1em;
    border-radius: 0px;
    background-color: #fff;
    font-size: 1.25rem;
  }

  .nav-dropdown:hover {
    background-color: #e5dbff;
  }

  .nav-dropdown:active {
    background-color: #ea552b;
    color: #f1f3f5;
  }

  .nav-list-item-desktop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0.5em 4em 0.5em 1.5em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .div-block-122 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .div-block-123 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .div-block-124 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .lightbox-link {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90vw;
    height: 60%;
    padding-left: 3em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hiw-steps-wrapper-alt {
    position: relative;
    z-index: 2;
    display: block;
    margin-top: -2em;
    padding: 4em 4em 0em;
    /* background-image: url('../images/hiw-steps-bg-alt.svg'); */
    background-position: 0px 0px;
    background-size: contain;
    text-align: center;
  }

  .hiw-step-wrapper-alt {
    margin-bottom: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hiw-step-number-graphic {
    margin-bottom: -1em;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .hiw-header-bg-alt {
    position: relative;
    top: 4vw;
    display: block;
    height: 60vw;
  }

  .ps-header-wrapper {
    padding: 3em 2em;
  }

  .ps-testimonials-wrapper {
    padding: 3em 2em;
  }

  .ps-testimonials {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ps-benefits-wrapper {
    padding: 0em 2em 3em;
  }

  .ps-benefits {
    padding: 3em 2em;
  }

  .ps-partners-wrapper {
    padding: 3em 2em 6em;
  }

  .ps-testimonial-thumbnail {
    width: 12em;
    height: 12em;
    margin-right: 2em;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .ps-testimonial-item {
    width: 100%;
    margin-bottom: 3em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ps-testimonials-content {
    margin-top: 0em;
  }

  .ps-testimonials-client-info {
    margin-top: 1em;
  }

  .ps-benefits-item {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .ps-benefits-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ps-benefits-graphic {
    min-width: 12em;
    margin-right: 1em;
  }

  .ps-partners-list {
    grid-row-gap: 3em;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }

  .ps-benefits-info {
    text-align: left;
  }

  .prs-header-wrapper {
    background-image: none;
  }

  .prs-item {
    grid-column-gap: 1em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .section {
    padding: 3em 2em;
  }

  .prs-header-content {
    margin-bottom: 3em;
  }

  .prs-suggested-wrapper {
    padding-bottom: 3em;
  }

  .prs-suggested {
    padding-right: 2em;
    padding-left: 2em;
  }

  .a-header-wrapper {
    background-position: 100% 100%;
  }

  .aff-text-wrapper {
    margin-top: 0px;
    padding-right: 0px;
  }

  .aff-hero_left {
    width: 100%;
    text-align: center;
  }

  .a-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-base {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .a-header-graphic {
    position: relative;
    right: 30%;
    max-width: none;
    margin-top: 3em;
  }

  .pub-block {
    display: -ms-grid;
    display: grid;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .pub-block.mbl-hde.mb032 {
    overflow: auto;
    width: 100vw;
    margin-left: -5vw;
    padding-right: 5vw;
    padding-left: 50vw;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    -ms-grid-columns: max-content max-content max-content max-content
      max-content max-content max-content;
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content max-content max-content max-content max-content;
  }

  .pub-block.mbl-show {
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .a-features-wrapper {
    padding: 3em 2em;
  }

  .a-hiw-wrapper {
    padding-top: 4em;
    padding-bottom: 4em;
    /* background-image: url('../images/hiw-features-bg-right.svg'), -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), to(#f1f3f5)); */
    /* background-image: url('../images/hiw-features-bg-right.svg'), linear-gradient(180deg, hsla(0, 0%, 100%, 0), #f1f3f5); */
    background-position: 100% 100%, 0px 0px;
    background-size: auto, auto;
    background-repeat: no-repeat, repeat;
  }

  .container-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .priv-content {
    padding-right: 0em;
    padding-left: 0em;
  }

  .priv-content-wrapper {
    padding: 2em;
  }

  .h1-large-2 {
    font-size: 4rem;
  }

  .nav-link-extra {
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1.5em;
    font-family: "Inter", sans-serif;
    color: #353a40;
    font-size: 1rem;
    line-height: 1em;
    font-weight: 500;
    text-align: left;
  }

  .nav-list-extra {
    display: block;
    margin-top: 1em;
  }

  .service-grid.margin-top-40 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .review-card.yellow.purple {
    top: 0em;
  }

  .review-card.yellow.green {
    top: 0px;
  }

  .review-card.yellow.blue {
    padding-right: 2em;
    padding-left: 2em;
  }

  .review-card.yellow.gray {
    top: 0px;
  }

  .grid-6 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .chart-desktop {
    display: none;
  }

  .chart-absolute-wrap {
    display: none;
  }

  .community-content {
    margin-right: 0em;
  }

  .community-content.center {
    text-align: center;
  }

  .carousel-placeholder {
    top: -30px;
  }

  .absoulte-container {
    position: static;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .chart-mobile {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    display: block;
    width: 100%;
  }

  .chart {
    padding: 30px 3% 50px;
    background-size: 100vw 100%;
  }

  .chart.response {
    padding: 4em 3em 24em;
    background-color: #31a8fe;
  }

  .chart.response.hidden {
    display: none;
  }

  .chart.response {
    padding-top: 80px;
    padding-bottom: 500px;
    background-color: #31a8fe;
  }

  .box__for__cursor {
    height: 20px;
  }

  .community-grid {
    width: 100%;
    margin-top: 2em;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
    -ms-grid-rows: max-content max-content max-content;
    grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-rows: max-content max-content max-content;
  }

  .paragraph.big.grey.define {
    max-width: none;
  }

  .item {
    width: 80vw;
  }

  .secondry-heading {
    font-size: 3rem;
  }

  .secondry-heading.align-left.white.margin-24.center {
    text-align: center;
  }

  .our--journey.hide-mbl {
    width: auto;
  }

  .our--journey.hide-mbl {
    width: auto;
  }

  .values-grid {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .carousel-wrapper {
    border-top-right-radius: 0px;
  }

  .carousel-image {
    width: 100%;
    height: auto;
    max-width: none;
    min-height: auto;
  }

  .sub-content-wrapper {
    margin-top: 32px;
  }

  .box__hidden {
    margin-bottom: 10px;
    padding: 12px 10px;
  }

  .section-4 {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section-5 {
    padding-top: 3em;
  }

  .section-6 {
    padding-right: 4em;
    padding-bottom: 3em;
    padding-left: 4em;
  }

  .section-7 {
    margin-bottom: 4em;
    padding-right: 3em;
    padding-left: 4em;
  }

  .section-7.hidden {
    display: none;
  }

  .section-8 {
    padding: 3em 4em 4em;
  }

  .div-block-133 {
    width: auto;
    padding-right: 8em;
    padding-left: 8em;
  }

  .image-84 {
    bottom: 5%;
    width: 30vw;
    max-width: none;
  }

  .image-85 {
    bottom: 5%;
    width: 25vw;
    max-width: none;
  }

  .ow-h2-heading {
    position: relative;
  }

  .div-block-135.align-object-left {
    display: block;
    margin-bottom: 1em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .image-87 {
    max-width: 200%;
  }

  .mask-3 {
    width: 90vw;
  }

  .slider-3 {
    left: 0%;
    width: 100vw;
    padding-left: 0%;
  }

  .div-block-138 {
    padding: 3em;
    /* background-image: url('../images/Rectangle-1579.png'); */
  }

  .slide-4 {
    margin-left: 5vw;
    padding-right: 0em;
  }

  .outline {
    display: inline-block;
    height: 3.5rem;
    margin: -1em 0.5em 0em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .div-block-141 {
    margin-bottom: 1em;
  }

  .co-grid-left.padding-left {
    padding-right: 0px;
  }

  .co-grid-left.padding-left.modal {
    width: 90%;
  }

  .color--gray-700.text-xl {
    width: auto;
  }

  .ch-top {
    margin-left: auto;
  }

  .small-title-wrapper.m-0.co-hide {
    display: none;
  }

  .co-content-wrapper {
    position: relative;
  }

  .co-form-wrapper {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .co-form-wrapper._100 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .co-form-wrapper.margin-negative {
    margin-top: -2rem;
  }

  .co-grid-wrapper {
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .sn-row.color--gray-700 {
    position: relative;
    z-index: 10;
    margin-top: 1em;
  }

  .co-sidebar {
    /* display: none; */
    width: 100%;
  }

  .co-sidebar.mobile {
    display: block;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-style: none;
    border-radius: 0em;
  }

  .co-grid-right {
    position: static;
  }

  .checkout-title-wrapper {
    margin-top: 0em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 3rem;
  }

  .color--gray-900 {
    text-align: center;
  }

  .small-title {
    margin-top: 2em;
  }

  .small-title.alternate {
    margin-top: 0em;
  }

  .small-title.alt {
    margin-top: 0em;
  }

  .ch-label-desc.padding-right-large {
    padding-right: 0%;
  }

  .ca-form-block {
    padding-right: 0px;
    padding-left: 0px;
  }

  .ca-form-block.p-0 {
    width: 100%;
    max-width: none;
    text-align: left;
  }

  .ca-form-validation {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .form-label-2.color--gray-900.align-left {
    text-align: left;
  }

  .image-98 {
    position: absolute;
    left: 25%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 68%;
  }

  .fi-heading {
    font-size: 4rem;
  }

  .div-block-149 {
    margin-right: 2em;
    margin-left: 2em;
  }

  .finish-new {
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }

  .nav-checkout-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 300;
    height: 5em;
    padding: 1em;
  }

  .co-nav-sign-in {
    display: none;
  }

  .co-nav-wrapper {
    position: -webkit-sticky;
    position: sticky;
    padding-top: 0em;
    padding-bottom: 0em;
    /* border-bottom: 1px solid #e9ecef; */
    /* background-color: #fff; */
  }

  .co-item {
    width: 100%;
    max-width: 600px;
  }

  .co-top-wrapper {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .co-nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .co-form {
    width: 100%;
  }

  /* .co---guarantee {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0em;
    padding: 0em;
    border-color: transparent;
    background-color: transparent;
  } */

  .co-sidebar-wrapper {
    position: static;
  }

  .co-page-wrapper.payment {
    display: none;
  }

  .co-billing-price {
    white-space: normal;
  }

  .co-billing-price {
    white-space: normal;
  }

  .hc-topic-wrapper {
    width: 100%;
    height: 100%;
  }

  .text-field-2.search.destop {
    display: none;
  }

  .text-field-2.search.tablet {
    display: block;
  }

  .need-help-new {
    padding-right: 2em;
    padding-left: 2em;
  }

  .div-block-43 {
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .div-block-78-copy {
    grid-column-gap: 20px;
    grid-row-gap: 60px;
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
  }

  .image-100 {
    margin-right: 0em;
    margin-bottom: 1em;
  }

  .hc-cta {
    display: block;
    text-align: center;
  }

  .hc-item-toggle {
    white-space: pre-wrap;
  }

  .hc-cta-button-wrapper {
    margin-top: 1em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .video-by-wrapper.margin-top-56 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }

  .dropdown-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .secondry-heading-2 {
    font-size: 42px;
    line-height: 48px;
  }

  .secondry-heading-2.align-left.hide-it {
    display: none;
  }

  .how-to-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .video-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .text--block.custom-width {
    width: auto;
    font-size: 24px;
    line-height: 32px;
  }

  .slider-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    font-size: 13px;
  }

  .comma-span {
    display: inline;
  }

  .map-wrapper {
    z-index: -1;
  }

  .box__for__cursor-2 {
    height: 20px;
  }

  .slider-img {
    width: auto;
  }

  .map-img {
    top: 10px;
  }

  .leaders-grid.margin {
    grid-column-gap: 20px;
    grid-row-gap: 100px;
  }

  .leaders-grid.margin {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .leaders-wrapper {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .section-14 {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section-15 {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section-16 {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .hc-links-wrapper {
    padding-right: 0em;
  }

  .column-4 {
    margin-bottom: 2em;
  }

  .section-17 {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .hc-article-wrapper {
    padding-bottom: 2em;
  }

  .lottie-animation {
    width: auto;
    height: 100vh;
  }

  .co-cart-tablet {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .co-cart-button {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    color: #ea552b;
    font-size: 2.5rem;
    cursor: pointer;
  }

  .text-block-44 {
    position: absolute;
    left: auto;
    top: auto;
    right: 5%;
    bottom: -10%;
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1em;
    height: 1em;
    padding: 0.75em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    border-style: solid;
    border-width: 2px;
    border-color: #ea552b;
    border-radius: 4em;
    background-color: #fff;
    color: #ea552b;
    font-size: 0.75rem;
    font-weight: 700;
  }

  .co-button-text {
    color: #ea552b;
    font-size: 1.125rem;
  }

  .co-schedule-mobile {
    position: relative;
    z-index: 5;
    display: none;
    margin: -3em -2em 1.5em;
    padding-top: 4em;
    padding-right: 2em;
    padding-left: 2em;
    border-top: 1px solid #cfd4da;
    border-bottom: 1px solid #cfd4da;
    background-color: #f1f3f5;
  }

  .feature-item-heading-3.margin-16.align-left {
    text-align: left;
  }

  .image-105 {
    display: block;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .quotes-2 {
    margin-bottom: 0px;
    font-size: 50px;
  }

  .heading-wrapper-2 {
    text-align: center;
  }

  .h1-small-2 {
    display: inline;
  }

  .h1-small-2.margin-bottom-large {
    margin-bottom: 1em;
  }

  .container-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hiw-feature-icon-wrapper-2 {
    background-color: transparent;
  }

  .c-header-graphic-left {
    display: none;
  }

  .section-18 {
    padding: 4em 2em 3em;
  }

  .section-18.c-header {
    padding-top: 4em;
    padding-bottom: 8em;
  }

  .section-18.c-benefits {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section-18.c-testimonials {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .section-18.c-work {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .section-18.c-leaders {
    padding-right: 2em;
    padding-left: 2em;
    background-position: 100% 5%;
  }

  .section-18.pg-members {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .section-18.pg-benefits {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .section-18.pg-header {
    padding-bottom: 6em;
  }

  .section-18.pg-header.pg-main {
    padding-bottom: 3em;
  }

  .blog-content-2 {
    margin-top: 0px;
    text-align: left;
  }

  .quote-text-2.margin-20 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .h1-outline-small {
    height: 3.5rem;
  }

  .div-block-168 {
    text-align: center;
  }

  .hiw-features-list-2 {
    -ms-grid-columns: 0.5fr 0.5fr;
    grid-template-columns: 0.5fr 0.5fr;
  }

  .paragraph-default-3 {
    margin-top: 1em;
  }

  .blog-wrapper-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .testimonial-name-2 {
    width: 120px;
  }

  .twinkle-img-2 {
    top: 0em;
    right: 0em;
  }

  .blog-image-2.d {
    width: 40%;
    margin-right: 2em;
  }

  .job-wrapper-2 {
    margin-top: 2em;
  }

  .quote-content-2 {
    margin-top: 60px;
  }

  .key-feature-grid-2 {
    padding-right: 4em;
    padding-left: 4em;
    grid-row-gap: 4em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .divider-3.margin-30 {
    width: 100%;
  }

  .quote-smaller-text-2 {
    font-size: 14px;
  }

  .c-header-graphic-right {
    display: none;
  }

  .overlay-text-2 {
    margin: 1px;
    padding: 10px;
  }

  .blog-grid-2 {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .overview-wrapper-2 {
    margin-bottom: 2em;
  }

  .overview-wrapper-2.margin {
    margin-bottom: 2em;
  }

  .job-details-wrapper-2 {
    width: 100%;
    margin-bottom: 3em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .job-details-2 {
    width: auto;
  }

  .div-block-171 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .sbout-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .video-conetent-2 {
    width: auto;
    margin-left: 0em;
    text-align: center;
  }

  .how-to-left-2 {
    width: auto;
  }

  .video-block-2 {
    width: auto;
    margin-top: 40px;
  }

  .how-to-right-2 {
    width: auto;
    margin-top: 40px;
  }

  .about-img-2 {
    width: 100%;
  }

  .about-content-2 {
    width: auto;
    margin-left: 0em;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .programs-features-2 {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .members-grid-2 {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .benefits-heading-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .pg-main-content-wrapper {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .pg-main-item-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .program-image-2 {
    width: 100%;
    height: 12em;
    margin-right: 0em;
    margin-bottom: 2em;
  }

  .co-modal-count {
    display: none;
  }

  .co-plans-mobile {
    position: relative;
    z-index: 5;
    display: none;
    margin: -4.5em -2em 1.5em;
    padding-top: 4em;
    padding-right: 2em;
    padding-left: 2em;
    border-top: 1px solid #cfd4da;
    border-bottom: 1px solid #cfd4da;
    background-color: #f1f3f5;
  }

  .co-count-mobile {
    position: relative;
    z-index: 5;
    display: none;
    margin: -2.5em -2em 1.5em;
    padding-top: 3em;
    padding-right: 2em;
    padding-left: 2em;
    border-top: 1px solid #cfd4da;
    border-bottom: 1px solid #cfd4da;
    background-color: #f1f3f5;
  }

  .image-89 {
    left: 5%;
    top: 35%;
    right: auto;
    bottom: auto;
    display: none;
    width: 200px;
    height: 200px;
  }

  .padding-left-right-2 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .mt-100.mob-40 {
    margin-top: 40px;
  }

  .div-block-172 {
    display: none;
  }

  .text-19px {
    font-size: 17px;
    line-height: 26px;
  }

  .blog-single_header-wrapper-2 {
    margin-right: auto;
    margin-left: auto;
  }

  .feature-blog1-2 {
    width: 100%;
  }

  .grey-block {
    padding-right: 10px;
    padding-left: 10px;
    /* background-image: url('../images/holiday-image.png'); */
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .blog-title {
    font-size: 36px;
  }

  .single-blog_grid-2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .feature-blog1_content-2 {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .grid-31 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 132px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .blog-image.mt-40 {
    margin-top: 20px;
  }

  .post-tags-2 {
    padding-right: 20px;
    padding-left: 20px;
    line-height: 34px;
  }

  .feature-blog2-2 {
    position: static;
    width: 100%;
  }

  .single-blog_featue-blog-2 {
    display: none;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .heading-normal-2 {
    font-size: 28px;
    line-height: 32px;
  }

  .div-block-125 {
    width: 100%;
    padding-bottom: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-41 {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .text-20px {
    font-size: 17px;
    line-height: 26px;
  }

  .cs-icon---play {
    font-size: 3rem;
  }

  .lc-search-wrapper {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .lc-all-wrappers {
    padding: 3em 2em;
  }

  .section-19 {
    padding-top: 3rem;
    padding-bottom: 0rem;
  }

  .html-embed-6 {
    color: #2c2c2c;
  }

  .div-block-175 {
    display: none;
  }

  .blog-toc-item {
    width: 100%;
    max-width: 600px;
  }

  .div-block-176 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .tabfeatured {
    width: 80vw;
  }

  .tabanimation {
    width: 80vw;
  }

  .tabbranding {
    width: 80vw;
  }

  .tabdigital {
    width: 80vw;
  }

  .tabprints {
    width: 80vw;
  }

  .tabilustrations {
    width: 80vw;
  }

  .tabpresentations {
    width: 80vw;
  }

  .tabwebapp {
    width: 80vw;
  }

  .buttonbranding {
    height: 12em;
    min-height: 12em;
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0.3em;
    background-image: none;
  }

  .bg-white {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin-top: 20%;
    background-color: #fff;
  }

  .co-heading-main {
    display: none;
    text-align: center;
  }

  .co-heading-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .co-heading-link.link {
    cursor: pointer;
  }

  .co-item-frequency {
    width: 100%;
    max-width: 600px;
  }

  .om-programs-wrapper-tablet {
    display: block;
  }

  .support-mobile {
    padding: 3em;
    border-radius: 0em;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(20%, #321f12),
      color-stop(30%, #321f12),
      to(#1c123b)
    );
    background-image: linear-gradient(
      180deg,
      #321f12 20%,
      #321f12 30%,
      #1c123b
    );
  }

  .ow-os-wrapper {
    margin-top: 2em;
  }

  .image-110 {
    min-width: 100vw;
    margin-left: -3em;
  }

  .nav-logo-2 {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .paragraph-default-4 {
    text-align: center;
  }

  .link-close-cart {
    display: block;
    margin-top: 3em;
    font-size: 1rem;
    text-align: center;
  }

  .om-heading {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .image-111 {
    margin-right: 5%;
  }

  .image-112 {
    display: none;
  }

  .tos-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .co-modal-pp {
    display: none;
  }

  .pp-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .co-checklist-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .co-checklist-list {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .co-checklist-list.tablet {
    margin-top: -0.5em;
  }

  .co-item-wrapper-static {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .co-item-gradient {
    width: 80vw;
  }

  .co-white {
    width: 100%;
    max-width: 600px;
  }
}

@media screen and (max-width: 767px) {
  .nav-links {
    min-width: auto;
    margin-left: 20%;
  }

  .h1 {
    line-height: 1.1em;
  }

  .button-large.padding-1em.hide-desktop {
    display: none;
  }

  .button-large.padding-1em.mobile {
    position: relative;
  }

  .button-text-large {
    text-align: center;
  }

  .paragraph-default.max-ch-2 {
    width: auto;
  }

  .paragraph-default.max-ch-2.em-mobile {
    margin-bottom: 1em;
  }

  .cs-story-info {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .cs-story-img-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cs-story-img-column-right {
    padding-top: 2em;
  }

  .cs-story-graphic {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .divider.lc-featured-item-divider {
    display: none;
  }

  .divider.large {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .cs-story-stats {
    grid-auto-columns: auto;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .h2 {
    font-size: 2rem;
  }

  .section-footer {
    padding-top: 2em;
    background-position: 0% 100%;
    background-size: auto;
  }

  .footer-cta-column {
    padding: 2em 3em;
  }

  .footer-cta-column-right {
    height: auto;
  }

  .paragraph-default-alt.wp-locked {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .paragraph-default-alt.p-money-back-paragraph {
    margin-right: 0em;
  }

  .footer-cta-graphic {
    left: auto;
    top: auto;
    right: 0%;
    bottom: 0%;
  }

  .cta-button-primary {
    width: 100%;
  }

  .cta-button-primary.alt {
    display: block;
  }

  .footer-cta-buttongroup {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .cta-button-secondary {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .footer-press-logo-list {
    padding-top: 2em;
  }

  .footer-press-logo.pos-top.mobile-hidden {
    display: none;
  }

  .footer-column {
    margin-left: 0px;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .footer-menu-list {
    text-align: center;
  }

  .footer-graphic-philly {
    display: none;
  }

  .footer-logo {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .footer-text-copyright {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    text-align: center;
  }

  .section-wp-header {
    overflow: hidden;
    padding-bottom: 3em;
  }

  .h1-large.text-color-dark-purple {
    font-size: 3rem;
  }

  .wp-header {
    margin-right: 2em;
    margin-left: 2em;
    padding-right: 0px;
    padding-left: 0px;
  }

  .wp-testimonial-card-blue {
    width: 100%;
    height: 560px;
    max-height: 560px;
    min-height: auto;
    padding-top: 2em;
    padding-right: 3em;
    padding-left: 3em;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: 5% minmax(auto, 320px) auto;
    grid-template-rows: 5% minmax(auto, 320px) auto;
    background-position: 110% 110%, 50% -60%;
    background-size: auto 50%, auto;
  }

  .wp-testimonial-container {
    margin-top: 1.5em;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .wp-testimonial-client-info {
    margin-top: 1em;
  }

  .wp-testimonial-designer-name-blue {
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    text-align: left;
  }

  .wp-testimonial-card-purple {
    width: 100%;
    height: 560px;
    max-height: none;
    min-height: auto;
    min-width: 100%;
    padding-top: 2em;
    padding-right: 3em;
    padding-left: 3em;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: 5% minmax(auto, 320px) auto;
    grid-template-rows: 5% minmax(auto, 320px) auto;
    background-position: 110% 110%, 50% -60%;
    background-size: auto 50%, auto;
  }

  .wp-testimonial-designer-name-purple {
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    text-align: left;
  }

  .wp-testimonial-card-orange {
    width: 100%;
    height: 560px;
    max-height: 560px;
    min-height: auto;
    min-width: 100%;
    padding-top: 2em;
    padding-right: 3em;
    padding-left: 3em;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: 5% minmax(auto, 320px) auto;
    grid-template-rows: 5% minmax(auto, 320px) auto;
    background-position: 110% 110%, 50% -60%;
    background-size: auto 50%, auto;
  }

  .wp-testimonial-designer-name-orange {
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    text-align: left;
  }

  .wp-testimonial-designer-name-green {
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    text-align: left;
  }

  .wp-testimonial-card-green {
    width: 100%;
    height: 560px;
    max-height: 560px;
    min-height: auto;
    min-width: 100%;
    padding-top: 2em;
    padding-right: 3em;
    padding-left: 3em;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: 5% minmax(auto, 320px) auto;
    grid-template-rows: 5% minmax(auto, 320px) auto;
    background-position: 110% 110%, 50% -60%;
    background-size: auto 50%, auto;
  }

  .wp-testimonial-card-yellow {
    width: 100%;
    height: 560px;
    max-height: 560px;
    min-height: auto;
    min-width: 100%;
    padding-top: 2em;
    padding-right: 3em;
    padding-left: 3em;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: 5% minmax(auto, 320px) auto;
    grid-template-rows: 5% minmax(auto, 320px) auto;
    background-position: 120% 120%, 50% -60%;
    background-size: auto 50%, auto;
  }

  .wp-testimonial-designer-name-yellow {
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    text-align: left;
  }

  .wp-testimonial-paragraph-yellow {
    padding-right: 0px;
  }

  .section-wp-services.mobile {
    display: none;
  }

  .wp-services {
    padding: 3em 2em;
  }

  .wp-services-column-right {
    padding-right: 2em;
    padding-left: 2em;
    white-space: pre-wrap;
  }

  .wp-services-list-item {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .icon-chevron-left-flipped {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .section-wp-assign {
    padding-top: 3em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .h1-small-alt.inline {
    margin-right: 0.25em;
  }

  .wp-assign-content {
    padding-right: 0em;
    padding-left: 0em;
  }

  .wp-assign-graphic {
    width: 100vw;
    max-width: 100vw;
    min-width: 1348px;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .wp-swap-wrapper {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .div-block {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .swap-designers {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .column {
    padding: 0px 2em;
  }

  .section-wp-dashboard {
    padding: 3em 2em;
  }

  .h1-small.alt {
    margin-bottom: 0em;
    text-align: center;
  }

  .h1-small.margin-bottom-large {
    margin-bottom: 1em;
  }

  .h1-small.max-ch {
    width: auto;
  }

  .section-footer-mobile {
    display: none;
    background-position: 100% 100%;
    background-size: auto;
  }

  .social {
    margin-top: 1em;
    padding-left: 1em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .social.blog {
    display: none;
  }

  .nav-button-link {
    display: none;
  }

  .section-wp-outsource {
    margin-right: -1%;
    padding-top: 3em;
    padding-bottom: 3em;
    padding-left: 2em;
  }

  .wp-outsource-content {
    padding-right: 2em;
  }

  .wp-outsource-benefits-item {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .column-2 {
    padding-right: 2em;
    padding-left: 2em;
  }

  .slider-wrapper {
    padding: 0px;
  }

  .slider-navigation-wrapper {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .slider-navigation-wrapper.spotlight {
    margin-top: 0em;
  }

  .slider-icon.left {
    left: 3%;
  }

  .slider-icon.right {
    right: 3%;
  }

  .slider-list {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .slider-list.h-portfolio {
    margin-left: -5%;
  }

  .slider-list.reviews {
    margin-left: 5%;
  }

  .slider-item {
    width: 90%;
    max-height: none;
    max-width: none;
    min-height: auto;
    min-width: 90%;
  }

  .slider-item.p-reviews-card {
    width: 40%;
    min-height: 360px;
    min-width: 360px;
  }

  .slider-item.h-item {
    left: 26px;
    overflow: visible;
    width: 60%;
    min-width: 360px;
  }

  .p-packages {
    margin-top: 2em;
  }

  .p-packages-item {
    max-width: 80vw;
  }

  .p-logo-list-container {
    width: 90%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .section-p-benefits {
    padding-top: 3em;
    padding-bottom: 3em;
    background-size: contain;
  }

  .p-benefits-heading-outline {
    height: 3.5rem;
  }

  .benefits-list {
    margin-top: 3em;
    grid-row-gap: 3em;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .p-cta {
    height: 50vh;
    padding: 2em;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-position: 10% 150%;
    background-size: auto 60vh;
  }

  .p-cta-content-main {
    width: 100%;
    text-align: center;
  }

  .cta-paragraph {
    margin-bottom: 2em;
  }

  .button-text-small {
    text-align: center;
  }

  .section-p-reviews {
    height: auto;
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .p-reviews-card {
    width: 80%;
  }

  .p-reviews-content {
    padding-right: 2em;
    padding-left: 2em;
  }

  .faq {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .faq-graphic {
    max-height: 300px;
    margin-top: -10px;
    padding-top: 0px;
  }

  .wp-designer-name {
    margin-top: -1px;
    margin-bottom: -30px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .wp-assign-graphic-container {
    height: 30%;
  }

  .cta-heading-2.max-ch {
    font-size: 2.6rem;
  }

  .p-cta-content {
    width: 100%;
  }

  .p-build-plan-button-text {
    text-align: center;
  }

  .p-money-back {
    height: auto;
    min-height: auto;
    margin-top: 3em;
    margin-bottom: 3em;
    padding-right: 3em;
    padding-left: 3em;
    background-size: contain;
    background-repeat: repeat-y;
  }

  .pricing-money-back-icon {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0.5em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .pricing-money-back-heading {
    width: auto;
  }

  .p-money-back-content-main {
    margin-bottom: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .pricing-money-back-graphic {
    width: 60vw;
    height: auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .cs-story-graphic-container {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .ow-header-content {
    padding-right: 0px;
    padding-left: 0px;
  }

  .ow-section-header {
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }

  .ow-section-header.ow-section-header-padding-horizontal {
    padding-right: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
  }

  .ow-category-list {
    overflow: auto;
    width: 100vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-grid-columns: minmax(10em, 30%) minmax(10em, 30%) minmax(10em, 30%)
      minmax(10em, 30%) minmax(10em, 30%) minmax(10em, 30%);
    grid-template-columns:
      minmax(10em, 30%) minmax(10em, 30%) minmax(10em, 30%) minmax(10em, 30%)
      minmax(10em, 30%) minmax(10em, 30%);
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .ow-category-item.infographics {
    height: 12em;
    min-height: 0em;
  }

  .ow-tab-content {
    overflow: hidden;
  }

  .h4.lc-featured-article-mobile {
    font-size: 1.25rem;
    line-height: 1.2em;
  }

  .h4.text-color-purple-600 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .ow-header-sparkle {
    margin-right: -3%;
  }

  .ow-tab-item {
    max-width: 80vw;
  }

  .ow-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .ow-modal-full {
    width: 100vw;
    border-top-left-radius: 0em;
    border-top-right-radius: 0em;
  }

  .lc-featured-info-wrapper {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .lc-author-name {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .lc-featured-thumbnail-wrapper {
    height: 320px;
  }

  .lc-featured-list {
    overflow: auto;
    width: 100vw;
    height: 45vh;
    max-height: 400px;
    padding-left: 2em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .lc-featured-column-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .lc-featured-thumbnail {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .lc-featured-item-vertical {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 100%;
    min-width: 320px;
    margin-right: 2em;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lc-featured-item-horizontal {
    width: 30%;
    min-width: 320px;
    margin-right: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lc-featured-info-wrapper-alt {
    margin-top: 1em;
    margin-left: 0em;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .lc-featured-info.margin-right-default {
    margin-right: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .lc-featured-thumbnail-wrapper-alt {
    width: 100%;
    height: 320px;
    max-height: none;
    min-height: auto;
    min-width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .lc-recent-list {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .lc-recent-thumbnail-wrapper {
    height: auto;
    max-height: 240px;
    min-height: auto;
  }

  .button-text-filled-gray.lc-all {
    margin-top: 1.5em;
    padding: 0.5em 1em;
  }

  .lc-all-thumbnail {
    width: 38%;
    max-width: 144px;
    min-height: 144px;
    margin-right: 1.5em;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .lc-all-item-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .lc-all-item {
    margin-bottom: 2em;
    padding: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .review-tab-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    width: 100%;
    max-width: 500px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .link-text-review {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .blue-right {
    display: block;
  }

  .review-logos-list {
    justify-items: center;
    grid-column-gap: 1em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .tab-link-review {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 150px;
    min-width: 125px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
  }

  .r-header-rating {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .right-review {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .blue-bar {
    height: 250px;
  }

  .video-details {
    width: 45%;
  }

  .star-title {
    text-align: center;
  }

  .media-details {
    margin-top: 1.5em;
    margin-left: 0em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .assets-thumbnail {
    width: 100%;
    height: auto;
    max-width: 110px;
    margin-right: 0.6rem;
    padding-right: 10px;
  }

  .media-item {
    margin-bottom: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .media-thumbnail {
    width: 100%;
    max-width: none;
  }

  .brand-container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .media-list {
    margin-top: 2em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .r-header-wrapper {
    padding-top: 2em;
    padding-bottom: 4em;
  }

  .r-header-thumbnail {
    max-width: 80vw;
  }

  .r-header-column-left {
    margin-bottom: 1em;
    padding-right: 0em;
    padding-left: 0em;
  }

  .r-header-column-right {
    top: 10%;
  }

  .ow-category-graphic {
    width: 100%;
    height: 80%;
  }

  .ow-category-graphic.gif {
    height: 100%;
  }

  .r-modal {
    width: 100vw;
    margin-left: -1%;
    border-radius: 0px;
  }

  .r-mobile-header-wrapper {
    display: none;
  }

  .list {
    display: none;
  }

  .mk-header-wrapper {
    padding-bottom: 0em;
  }

  .h-header-graphic {
    display: none;
  }

  .image-78 {
    margin-top: 0.5em;
    margin-bottom: 8px;
  }

  .pub-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 20em;
    padding: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* background-image: url('../images/h-card-1-bg.png'); */
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
  }

  .pub-card.blue {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    /* background-image: url('../images/h-card-2-bg.png'); */
    background-position: 50% 100%;
    background-size: cover;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  .btn-grey.pub {
    position: absolute;
    right: auto;
    bottom: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 0.75em 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0.3em;
  }

  .pub-card-con.destop {
    display: none;
  }

  .pub-card-con.mbl {
    display: -ms-grid;
    display: grid;
  }

  .div-block-113 {
    position: absolute;
    top: auto;
    bottom: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0.75em 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0.3em;
    background-color: #fff;
    color: #ea552b;
    font-weight: 700;
  }

  .image {
    right: -10%;
  }

  .circle-pub-bg {
    height: 180px;
  }

  .work-card-link {
    margin-right: 1.5vw;
    margin-left: 1.5vw;
  }

  .work-card-img-con {
    height: 75vw;
  }

  .work-card {
    width: 75vw;
  }

  .div-block-114 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-100 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    margin-top: 2em;
    margin-right: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mr-16._12 {
    margin-right: 12px;
  }

  .slider-wraper {
    padding-right: 19px;
    padding-left: 18px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
  }

  .h-hiw-item-description-block {
    max-width: 279px;
    padding-bottom: 20px;
  }

  .testi-img-person {
    display: none;
    margin-left: -5%;
  }

  .testi-img-person.mobile {
    position: relative;
    bottom: 0px;
    display: block;
    margin-left: 0%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .test-img-el._2 {
    right: 5%;
  }

  .test-img-el._2.bulb {
    right: 0%;
  }

  .test-img-el._3 {
    right: 20%;
  }

  .test-img-el._1 {
    top: 15%;
    right: 15%;
  }

  .test-img-el._1.holmes {
    top: 20%;
    width: 10em;
  }

  .test-img-el._1.holmes.lance {
    top: 10%;
    right: 7%;
    width: 170px;
  }

  .test-img-el._1.holmes.lance._2 {
    top: 44%;
    width: 150px;
  }

  .h-testimonial-left {
    height: 50%;
  }

  .h-testimonial-right {
    position: -webkit-sticky;
    position: sticky;
    height: 100%;
    margin-top: -2em;
    padding: 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .h-testimonials-wrapper {
    padding-top: 0em;
  }

  .left-arrow-5.h-testimonial {
    display: none;
  }

  .right-arrow-5.h-testimonial {
    display: none;
  }

  .h-testimonial-img {
    position: relative;
    height: 20em;
  }

  .h-header-graphic-alt {
    display: block;
  }

  .h-hiw-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .h-benefits-wrapper {
    padding-bottom: 3em;
  }

  .h-reviews-wrapper {
    height: auto;
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .h-portfolio-heading {
    padding-right: 0em;
    padding-left: 0em;
  }

  .header-press-logo-list {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .h-portfolio-thumbnail {
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .h-testimonial-small-client-info {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .page-wrapper {
    overflow: hidden;
  }

  .h-card-title-mobile {
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
    line-height: 1em;
    font-weight: 700;
  }

  .semi-bold.mbl-block {
    display: block;
    text-align: center;
  }

  .hiw-process-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .sos-header-wrapper {
    padding: 3em 2em;
  }

  .sos-search-wrapper {
    width: 100%;
  }

  .filters-horizontal {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .custom-btn.blue.margin-24 {
    margin-top: 20px;
    margin-left: 0px;
  }

  .sos-sidebar {
    width: 100%;
    margin-top: 0em;
  }

  .wd-form-field._w-select.margin-bottom-30 {
    margin-bottom: 26px;
  }

  .wd-form-field.margin-bottom-25 {
    margin-bottom: 20px;
  }

  .wd-header-content {
    margin-bottom: 2em;
  }

  .form-wrapper {
    margin-top: 0px;
  }

  .hiw-header-wrapper {
    padding-top: 4em;
  }

  .hiw-features-wrapper {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .hiw-features-list {
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .hiw-reviews-wrapper {
    height: auto;
    padding-top: 3em;
    padding-bottom: 2em;
  }

  .hiw-reviews-content {
    padding-right: 2em;
    padding-left: 2em;
  }

  .wd-header-bg {
    background-position: 50% 0%;
  }

  .wd-header-wrapper {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .sos-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .lightbox-link {
    padding-left: 0em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hiw-steps-wrapper-alt {
    padding-top: 3em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .hiw-step-wrapper-alt {
    margin-bottom: 3em;
  }

  .hiw-header-bg-alt {
    height: 60vh;
    max-width: none;
  }

  .ps-header-wrapper {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .ps-testimonials-wrapper {
    padding-top: 2em;
    padding-bottom: 0em;
  }

  .ps-benefits-wrapper {
    padding-bottom: 2em;
  }

  .ps-benefits {
    padding-bottom: 0em;
  }

  .ps-partners-wrapper {
    padding-top: 2em;
    padding-bottom: 4em;
  }

  .ps-header-content {
    margin-bottom: 2em;
  }

  .ps-testimonial-thumbnail {
    width: 100%;
    margin-right: 0em;
    margin-bottom: 1.5em;
  }

  .ps-testimonial-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ps-benefits-item {
    margin-bottom: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ps-benefits-graphic {
    min-width: auto;
  }

  .ps-benefits-info {
    text-align: center;
  }

  .prs-suggested-wrapper {
    padding-bottom: 2em;
  }

  .pub-icon {
    margin-right: 40px;
  }

  .aff-text-wrapper {
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .aff-hero_left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .aff-hero_right {
    width: 100%;
    margin-top: 40px;
    text-align: center;
  }

  .a-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .a-header-graphic {
    right: 0%;
  }

  .logo-sec {
    padding-bottom: 0em;
  }

  .pub-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .pub-block.mbl-hde {
    display: none;
  }

  .pub-block.mbl-show {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 48px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .a-features-wrapper {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .a-hiw-wrapper {
    display: none;
  }

  .h1-large-2 {
    font-size: 3rem;
  }

  .value-image {
    display: none;
  }

  .value-image.m {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: 0%;
    display: block;
    width: auto;
  }

  .mobile-scroll {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .value-image-2 {
    display: none;
  }

  .value-card.margin-40 {
    margin-bottom: 2em;
    margin-left: 2em;
  }

  .value-card.white {
    position: relative;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .service-grid.margin-top-40 {
    grid-column-gap: 12px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .review-card.yellow {
    padding-right: 2em;
    padding-left: 2em;
  }

  .review-card.yellow.purple {
    position: static;
  }

  .review-card.yellow.green {
    position: static;
  }

  .review-card.yellow.gray {
    position: static;
  }

  .grid-6 {
    max-width: none;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .carousel-placeholder {
    top: -20px;
    width: 100%;
  }

  .service-card {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .chart {
    background-size: 100vw;
  }

  .chart.response {
    padding-top: 3em;
    padding-bottom: 16em;
  }

  .chart.response {
    padding-bottom: 350px;
  }

  .box__for__cursor {
    height: 15px;
  }

  .community-grid {
    width: 100%;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }

  .paragraph.big {
    text-align: center;
  }

  .paragraph.big.green {
    text-align: left;
  }

  .paragraph.big.center.grey {
    text-align: center;
  }

  .paragraph.big.pink {
    text-align: left;
  }

  .paragraph.big.yelllow {
    text-align: left;
  }

  .paragraph.big.gray {
    max-width: none;
    text-align: left;
  }

  .paragraph.big.grey {
    text-align: left;
  }

  .paragraph.big.grey.left {
    margin-bottom: 0px;
    text-align: left;
  }

  .paragraph.big.grey.define {
    max-width: none;
    text-align: left;
  }

  .wrapper__join {
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .item {
    width: 90vw;
  }

  .site-container {
    padding-right: 0px;
    padding-left: 0px;
  }

  .service-heading {
    font-size: 40px;
  }

  .secondry-heading.align-left.margin-24 {
    margin-bottom: 20px;
    text-align: center;
  }

  .secondry-heading.align-left.margin-24 {
    margin-bottom: 0.25em;
    text-align: center;
  }

  .values-grid {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .carousel-wrapper {
    padding: 20px;
  }

  .carousel-wrapper.m {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .carousel-wrapper.d {
    display: none;
  }

  .carousel-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .carousel-image {
    width: 100%;
    height: auto;
  }

  .carousel-icons {
    width: 50px;
    height: 50px;
  }

  .value-card-wrapper {
    padding-right: 25px;
    padding-left: 100px;
  }

  .section-3 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .job-item {
    padding: 10px 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .testimonial-inner-wrapper {
    position: relative;
    top: -4%;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-4 {
    padding: 3em 2em;
  }

  .section-5 {
    padding: 3em 2em 4em;
  }

  .section-6 {
    padding-right: 2em;
    padding-left: 2em;
  }

  .section-7 {
    padding-right: 2em;
    padding-left: 2em;
  }

  .section-8 {
    padding-right: 2em;
    padding-left: 0em;
  }

  .div-block-133 {
    padding-right: 0%;
    padding-left: 0%;
  }

  .ow-h2-heading {
    display: block;
    margin-bottom: 0.5em;
  }

  .div-block-138 {
    padding-right: 2em;
    padding-left: 2em;
    text-align: center;
  }

  .slide-4 {
    width: 510px;
  }

  .div-block-140 {
    margin-right: -1%;
    margin-left: -1%;
  }

  .outline {
    margin-bottom: 0em;
  }

  .sn-value.color--gray-800 {
    color: #878e96;
  }

  .small-title-wrapper.m-0.co-hide {
    display: block;
  }

  .co-form-wrapper._100 {
    width: 100%;
  }

  .co-form-wrapper.margin-negative {
    margin-top: 0rem;
  }

  .co-item-thumbnail {
    width: 6em;
    height: 4em;
  }

  .co-bottom-wrapper {
    padding-top: 0em;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#f1f3f5),
      to(hsla(0, 0%, 100%, 0))
    );
    background-image: linear-gradient(0deg, #f1f3f5, hsla(0, 0%, 100%, 0));
  }

  .hide-desktop {
    display: block;
  }

  .flex-horizontal-center {
    margin-bottom: 0.25em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .flex-horizontal-center.grid-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .co-grid-wrapper {
    justify-items: start;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .ch-label-text.color--gray-700.mobile-visible {
    display: block;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .ch-dc-price.color--gray-700 {
    position: relative;
    display: block;
    margin-bottom: 0px;
    margin-left: 1em;
  }

  .checkout-title-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2rem;
    text-align: left;
  }

  .color--gray-900.ch-heading {
    font-size: 32px;
    line-height: 42px;
  }

  .color--gray-900.text-dark.mob-align-left {
    font-size: 2rem;
    text-align: left;
  }

  .co---guarantee---content {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .ch-label-desc {
    margin-top: 0em;
    margin-bottom: 0em;
    margin-left: 1em;
  }

  .ch-discount {
    text-align: left;
  }

  .ch-discount.hide-mob {
    display: none;
  }

  .ch-discount.hide-desktop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-block-40 {
    display: none;
  }

  .width-50 {
    width: 100%;
  }

  .div-block-146 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .ff-csz {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
  }

  .grid-34.width {
    width: 100%;
  }

  .checkbox-field {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .inter-16-24.text-white.center {
    text-align: center;
  }

  .inter-16-24.text-white.center.align-left.mbl-new {
    text-align: left;
  }

  .image-98 {
    left: 15%;
    width: 74%;
  }

  .div-block-149 {
    padding-right: 2em;
    padding-left: 2em;
  }

  .co-item {
    max-width: 80vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .co-item.default {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .discount-tag {
    display: none;
    margin-top: 0.25em;
    margin-left: 0em;
  }

  .co-item-toggle.grid-alt {
    top: auto;
    bottom: 1em;
  }

  .co-item-list.alt {
    width: auto;
    height: auto;
  }

  .co-top-wrapper {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }

  .co-item-top {
    margin-bottom: 0.75em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .co-item-top.alt {
    margin-bottom: 0em;
  }

  .co-item-top.alternate {
    margin-bottom: 0em;
  }

  .co-form {
    width: 100vw;
    max-width: 90vw;
    min-width: 80vw;
  }

  .discount-tag-mobile {
    display: block;
    margin: 0.5em 0em 0em;
    padding: 0em;
    background-color: transparent;
    color: #40c057;
  }

  .co---guarantee {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.5em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .co-sidebar-wrapper {
    display: block;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }

  .div-block-154 {
    padding-bottom: 0em;
  }

  .grid-35 {
    display: none;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .input-field-multi._60 {
    width: 60%;
  }

  .input-field-multi._20 {
    width: 20%;
  }

  .inter_16-22-2.grey-700.mb_76.mbl-white {
    margin-bottom: 40px;
  }

  .form-block.mb_28 {
    width: 100%;
  }

  .div-block-43 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .hc-topic-thumb {
    display: block;
    margin-right: 16px;
  }

  .div-block-78-copy {
    margin-top: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    background-color: #f1f3f5;
  }

  .hc-section-topic {
    border-bottom: 1px solid #dee2e6;
  }

  .hc-section-topic.no-brdr {
    border-bottom-style: none;
  }

  .hc-section-topic.list-actve {
    background-color: transparent;
    color: #353a40;
  }

  .horizontal-center-start.tab-hid {
    display: none;
  }

  ._1st-dropdown-list-copy {
    background-color: #fff;
  }

  .hc-section-wrapper {
    margin-bottom: 1em;
    border-bottom-style: none;
    background-color: #fff;
  }

  .div-block-160 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ea552b;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0.2px;
    cursor: pointer;
  }

  .cht {
    display: none;
  }

  .cht.tb-show {
    display: block;
  }

  .hc-articles-desktop {
    position: absolute;
    z-index: 50;
    background-color: #f1f3f5;
  }

  .hc-cta-wrapper {
    padding-bottom: 1em;
  }

  .hc-cta-wrapper.mobile {
    display: block;
    margin-right: 2em;
    margin-left: 2em;
    padding-bottom: 1em;
  }

  .link-block-17 {
    width: 100%;
    background-color: #fff;
    color: #31a8fe;
  }

  .div-block-161 {
    display: none;
    opacity: 0;
  }

  .text-block-32-copy.tbshw {
    display: block;
  }

  .text-block-32-copy.tb-hd {
    display: none;
  }

  .left-copy {
    margin-top: 0px;
    margin-right: -1%;
    margin-left: -1%;
    border-right-style: none;
    background-color: transparent;
  }

  .dark-grey-copy.mb_40 {
    font-size: 36px;
    line-height: 44px;
  }

  .hc-item-toggle {
    padding-right: 40px;
    white-space: pre-wrap;
  }

  .div-block-162 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .dropdown-list-6 {
    padding-right: 21px;
  }

  .image-103 {
    margin-right: 12px;
  }

  .hc-cta-button-wrapper {
    margin-top: 0em;
  }

  .video-by-wrapper.margin-top-56 {
    margin-top: 40px;
  }

  .video-by-wrapper.margin-top-56 {
    margin-top: 40px;
  }

  .secondry-heading-2 {
    font-size: 38px;
    line-height: 50px;
  }

  .text--block.custom-width {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  .slider-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .inner-wrapper {
    max-width: 400px;
    border-bottom: 2px dashed #dee2e6;
  }

  .map-wrapper {
    display: none;
  }

  .rusty.mt--40 {
    display: none;
    margin-top: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .rusty.mt--40 {
    margin-top: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .box__for__cursor-2 {
    height: 15px;
  }

  .testimonial-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .slider-img {
    display: none;
  }

  .slider-img.m {
    display: block;
    width: 100%;
  }

  .slider-img.m {
    display: block;
    width: 80%;
  }

  .leaders-grid.margin {
    grid-column-gap: 15px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .leaders-grid.margin {
    margin-top: 2em;
    margin-bottom: 2em;
    grid-column-gap: 15px;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .testimonial-image {
    height: auto;
  }

  .testimonial-image.m {
    display: inline-block;
  }

  .testimonial-image.hide-it {
    display: none;
  }

  .leaders-wrapper.down {
    top: 0px;
  }

  .div-block-163 {
    text-align: center;
  }

  .section-17 {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .hc-article-wrapper {
    position: relative;
    padding: 0em 0em 1em;
  }

  .hc-article-wrapper.mobile {
    padding-bottom: 2em;
  }

  .co-cart-tablet {
    display: none;
  }

  .hc-articles-mobile {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 50;
    display: none;
    height: 100%;
    margin-left: 0em;
    padding: 2em;
    background-color: #f1f3f5;
  }

  .feature-item-heading-3.margin-16 {
    margin-bottom: 12px;
  }

  .feature-item-heading-3.margin-16.align-left {
    text-align: left;
  }

  .quotes-2 {
    display: none;
    margin-bottom: 15px;
    font-size: 60px;
  }

  .h1-small-2.margin-bottom-large {
    margin-bottom: 1em;
  }

  .section-18 {
    padding: 3em 2em;
  }

  .section-18.c-testimonials {
    padding-bottom: 2em;
  }

  .section-18.c-work {
    padding-bottom: 0em;
  }

  .section-18.c-leaders {
    padding-top: 3em;
    background-image: none;
    background-size: auto;
    background-repeat: repeat;
  }

  .section-18.pg-members {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section-18.pg-benefits {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section-18.pg-apply {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .section-18.pg-header {
    background-position: 50% 100%;
  }

  .blog-content-2 {
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .quote-text-2.margin-20 {
    font-size: 24px;
  }

  .div-block-168 {
    padding-right: 0em;
    padding-left: 0em;
  }

  .hiw-features-list-2 {
    grid-row-gap: 3em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .testimonial-item-2.hide-it {
    display: none;
  }

  .paragraph-default-3 {
    margin-top: 1em;
  }

  .blog-wrapper-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .job-grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .testimonial-name-2 {
    left: auto;
    top: 0%;
    right: auto;
    bottom: auto;
  }

  .job-content-2 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .twinkle-img-2 {
    display: none;
  }

  .blog-image-2 {
    margin-right: 2em;
  }

  .blog-image-2.d {
    display: none;
  }

  .blog-image-2.m {
    display: block;
    width: 10em;
    height: 10em;
    border-radius: 0.5em;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .quote-content-2 {
    margin-top: 40px;
  }

  .feature-icon-2 {
    width: 104px;
    height: 104px;
    margin-bottom: 16px;
  }

  .key-feature-grid-2 {
    margin-right: auto;
    margin-bottom: 80px;
    margin-left: auto;
    padding-right: 0em;
    padding-left: 0em;
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .h1-2 {
    font-size: 3.5rem;
  }

  .quote-smaller-text-2 {
    font-size: 16px;
  }

  .overlay-text-2 {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 4em;
    padding: 0px;
    background-color: transparent;
  }

  .paragraph-3.big.dark-grey {
    text-align: left;
  }

  .paragraph-3.big.dark-grey.margin {
    text-align: left;
  }

  .paragraph-3.big.bold {
    text-align: left;
  }

  .paragraph-3.big.margin-24 {
    text-align: left;
  }

  .div-block-170 {
    width: auto;
  }

  .c-job-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .blog-grid-2 {
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .h2-2 {
    font-size: 2rem;
  }

  .requirements-inner-2 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .pg-header-wrapper {
    width: 100%;
  }

  .about-content-2 {
    text-align: center;
  }

  .programs-features-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .members-grid-2 {
    margin-top: 2em;
  }

  .pg-main-content-wrapper {
    margin-top: 30px;
  }

  .pg-main-info {
    margin-right: 0em;
    margin-bottom: 1em;
  }

  .pg-main-info-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .program-image-2 {
    margin-bottom: 0em;
  }

  .pg-main-header-wrapper {
    width: 100%;
  }

  .select-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .social-link-circle {
    width: 45px;
    height: 45px;
    margin-right: 0px;
  }

  .social-link-circle.hide-desktop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .text-19px {
    font-size: 20px;
    line-height: 36px;
  }

  .blog-single_header-wrapper-2 {
    width: 100%;
    text-align: center;
  }

  .blog-single_author-2 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .mt-45 {
    margin-top: 0em;
  }

  .blog-single_info-grid-2 {
    width: 85%;
    grid-column-gap: 10px;
    -ms-grid-columns: 14% 1fr;
    grid-template-columns: 14% 1fr;
  }

  .blog-single_heading.align-left {
    text-align: left;
  }

  .feature-blog1-2 {
    display: none;
    width: 270px;
  }

  .div-block-173 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mt-64 {
    height: auto;
  }

  .single-blog_grid-2 {
    position: static;
    bottom: 148px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .profile-info-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .grid-31 {
    padding-bottom: 175px;
  }

  .mt-32.hide-mob {
    display: none;
  }

  .post-tags-2 {
    background-color: #f3f3f3;
  }

  .single-blog_post-body-2 {
    width: 100%;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: transparent;
  }

  .feature-blog2-2 {
    width: 100%;
    margin-left: 0px;
    /* background-image: url('../images/holidays.png'); */
    background-position: 50% 90%;
    background-size: 99%;
    background-repeat: no-repeat;
  }

  .feature-blog2-content-2 {
    height: 350px;
  }

  .single-blog_featue-blog-2 {
    width: 100%;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .heading-normal-2 {
    font-size: 40px;
    line-height: 48px;
  }

  .image-88 {
    height: 110px;
  }

  .show-mob {
    display: block;
  }

  .images-grid-2 {
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
  }

  .div-block-41 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .text-20px {
    font-size: 20px;
    line-height: 36px;
  }

  .lc-all-wrappers {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .section-19 {
    background-color: #fff;
    background-image: none;
  }

  .html-embed-6 {
    font-size: 2rem;
  }

  .blog-post-toc-content {
    width: 100%;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: transparent;
  }

  .blog-toc-item {
    max-width: 80vw;
  }

  .wp-services-list-mobile {
    display: none;
  }

  .co-heading-main {
    font-size: 2rem;
    text-align: left;
  }

  .co-item-frequency {
    max-width: 80vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .support-mobile {
    padding-right: 2em;
    padding-left: 2em;
    text-align: center;
  }

  .co-item-grid {
    width: 97%;
    justify-items: stretch;
    grid-auto-rows: auto;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .co-item-grid._2 {
    width: 100%;
  }

  .co-item-wrapper-2.grid-alt {
    height: 120px;
  }

  .co-item-check-wrapper {
    top: 1em;
    right: 1em;
    bottom: auto;
  }

  .co-item-check-wrapper.grid-alt {
    top: auto;
    bottom: 1em;
  }

  .co-item-checkmark-copy {
    top: 1em;
    right: 1em;
    bottom: auto;
  }

  .co-item-checkmark-copy.grid-alt {
    top: auto;
    bottom: 1em;
  }

  .co-white {
    max-width: 80vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  .section-nav {
    z-index: 1000;
    border-bottom-color: transparent;
    background-color: transparent;
  }

  .nav {
    padding-left: 5%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .nav.padding-vertical-default.watch-demo {
    position: static;
  }

  .nav-logo {
    padding: 0px;
  }

  .cs-header-wrapper {
    padding: 2em 0em;
  }

  .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .h1 {
    font-size: 3rem;
  }

  .h1.h-header {
    z-index: 4;
  }

  .h1.inline {
    position: relative;
  }

  .cs-list-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 2em 0em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .cs-list {
    width: 90%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .cs-hero-logo {
    display: none;
  }

  .cs-header-content {
    margin-bottom: 1em;
  }

  .nav-menu.alt {
    color: #fff;
  }

  .paragraph-default {
    font-size: 1.125rem;
  }

  .paragraph-default.text-color-dark-blue {
    color: #2c2c2c;
  }

  .paragraph-default.text-color-dark-blue.margin-top-default {
    margin-top: 0.5em;
  }

  .paragraph-default.text-align-center {
    text-align: center;
  }

  .paragraph-default.max-ch-2.em-mobile {
    margin-bottom: 1em;
  }

  .section-cs-story-header {
    margin-right: 0em;
    margin-left: 0px;
  }

  .cs-story-header-content {
    margin-top: 1em;
  }

  .cs-story-info {
    margin-bottom: 2em;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }

  .section-cs-story-article {
    padding-right: 0px;
    padding-left: 0px;
  }

  .cs-story-paragraph-default {
    text-align: left;
  }

  .cs-story-img-column {
    margin-bottom: 2em;
  }

  .cs-story-img-column-right {
    padding-top: 1em;
  }

  .cs-story-content {
    margin-bottom: 2em;
  }

  .cs-story-stats {
    margin-bottom: 2em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .cs-story-stats-item {
    text-align: left;
  }

  .cs-story-more {
    margin-top: 2em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cs-story-list {
    width: 90%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .nav-banner {
    display: none;
  }

  .section-footer {
    display: none;
    padding-top: 2em;
    padding-right: 0em;
    padding-left: 0em;
  }

  .footer-cta-column {
    width: 100%;
    padding: 2em 1.5em;
    border-radius: 0px;
  }

  .footer-cta-column-right {
    display: none;
    height: auto;
  }

  .cta-heading {
    font-size: 2.5rem;
  }

  .cta-heading.alt {
    font-size: 2.5rem;
  }

  .paragraph-default-alt.wp-locked {
    margin-right: 0em;
  }

  .paragraph-default-alt.p-money-back-paragraph {
    margin-bottom: 0px;
    font-size: 1.1rem;
  }

  .paragraph-default-alt.margin-disable {
    margin-right: 0em;
    margin-left: 0em;
  }

  .footer-cta-graphic {
    width: 100vw;
    height: auto;
  }

  .cta-button-secondary {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .footer-press-logo-list {
    margin-bottom: 0px;
    padding-top: 2em;
    padding-bottom: 2em;
    grid-column-gap: 2em;
    -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    -ms-grid-columns: max-content max-content max-content max-content
      max-content max-content;
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content max-content max-content max-content;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .footer-press-logo {
    margin-right: 1em;
    margin-left: 1em;
  }

  .footer-press-logo.pos-top.mobile-hidden {
    display: none;
  }

  .footer-main {
    margin: 0px;
    padding: 0px;
  }

  .footer-column {
    justify-items: center;
    grid-column-gap: 2em;
    text-align: center;
  }

  .footer-logo {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .footer-text-copyright {
    margin-top: 12em;
    text-align: right;
  }

  .section-wp-header {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .h1-large {
    font-size: 3rem;
  }

  .wp-header {
    margin-right: 0px;
    margin-left: 0px;
  }

  .wp-header-rating-graphic {
    margin-bottom: 0.5em;
  }

  .wp-header-rating-wrapper {
    margin-top: 1em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .wp-testimonial-card-blue {
    height: auto;
    max-height: 640px;
    min-height: 640px;
    padding-top: 2em;
    padding-right: 10%;
    padding-left: 10%;
    background-position: 50% 110%, 50% -90%;
    background-size: 80%, auto;
  }

  .wp-testimonial-container {
    margin-top: 1em;
  }

  .wp-testimonial-designer-name-blue {
    display: none;
  }

  .wp-header-bg {
    position: relative;
  }

  .wp-testimonial-card-purple {
    height: auto;
    max-height: 640px;
    min-height: 640px;
    padding-top: 2em;
    padding-right: 10%;
    padding-left: 10%;
    background-position: 50% 110%, 50% -90%;
    background-size: 80%, auto;
  }

  .wp-testimonial-designer-name-purple {
    display: none;
  }

  .wp-testimonial-card-orange {
    height: auto;
    max-height: 640px;
    min-height: 640px;
    padding-top: 2em;
    padding-right: 10%;
    padding-left: 10%;
    background-position: 50% 120%, 50% -90%;
    background-size: 80%, auto;
  }

  .wp-testimonial-designer-name-orange {
    display: none;
  }

  .wp-testimonial-designer-name-green {
    display: none;
  }

  .wp-testimonial-card-green {
    height: auto;
    max-height: 640px;
    min-height: 640px;
    padding-top: 2em;
    padding-right: 10%;
    padding-left: 10%;
    background-position: 50% 120%, 50% -90%;
    background-size: 80%, auto;
  }

  .wp-testimonial-card-yellow {
    height: auto;
    max-height: 640px;
    min-height: 640px;
    padding-top: 2em;
    padding-right: 10%;
    padding-left: 10%;
    background-position: 50% 100%, 50% -90%;
    background-size: 80%, auto;
  }

  .wp-testimonial-designer-name-yellow {
    display: none;
  }

  .section-wp-services.desktop {
    display: none;
  }

  .section-wp-services.mobile {
    display: block;
  }

  .wp-services {
    padding: 2em 0px;
    text-align: center;
  }

  .wp-services-content-header-line {
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .wp-content-headline {
    height: 2.5rem;
    margin-top: -1em;
    margin-right: 0.5em;
  }

  .wp-services-column-right {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: 5% auto auto;
    grid-template-rows: 5% auto auto;
  }

  .wp-services-column-right.desktop {
    margin-top: 0%;
    padding-right: 1em;
    padding-left: 1em;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .wp-services-graphic {
    margin-bottom: 2em;
  }

  .wp-services-header {
    display: inline;
    font-size: 2.5rem;
  }

  .wp-services-list {
    display: none;
  }

  .icon-check-circle {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .wp-services-item-text-default {
    text-align: left;
  }

  .section-wp-assign {
    padding-top: 3em;
    padding-right: 0em;
    padding-left: 0em;
  }

  .h1-small-alt {
    font-size: 2.5rem;
  }

  .h1-small-alt.inline.text-color-dark-blue {
    font-size: 3rem;
  }

  .wp-assign-graphic {
    min-width: 1000px;
  }

  .wp-swap-wrapper {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .column {
    padding-right: 0px;
    padding-left: 0px;
  }

  .section-wp-dashboard {
    padding: 2em 0px;
  }

  .h1-small {
    font-size: 2.5rem;
  }

  .h1-small.shrink.alt {
    margin-right: 0px;
    margin-left: 0px;
  }

  .h1-small.alt {
    position: relative;
    z-index: 5;
  }

  .h1-small.text-color-inverse {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .h1-small.mobile-center {
    text-align: center;
  }

  .wp-dashboard-tab-container {
    padding-right: 1em;
    padding-left: 1em;
    text-align: center;
  }

  .wp-dashboard-tab-icon {
    margin-bottom: 1em;
  }

  .wp-dashboard-tab-heading-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .section-footer-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 0em 0em 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-position: 0% 100%;
    background-size: auto 90vw;
  }

  .section-footer-mobile.alt {
    background-color: #f1f3f5;
    /* background-image: url('../images/footer-bg-alt.svg'); */
  }

  .footer-press-logo-list-header {
    color: #878e96;
    font-size: 0.75rem;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.1em;
  }

  .footer-press-logo-list-mobile {
    display: -ms-grid;
    display: grid;
    margin-top: 1em;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .footer-press-logo-mobile {
    margin-top: 2em;
  }

  .social {
    margin-top: 0px;
  }

  .footer-main-mobile {
    margin-right: 5%;
    margin-left: 5%;
  }

  .footer-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 5%;
    margin-bottom: 2em;
    margin-left: 5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-menu-list-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .footer-menu-title-mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    font-family: "Inter", sans-serif;
    color: #ea552b;
    font-weight: 700;
  }

  .footer-menu-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1.5em;
    height: 1.5em;
    margin: 0px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    color: #aeb5bd;
  }

  .footer-menu-item-mobile {
    display: block;
    color: #737980;
    font-size: 1rem;
    line-height: 1.25em;
  }

  .footer-menu-mobile {
    display: block;
    height: auto;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .menu-item-container-mobile {
    position: static;
    display: block;
    margin-top: 0.5em;
    background-color: transparent;
  }

  .divider-small {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .footer-text-copyright-container {
    margin-top: 4em;
  }

  .section-wp-outsource {
    margin-right: -5%;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 0px;
    text-align: center;
  }

  .wp-outsource-content {
    padding-right: 5%;
  }

  .wp-outsource-benefits {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    text-align: left;
  }

  .wp-outsource-benefits-item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-2 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .slider-navigation-wrapper {
    overflow: hidden;
  }

  .slider-icon.left {
    display: none;
  }

  .slider-list.reviews {
    margin-left: 0%;
  }

  .slider-list.spolight {
    margin-left: 0em;
  }

  .slider-item {
    max-height: 640px;
    min-height: 640px;
  }

  .slider-item.p-reviews-card {
    width: 90vw;
    min-width: auto;
    margin-right: 0.875em;
    margin-left: 0.875em;
  }

  .slider-item.h-item {
    left: 5px;
    width: 90vw;
    margin-left: 0.875em;
  }

  .slider-item.spotlight {
    min-height: auto;
  }

  .p-header-content {
    margin-bottom: 3em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .p-packages {
    margin-top: 2em;
    -ms-grid-row-align: center;
    align-self: center;
    grid-auto-flow: row;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }

  .p-packages-item {
    max-width: 90vw;
  }

  .p-package-heading-container {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }

  .p-package-price-container {
    margin-top: 0.25em;
  }

  .p-package-info-top {
    padding-top: 1.5em;
    padding-right: 1em;
    padding-left: 1em;
  }

  .p-package-info-bottom {
    padding-right: 1em;
    padding-left: 1em;
  }

  .p-package-heading-bottom.margin-right-0-25 {
    text-align: left;
  }

  .icon-check-circle-green {
    height: 1.2em;
    min-height: 20px;
  }

  .p-package-benefits-item {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .p-logo-list {
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .p-logo-list-item {
    min-width: 32px;
  }

  .p-logo-list-item.mobile-hidden {
    display: none;
  }

  .section-p-benefits {
    padding: 2em 0px;
  }

  .p-benefits-heading-graphic {
    display: none;
  }

  .p-benefits-heading-outline {
    height: 2.5rem;
    min-height: auto;
  }

  .feature-item-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .feature-item-heading {
    text-align: left;
  }

  .feature-item-icon {
    margin-bottom: 1em;
  }

  .feature-item-paragraph {
    text-align: left;
  }

  .feature-item {
    padding-right: 1em;
    padding-left: 1em;
    text-align: center;
  }

  .benefits-list {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .section-p-reviews {
    padding-top: 2em;
    padding-bottom: 2em;
    background-size: auto 10%;
  }

  .p-reviews-subheading {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .p-pricing-reviews-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    white-space: pre;
  }

  .p-reviews-card-rating {
    margin-bottom: 0.5em;
  }

  .p-reviews-card {
    width: 100%;
  }

  .p-reviews-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0em;
    padding-left: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .faq {
    padding-top: 3em;
    padding-right: 0px;
    padding-left: 0px;
  }

  .pricing-link {
    position: relative;
  }

  .faq-graphic {
    z-index: 1;
    margin-top: -3em;
  }

  .p-faq-list {
    margin-top: -3em;
  }

  .faq-question {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .p-faq-question-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
  }

  .p-faq-answer {
    position: static;
    display: block;
    background-color: transparent;
  }

  .p-faq-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1.5em;
    height: 1.5em;
    margin: 0px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    color: #aeb5bd;
  }

  .faq-answer-text {
    margin-right: 0px;
  }

  .wp-designer-name {
    bottom: -16%;
    max-width: 80vw;
    min-width: auto;
    margin-bottom: 0px;
  }

  .wp-assign-graphic-container {
    position: relative;
    min-width: 1200px;
  }

  .cta-heading-2 {
    font-size: 2.5rem;
  }

  .p-packages-main {
    margin-bottom: 1em;
    padding-right: 0em;
  }

  .p-pricing-reviews-text-container {
    width: 90vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nav-list-text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: left;
  }

  .p-faq-subheading {
    position: relative;
    z-index: 5;
    margin-top: 0.25em;
  }

  .p-money-back {
    padding-top: 2em;
    padding-right: 1em;
    padding-left: 1em;
    background-size: auto 50vw;
  }

  .pricing-money-back-heading {
    font-size: 2.2rem;
    line-height: 1.1em;
  }

  .p-money-back-content {
    width: auto;
  }

  .p-faq-button-expand {
    margin-top: 0em;
  }

  .pricing-money-back-graphic {
    width: 80vw;
    height: auto;
  }

  .section-p-tabs {
    padding-top: 2em;
  }

  .p-tab-button-container {
    width: 33.333333333333336vw;
  }

  .header-graphic-right {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ow-section-header.ow-section-header-padding-horizontal {
    padding-right: 1em;
    padding-bottom: 1.5em;
    padding-left: 1em;
  }

  .ow-category-list {
    overflow: auto;
    height: auto;
    margin-left: -2em;
    padding-right: 1em;
    padding-left: 2em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .ow-modal-thumbnail {
    border-radius: 5px;
  }

  .ow-category-item.web {
    height: auto;
    min-width: 136px;
    padding-top: 20px;
    border-radius: 5px;
  }

  .ow-category-item.illus {
    height: auto;
    min-width: 136px;
    padding-top: 20px;
    border-radius: 5px;
  }

  .ow-category-item.presentations {
    height: auto;
    min-width: 136px;
    padding-top: 20px;
    border-radius: 5px;
  }

  .ow-category-item._2d-animation {
    height: auto;
    min-width: 136px;
    padding-top: 20px;
    border-radius: 5px;
  }

  .ow-category-item.infographics {
    height: auto;
    min-width: 136px;
    padding-top: 20px;
    border-radius: 5px;
  }

  .button-wrapper {
    margin-top: 1em;
  }

  .button-wrapper.reviews {
    margin-top: 0em;
  }

  .ow-modal-wrapper {
    max-width: 300px;
    border-radius: 5px;
  }

  .ow-tab-text {
    font-size: 1rem;
  }

  .ow-modal-overlay {
    border-radius: 5px;
  }

  .ow-tab-content {
    padding-right: 0px;
    padding-left: 0px;
  }

  .h4.lc-featured-article-mobile {
    margin-bottom: 0.75em;
  }

  .h4.text-color-inverse {
    white-space: break-spaces;
  }

  .ow-header-sparkle {
    margin-right: 0%;
  }

  .ow-tab-item {
    max-width: 90vw;
  }

  .ow-list {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .ow-modal-full {
    padding-bottom: 1.5em;
  }

  .lc-header-wrapper {
    padding-right: 0px;
    padding-bottom: 1em;
    padding-left: 0px;
  }

  .lc-header {
    width: 90vw;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .lc-featured-wrapper {
    padding-top: 2em;
    padding-right: 1%;
    padding-left: 1%;
  }

  .lc-author-name {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .lc-featured-thumbnail-wrapper {
    width: 100%;
    height: 220px;
  }

  .lc-featured-list {
    padding-left: 5%;
  }

  .lc-author-link {
    margin-bottom: 0.25em;
  }

  .lc-featured-thumbnail-wrapper-alt {
    width: 100%;
    height: 220px;
  }

  .lc-recent-list {
    margin-bottom: 2em;
  }

  .lc-recent-list.bp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    width: 100vw;
    margin-left: -6%;
    padding-right: 6%;
    padding-left: 6%;
  }

  .lc-recent-item {
    min-width: 80%;
  }

  .lc-recent-thumbnail-wrapper {
    width: 100%;
    height: 220px;
  }

  .lc-header-img {
    height: auto;
  }

  .lc-all-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }

  .button-text-filled-gray.lc-all {
    display: none;
  }

  .lc-all-item {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .r-stats-item {
    margin-left: 0px;
  }

  .video-lightbox {
    width: 90vw;
    height: auto;
    padding-right: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .review-tab-menu {
    position: static;
    display: -ms-grid;
    display: grid;
    overflow: auto;
    width: auto;
    height: 100%;
    max-width: none;
    margin-right: 0px;
    margin-bottom: 0rem;
    margin-left: 0px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: auto;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: auto auto auto auto auto -webkit-max-content;
    -ms-grid-columns: auto auto auto auto auto max-content;
    grid-template-columns: auto auto auto auto auto -webkit-max-content;
    grid-template-columns: auto auto auto auto auto max-content;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    background-color: #fff;
  }

  .rating-bar {
    height: 42px;
  }

  .review-logo-item-mobile {
    display: block;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }

  .review-logos-list {
    grid-column-gap: 20px;
  }

  .r-item-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 509px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .tab-link-review {
    padding: 2px;
  }

  .tab-link-review.w--current {
    padding: 2px;
    font-weight: 700;
  }

  .video-thumbnail-imge {
    width: 100%;
    min-height: auto;
    min-width: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }

  .r-header-rating {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-reviews {
    padding: 0em 0em 1em;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .r-list {
    width: 100vw;
    margin-bottom: 0px;
    background-color: transparent;
  }

  .r-list.alt {
    background-color: transparent;
  }

  .r-item {
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 0px;
  }

  .review-video-lighbox {
    overflow: hidden;
    width: 100%;
    max-height: 8em;
    max-width: none;
    margin-right: 0rem;
  }

  .review-tab_component {
    width: 100vw;
    margin-left: -5%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .review-left {
    margin-top: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .review-video-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }

  .review-logo-item {
    display: none;
  }

  .right-review {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    margin-top: 0em;
    padding-top: 2em;
    background-color: #fff;
  }

  .r-stats {
    display: none;
    height: auto;
  }

  .reviews-tab {
    margin-top: 0rem;
  }

  .video-item {
    background-color: transparent;
  }

  .blue-bar {
    display: none;
    background-color: #e9ecef;
  }

  .video-details {
    width: 100%;
    padding-left: 1rem;
  }

  .r-videos {
    width: 100%;
    margin: 0px;
    padding: 1em 5% 0em;
    background-color: #fff;
  }

  .review-title {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .brand-assets {
    margin-top: auto;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .assets-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .download-button {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .media-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 0.5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .assets-thumbnail {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    max-width: 101px;
    margin-right: 0rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 0.25fr;
    grid-template-columns: 1fr 0.25fr;
    -ms-grid-rows: auto minmax(0px, 0.25fr);
    grid-template-rows: auto minmax(0px, 0.25fr);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .assets-thumbnail.grey {
    height: auto;
    max-width: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .media-item {
    width: 100%;
  }

  .media-thumbnail {
    height: auto;
  }

  .media-list {
    overflow: auto;
    margin-top: 1em;
    padding-right: 2%;
    padding-bottom: 0px;
    padding-left: 2%;
    grid-column-gap: 20px;
  }

  .assets-title {
    margin-left: 1em;
    font-size: 1rem;
  }

  .r-header-wrapper {
    display: none;
    padding-right: 0px;
    padding-left: 0px;
  }

  .r-header-video {
    position: relative;
    height: 100%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .r-header-thumbnail {
    width: 100%;
    height: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .r-header-thumbnail.large {
    height: 140%;
  }

  .r-icon-play {
    width: 3em;
  }

  .r-header-column-left {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .icon-play-alt {
    position: relative;
    font-size: 1rem;
  }

  .r-header-thumbnail-info {
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 0, 0, 0.8)),
      to(hsla(0, 0%, 100%, 0))
    );
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8),
      hsla(0, 0%, 100%, 0)
    );
    text-align: center;
  }

  .r-customer-info {
    display: none;
  }

  .ow-tab-menu {
    padding-right: 5%;
    padding-left: 5%;
  }

  .wp-swap-icon {
    font-size: 2.5rem;
  }

  .r-modal-wrapper {
    display: none;
  }

  .r-modal {
    margin-top: 4em;
    margin-left: -6%;
    padding-bottom: 1.5em;
    border-radius: 0px;
  }

  .icon-play-svg {
    width: auto;
    height: auto;
  }

  .button-endenhancer-gray-large-link {
    padding: 1em;
    background-color: #e9ecef;
  }

  .button-endenhancer-gray-large-link.block {
    width: 100%;
    color: #2c2c2c;
  }

  .button-endenhancer-gray-large-link.block.hidden {
    display: none;
  }

  .r-mobile-header-wrapper {
    display: block;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 0.5em;
    color: #2c2c2c;
  }

  .list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    margin: 2em -5% 0px 0px;
    padding: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    list-style-type: none;
  }

  .list-item {
    left: 0.5em;
    top: 0.5em;
    right: auto;
    bottom: auto;
    z-index: 5;
    width: 1.25em;
    height: 1.25em;
    padding-left: 0em;
  }

  .r-story-item-mobile {
    height: 12em;
    max-width: 40%;
    min-width: 168px;
    margin-right: 1em;
  }

  .r-logo-list-mobile {
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: #f1f3f5;
  }

  .r-logo-list-mobile-grid {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 50% 50%;
    grid-template-columns: 50% 50%;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .logo-rating-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    padding: 0.75em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0.3em;
    background-color: #fff;
  }

  .r-logo-embed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0.5em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .r-rating-embed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    max-width: 7.1em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mk-header-wrapper {
    padding: 2em 0px 0em;
  }

  .mk-brand-wrapper {
    padding-top: 2em;
    padding-right: 2%;
    padding-left: 2%;
  }

  .r-item-text-default {
    font-size: 1rem;
  }

  .r-tab {
    background-color: #f1f3f5;
  }

  .r-list-heading {
    margin-right: 0px;
    margin-bottom: 1em;
    margin-left: 5%;
    padding: 0px;
    background-color: #fff;
  }

  .container-2 {
    width: 90vw;
  }

  .hero-header-con {
    position: relative;
    z-index: 5;
  }

  .h-header-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .h-header-content {
    padding: 1em 0em 2em;
  }

  .pub-card {
    background-size: cover;
  }

  .pub-card.blue {
    min-height: 24em;
  }

  .btn-grey.pub {
    position: relative;
    bottom: 0%;
  }

  .pub-card-deets {
    margin-bottom: 10px;
  }

  .pub-card-con.mbl {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .image {
    right: -19%;
    bottom: -20%;
  }

  .pub-card-rev {
    margin-left: -35px;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  .image-49 {
    width: 130px;
  }

  .pub-card-icons {
    left: -20%;
    width: 160%;
    -webkit-transform: scale(0.7) perspective(500px);
    transform: scale(0.7) perspective(500px);
  }

  .h-portfolio-wrapper {
    padding: 2em 0em;
  }

  .div-block-114 {
    text-align: left;
  }

  .div-block-100 {
    margin-top: 1em;
  }

  .image-79 {
    z-index: 20;
  }

  .h-testimonial-small {
    padding-bottom: 20px;
  }

  .why-us-wrapper {
    grid-column-gap: 1em;
    grid-row-gap: 1em;
  }

  .testi-img-person {
    display: none;
  }

  .testi-img-person.mobile {
    margin-left: 0%;
  }

  .test-img-el._2 {
    top: 60%;
  }

  .test-img-el._2.bulb {
    top: 40%;
    width: 144px;
  }

  .test-img-el._3 {
    top: 55%;
  }

  .test-img-el._1 {
    top: 15%;
    right: 5%;
  }

  .test-img-el._1.holmes {
    top: 30%;
    width: 7em;
  }

  .test-img-el._1.holmes.lance {
    width: 150px;
  }

  .test-img-el._1.holmes.lance._2 {
    width: 130px;
  }

  .h-testimonial-right {
    margin-top: -4em;
    padding: 2em;
  }

  .h-testimonials-slider {
    margin-right: -5%;
    margin-left: -5%;
  }

  .h-spotlight-wrapper {
    padding-right: 0em;
    padding-bottom: 2em;
    padding-left: 0em;
  }

  .h-hiw-item-subheading {
    text-align: justify;
  }

  .h-hiw-wrapper {
    display: block;
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .h-benefits-wrapper {
    padding-right: 0em;
    padding-bottom: 1em;
    padding-left: 0em;
  }

  .h-reviews-wrapper {
    padding: 3em 0em;
    background-image: none;
  }

  .h-reviews-logo-list {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .h-reviews-logo.mobile-hidden {
    display: none;
  }

  .header-press-logo-list {
    grid-auto-columns: 1fr;
    grid-column-gap: 1em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .h-portfolio-btn {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    text-align: center;
  }

  .h-portfolio-thumbnail {
    height: 100%;
    max-width: none;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .h-portfolio-item {
    -o-object-fit: fill;
    object-fit: fill;
  }

  .image-80 {
    max-width: 100%;
  }

  .semi-bold.mbl-new-line {
    display: block;
  }

  .hiw-process-list-item {
    padding-top: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .hiw-process-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .sidebar-wrapper {
    margin-bottom: 2em;
    padding-right: 10px;
    padding-left: 10px;
  }

  .sos-header-wrapper {
    min-height: 30vh;
    padding: 2em 0em;
  }

  .sos-search-wrapper {
    padding: 1em;
  }

  .search-bar.search-icon.tablet {
    margin-right: 0em;
    margin-left: 0em;
  }

  .custom-btn {
    width: auto;
  }

  .sos-sidebar {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .wd-form-field._w-select.margin-bottom-30 {
    width: 100%;
  }

  .wd-form-field.margin-bottom-25 {
    width: 100%;
  }

  .wd-header-content {
    margin-bottom: 1em;
  }

  .wd-list-wrapper {
    margin-top: 2em;
    text-align: left;
  }

  .wd-header-form {
    width: 100%;
    min-width: auto;
  }

  .hiw-header-wrapper {
    height: 18em;
    padding-top: 3em;
    padding-right: 0em;
    padding-left: 0em;
  }

  .hiw-step-graphic {
    min-height: auto;
  }

  .hiw-step-heading {
    position: relative;
    z-index: 2;
    font-size: 2.5rem;
  }

  .hiw-features-wrapper {
    padding: 2em 0em;
  }

  .hiw-features-heading {
    margin-bottom: 2em;
  }

  .everything-outline.margin-disable {
    height: 2.5rem;
  }

  .everything-outline.margin-disable.tablet {
    height: 2.75rem;
    margin-top: 0.25em;
    margin-bottom: -0.25em;
  }

  .hiw-features-content {
    margin-right: 0em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: center;
  }

  .hiw-feature-icon-wrapper {
    margin-bottom: 1em;
  }

  .hiw-feature-icon {
    margin-bottom: 1em;
  }

  .hiw-feature-item {
    padding-right: 1em;
    padding-left: 1em;
    text-align: center;
  }

  .hiw-feature-heading {
    text-align: left;
  }

  .hiw-feature-paragraph {
    text-align: left;
  }

  .hiw-feature-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hiw-reviews-wrapper {
    padding-top: 2em;
    padding-bottom: 2em;
    background-image: none;
  }

  .hiw-reviews-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0em;
    padding-left: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .hiw-reviews-subheading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    white-space: pre;
  }

  .p-tab-text-small {
    display: none;
  }

  .wd-header {
    width: 90vw;
  }

  .wd-list-item {
    margin-bottom: 1.5em;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .wd-list-itext {
    white-space: break-spaces;
  }

  .wd-form-content {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .wd-header-wrapper {
    padding: 1em 0em;
  }

  .sos-content-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .hiw-steps-wrapper-alt {
    margin-top: 0em;
    padding-right: 0em;
    padding-left: 0em;
  }

  .hiw-step-wrapper-alt {
    margin-bottom: 2em;
  }

  .hiw-step-number-graphic {
    position: relative;
    z-index: 1;
  }

  .hiw-header-bg-alt {
    height: auto;
  }

  .ps-header-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .ps-testimonials-wrapper {
    padding-right: 0em;
    padding-bottom: 0em;
    padding-left: 0em;
  }

  .ps-benefits-wrapper {
    padding-right: 0em;
    padding-bottom: 0em;
    padding-left: 0em;
  }

  .ps-benefits {
    width: 100vw;
    margin-left: -5%;
    padding-top: 2em;
    border-radius: 0em;
  }

  .ps-partners-wrapper {
    padding-right: 0em;
    padding-bottom: 2em;
    padding-left: 0em;
  }

  .ps-testimonial-item {
    margin-bottom: 2em;
  }

  .ps-partners-list {
    overflow: auto;
    width: 100vw;
    margin-left: -5%;
    padding-right: 5%;
    padding-left: 5%;
    grid-auto-columns: -webkit-max-content;
    grid-auto-columns: max-content;
    grid-column-gap: 3em;
    -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    -ms-grid-columns: max-content max-content max-content max-content
      max-content max-content max-content max-content max-content max-content
      max-content max-content max-content max-content max-content;
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .prs-item {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section {
    padding: 2em 0em;
  }

  .prs-header-content {
    margin-bottom: 2em;
  }

  .prs-suggested {
    padding-right: 0em;
    padding-left: 0em;
  }

  .button-medium.margin-top {
    font-size: 15px;
  }

  .pub-icon {
    margin-right: 30px;
  }

  .a-header-wrapper {
    padding-top: 2em;
    padding-bottom: 0em;
  }

  .aff-text-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }

  .a-header {
    padding-bottom: 40px;
  }

  .section-base {
    padding-bottom: 20px;
  }

  .a-header-graphic {
    margin-top: 0em;
  }

  .pub-block.mbl-show {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .a-features-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .a-hiw-wrapper {
    padding-bottom: 20px;
  }

  .container-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .priv-content-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .h1-large-2 {
    font-size: 3rem;
  }

  .paragraph-default-2 {
    text-align: justify;
  }

  .paragraph-default-2.text-color-dark-blue {
    color: #2c2c2c;
  }

  .service-grid.margin-top-40 {
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .review-card.yellow {
    padding: 2em;
  }

  .carousel-placeholder {
    top: -5px;
  }

  .service-head {
    margin-bottom: 0px;
  }

  .chart.response {
    padding: 2em 5% 8em;
  }

  .chart.response {
    padding-bottom: 250px;
  }

  .review-heading {
    letter-spacing: -0.5px;
  }

  .box__for__cursor {
    width: 30%;
  }

  .carousel-content {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .community-card {
    text-align: left;
  }

  .paragraph.big.gray {
    display: block;
  }

  .paragraph.big.gray._3em {
    margin-right: -3em;
  }

  .paragraph.big.grey.define {
    text-align: center;
  }

  .item {
    width: 85vw;
  }

  .site-container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .service-heading.margin-10 {
    margin-bottom: 0px;
  }

  .secondry-heading.align-left.white.margin-24 {
    font-size: 2.5rem;
  }

  .values-grid {
    margin-right: -5%;
    margin-left: -5%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .carousel-wrapper.m {
    display: none;
  }

  .carousel-wrapper.d {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .carousel-icon {
    width: 50%;
  }

  .carousel-icons {
    width: auto;
    height: auto;
  }

  .service-container {
    width: auto;
    max-width: none;
  }

  .value-card-wrapper {
    margin-right: -2em;
    padding-right: 0px;
    padding-left: 4em;
  }

  .section-4 {
    padding: 2em 0em 0em;
  }

  .section-5 {
    padding-right: 0em;
    padding-left: 0em;
  }

  .section-6 {
    padding-right: 0em;
    padding-left: 0em;
  }

  .section-7 {
    margin-bottom: 0em;
    padding-right: 0em;
    padding-left: 0em;
  }

  .section-8 {
    overflow: hidden;
    padding-bottom: 3em;
  }

  .div-block-133 {
    margin-bottom: 2em;
  }

  .image-84 {
    width: 40vw;
  }

  .image-85 {
    width: 35vw;
  }

  .ow-h2-heading {
    display: block;
    font-size: 3rem;
    white-space: break-spaces;
  }

  .div-block-135.align-object-left {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .mask-3 {
    width: 100vw;
  }

  .slider-3 {
    left: -5%;
  }

  .div-block-138 {
    position: relative;
    left: -6%;
    width: 101vw;
    border-radius: 0em;
  }

  .section-9 {
    margin-bottom: 2em;
  }

  .outline {
    height: 3rem;
  }

  .ch-back-btn {
    margin-bottom: 20px;
  }

  .small-title-wrapper {
    margin-bottom: 20px;
  }

  .co-form-wrapper {
    position: relative;
  }

  .co-item-thumbnail {
    width: 100%;
    max-width: 4em;
    min-width: 4em;
  }

  .co-bottom-wrapper {
    padding-right: 0em;
    padding-left: 0em;
  }

  .hide-desktop {
    display: block;
  }

  .image-92.hide-mob {
    display: none;
  }

  .checkout-title-wrapper {
    font-size: 1.5rem;
  }

  .lab-gasture20-24.text-dark {
    margin-top: 0px;
    white-space: pre-line;
  }

  .ch-label-desc.padding-right-large {
    margin-right: 0em;
    padding-right: 0%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .ch-label-desc.grid-alt.discount {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .heading-20-24 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  .width-50 {
    width: 100%;
  }

  .form-label-2.color--gray-900 {
    text-align: left;
  }

  .div-block-146 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .grid-34.width {
    grid-column-gap: 10px;
  }

  .inter-16-24.text-white.center.align-left {
    max-width: 320px;
    text-align: left;
  }

  .inter-16-24.text-white.center.align-left.mbl-new {
    text-align: center;
  }

  .image-98 {
    left: 10%;
    width: 79%;
  }

  .fi-heading {
    font-size: 3rem;
  }

  .div-block-148 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .div-block-149 {
    padding-right: 0em;
    padding-left: 0em;
  }

  .nav-checkout-wrapper {
    padding-right: 0em;
    padding-left: 0em;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .co-nav-wrapper {
    z-index: 1000;
  }

  .nav-checkout-banner {
    display: none;
  }

  .co-item-wrapper.grid-alt {
    width: auto;
  }

  .co-item {
    max-width: 90vw;
  }

  .co-item-toggle.grid-alt {
    top: auto;
  }

  .co-item-list.alt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .co-top-wrapper {
    padding: 1em 0em;
  }

  .co-form {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }

  .co---guarantee {
    margin-top: 0em;
  }

  .co-sidebar-wrapper {
    left: -5%;
  }

  .co-billing-price {
    font-size: 14px;
  }

  .co-billing-price {
    font-size: 14px;
  }

  .input-field-multi._60 {
    width: 60%;
  }

  .input-field-multi._20 {
    width: 20%;
  }

  .mr-20.mb-10 {
    margin-right: 0px;
  }

  .flex-horizontal-center-2.mb_32.vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hc-item-wrapper {
    padding-top: 0px;
    padding-left: 0px;
  }

  .hc-item-wrapper.mb_20 {
    margin-bottom: 60px;
  }

  .text-span-12 {
    display: block;
  }

  .hc-cta-wrapper.mobile {
    margin-right: 0em;
    margin-left: 0em;
  }

  .hc-icon {
    margin-top: 14px;
  }

  .left-copy {
    margin-right: -5%;
    margin-left: -5%;
  }

  .hc-item-toggle {
    padding-right: 20px;
  }

  .text--block.custom-width {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .rusty.mt--40 {
    margin-top: 10px;
  }

  .rusty.mt--40 {
    margin-top: 10px;
  }

  .box__for__cursor-2 {
    width: 30%;
  }

  .leaders-grid.margin {
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .leaders-grid.margin {
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .leaders-wrapper.down {
    top: 0px;
  }

  .section-14 {
    padding: 2em 0em;
  }

  .section-15 {
    padding: 2em 0em;
  }

  .section-16 {
    padding-right: 0em;
    padding-left: 0em;
  }

  .section-17 {
    padding-right: 0%;
    padding-left: 0%;
  }

  .co-schedule-mobile {
    display: none;
  }

  .hc-articles-mobile {
    position: absolute;
    height: auto;
    padding-right: 5%;
    padding-left: 5%;
  }

  .hiw-feature-heading-2 {
    text-align: left;
  }

  .h1-small-2 {
    font-size: 2.5rem;
  }

  .h1-small-2.shrink.alt {
    margin-right: 0px;
    margin-left: 0px;
  }

  .container-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hiw-feature-icon-wrapper-2 {
    margin-bottom: 1em;
  }

  .section-18 {
    padding: 3em 0em;
    border: 0px none #000;
  }

  .section-18.c-testimonials {
    padding-top: 3em;
  }

  .section-18.c-leaders {
    padding-right: 0em;
    padding-left: 0em;
  }

  .section-18.c-jp {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 5%;
  }

  .section-18.pg-benefits {
    padding-top: 2em;
    padding-bottom: 1em;
  }

  .section-18.pg-apply {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .quote-text-2.margin-20 {
    line-height: 32px;
  }

  .h1-outline-small {
    height: 2.5rem;
  }

  .site-container-3 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .paragraph-default-3 {
    margin-top: 1em;
    font-size: 1.125rem;
  }

  .hiw-feature-item-2 {
    padding-right: 0em;
    padding-left: 0em;
    text-align: center;
  }

  .blog-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .job-grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .job-content-2 {
    margin-right: 1em;
  }

  .html-embed-5 {
    font-size: 3.5rem;
  }

  .blog-image-2 {
    margin-right: 0em;
    margin-bottom: 1em;
  }

  .blog-image-2.m {
    width: 100%;
  }

  .career-hero-wrapper-2 {
    max-width: none;
  }

  .key-feature-grid-2 {
    max-width: none;
  }

  .h1-2 {
    font-size: 3rem;
  }

  .requirements-icon-2 {
    margin-right: 0em;
  }

  .how-to-right-2 {
    text-align: center;
  }

  .requirements-inner-2 {
    margin-bottom: 3em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .requirements-content-2 {
    margin-top: 1em;
  }

  .programs-features-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .members-grid-2 {
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .benefits-heading-2 {
    text-align: center;
  }

  .select-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .co-plans-mobile {
    display: none;
    margin-top: -4.5em;
  }

  .co-count-mobile {
    display: none;
  }

  .social-link-circle.hide-desktop {
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
  }

  .padding-left-right-2 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .padding-left-right-2.blg {
    padding-right: 0px;
    padding-left: 0px;
  }

  .get-design-2 {
    width: 112%;
    margin-left: -6%;
    padding-bottom: 30px;
    border-radius: 0px;
  }

  .get-design-2.mbl-overflow-hid {
    width: 101vw;
    margin-left: -6%;
  }

  .blog-single_author-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mt-45 {
    margin-top: 40px;
  }

  .blog-single_info-grid-2 {
    width: 100%;
    margin-top: 20px;
    justify-items: start;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    -ms-grid-columns: 20% 1fr;
    grid-template-columns: 20% 1fr;
  }

  .feature-blog1-2 {
    width: 88%;
  }

  .fetaure-blog1_image-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .blog-title {
    font-size: 32px;
    line-height: 44px;
  }

  .feature-blog1_content-2 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .image-90 {
    width: 18px;
  }

  .text-block-33.px-40 {
    padding-right: 54px;
    padding-left: 54px;
  }

  .single-blog_post-body-2 {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
  }

  .feature-blog2-2 {
    width: 88%;
    margin-top: 40px;
    margin-left: 0px;
  }

  .single-blog_featue-blog-2 {
    width: 100%;
    margin-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading-normal-2 {
    line-height: 48px;
  }

  .heading-normal-2.px-40 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .image-88 {
    height: 180px;
  }

  .images-grid-2 {
    grid-column-gap: 0px;
    -ms-grid-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
    grid-template-columns: 0.25fr 1fr 1fr 1fr 0.25fr;
  }

  .images-grid-2.mbl-show {
    display: -ms-grid;
    display: grid;
    width: 130%;
    margin-bottom: 44px;
    margin-left: -15%;
    grid-column-gap: 24px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .images-grid-2.mb-hid {
    display: none;
  }

  .cs-icon---play {
    width: auto;
    height: auto;
  }

  .lc-all-wrappers {
    padding-right: 1%;
    padding-left: 1%;
  }

  .section-19 {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .blog-post-toc-content {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .blog-toc-item {
    max-width: 90vw;
  }

  .image-106 {
    position: relative;
    bottom: 0.5em;
    margin-bottom: 0em;
    margin-left: 0em;
    font-size: 1rem;
  }

  .wp-services-list-mobile {
    display: block;
  }

  .div-block-177 {
    display: -ms-grid;
    display: grid;
    margin-top: 2em;
    grid-auto-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .footer-list-mobile {
    list-style-type: none;
  }

  .footer-list-item {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    cursor: pointer;
  }

  .buttonbranding {
    border-radius: 5px;
  }

  .image-109 {
    position: relative;
  }

  .co-item-frequency {
    max-width: 90vw;
  }

  .support-mobile {
    position: relative;
    width: 101vw;
    border-radius: 0em;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(10%, #321f12),
      color-stop(50%, #1c123b)
    );
    background-image: linear-gradient(180deg, #321f12 10%, #1c123b 50%);
  }

  .heading-swipe {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .nav-logo-2 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .paragraph-default-4 {
    text-align: justify;
  }

  .om-heading {
    width: 150%;
  }

  .service-outline {
    display: inline-block;
    height: 2.7rem;
    margin-top: 0%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    line-height: 1.14em;
  }

  .grid-36 {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .co-checkout-checklist {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .co-checklist-wrapper {
    margin-left: 0em;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .co-item-grid._2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .div-block-181 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .co-item-check-wrapper.grid-alt {
    top: auto;
  }

  .co-item-checkmark-copy.grid-alt {
    top: auto;
  }

  .co-white {
    max-width: 90vw;
  }
}

#w-node-c8fb4101-d660-f32b-8621-96c2c3ca2ef2-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c8fb4101-d660-f32b-8621-96c2c3ca2f00-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c8fb4101-d660-f32b-8621-96c2c3ca2f0e-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_121cf400-1fab-2294-7f90-ec895fd96214-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_121cf400-1fab-2294-7f90-ec895fd96222-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_121cf400-1fab-2294-7f90-ec895fd96230-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_121cf400-1fab-2294-7f90-ec895fd96238-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_121cf400-1fab-2294-7f90-ec895fd9623d-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_121cf400-1fab-2294-7f90-ec895fd96240-a1efd709 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2caebc76-b444-a83c-fc1b-ad968225c144-51efd728 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c423b82f-6645-2556-19a4-eae3b0b452be-51efd728 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_44b130d2-e507-03e6-7094-74fd95ffc55c-51efd728 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_15e343ff-9676-a7ef-2f88-d6eb930058b4-51efd728 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_15e343ff-9676-a7ef-2f88-d6eb930058b9-51efd728 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_15e343ff-9676-a7ef-2f88-d6eb930058bc-51efd728 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 767px) {
  #w-node-_4afac97e-2cfa-064d-c244-4692e3d86fd0-a1efd709 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_4afac97e-2cfa-064d-c244-4692e3d8704d-a1efd709 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_121cf400-1fab-2294-7f90-ec895fd961f5-a1efd709 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_121cf400-1fab-2294-7f90-ec895fd96276-a1efd709 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_2bbda309-f161-7dd2-fd29-0599e4a0e1a6-51efd728 {
    -ms-grid-column-align: start;
    justify-self: start;
  }

  #w-node-_2bbda309-f161-7dd2-fd29-0599e4a0e1e8-51efd728 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }
}

@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../images/') format('opentype'); */
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../images/') format('opentype'); */
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../fonts/Lab-Grotesque---Bold.otf') format('opentype'); */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../images/') format('opentype'); */
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../fonts/Lab-Grotesque---Black.otf') format('opentype'); */
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../fonts/Lab-Grotesque---Light.otf') format('opentype'); */
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../fonts/Lab-Grotesque---Italic.otf') format('opentype'); */
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../fonts/Lab-Grotesque---Regular.otf') format('opentype'); */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../images/') format('opentype'); */
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../fonts/Lab-Grotesque---Medium.otf') format('opentype'); */
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../images/') format('opentype'); */
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque";
  /* src: url('../fonts/Lab-Grotesque---Thin.otf') format('opentype'); */
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque Mono";
  /* src: url('../fonts/Lab-Grotesque-Mono---Regular.otf') format('opentype'); */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lab Grotesque Mono";
  /* src: url('../fonts/Lab-Grotesque-Mono---Bold.otf') format('opentype'); */
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.qty {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.qty label {
  flex: 1 0 100%;
}
.qty input {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 20px;
  text-align: center;
  border: 0;
  font-weight: 700;
}

/* .qty button {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  color: #444;
  font-size: 2rem;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #ccc;
} */

.qty button.qtyminus {
  margin-right: 0.3rem;
}
.qty button.qtyplus {
  margin-left: 0.3rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.popular-tag {
  display: block;
  width: 130px;
  height: 31px;
  padding: 4px 14px 3px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ffce0a;
  font-size: 15px;
  line-height: 134%;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-left: 10px;
}

.sm-amt {
  font-size: 16px;
  letter-spacing: 1px;
  margin-right: 10px;
  text-decoration: line-through;
  color: #666;
  margin-top: 30px;
}

.mgb2 {
  margin-bottom: 20px;
}

.pc-tab > input,
.pc-tab section > div {
  display: none;
}

#tab1:checked ~ section .tab1,
#tab2:checked ~ section .tab2,
#tab3:checked ~ section .tab3 {
  display: block;
}

#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3 {
  color: red;
}

.pc-tab {
  width: 100%;
}
.pc-tab ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pc-tab ul li label {
  float: left;
  padding: 15px 12px;
  width: 160px;
  text-align: center;
  border: 1px solid #ddd;
  background: #fff;
  color: #798184;
  font-weight: 500;
  cursor: pointer;
}
.pc-tab ul li label:hover {
  background: #f9ebe7;
  color: #ea552b;
}
.pc-tab ul li label:active {
  background: #ffffff;
}
.pc-tab ul li:not(:last-child) label {
  border-right-width: 0;
}
.pc-tab section {
  clear: both;
}

#tab1:checked ~ nav .tab1 label,
#tab2:checked ~ nav .tab2 label,
#tab3:checked ~ nav .tab3 label {
  background: #f9ebe7;
  color: #ea552b;
  position: relative;
}
#tab1:checked ~ nav .tab1 label:after,
#tab2:checked ~ nav .tab2 label:after,
#tab3:checked ~ nav .tab3 label:after {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  left: 0;
}

.tab1 label {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.tab2 label {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mgt2 {
  margin-top: 20px;
}
.mgt3 {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .qty button {
    width: 2.3rem;
    height: 2.3rem;
    color: #444;
    font-size: 1.5rem;
    background: #fff;
    border-radius: 100%;
    /* border: 1px solid #ccc; */
  }
  .qty input {
    width: 2rem;
    height: 2rem;
  }
  .pc-tab ul li label {
    width: 140px;
    margin-bottom: 22px;
  }
  .nav-checkout-wrapper {
    background: #000 !important;
  }
}

.fs-custom {
  /* font-size: 1.125rem !important; */
  font-family: "Inter", sans-serif;
}

.font__18 {
  font-size: 18px !important;
}
