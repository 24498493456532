.text--_fontBase--QdLsd {
  font-family: var(--inter-font-family, "Inter"), sans-serif;
}
.text--fontPos11--2LvXf {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: calc(0.005px + var(--text-tracking-pos, 0) * 11px);
}
.text--fontPos13--xW8hS {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: calc(-0.003px + var(--text-tracking-pos, 0) * 13px);
}
.overflow--momentumScroll--qtsu7 {
  -webkit-overflow-scrolling: touch;
}
.overflow--overflowYAuto--nfK38 {
  overflow-y: auto;
}
.raw_components--_iconButton---ybo6 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
  grid-column-end: span 4;
}
.raw_components--iconButtonEnabled--WmVk5:hover {
  background-color: var(--color-bg-hover, rgba(0, 0, 0, 0.06));
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  border-radius: 3px;
}
.raw_components--transparentIconButtonEnabled--l2bm3:hover {
  background-color: transparent;
}
.raw_components--iconButtonDisabled--SoTjK {
  fill: var(--color-icon-disabled);
  color: var(--color-icon-disabled);
}
.thread_comment_composer--inputSectionWrapper--Bxyab {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  background-color: var(--color-bg-secondary, rgba(0, 0, 0, 0.06));
  border-radius: 6px;
  margin: 8px 16px 16px 8px;
  min-height: 96px;
  max-height: 100%;
  outline: 0px solid transparent;
  transition: min-height 0.1s, outline 0.2 ease-in-out;
}
.thread_comment_composer--inputSectionWrapper--Bxyab.thread_comment_composer--newComment--eYLj7 {
  background-color: var(--color-bg, white);
  box-shadow: var(--elevation-200-canvas);
  margin: 0;
}
.thread_comment_composer--inputSectionWrapper--Bxyab.thread_comment_composer--composerEmpty--VgMY78 {
  min-height: 40px;
  flex-direction: row;
  align-items: center;
}
.thread_comment_composer--editableTypeahead--9R6Yd {
  flex-grow: 1;
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 8px 16px;
  transition: padding-bottom 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.1s;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:first-child {
  fill: var(--color-bg-brand, #0d99ff) !important;
}
.thread_comment_composer--submitUpArrowIcon--e8uT9 path:not(:first-child) {
  fill: var(--color-icon-onbrand, white) !important;
}
.thread_comment_composer--submitUpArrowIconDisabled--iPBY3 path:first-child {
  fill: var(--color-bg-disabled, rgba(0, 0, 0, 0.3)) !important;
}
.thread_comment_composer--submitUpArrowIconDisabled--iPBY3
  path:not(:first-child) {
  fill: var(--color-icon-ondisabled, #fff) !important;
}
.thread_comment_composer--actions--7LRnN {
  height: 40px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  border-top: 1px solid var(--color-border, rgba(0, 0, 0, 0.05));
}
.thread_comment_composer--composerEmpty--VgMY7
  .thread_comment_composer--actions--7LRnN {
  border: unset;
}
.editable_typeahead--wrapperBase--rwdvD {
  cursor: text;
}
.public-DraftEditorPlaceholder-root {
  color: var(--color-text-tertiary, #b3b3b3);
  position: absolute;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}
.public-DraftEditorPlaceholder-root {
  pointer-events: none;
}
.wiggle--wiggleContainer--oGy1O {
  height: 100%;
  width: 100%;
}
.viewport_draggable--container---XH8- {
  position: fixed;
  display: flex;
  flex: 0;
  overflow: hidden;
}
.new_comment_container--translatedThreadContainer--2xkOH {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: auto;
}
.new_comment_container--threadContainer--XIsVX {
  position: absolute;
  top: 13%;
  left: 35%;
  width: 320px;
  border-radius: 3px;
  box-shadow: var(
    --elevation-200-canvas,
    0px 2px 14px rgba(0, 0, 0, 0.15),
    0 0 0 0.5px rgba(0, 0, 0, 0.2)
  );
  animation-name: new_comment_container--comments-fade-in--o49tI;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  cursor: default;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  pointer-events: auto;
}
.new_comment_container--newCommentContainerNext--bCfo7 {
  width: 256px;
  border-radius: 6px;
  box-shadow: var(
    --elevation-200-canvas,
    0px 2px 7px rgba(0, 0, 0, 0.15),
    0px 5px 17px rgba(0, 0, 0, 0.2)
  );
  outline: 0px solid transparent;
  transition: outline 0.2s ease-in-out;
}
div,
span,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: inherit;
  outline: none;
  letter-spacing: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: inherit;
}
button {
  background-color: var(--color-bg, #fff);
}
/* svg {
  display: block;
} */
.svg-container path:not([fill="none"]) {
  fill: inherit;
}
.fullscreen_view--fullscreenBody--w5jxN *::selection {
  background-color: var(--color-texthighlight, #0d99ff);
}
/*! CSS Used keyframes */
@keyframes new_comment_container--comments-fade-in--o49tI {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.browbtn {
  display: table;
  text-align: center;
  margin: 0 auto;
}

* {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  font-family: inherit;
  font-size: inherit;
}
a {
  outline: none;
  -webkit-text-decoration: none;
  text-decoration: none;
}
a,
a:active,
a:hover,
a:visited {
  color: inherit;
}
.icon path {
  stroke-width: 1.2;
  vector-effect: non-scaling-stroke;
}
.icon:not([data-style]) path {
  stroke: currentcolor;
}
.icon-button {
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 28px;
}
.icon-button:disabled {
  cursor: not-allowed;
}
.icon-button.icon-button--variant-white {
  background: #fff;
  color: #000;
}
.icon-button.icon-button--variant-white:hover:not(:disabled) {
  background: #f5f6f7;
}
.icon-button.icon-button--variant-white:active:not(:disabled) {
  background: #e9ebec;
}
.icon-button.icon-button--variant-white:disabled {
  color: #979a9b;
}
.ql-toolbar {
  position: relative;
}
.ql-emoji {
  color: #979a9b;
}
.ql-emoji:hover {
  color: #636567;
}
.canvas-comment-input {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.canvas-comment-input .ql-snow .ql-editor {
  color: #636567;
  font-family: AvenirNext LT Pro, arial, helvetica, sans-serif;
  font-size: 13px;
  padding: 11px 16px;
}
.canvas-comment-input .ql-snow .ql-editor p {
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
.canvas-comment-input .ql-toolbar.ql-snow .ql-emoji {
  color: #979a9b;
}
.canvas-comment-input .ql-toolbar.ql-snow .ql-emoji:hover {
  color: #636567;
}
.canvas-comment-input .quill {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  width: 100%;
}
.canvas-comment-input .quill .ql-container.ql-snow {
  border: none;
  width: 100%;
}
.canvas-comment-input .quill .ql-container.ql-snow .ql-editor {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  overflow: auto;
}
.canvas-comment-input .quill .ql-container.ql-snow .ql-editor.ql-blank:before {
  color: #979a9b;
  content: attr(data-placeholder);
  font-style: normal;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.canvas-comment-input .quill .ql-toolbar.ql-snow {
  border: none;
  padding: 0;
}
.canvas-comment-input .quill .ql-toolbar.ql-snow svg {
  color: #000;
  width: 20px;
}
.canvas-comment-input .quill .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}
.canvas-comment-input .quill .ql-emoji {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-pack: distribute;
  align-items: center;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 28px;
  justify-content: space-around;
  width: 28px;
}
.canvas-comment-input .quill .ql-emoji:hover {
  background: #f5f6f7;
}
.canvas-comment-input .quill .ql-emoji:active {
  background: #e9ebec;
}
.canvas-comment-buttons {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.canvas-comment-buttons button {
  margin-right: 8px;
}
.canvas-comment-buttons__left-buttons,
.canvas-comment-buttons__right-buttons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 8px;
}
.markup-quill-editor .ql-editor {
  overflow: hidden !important;
}
.markup-quill-editor .ql-editor > p:first-of-type {
  min-width: 1px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.canvas-comment-thread__reply-form {
  margin-top: 14px;
}
.canvas-comment-thread__reply-form .canvas-comment-input {
  background: #f5f6f7;
  border-radius: 6px;
}
.canvas-comment-thread__reply-form .form-contents {
  background: #fff;
  border: 1px solid #e9ebec;
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  position: relative;
  width: 380px;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-snow
  .ql-editor {
  color: #636567;
  font-family: AvenirNext LT Pro, arial, helvetica, sans-serif;
  font-size: 13px;
  padding: 11px 16px;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-snow
  .ql-editor
  p {
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-toolbar.ql-snow
  .ql-emoji {
  color: #979a9b;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-toolbar.ql-snow
  .ql-emoji:hover {
  color: #636567;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-toolbar.ql-snow
  + .ql-container.ql-snow {
  border: 1px solid #e9ebec;
  border-left: none;
  border-right: none;
}
.canvas-comment-thread__reply-form .form-contents {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-toolbar.ql-snow
  + .ql-container.ql-snow {
  border: none;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-toolbar.ql-snow
  .ql-emoji,
.canvas-comment-thread__reply-form .icon-button {
  background: transparent;
}
.canvas-comment-thread__reply-form
  .form-contents
  .markup-quill-editor
  .ql-toolbar.ql-snow
  .ql-emoji:hover,
.canvas-comment-thread__reply-form .icon-button:hover {
  background: #fff;
}
.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0;
  position: relative;
}
.ql-clipboard {
  height: 1px;
  left: -100000px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-editor {
  word-wrap: break-word;
  box-sizing: border-box;
  height: 100%;
  line-height: 1.42;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  -o-tab-size: 4;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}
.ql-editor > * {
  cursor: text;
}
.ql-editor p {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}
.ql-editor.ql-blank:before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.ql-snow.ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}
.ql-snow.ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}
.ql-snow.ql-toolbar button svg {
  float: left;
  height: 100%;
}
.ql-snow.ql-toolbar button:active:hover {
  outline: none;
}
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: #06c;
}
@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active) {
    color: #444;
  }
}
.ql-snow,
.ql-snow * {
  box-sizing: border-box;
}
.ql-snow .ql-hidden {
  display: none;
}
.ql-snow .ql-tooltip {
  position: absolute;
  transform: translateY(10px);
}
.ql-snow .ql-tooltip a {
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}
.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 8px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}
.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: nowrap;
}
.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px;
}
.ql-snow .ql-tooltip input[type="text"] {
  border: 1px solid #ccc;
  display: none;
  font-size: 13px;
  height: 26px;
  margin: 0;
  padding: 3px 5px;
  width: 170px;
}
.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.ql-snow .ql-tooltip a.ql-action:after {
  border-right: 1px solid #ccc;
  content: "Edit";
  margin-left: 16px;
  padding-right: 8px;
}
.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}
.ql-snow .ql-tooltip a {
  line-height: 26px;
}
.ql-snow a {
  color: #06c;
}
.ql-container.ql-snow {
  border: 1px solid #ccc;
}
.thread-list .markup-quill-editor .ql-snow .ql-editor {
  color: #636567;
  font-family: AvenirNext LT Pro, arial, helvetica, sans-serif;
  font-size: 13px;
  padding: 11px 16px;
}
.thread-list .markup-quill-editor .ql-snow .ql-editor p {
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
.thread-list .markup-quill-editor .ql-toolbar.ql-snow .ql-emoji {
  color: #979a9b;
}
.thread-list .markup-quill-editor .ql-toolbar.ql-snow .ql-emoji:hover {
  color: #636567;
}
.thread-list .markup-quill-editor .ql-toolbar.ql-snow {
  border-color: #e9ebec;
}
.thread-list .markup-quill-editor .ql-container.ql-snow {
  border-color: #e9ebec;
}
.thread-list
  .markup-quill-editor
  .ql-container.ql-snow
  .ql-tooltip
  .ql-preview {
  max-width: 150px;
}
.thread-list-item-reply-container {
  padding: 2px 20px;
}
.thread-list-item-reply-container.thread-list-item-reply-container__dot
  .ql-container {
  position: relative;
}
.thread-list-item-reply-container.thread-list-item-reply-container__dot
  .ql-container:after {
  background-color: #000;
  border-radius: 50%;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 8px;
}
/*! CSS Used from: Embedded ; media=all */
@media all {
  .a {
    fill: currentColor;
  }
}
/*! CSS Used from: Embedded ; media=all */
@media all {
  .a {
    fill: currentColor;
  }
}

div {
  display: block;
}
img {
  height: auto;
  vertical-align: middle;
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
div {
  display: block;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
* {
  box-sizing: border-box;
  word-wrap: break-word;
}
.Icon-color svg {
  fill: currentColor;
}
.Icon-color--gray600 {
  color: #4f566b;
}
.va1 {
  vertical-align: text-bottom;
}
.va2 {
  vertical-align: middle;
  margin-left: 5px;
}
.filebox {
  background: #fafafa;
  border-radius: 6px;
  padding: 20px 20px;
  width: max-content;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#mainn div {
  width: max-content;
  height: auto;
  margin-right: 6px;
  margin-top: 6px;
  margin-left: 7px;
}
div {
  margin: 0;
  outline: 0;
  padding: 0;
}
.tsform2 {
  transform: translateY(1.1px);
  margin-left: 15px;
}
:before {
  box-sizing: initial !important;
}
@media screen and (max-width: 767px) {
  img {
    max-width: 100%;
  }
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.flec {
  display: flex;
  align-items: center;
}

/* Custom radio buttons */
.radio {
  margin-top: 15px;
}
input[type="radio"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 23px;
  margin-right: 15px;
  font-size: 14px;
}

input[type="radio"] {
  display: none !important;
  display: inline;
}
input[type="radio"]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ea552b;
}

.txtar {
  width: 100%;
  height: 80px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  background-color: #fafafa;
  font-size: 16px;
  resize: none;
  margin-top: 20px;
}

.txtar:focus-visible {
  border: 0;
  outline: 0;
}

div {
  display: block;
}
label {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
}
label {
  cursor: pointer;
}
img {
  height: auto;
  vertical-align: middle;
  border: 0;
}
a {
  color: #3781b8;
  text-decoration: none;
}
a:hover,
a:active {
  outline: 0;
}
div {
  display: block;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
img {
  border: 0;
}
* {
  box-sizing: border-box;
  word-wrap: break-word;
}
a {
  color: #ea552b;
  font-weight: 500;
}
a,
a:hover {
  text-decoration: none;
}
a:hover {
  color: #1a1f36;
}
.UnstyledLink {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.UnstyledLink:hover {
  color: inherit;
}
p {
  margin: 0;
  line-height: 1.6;
}
a:hover {
  color: #2a2f45;
}
.atd-Fieldset-row {
  display: flex;
  padding: 10px 20px;
}
.atd-Fieldset-row:last-child {
  padding-bottom: 20px;
}
.atd-Fieldset-label {
  align-self: flex-start;
  flex: 18% 0 0;
  padding: 3px 5px 0 0;
  color: #2a2f45;
  line-height: 1.6;
  text-align: left;
}
.Text-color--blue {
  color: #ea552b;
}
.InlineLink:disabled {
  pointer-events: none;
}
.InlineLink:active,
.InlineLink:hover {
  color: #1a1f36;
}
.InlineLink:focus:not([data-input-method="mouse"]):not(
    [data-input-method="touch"]
  ) {
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 0 0 4px rgba(58, 151, 212, 0.28),
    0 0 1px 1px rgba(7, 89, 150, 0.36);
}
.mgt1 {
  margin-top: 10px;
}
.acc-avt {
  border: 2px solid #f4f5f7;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-left: 8px;
  position: absolute;
}
div {
  margin: 0;
  outline: 0;
  padding: 0;
}
.mglmgt {
  margin-left: 70px;
  margin-top: 6px;
}
:before {
  box-sizing: initial !important;
}
@media screen and (max-width: 767px) {
  img {
    max-width: 100%;
  }
}
.atd-Fieldset-row {
  padding: 0;
  margin-top: 15px;
}

#annotation {
  top: 0 !important;
  bottom: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.mfp-container {
  padding: 0 !important;
}

.fnt12 {
  font-size: 12px !important;
}

.bggrey {
  background: #fafafa;
}

@media screen and (max-width: 1440px) {
  .leftcol {
    width: 22%;
  }
  .middlecol {
    width: 68%;
  }
  .rightimg {
    max-width: 100px;
  }
}
.reply-comment {
  x: 241px !important;
  y: 48px !important;
  width: 350px !important;
  margin: -90px 0px 0px 67px !important;
  cursor: default;
  z-index: 9999;
}
.reply-comment-move-top-corner {
  left: 16.3%;
  top: 2.5%;
  position: absolute;
  width: 350px;
  cursor: default;
  z-index: 9999;
}

.attachment--thumbnailImage--Jx4faT {
  display: block;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  background-color: #ffffff;
  border-radius: 8px;
  max-width: 100% !important;
}
.attachment--thumbnailImage--Jx4faT:hover {
  background-color: #f5f5f5;
  opacity: 0.9;
}
.attachment--thumbnailImage--Jx4faz {
  display: block;
  width: 94px;
  height: 94px;
  object-fit: cover;
  background-color: #ffffff;
  border-radius: 8px;
  /* max-width: 94px !important; */
}

.attachment--darkOverlay--l3K9x {
  background-color: rgba(0, 0, 0, 0.1);
}
.zoom-dropdown-reply-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 250px;
  padding: 10px 0 10px 0;
  text-align: start;
}
/* .draggable_modal--positionedContainer--HdvZU {
  position: fixed;
  z-index: 7;
} */

/* .draggable_modal--frame--keEDC {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg, #fff);
  position: relative;
  border-radius: 2px;
} */

.modal--modalShadow--d-rJf {
  box-shadow: var(
    --elevation-500-modal-window,
    0px 2px 14px rgba(0, 0, 0, 0.15),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.2)
  );
}

.raw_components--_iconButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  flex: 0 0 32px;
  line-height: 32px;
  fill: var(--color-icon, #333333);
  color: var(--color-icon, #333333);
  box-sizing: border-box;
  border: 1px solid transparent;
  position: relative;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: transparent;
  grid-column-end: span 4;
}
.raw_components--_iconButtons:hover {
  background: #ccc7c7;
}
