.comment_cluster_elements--avatarContainer {
  display: flex;
  min-width: 26px;
  visibility: visible;
}
.comment_cluster_elements--avatar {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid var(--color-bg, #ffffff);
  background: linear-gradient(136.68deg, #0000000d 11.37%, #ffffff0a 82.26%),
    #f0f0f0;
}
.comment_cluster_elements--fallbackAvatar {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  border: 1px solid var(--color-bg, #ffffff);
}
.comment_cluster_elements--avatarsRoot {
  display: flex;
  transition: opacity var(--expandAnimationTime) ease-in-out,
    visibility var(--expandAnimationTime) ease-in-out;
}
.comment_cluster_elements--avatarsContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 26px;
  min-height: 26px;
}
.comment_cluster_elements--avatarsPrimaryContainer {
  display: flex;
  /* flex-direction: row-reverse; */
}
.comment_cluster_elements--avatarsPrimaryContainer
  > .comment_cluster_elements--avatarContainer {
  margin-left: -6px;
  margin-right: 0;
  transition: margin-left var(--expandAnimationTime) ease-in-out;
  -webkit-transition: margin-left var(--expandAnimationTime) ease-in-out;
  z-index: 0;
}
.comment_cluster_elements--avatarsPrimaryContainer
  > .comment_cluster_elements--avatarContainer:last-child {
  margin-left: 0;
}
.comment_cluster_elements--avatarsOverflowContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-left: -6px;
  transition: margin-left var(--expandAnimationTime) ease-in-out;
  -webkit-transition: margin-left var(--expandAnimationTime) ease-in-out;
  min-width: 4px;
  z-index: 0;
}
.comment_cluster_elements--avatarsSecondaryContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-left: -26px;
  max-width: 26px;
  overflow: hidden;
  border-radius: 16px 0 0 16px;
  transition: max-width var(--expandAnimationTime) ease-in-out;
  -webkit-transition: max-width var(--expandAnimationTime) ease-in-out;
}
.comment_cluster_elements--avatarOverflowCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--color-bg-secondary, #b2b2b2);
  border: 1px solid var(--color-bg, white);
  color: var(--color-text, white);
  font-size: 13px;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  transition: --num var(--expandAnimationTime);
  -webkit-transition: --num var(--expandAnimationTime);
  counter-reset: num var(--num);
  --num: var(--overflowCount);
  opacity: var(--num);
  z-index: 0;
}
.comment_cluster_elements--avatarOverflowCounter:after {
  content: counter(num);
}
.comment_cluster_elements--avatarPinAnchor {
  will-change: transform;
  transform-origin: bottom left;
  user-select: none;
  -webkit-user-select: none;
}
@media (pointer: coarse) {
  .comment_cluster_elements--avatarPinAnchor {
    --scale: 1.15;
  }
}
.comment_cluster_elements--avatarPinAnchor:not(
    .comment_cluster_elements--selected
  )
  .comment_cluster_elements--avatarPinContainer:hover
  .comment_cluster_elements--pinnedThreadToFileIndicator {
  transform: scale(0);
  opacity: 0;
  transition: unset;
  pointer-events: none;
}
.comment_cluster_elements--avatarPinContainer {
  display: flex;
  left: 0;
  bottom: 0;
  min-width: 26px;
  align-self: flex-start;
  justify-self: flex-start;
  flex-direction: row;
  max-width: 250px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-text, #333333);
  background-color: var(--color-bg, white);
  border-radius: 16px 16px 16px 0;
  box-shadow: var(
    --elevation-200-canvas,
    0px 3px 6px rgba(0, 0, 0, 0.3),
    0 0 1px rgba(0, 0, 0, 0.05)
  );
  font-family: Inter, sans-serif;
  transform-origin: bottom left;
  transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out,
    padding var(--expandAnimationTime) ease-in-out,
    min-width var(--expandAnimationTime) ease-in-out;
  -webkit-transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out,
    padding var(--expandAnimationTime) ease-in-out,
    min-width var(--expandAnimationTime) ease-in-out;
}
.comment_cluster_elements--pinnedThreadToFileIndicator {
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 1;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: var(--elevation-200-canvas);
  display: flex;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}
.comment_cluster_elements--avatarPinPreview {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 11px;
  transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out;
  -webkit-transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out;
  cursor: default;
  margin-left: 10px;
}
.comment_cluster_elements--avatarPinPreviewHeader {
  display: flex;
  font-weight: 400;
}
.comment_cluster_elements--avatarPinPreviewAuthorName {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 15px;
}
.comment_cluster_elements--avatarPinPreviewTimestamp {
  margin-left: 4px;
  flex-shrink: 0;
  color: var(--color-text-secondary, rgba(0, 0, 0, 0.3));
}
.comment_cluster_elements--avatarPinPreviewText {
  display: flex;
  overflow: hidden;
  min-width: 180px;
  max-width: 180px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: start;
}
.comment_cluster_elements--avatarPinPreviewReplySummary {
  display: flex;
  flex-grow: 1;
  color: var(--color-text-secondary, rgba(0, 0, 0, 0.3));
  -webkit-line-clamp: 1;
  max-height: 2.5em;
}
@media (hover: hover) {
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    > .comment_cluster_elements--avatarPinContainer {
    pointer-events: all;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsSecondaryContainer {
    max-width: 160px;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    :not(.comment_cluster_elements--multipleAvatars--3RDVv)
    .comment_cluster_elements--avatarPinPreview {
    margin-left: 8px;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    ):not(.comment_cluster_elements--multipleAvatars--3RDVv)
    .comment_cluster_elements--avatarsPrimaryContainer
    > .comment_cluster_elements--avatarContainer:last-child {
    margin-left: 0 !important;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsPrimaryContainer
    > .comment_cluster_elements--avatarContainer:last-child {
    margin-left: -2px !important;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsPrimaryContainer
    > .comment_cluster_elements--avatarContainer {
    margin-left: 5px !important;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarsOverflowContainer {
    margin-left: 5px !important;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarOverflowCounter {
    --num: 0;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    ) {
    z-index: 1;
    transition: z-index var(--expandAnimationTime) linear;
    -webkit-transition: z-index var(--expandAnimationTime) linear;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarPinContainer {
    min-width: 240px;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarPinContainer:not(
      .comment_cluster_elements--multipleAvatars--3RDVv
    ):not(.comment_cluster_elements--feedPostPinContainer--Kq02d) {
    padding: 10px;
  }
  .comment_cluster_elements--avatarsHoverContainer:hover:not(
      .comment_cluster_elements--hoverDisabled--j76j7
    )
    .comment_cluster_elements--avatarPinPreview {
    max-width: 240px;
    max-height: 156px;
    visibility: visible;
  }
}

.comment_cluster_elements--avatarPinContainer---daUJA {
  display: flex;
  left: 100px;
  bottom: 300px;
  min-width: fit-content;
  align-self: flex-start;
  justify-self: flex-start;
  flex-direction: row;
  max-width: auto;
  box-sizing: border-box;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-text, #333333);
  border-radius: 16px 16px 16px 0;
  font-family: var(--inter-font-family, "Inter"), sans-serif;
  transform-origin: bottom left;
  transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out,
    padding var(--expandAnimationTime) ease-in-out,
    min-width var(--expandAnimationTime) ease-in-out;
  -webkit-transition: max-width var(--expandAnimationTime) ease-in-out,
    max-height var(--expandAnimationTime) ease-in-out,
    padding var(--expandAnimationTime) ease-in-out,
    min-width var(--expandAnimationTime) ease-in-out;
}
