.pop-title-new-version {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
  color: #1a1f36;
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.atd-Fieldset-row {
  display: flex;
  padding: 10px 20px;
}
.atd-Fieldset-row:last-child {
  padding-bottom: 20px;
}
.atd-Fieldset-row {
  padding: 0;
  margin-top: 15px;
}

/* Custom radio buttons */
.radio {
  margin-top: 15px;
}
input[type="radio"] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 23px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 500;
}
input[type="radio"] + label:before {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  margin-right: 14px;
  position: absolute;
  top: 1px;
  left: 0;
  border: 1px solid #ea552b;
  background-color: #fff;
  border-radius: 50%;
  font-weight: 500;
}
label.no-annotation:before {
  border: 1px solid #ccc !important;
}
label.with-annotation:before {
  border: 1px solid #ea552b !important;
}
input[type="radio"] {
  display: none !important;
  display: inline;
}
input[type="radio"]:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 11px;
  height: 12px;
  border-radius: 50%;
  background: #ea552b;
}

.txtar {
  width: 100%;
  height: 80px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  background-color: #fafafa;
  font-size: 16px;
  resize: none;
  margin-top: 20px;
}

.txtar:focus-visible {
  border: 0;
  outline: 0;
}

.circle {
  display: inline-block;
  background-color: #fff;
  border: 4px solid #ea552b;
  margin: 10px;
  border-radius: 50%;
}
.circle {
  width: 63px;
  height: 63px;
}

.circle-inner {
  color: #ea552b;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  height: 52px;
  width: 52px;
  font-size: 22px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
}
.bs-Fieldset-field {
  display: flex;
}
.version-review {
  margin-top: 5%;
  margin-left: 6px;
  font-size: 16px;
}

.ormsg {
  color: #ea552b;
  padding: 10px;
  background: #fff5f5;
  margin: 0 auto;
  border-radius: 6px;
  text-align: center;
  width: 350px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 450;
  cursor: pointer;
}

.timeline:before {
  background: #ccd1d9;
  content: "";
  height: 41px;
  left: 12.3%;
  margin-left: -4px;
  margin-top: 13%;
  position: absolute;
  width: 2px;
  z-index: 1;
}

.circle {
  border: 3px solid #ea552b;
  height: 58px;
  width: 58px;
}

.timeline:before {
  height: 40px;
}

.version-review {
  margin-top: 0;
}

.bs-Fieldset-field {
  align-items: center;
}

.disabled-finish-uploading {
  cursor: not-allowed !important;
  background-color: rgb(123, 164, 194) !important;
}
