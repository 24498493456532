.pop-title-new-version {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 24px;
  font-weight: 600;
  font-size: 16px;
  color: #1a1f36;
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.fileboxs {
  background: #fafafa;
  border-radius: 6px;
  padding: 20px 20px;
  width: max-content;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flec {
  display: flex;
  align-items: center;
}
#fileUploadIconInput-newCommentComposers-browse {
  position: absolute;
  left: -85px;
  opacity: 0;
}

.file-name-upload {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
