/* .Btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
  text-align: center;
  text-shadow: none;
  border-radius: 16px;
  background: white;
  font-size: 14px;
  font-weight: 400;
  color: #262e39;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 1.14285714;
  border-radius: 4px;
}

.Btn .glyphicons.prepends-text {
  margin-right: 0;
}

.Btn .glyphicons {
  width: 30px;
  text-align: center;
  margin-left: -12px;
  float: left;
  line-height: 1;
}

.Btn--small .glyphicons {
  width: 26px;
}

.Btn--small {
  line-height: 1.23076923;
  font-size: 13px;
  padding: 4px 12px;
} */

.Button {
  position: relative;
  max-height: 36px;
}
.Button--left-sm {
  margin-left: 15px;
}
.Buttton-Left-md {
  margin-left: 20px;
}
.Button--left-lg {
  margin-left: 30px;
}
.Button--icon {
  padding-top: 4px;
}
.Button--icon.btn-small,
.Button--icon.btn-mini {
  padding: 0;
  outline: none;
}
.Button--wide {
  padding: 6px 24px;
}
.Button--fullWidth {
  display: block;
  width: 100%;
}
.Button--withDropdownArrow::after {
  content: "";
  display: inline-block;
  margin-left: 7px;
  top: 0px;
  /* background: url(../src/images/down-arrow.png) no-repeat; */
  /* border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-top: 4px solid currentColor; */
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='3' y='5' width='10' height='6'%3E%3Cpath fillRule='evenodd' clip-rule='evenodd' d='M3.52864 5.52858C3.78899 5.26823 4.2111 5.26823 4.47145 5.52858L8.00004 9.05717L11.5286 5.52858C11.789 5.26823 12.2111 5.26823 12.4714 5.52858C12.7318 5.78892 12.7318 6.21103 12.4714 6.47138L8.47145 10.4714C8.2111 10.7317 7.78899 10.7317 7.52864 10.4714L3.52864 6.47138C3.26829 6.21103 3.26829 5.78892 3.52864 5.52858Z' fill='%2342526E'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Crect width='16' height='16' fill='%23061938'/%3E%3C/g%3E%3C/svg%3E%0A")
    no-repeat;
  vertical-align: middle;
  position: relative;
}
.Button--withNewIcon .Button__icon svg {
  width: 16px;
  height: 16px;
}
.Button--withNewIcon.btn-small .Button__icon svg {
  width: 14px;
  height: 14px;
}
.Button--withNewIcon.btn-mini .Button__icon svg {
  width: 12px;
  height: 12px;
}
.Button--withNewIcon .Button__icon svg.svg-inline--fa {
  width: 14px;
  height: 14px;
}
.Button__icon {
  display: flex;
  /* position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30px;
  height: 25px;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%); */
}
.Button__icon + .Button__text {
  margin-left: 6px;
  /* margin-left: 16px; */
}
.Button__icon svg {
  width: inherit;
  height: inherit;
}
.Button__iconLoader {
  margin-right: 4px;
}
/* .Button.btn-small .Button__icon {
  width: 24px !important;
  height: 24px !important;
} */
.Button.btn-small .Button__icon + .Button__text {
  margin-left: 5px;
}
.Button.btn-mini .Button__icon {
  width: 20px;
  height: 20px;
}
.Button.btn-mini .Button__icon + .Button__text {
  margin-left: 16px;
}
.Button.btn-transparent {
  background: none;
  border: 0 none;
  box-shadow: none;
}
/* .Button.btn-transparent:hover {
  background-color: rgba(0, 0, 0, 0.05);
} */
.Button.btn-link[disabled],
.Button.btn-link[disabled]:focus:active,
.Button.btn-link[disabled]:focus:hover,
.Button.btn-link[disabled]:active:hover {
  color: var(--primary-color);
  text-decoration: none;
  border: 0 none;
}
.Button.Button--iconOnly {
  width: 33px;
  height: 36px;
}
.Button.Button--iconOnly.Button--withDropdownArrow {
  width: 42px;
}
.Button.Button--iconOnly.Button--withDropdownArrow::after {
  margin-left: 16px;
}
.Button.Button--iconOnly.Button--withDropdownArrow .Button__icon {
  padding-right: 12px;
  box-sizing: border-box;
}
.Button.Button--iconOnly .Button__icon {
  width: 40px;
  /* height: 100%; */
}
.Button--withFlexLayout,
.Button--withIcon {
  display: flex;
  align-items: center;
}
.Button--iconOnly {
  display: inline-block;
}
.Button--withIcon .Button__icon {
  position: relative;
  top: auto;
  bottom: auto;
  transform: none;
  width: auto;
}
.Button--withIcon .Button__icon + .Button__text {
  margin-left: 5px;
}

.wizard-cancel-button {
  margin-left: auto;
  margin-right: 11px;
}

.btn-link {
  transition: all 200ms ease-out;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  text-decoration: none !important;
}

/* Updated 7/8/2021 */

/* .Button.btn-transparent:hover,
.btn:focus:not(:hover):not(.btn-link) {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px !important;
  background-color: rgb(255, 255, 255) !important;
} */
.Button.btn-transparent {
  height: 33px !important;
  font-weight: 500 !important;
}

.Button.Button--iconOnly.Button--withDropdownArrow {
  width: 50px !important;
}

.btn-small > svg {
  width: 14px;
}

@media (max-width: 600px) {
  .Button.btn-transparent {
    width: max-content;
  }
  .btn-small {
    width: max-content;
  }
}

.Btn {
  display: inline-block;
  padding: 7px 12px;
  margin-bottom: 0;
  vertical-align: middle;
  border: 0;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
  /* border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05); */
  text-align: center;
  text-shadow: none;
  border-radius: 4px;
  background: white;
  font-size: 14px;
  font-weight: 500;
  color: #262e39;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 1.14285714;
}
.Btn:hover,
.Btn:focus,
.Btn:active,
.Btn.active {
  outline: none;
  text-decoration: none;
}
.Btn:hover {
  /* background: #f1f1f1; */
  color: #262e39;
}
.Btn:focus:not(:hover) {
  box-shadow: 0 0 0 3px rgba(20, 99, 255, 0.5);
}
/* .Btn.active,
.Btn:active,
.Btn.active:hover,
.Btn:active:hover {
  background-color: #f1f1f1;
} */
.Btn:focus:hover {
  outline: none;
}
.Btn[disabled],
.Btn.disabled {
  background: white;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-image: none !important;
  text-shadow: none !important;
}
.Btn[disabled]:focus,
.Btn.disabled:focus,
.Btn[disabled]:focus:active,
.Btn.disabled:focus:active,
.Btn[disabled]:active:hover,
.Btn.disabled:active:hover,
.Btn[disabled]:focus:hover,
.Btn.disabled:focus:hover,
.Btn[disabled]:active,
.Btn.disabled:active,
.Btn[disabled].active,
.Btn.disabled.active,
.Btn[disabled]:hover,
.Btn.disabled:hover {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  background-image: none !important;
  text-shadow: none !important;
}
.Btn .glyphicons {
  width: 30px;
  text-align: center;
  margin-left: -12px;
  float: left;
  line-height: 1;
}
.Btn .glyphicons:before {
  text-align: center;
  display: block;
  color: inherit;
}
.Btn .glyphicons.prepends-text {
  margin-right: 0;
}
.Btn.Btn--primary,
.Btn.Btn--danger {
  font-weight: 500;
}
.Btn--primary {
  background-color: #1463ff;
  border-color: rgb(16, 79, 204);
  border-radius: 50px;
  color: #ffffff;
  outline: 0;
  display: flex;
}
.Btn--primary .glyphicons:before {
  color: #ffffff;
}
.Btn--primary:hover,
.Btn--primary[disabled] {
  color: #ffffff;
  background-color: color-mix(in srgb, #000 10%, #1463ff);
}
.Btn--primary:focus {
  border-color: color-mix(in srgb, #000 10%, #1463ff);
  color: #ffffff;
}
.Btn--primary:focus:not(:hover) {
  box-shadow: 0 0 0 3px rgb(16, 79, 204, 0.5);
}
.Btn--primary:active,
.Btn--primary.active,
.Btn--primary:active:hover,
.Btn--primary.active:hover,
.Btn--primary:active:focus,
.Btn--primary.active:focus {
  background-color: color-mix(in srgb, #000 10%, #1463ff);
}
.Btn--danger,
.Btn--danger:focus,
.Btn--danger[disabled]:hover {
  background-color: white;
  border-color: #e2e2e2;
  color: #d0021b;
}
.Btn--danger:focus:not(:hover) {
  box-shadow: 0 0 0 3px rgba(208, 2, 27, 0.5);
  border-color: #bf0219;
}
.Btn--danger .glyphicons:before,
.Btn--danger svg {
  color: #d0021b;
}
.Btn--danger:hover {
  background-color: #d0021b;
  color: white;
  border-color: #a60216;
}
.Btn--danger:active,
.Btn--danger.active,
.Btn--danger:active:hover,
.Btn--danger.active:hover {
  background-color: #b10217;
  border-color: #a60216;
  color: white;
}
.Btn--transparent,
.Btn--transparent:focus,
.Btn--transparent[disabled],
.Btn--transparent[disabled]:hover {
  background: none;
  border: 1px solid transparent;
  box-shadow: none;
}
.Btn--transparent:hover {
  background-color: #f1f1f1;
}
.Btn--transparent:active,
.Btn--transparent:active:hover {
  border-color: transparent;
  background-color: #f1f1f1;
}
.Btn--withDropdownArrow::after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 4px solid currentColor;
  vertical-align: middle;
}
.Btn--withDropdownArrow.Btn--small::after {
  margin-top: -1px;
}
.Btn__text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.Btn__icon {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 38px;
  height: 28px;
  align-items: center;
  justify-content: center;
}
.Btn__icon + .Btn__text {
  margin-left: 24px;
}
.Btn__icon.iconEnd {
  left: auto;
  right: 0;
}
.Btn__icon.iconEnd + .Btn__text {
  margin: 0 24px 0 0;
}
.Btn__icon svg {
  width: 12px;
  height: 12px;
}
.Btn--mini {
  line-height: 1.09090909;
  font-size: 11px;
  padding: 4px 6px;
}
.Btn--mini .glyphicons {
  width: 22px;
  margin-left: -6px;
}
.Btn--mini .Btn__icon {
  width: 22px;
  height: 20px;
}
.Btn--mini .Btn__icon + .Btn__text {
  margin-left: 16px;
}
.Btn--mini .Btn__icon.iconEnd + .Btn__text {
  margin: 0 16px 0 0;
}
.Btn--mini .Btn__icon svg {
  width: 12px;
  height: 12px;
}
.Btn--small {
  line-height: 1.23076923;
  font-size: 13px;
  padding: 4px 12px;
}
.Btn--small .glyphicons {
  width: 26px;
}
.Btn--small .Btn__icon {
  width: 30px;
  height: 24px;
}
.Btn--small .Btn__icon + .Btn__text {
  margin-left: 18px;
}
.Btn--small .Btn__icon.iconEnd + .Btn__text {
  margin: 0 18px 0 0;
}
.Btn--small .Btn__icon svg {
  width: 14px;
  height: 14px;
}
.Btn--large {
  line-height: 1.25;
  font-size: 16px;
  padding: 11px 16px;
  border-radius: 22px;
}
.Btn--large .glyphicons {
  width: 44px;
  margin-left: -16px;
  top: 2px;
}
.Btn.Btn--icon {
  padding-left: 0;
  padding-right: 0;
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.Btn.Btn--icon.Btn--mini {
  width: 22px;
  height: 22px;
}
.Btn.Btn--icon.Btn--small {
  width: 26px;
  height: 26px;
  padding: 5px;
}
.Btn.Btn--icon.Btn--small svg {
  width: 12px;
  height: 12px;
}
.Btn.Btn--icon.Btn--large {
  width: 44px;
  height: 44px;
}
.Btn.Btn--icon.Btn--small .glyphicons,
.Btn.Btn--icon.Btn--mini .glyphicons,
.Btn.Btn--icon.Btn--large .glyphicons {
  width: auto;
}
.Btn.Btn--icon .glyphicons {
  top: 0;
  margin-left: 0;
  float: none;
  width: auto;
}
.Btn--iconOnly {
  width: 30px;
  height: 30px;
}
.Btn--iconOnly.Btn--withDropdownArrow {
  width: 62px;
}
.Btn--iconOnly.Btn--withDropdownArrow::after {
  margin-left: 24px;
}
.Btn--iconOnly.Btn--withDropdownArrow .Btn__icon {
  padding-right: 16px;
  box-sizing: border-box;
}
.Btn--iconOnly.Btn--withDropdownArrow.Btn--small {
  width: 50px;
}
.Btn--iconOnly.Btn--withDropdownArrow.Btn--small::after {
  margin-left: 18px;
}
.Btn--iconOnly.Btn--small {
  width: 26px;
  height: 26px;
}
.Btn--iconOnly.Btn--mini {
  width: 20px;
  height: 20px;
}
.Btn--iconOnly .Btn__icon {
  width: 100% !important;
  height: 100% !important;
}

.Button {
  position: relative;
  max-width: 100%;
}
.Button--wide {
  padding: 6px 24px;
}
.Button--fullWidth {
  display: block;
  width: 100%;
}
.Button.Button--withFlexLayout {
  display: flex;
  align-items: center;
}

/* Links and Group */
.Btn--link {
  border-color: transparent;
  cursor: pointer;
  color: #000;
  border-radius: 0;
  /* padding: 0; */
}
.Btn--link:hover,
.Btn--link:focus,
.Btn--link:focus:not(:hover),
.Btn--link:active,
.Btn--link:active:hover,
.Btn--link:active:focus,
.Btn--link.active,
.Btn--link.active:hover,
.Btn--link.active:focus {
  color: #3781b8;
  text-decoration: none;
  background-color: transparent;
  background: transparent;
  box-shadow: none;
  text-shadow: none;
  border-color: transparent;
}
.Btn--link[disabled],
.Btn--link[disabled]:focus:active,
.Btn--link[disabled]:focus:hover,
.Btn--link[disabled]:active:hover {
  color: var(--primary-color);
  text-decoration: none;
  border: 0 none;
}
.Btn--link,
.Btn--link:active,
.Btn--link.active,
.Btn--link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border-color: transparent;
}
.BtnGroup {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}
.BtnGroup > .Btn {
  position: relative;
  border-radius: 0;
}
.BtnGroup > .Btn.Btn--icon:not(:only-child):not(.dropdown-toggle):first-child {
  width: auto;
  padding-left: 9px;
  padding-right: 6px;
}
.BtnGroup > .Btn.Btn--icon:not(:only-child):not(.dropdown-toggle):last-child {
  width: auto;
  padding-left: 6px;
  padding-right: 9px;
}
.BtnGroup > .Btn--iconOnly:not(:only-child):first-child,
.BtnGroup > .Btn--iconOnly:not(:only-child):last-child {
  width: 36px;
}
.BtnGroup > .Btn--iconOnly:not(:only-child).Btn--small:first-child,
.BtnGroup > .Btn--iconOnly:not(:only-child).Btn--small:last-child {
  width: 30px;
}
.BtnGroup > .Btn:hover,
.BtnGroup > .Btn:focus,
.BtnGroup > .Btn:active {
  z-index: 2;
}
.BtnGroup > .Btn:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.BtnGroup > .Btn:last-child,
.BtnGroup > .dropdown-toggle {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.BtnGroup > .Btn + .Btn {
  margin-left: -1px;
}
.BtnGroup.open .Btn.dropdown-toggle {
  background-color: #f1f1f1;
}
.BtnGroup.open .Btn.dropdown-toggle:focus:not(:hover) {
  box-shadow: 0 0 0 3px rgba(20, 99, 255, 0.5);
}
.cancel-edit + .Btn {
  margin-left: 5px;
}
.Btn--block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.Btn--secondary {
  --btnBaseRadius: 16px;
  --btnMiniSize: 22px;
  --btnSmallSize: 26px;
  --btnRegularSize: 30px;
  --btnLargeSize: 44px;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
  text-shadow: none;
  border-radius: 16px;
  border-radius: var(--btnBaseRadius);
  background: white;
  font-size: 14px;
  font-weight: 400;
  color: #262e39;
  color: var(--textColor);
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 16px;
  transition: all 0.2s ease-in-out;
}
.Btn--secondary:hover {
  background: #f1f1f1 !important;
  color: #262e39;
}
