/* .tooltip{
    content: " ";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    width: 180px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
} */

.dot {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  margin: 4px 10px 4px 0px;
  background-color: #5769d6;
  text-align: start;
}
.marker {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 5px;
  height: 5px;
  margin: 10px;
  padding: 4px 6px;
  background-color: #5769d6;
  text-align: start;
  white-space: nowrap;
}

.series {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: 50px 0px; */
  white-space: nowrap;
  /* overflow: hidden; */
  font-size: 10px;
  font-weight: 700;
  margin: 0 60px 0 0;
  text-align: start;
}

.category {
  font-size: 12px;
  font-weight: 700;
  margin-left: 2px;
}

.bar_tooltip {
  padding: 10px;
  /* width: 25%; */
}
.tooltip {
  position: relative;
  background: #000000;
  color: #fff;
  border: 2px solid #000000;
  padding: 6px;
}
.tooltip:after,
.tooltip:before {
  right: 100%;
  bottom: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.tooltip:after {
  border-color: rgba(85, 85, 85, 0);
  border-right-color: #555;
  border-width: 10px;
  margin-top: -10px;
}
.tooltip:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000000;
  border-width: 13px;
  margin-top: -13px;
}

#chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
}

#chart .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
}

.label {
  white-space: nowrap;
  text-align: end;
  margin: 0 10px 0 10px;
}

.data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px 0px;
}
.data_label {
  font-size: 10px !important;
  font-weight: 700;
  margin: 0 2px 0 10px;
  white-space: nowrap;
}

.line_series {
  margin: 0 10px !important;
}
