.atd-modal-container {
  border: none;
  background: rgb(255, 255, 255);
  /* overflow: auto; */
  border-radius: 4px;
  outline: none;
  padding: 20px;
  position: fixed;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* overflow: hidden; */
  border-radius: 4px;
  padding: 0;
  /* width: 600px; */
  background: #fff;
  /* box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%), 0 3px 6px 0 rgb(0 0 0 / 12%); */
}

.atd-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 70%) !important;
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.atd-modal-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 6px;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

button.atd-modal-close {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: 0;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  color: #fff;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  overflow: hidden !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: scale(1);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: scale(0.8);
}
.dialog_preview {
  margin: auto;
  width: 100% !important;
  background: transparent;
  box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%), 0 3px 6px 0 rgb(0 0 0 / 12%);
  text-align: center;
}

.pop-title-preview {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 16px;
  background: transparent;
}
