.dropdown-menu .datepicker-container {
  display: flex;
  padding: 2px 7px;
}
.dropdown-menu .datepicker-container .datepicker-input {
  width: 120px;
  margin: 0 5px 0 0;
}
.dropdown-menu .datepicker-container .timepicker-input {
  width: 120px;
  margin: 0;
}

.btn-group > .btn-mini,
.btn-mini {
  font-size: 14px !important;
  padding: 7px 11px;
  border-radius: 12px;
  font-weight: 500 !important;
}

.Btn--mini {
  font-size: 12px !important;
  padding: 7px 11px !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  width: 125px !important;
}

.snooze-btn-group .quick-picks {
  zoom: 1;
}
.snooze-btn-group .quick-picks:before,
.snooze-btn-group .quick-picks:after {
  display: table;
  content: "";
  line-height: 0;
}
.snooze-btn-group .quick-picks:after {
  clear: both;
}
.snooze-btn-group .quick-picks a {
  display: block;
  /* float: left; */
  width: 35px;

  border-radius: 4px;
  padding: 2px 10px 1px;
  box-sizing: border-box;
  margin: 1px;
  font-size: 11px;

  color: #262e39;
  line-height: 20px;
}
.snooze-btn-group .quick-picks a[data-value="1"] {
  clear: left;
  width: 277px;
}
.snooze-btn-group .quick-picks a:hover {
  text-decoration: none;
  background: var(--primary-color);
  color: #fff;
}
.dropdown-menu .quick-picks ul li a:focus,
.dropdown-menu .quick-picks ul li a:active {
  text-decoration: none;
  background: #d4d4d4;
}
.dropdown-menu .quick-picks ul li a:not(.active):hover {
  text-decoration: none;
  background: var(--primary-color);
  color: white;
}
.dropdown-menu .quick-picks ul li a.disabled {
  pointer-events: none;
  background: transparent;
  text-decoration: line-through;
}
.send-later-btn-group .dropdown-menu .quick-picks ul li a.active {
  cursor: default;
  text-decoration: none;
  background: #d4d4d4;
}
.dropdown-menu .quick-picks .dates {
  margin-right: 5px;
}
.dropdown {
  width: unset !important;
}
.dropdown-menu.show {
  background: #f7f4f4 !important;
}
.custom-dropdown-menu {
  transform: translate3d(0px, -445px, 0px) !important;
}
.dropdown ul li {
  padding: 5px 6px !important;
}

.if-no-reply.quick-picks {
  zoom: 1;
}
.if-no-reply .quick-picks:before,
.if-no-reply .quick-picks:after {
  display: table;
  content: "";
  line-height: 0;
}

.if-no-reply .quick-picks:after {
  clear: both;
}
.if-no-reply .quick-picks a {
  display: block;
  /* float: left; */
  width: 35px;

  border-radius: 4px;
  padding: 2px 10px 1px;
  box-sizing: border-box;
  margin: 1px;
  font-size: 11px;

  color: #262e39;
  line-height: 20px;
}
.if-no-reply .quick-picks a[data-value="1"] {
  clear: left;
  width: 263px;
}
.if-no-reply .quick-picks a:hover {
  text-decoration: none;
  background: var(--primary-color);
  color: #fff;
}
.if-no-reply .quick-picks ul li a:focus,
.if-no-reply .quick-picks ul li a:active {
  text-decoration: none;
  background: #d4d4d4;
}
.if-no-reply.quick-picks ul li a:not(.active):hover {
  text-decoration: none;
  background: var(--primary-color);
  color: white;
}
.if-no-reply.quick-picks ul li a.disabled {
  pointer-events: none;
  background: transparent;
  text-decoration: line-through;
}
.if-no-reply .quick-picks ul li a.active {
  cursor: default;
  text-decoration: none;
  background: #d4d4d4;
}
.if-no-reply .quick-picks .dates {
  margin-right: 5px;
}
.if-no-reply .quick-picks .clear-reminder a {
  display: unset;
  /* float: left; */
  width: 35px;

  border-radius: 4px;
  padding: 9px 10px 4px;
  box-sizing: border-box;
  margin: 1px;
  font-size: 12px;

  color: #262e39;
  line-height: 20px;
}

.if-no-reply .quick-picks .clear-reminder a:hover {
  background-color: unset;
}
.if-no-reply .quick-picks .clear-reminder a span:hover {
  cursor: pointer;
  color: #4681b6;
}
.if-no-reply .quick-picks .clear-reminder a span {
  color: #0065ff;
}
.clear-reminder {
  padding: 4px 0px 0px 0px;
}
