.visual_bell--messageContainer {
  background: #222;
  transition: transform 0.1s ease-out;
  transition-property: transform, opacity;
  transform: translate3d(0, 3px, 0);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  max-width: max-content;
  margin: 0 50px;
  pointer-events: none;
  animation: slideUp 0.3s ease-out; /* Apply the animation */
}
.visual_bell--messageBase {
  padding: 7px 18px;
  font-size: 16px;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.exit-annotation-page {
  border-radius: 4px !important;
}
