/*
* Bootstrap v2.0.4
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */

/* Allow for input prepend/append in search forms */

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1060;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  /* *border-right-width: 2px;
   *border-bottom-width: 2px; */
  border-radius: 4px;
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); */
  background-clip: padding-box;
  box-shadow: 0 0 0 1px rgb(136 152 170 / 10%),
    0 15px 35px 0 rgb(49 49 93 / 10%), 0 5px 15px 0 rgb(0 0 0 / 8%);
}

.dropdown-menu.show {
  display: block;
}
