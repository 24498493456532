.wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.prefix {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  padding: 8px 7px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 1.14285714;
  position: relative;
  z-index: 1;
}

input.input {
  flex: 1 1 auto;
  min-width: 0;
  border: none;
  background: transparent !important;
  /* box-shadow: inset 0 1px 1px 0 rgb(0 0 0 / 8%); */
  box-shadow: none !important;
  height: 32px;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 0;
  padding: 8px 7px 8px 0 !important;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

:global(body) input.input {
  border: 0 !important;
}

input.input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

:global(body) input.input:focus {
  box-shadow: none !important;
}

input.input:focus + .fakeInput {
  border-color: var(--primary-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 3px rgba(155, 191, 219, 0.6);
}

input.input:focus:invalid + .fakeInput {
  color: #262e39;
  border: 1px solid rgba(82, 168, 236, 0.8);
}

.fakeInput {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: #262e39;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border linear 0.2s, box-shadow linear 0.2s;
}

.fakeInputDisabled {
  background-color: #eee;
}
